import { Button, SearchBarDebounce, VirtualizedTable } from '@/components';
import { useTable } from '@/hooks/table';
import { selectAuthUser } from '@/store/auth/auth.selector';
import { FC, Fragment, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useCompaniesTableColumns, { CompaniesTableColumns } from './useCompaniesTableColumns';
import NewCompanyModal from '../NewCompanyModal';
import { useToggle } from '@/hooks/toggle';
import { FilterFnOption, Row } from '@tanstack/react-table';
import { getCompanyCredits } from '@/utils/company';
import { round } from '@/utils/numbers';

interface CompaniesTableProps {
  companies: Array<Company>;
}
const companiesGlobalFilter = (row: Row<CompaniesTableColumns>, _columnId: keyof CompaniesTableColumns, filterValue: string) => {
  return (
    row.original.name.toLowerCase().trim().includes(filterValue.trim().toLowerCase()) ||
    row.original.created_at.trim().includes(filterValue.trim()) ||
    row.original.credits.trim().includes(filterValue.trim()) ||
    row.original.num_users.toString().trim().includes(filterValue.trim())
  );
};

const CompaniesTable: FC<CompaniesTableProps> = ({ companies }) => {
  const navigate = useNavigate();
  const user = useSelector(selectAuthUser);
  const formattedCompanies = useMemo(() => {
    return companies.map((company) => {
      return {
        id: company.id,
        name: company.name,
        num_users: company.users.length,
        created_at: new Date(company.created_at).toLocaleDateString(),
        credits: round(Number(getCompanyCredits(company)) || 0, 2).toString(),
      };
    });
  }, [companies]);

  const { value: isNewCompanyModalOpen, toggle: toggleNewCompanyModal } = useToggle();
  const [globalFilter, setGlobalFilter] = useState('');
  const columns = useCompaniesTableColumns({
    editAction: (id, name) => navigate(`edit-company/${id}?name=${name}`),
    deleteAction: (id) => navigate(`delete-company/${id}`),
  });

  const [table] = useTable({
    data: formattedCompanies,
    columns,
    tableCustomOptions: ['allowSort', 'allowFilters'],
    tableOptions: {
      onGlobalFilterChange: setGlobalFilter,
      globalFilterFn: companiesGlobalFilter as FilterFnOption<CompaniesTableColumns>,
      state: {
        globalFilter,
      },
    },
  });

  return (
    <Fragment>
      <div className='flex w-full items-center justify-between pb-4'>
        <SearchBarDebounce
          onChange={(val) => {
            setGlobalFilter(val);
          }}
          delay={500}
          inputClassName='w-[25.75rem] h-10'
        />
        {user && (user.isSuperuser || (user.company && user.company.role === 'owner')) && (
          <Button id='tutorial-new-user' onClick={() => toggleNewCompanyModal(true)} className='whitespace-nowrap '>
            New Company
          </Button>
        )}
      </div>
      <div className='h-[95%] rounded-leap bg-white p-4'>
        <VirtualizedTable table={table} name='companies' containerClass='max-h-full' />
      </div>
      {isNewCompanyModalOpen && (
        <NewCompanyModal
          isOpen={isNewCompanyModalOpen}
          onClose={() => {
            toggleNewCompanyModal(false);
            navigate('/user-management/companies');
          }}
        />
      )}
    </Fragment>
  );
};

export default CompaniesTable;

import { AlertIcon, LinkIcon, TaskIcon, UserIcon } from '@/components/Icons';

import { useMarkNotificationAsReadMutation } from '@/store/users/users.api';
import cn from '@/utils/style';
import { FC } from 'react';
import UnreadMark from './UnreadMark';

const ICON_TYPE = {
  task: TaskIcon,
  user: UserIcon,
  link: LinkIcon,
  alert: AlertIcon,
};

const NotificationIcon: FC<{ notification: LeapNotification; className: string }> = ({ notification, className }) => {
  const [markAsRead] = useMarkNotificationAsReadMutation();
  const isRead = notification.read_at;

  const handleDismiss = async () => {
    if (isRead) {
      return false;
    }
    await markAsRead({ notification }).unwrap();
  };
  const Icon = ICON_TYPE[notification.data.icon || 'alert'];

  return (
    <div className={cn('relative m-2', !isRead && 'cursor-pointer')} onClick={handleDismiss}>
      {!isRead && <UnreadMark />}
      <Icon className={cn(className)} />
    </div>
  );
};

export default NotificationIcon;

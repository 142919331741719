import { Button, Tooltip } from '@/components';
import cn from '@/utils/style';
import { FilterFnOption, createColumnHelper } from '@tanstack/react-table';

const columnHelper = createColumnHelper<ScenarioUrl>();
type TableMeta = {
  handleAllocation: (url: ScenarioUrl) => void;
};

const priorityKeywordsColumns = () => {
  const stringFilter = 'stringFilter' as FilterFnOption<ScenarioUrl>;
  const numberFilter = 'numberFilter' as FilterFnOption<ScenarioUrl>;

  return [
    columnHelper.accessor('links_to_build', {
      header: () => <p className='text-right'># of Links</p>,
      cell: ({ getValue }) => <p className='text-right'>{getValue().toLocaleString()}</p>,
      filterFn: numberFilter,
      size: 70,
    }),
    columnHelper.accessor('target_page', {
      header: () => <p className='text-left'>Target URL</p>,
      cell: ({ getValue, row }) => (
        <Tooltip id={`${row.id}-target-page`} content={<p className='max-w-lg'>{getValue()}</p>} place='top'>
          <p className='max-w-4xl overflow-hidden text-ellipsis whitespace-nowrap text-left'>{getValue()}</p>
        </Tooltip>
      ),
      filterFn: stringFilter,
    }),
    columnHelper.accessor('total_volume', {
      header: () => <p className='text-right'>Volume</p>,
      cell: ({ getValue }) => <p className='text-right'>{getValue().toLocaleString()}</p>,
      filterFn: numberFilter,
    }),
    columnHelper.accessor('lrd_gap', {
      header: () => <p className='text-right'>Gap</p>,
      cell: ({ getValue }) => <p className='text-right'>{getValue() > 0 ? Math.ceil(getValue()).toLocaleString() : '-'}</p>,
      filterFn: numberFilter,
    }),
    columnHelper.display({
      id: 'keyword-selection',
      cell: ({ row, table }) => {
        const isAllocated = row.original.is_allocated;
        const { handleAllocation } = table.options.meta as TableMeta;

        return (
          <Button variant='secondary' onClick={() => handleAllocation(row.original)} className={cn(isAllocated && 'bg-green-500 hover:bg-green-600 active:bg-green-700')}>
            <p className={cn(isAllocated && 'text-white')}>{isAllocated ? 'Allocated' : 'Allocate'}</p>
          </Button>
        );
      },
      enableSorting: false,
    }),
  ];
};

export default priorityKeywordsColumns;

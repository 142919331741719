import { MAP_FIELD_TO_TITLE } from '@/utils/campaigns';
import { MinusCircleIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useField } from 'formik';
import { useMemo } from 'react';
import SelectField from '../SelectField';
import NumberInputField from '../NumberInputField';
import TextInputField from '../TextInputField';
import BetweenInputField from '../BetweenInputField';

interface ColumnFiltersFieldProps<T> {
  name: string;
  options: Partial<ColumnFilters<T>>;
}

const ColumnFiltersField = <T extends Record<string, unknown>>({ name, options }: ColumnFiltersFieldProps<T>) => {
  const [field, , { setValue }] = useField<Array<ColumnFilterValue>>(name);

  const fieldsOptions = useMemo(
    () =>
      Object.keys(options).map((opt) => ({
        label: MAP_FIELD_TO_TITLE[opt as EveryFilter] || '',
        value: opt,
      })),
    [options],
  );

  const handleAddFilter = () => {
    const filters = field.value;
    const lastFilter = filters.at(-1);

    if (filters.length === 0 || (lastFilter?.field.trim().length && lastFilter?.filter.trim().length && lastFilter?.value.trim().length)) {
      setValue([...filters, { field: 'name', filter: 'exact', value: '' }]);
    }
  };

  const renderFilters = (_: ColumnFilterValue, index: number) => {
    const fieldName = field.value.at(index)?.field || 'name';

    const handleRemove = () => {
      const newFilters = [...field.value].filter((_, idx) => idx !== index);
      setValue(newFilters);
    };

    const renderFilterInput = () => {
      if (field.value.at(index)?.filter === 'between') {
        return <BetweenInputField name={`${name}[${index}].value`} />;
      }

      if (options[fieldName as keyof T]?.some((opt) => opt.value === 'gt')) {
        return <NumberInputField name={`${name}[${index}].value`} />;
      }

      return <TextInputField name={`${name}[${index}].value`} />;
    };

    return (
      <div className='flex items-center gap-2' key={index}>
        <div className='w-1/3'>
          <SelectField name={`${name}[${index}].field`} options={fieldsOptions} />
        </div>
        <div className='w-1/3'>
          <SelectField name={`${name}[${index}].filter`} options={options[fieldName as keyof T] || []} />
        </div>
        <div className='w-1/3'>{renderFilterInput()}</div>
        <MinusCircleIcon className='h-6 w-6 cursor-pointer text-black hover:text-red-500' onClick={handleRemove} />
      </div>
    );
  };

  return (
    <div>
      <div className='flex flex-col gap-y-2'>{field.value.map(renderFilters)}</div>
      <div className='mt-5 flex cursor-pointer items-center gap-2 text-blue-500' onClick={handleAddFilter}>
        <PlusIcon className='h-4 w-4' />
        <p>Add Filter</p>
      </div>
    </div>
  );
};

export default ColumnFiltersField;

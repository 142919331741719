import { PERCENTAGE_FIELDS } from '@/constants/defaultValues';
import { ColumnFiltersState, Row } from '@tanstack/react-table';

interface GenericFilter {
  field: string;
  filter: FiltersOptions;
  value: string | number;
}

enum FilterOptionsSymbol {
  gt = '>',
  lt = '<',
  exact = '=',
  icontains = ':',
  not_containing = '!',
  between = '&',
}

const FILTER_SYMBOLS: Array<FilterSymbol> = ['>', '<', '=', '!', ':', '&'];

const symbolMap: Partial<Record<FilterSymbol, FiltersOptions>> = {
  '>': 'gt',
  '<': 'lt',
  '=': 'exact',
  '!': 'not_containing',
  ':': 'icontains',
  '&': 'between',
};

const getColumnStateFromFilters = (filters: Array<GenericFilter>) => {
  return filters.reduce((acc: Array<{ id: string; value: Array<string | number> }>, filter) => {
    const existingFilter = acc.findIndex((item) => item.id === filter.field);

    if (existingFilter !== -1) {
      acc[existingFilter].value.push(`${FilterOptionsSymbol[filter.filter] || ''}${filter.value}`);
      return acc;
    }

    acc.push({
      id: filter.field,
      value: [`${FilterOptionsSymbol[filter.filter] || ''}${filter.value}`],
    });
    return acc;
  }, []);
};

const getFilterFromSymbol = (symbol: string) => {
  return symbolMap[symbol as FilterSymbol] || 'icontains';
};

const getSymbolFromFilter = (filter: FiltersOptions) => {
  const filterMap: Record<FiltersOptions, string | null> = {
    gt: '>',
    lt: '<',
    exact: '=',
    icontains: ':',
    not_containing: '!=', // this is different to show '!=' symbol on filter pills instead of '!'
    between: '&',
  };

  return filterMap[filter] || ':';
};

const getFiltersFromColumnState = (columnState: ColumnFiltersState) => {
  return columnState.flatMap((column) => {
    return ((column.value as Array<string>) || []).map((val) => {
      const hasSymbol = FILTER_SYMBOLS.includes(val.charAt(0) as FilterSymbol);
      const filter = getFilterFromSymbol(val.charAt(0));
      const cleanVal = hasSymbol ? val.slice(1) : val;

      return { field: column.id, filter: filter, value: cleanVal };
    });
  });
};

const getCellVal = <T>(original: T, columnId: keyof T) => {
  const isPercentageField = PERCENTAGE_FIELDS.includes(columnId as string);

  if (columnId.toString().includes('.')) {
    const [obj, key] = columnId.toString().split('.');
    const typedObj = original[obj as keyof T] as Record<string, unknown>;

    const cellVal = key in typedObj ? typedObj[key] : '';

    if (cellVal && isPercentageField) {
      return Number(cellVal) * 100;
    }

    return cellVal;
  }

  const cellVal = original[columnId];

  if (cellVal && isPercentageField) {
    return Number(cellVal) * 100;
  }

  return cellVal;
};

const stringFilter = <T>(row: Row<T>, columnId: keyof T, filterValue: Array<string>) => {
  if (filterValue.length === 0) {
    return true;
  }

  const cellVal = getCellVal(row.original, columnId) as string;

  return (filterValue || []).some((val) => {
    const cleanVal = val.slice(1);

    if (val.startsWith(FilterOptionsSymbol.not_containing)) {
      return !cellVal.includes(cleanVal);
    }

    if (val.startsWith(FilterOptionsSymbol.exact)) {
      return cellVal === cleanVal;
    }

    return cellVal.includes(cleanVal);
  });
};

const arrayStrFilter = <T>(row: Row<T>, columnId: keyof T, filterValue: Array<string>) => {
  if (filterValue.length === 0) {
    return true;
  }

  const cellVal = getCellVal(row.original, columnId) as Array<string>;

  return (filterValue || []).some((val) => {
    const cleanVal = val.slice(1);

    if (val.startsWith(FilterOptionsSymbol.not_containing)) {
      return cellVal.every((item) => !item.includes(cleanVal));
    }

    if (val.startsWith(FilterOptionsSymbol.exact)) {
      return cellVal.some((item) => item === cleanVal);
    }

    return cellVal.some((item) => item.includes(cleanVal));
  });
};

const numberFilter = <T>(row: Row<T>, columnId: keyof T, filterValue: Array<string>) => {
  if (filterValue.length === 0) {
    return true;
  }

  const cellVal = getCellVal(row.original, columnId) as number;

  return (filterValue || []).some((val) => {
    const cleanVal = val.slice(1);

    if (val.startsWith(FilterOptionsSymbol.between)) {
      const [min, max] = cleanVal.split('-');
      return cellVal >= Number(min) && cellVal <= Number(max);
    }

    if (val.startsWith(FilterOptionsSymbol.gt)) {
      return cellVal > Number(cleanVal);
    }

    if (val.startsWith(FilterOptionsSymbol.lt)) {
      return cellVal < Number(cleanVal);
    }

    return Number(cleanVal) === cellVal;
  });
};

export { getColumnStateFromFilters, getFiltersFromColumnState, stringFilter, numberFilter, getSymbolFromFilter, arrayStrFilter };

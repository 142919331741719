const QuestionMarkIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7.66885 7.04C6.86885 6.7 6.52885 5.71 7.00885 4.99C7.97885 3.55 9.59884 2.5 11.7388 2.5C14.0888 2.5 15.6988 3.57 16.5188 4.91C17.2188 6.06 17.6288 8.21 16.5488 9.81C15.3488 11.58 14.1988 12.12 13.5788 13.26C13.4288 13.53 13.3388 13.75 13.2788 14.2C13.1888 14.93 12.5888 15.5 11.8488 15.5C10.9788 15.5 10.2688 14.75 10.3688 13.88C10.4288 13.37 10.5488 12.84 10.8288 12.34C11.5988 10.95 13.0788 10.13 13.9388 8.9C14.8488 7.61 14.3388 5.2 11.7588 5.2C10.5888 5.2 9.82884 5.81 9.35884 6.54C9.00884 7.11 8.27885 7.29 7.66885 7.04ZM13.7488 19.5C13.7488 20.6 12.8488 21.5 11.7488 21.5C10.6488 21.5 9.74885 20.6 9.74885 19.5C9.74885 18.4 10.6488 17.5 11.7488 17.5C12.8488 17.5 13.7488 18.4 13.7488 19.5Z'
      fill='#94A3B8'
    />
  </svg>
);

export default QuestionMarkIcon;

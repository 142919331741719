import { FiltersModal, FiltersPills, Modal, ModalProps, PriorityLegend, SearchBarDebounce, VirtualizedTable } from '@/components';
import { NUMERIC_FILTERS_OPTIONS, STRING_FILTERS_OPTIONS } from '@/constants/defaultValues';
import useKeywordContext from '@/hooks/keywords';
import { useTable } from '@/hooks/table';
import { useGetRefineKeywordsQuery, useUpdateRefineKeywordsMutation } from '@/store/campaign/campaign.api';
import { setIsDirty } from '@/store/campaign/campaign.slice';
import { AppDispatch } from '@/store/store';
import { getColumnStateFromFilters, getFiltersFromColumnState } from '@/utils/filters';
import { FunnelIcon } from '@heroicons/react/24/solid';
import { ColumnFiltersState, FilterFnOption, Row } from '@tanstack/react-table';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useKeywordsColumns from './useKeywordsColumns';
import { selectTablesConfig } from '@/store/app/app.selector';
import { useScreenSize } from '@/hooks/screenSize';

interface KeywordsModalProps extends Omit<ModalProps, 'onConfirm'> {
  onConfirm: (message: string, error?: boolean) => void;
  readonly?: boolean;
}

const COLUMN_FILTERS: Partial<RefineKeywordsColumnFilters> = {
  text: STRING_FILTERS_OPTIONS,
  target_page: STRING_FILTERS_OPTIONS,
  campaign_rank: NUMERIC_FILTERS_OPTIONS,
  search_volume: NUMERIC_FILTERS_OPTIONS,
  difficulty: NUMERIC_FILTERS_OPTIONS,
  rootDomainDiff: NUMERIC_FILTERS_OPTIONS,
};

const keywordsGlobalFilter = (row: Row<RefineCampaignKeyword>, _columnId: keyof RefineCampaignKeyword, filterValue: string) => {
  const { text, target_page } = row.original;
  return text.includes(filterValue) || target_page.includes(filterValue);
};

const KeywordsModal: FC<KeywordsModalProps> = ({ isOpen, onClose, onConfirm, readonly = false }) => {
  const { campaignId } = useParams() as { campaignId: string };
  const dispatch = useDispatch<AppDispatch>();
  const { priorities } = useKeywordContext();
  const tableConfig = useSelector(selectTablesConfig);

  const { data: refineKws, isLoading: isLoadingKeywords } = useGetRefineKeywordsQuery({ campaignId });
  const [refine, { isLoading: isRefining }] = useUpdateRefineKeywordsMutation();

  const [columnFiltersState, setColumnFiltersState] = useState<ColumnFiltersState>([]);

  const [globalFilter, setGlobalFilter] = useState('');
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);

  const isLoading = useMemo(() => isLoadingKeywords || isRefining, [isLoadingKeywords, isRefining]);
  const filtersFromColumnState = useMemo(() => getFiltersFromColumnState(columnFiltersState) as Array<AnalysisFilterValue>, [columnFiltersState]);

  const { width } = useScreenSize();

  const keywordsColumns = useKeywordsColumns(readonly);
  const [table, rows] = useTable({
    data: refineKws?.results || [],
    columns: keywordsColumns,
    tableCustomOptions: ['allowResize', 'allowSort', 'allowFilters', 'allowSelect'],
    defaultSorting: tableConfig['keywords'].sorting || [{ id: 'totalVolume', desc: true }],
    tableOptions: {
      meta: {
        size: (width - 165) / keywordsColumns.length,
      },
      onGlobalFilterChange: setGlobalFilter,
      onColumnFiltersChange: setColumnFiltersState,
      globalFilterFn: keywordsGlobalFilter as FilterFnOption<RefineCampaignKeyword>,
      state: {
        globalFilter: globalFilter,
        columnFilters: columnFiltersState,
      },
    },
  });

  const getKeywordsForRefinement = useCallback(() => {
    return rows.map((row) => {
      const priority = priorities.find((i) => i.id === row.original.id);

      return {
        id: row.original.id,
        text: row.original.text,
        excluded: !row.getIsSelected(),
        text_hash: row.original.text_hash,
        priority: priority === undefined ? row.original.priority : priority.value,
      };
    });
  }, [rows, priorities]);

  const handleRefine = useCallback(async () => {
    try {
      const data = await refine({ campaignId, keywords: getKeywordsForRefinement() }).unwrap();

      dispatch(setIsDirty({ step: 'analysis', isDirty: true }));
      data && onConfirm(data.message);
    } catch (error) {
      const typedError = error as APIError;
      onConfirm(typedError.data.message, true);
    }
  }, [campaignId, onConfirm, refine, getKeywordsForRefinement, dispatch]);

  useEffect(() => {
    rows.forEach((row) => {
      const campaignKw = (refineKws?.results || []).find((k) => k.id === row.original.id);

      if (campaignKw) {
        row.toggleSelected(!row.original.excluded);
      }
    });
  }, [refineKws?.results]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleConfirm = useCallback((values: AnalysisFiltersFormValues) => {
    setColumnFiltersState(getColumnStateFromFilters(values.filters));
    setIsFiltersModalOpen(false);
  }, []);

  const handleFilterRemove = useCallback((filter: AnalysisFilterValue) => {
    setColumnFiltersState((oldState) => oldState.filter((i) => i.id !== filter.field));
  }, []);

  return (
    <Modal isOpen={isOpen} title='Keywords' onClose={onClose} onConfirm={handleRefine} className='w-full max-w-modal-2xl' footerClassName='w-fit ml-auto'>
      <div className='mb-4 flex w-full items-center gap-4'>
        <div className='flex w-full items-center gap-2'>
          <SearchBarDebounce onChange={(value) => setGlobalFilter(value)} inputClassName='w-full h-10' className='w-full' disabled={isLoading} />
          <button onClick={() => setIsFiltersModalOpen(true)} className='rounded-lg border p-2' disabled={isLoading}>
            <FunnelIcon className='w-5' />
          </button>
        </div>
      </div>
      <FiltersPills filters={filtersFromColumnState} onFilterRemove={handleFilterRemove} />
      <VirtualizedTable table={table} name='keywords' isLoading={isLoading} loadingText='Loading keywords...' className='w-full' itemHeight={39.5} allowResize />
      <PriorityLegend />
      {isFiltersModalOpen && (
        <FiltersModal
          isOpen={isFiltersModalOpen}
          onClose={() => setIsFiltersModalOpen(false)}
          columnFilters={COLUMN_FILTERS}
          initialValue={filtersFromColumnState}
          onConfirm={handleConfirm}
        />
      )}
    </Modal>
  );
};

export default KeywordsModal;

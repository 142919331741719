import { Button, Input, Modal, ModalProps, TokenIcon } from '@/components';
import { round } from '@/utils/numbers';
import { FC, Fragment, useState } from 'react';

const CREDIT_VALUE = 10;

interface PurchaseModalProps extends ModalProps {
  onBuy: (amount: number) => void;
}

const PurchaseModal: FC<PurchaseModalProps> = ({ isOpen, onBuy, onClose, isLoading }) => {
  const [credits, setCredits] = useState('');
  const [amount, setAmount] = useState('');

  const handleQuantityChange = (newCredits: string) => {
    if (isNaN(Number(newCredits))) {
      return;
    }

    setCredits(newCredits);
    setAmount(round(Number(newCredits) * CREDIT_VALUE, 2).toString());
  };

  const handleAmountChange = (newAmount: string) => {
    if (isNaN(Number(newAmount))) {
      return;
    }

    setAmount(newAmount);
    setCredits(round(Number(newAmount) / CREDIT_VALUE, 0).toString());
  };

  const handleQuickCredits = (newCredits: number) => {
    setCredits(newCredits.toString());
    setAmount(round(newCredits * CREDIT_VALUE, 2).toString());
  };

  return (
    <Modal isOpen={isOpen} title='Additional Credits' onClose={onClose}>
      <Fragment>
        <div className=' flex flex-col gap-4'>
          <section className='flex items-center gap-3'>
            <Input onChange={handleQuantityChange} value={credits} suffix={<TokenIcon className='w-5 text-slate-400' />} placeholder='Enter quantity' />
            <div className='h-px w-4 bg-slate-400'></div>
            <Input onChange={handleAmountChange} value={amount} suffix='in $' placeholder='Enter Amount' />
          </section>

          <section className='flex items-center justify-center gap-3'>
            <Button variant='outline-light' className='flex w-full items-center justify-center gap-2' onClick={() => handleQuickCredits(10)}>
              <TokenIcon className='w-5 text-blue-500' />
              <p>10</p>
            </Button>
            <Button variant='outline-light' className='flex w-full items-center justify-center gap-2' onClick={() => handleQuickCredits(20)}>
              <TokenIcon className='w-5 text-blue-500' />
              <p>20</p>
            </Button>
            <Button variant='outline-light' className='flex w-full items-center justify-center gap-2' onClick={() => handleQuickCredits(30)}>
              <TokenIcon className='w-5 text-blue-500' />
              <p>30</p>
            </Button>
          </section>
        </div>

        <Button onClick={() => onBuy(Number(amount))} className='mt-6 w-full' isLoading={isLoading}>
          Buy
        </Button>
      </Fragment>
    </Modal>
  );
};

export default PurchaseModal;

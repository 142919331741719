import { Form, FormikProvider, useFormik } from 'formik';
import ColumnFiltersField from '../ColumnFiltersField';
import Modal, { ModalProps } from '../Modal';
import * as yup from 'yup';
import { NUMERIC_FILTERS, STRING_FILTERS } from '@/constants/defaultValues';

interface FiltersModalProps<T, K> extends Omit<ModalProps, 'onConfirm'> {
  filters?: [];
  initialValue: T;
  onConfirm: (values: { filters: T }) => void;
  columnFilters: K;
}

const filtersOptionsScheme: yup.SchemaOf<EveryFilterValue> = yup.object({
  field: yup.mixed().required('field is required'),
  filter: yup
    .mixed()
    .oneOf([...NUMERIC_FILTERS, ...STRING_FILTERS])
    .required('filter is required'),
  value: yup.string().required('value is required'),
});

const filtersValidationScheme: yup.SchemaOf<EveryFiltersFormValues> = yup.object({
  filters: yup.array().of(filtersOptionsScheme),
});

const FiltersModal = <T extends Array<unknown>, K extends Partial<ColumnFilters<K>>>({ isOpen, onClose, initialValue, onConfirm, columnFilters }: FiltersModalProps<T, K>) => {
  const formik = useFormik<{ filters: T }>({
    onSubmit: onConfirm,
    initialValues: {
      filters: initialValue,
    },
    validationSchema: filtersValidationScheme,
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} onConfirm={formik.handleSubmit}>
      <div className='w-[700px]'>
        <FormikProvider value={formik}>
          <Form>
            <ColumnFiltersField name='filters' options={columnFilters} />
          </Form>
        </FormikProvider>
      </div>
    </Modal>
  );
};

export default FiltersModal;

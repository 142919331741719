import { Skeleton } from '@/components';
import { FC, PropsWithChildren } from 'react';

const PageSkeleton: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className='relative flex max-h-full w-full max-w-leap-screen flex-col'>
      <Skeleton className='w-full py-10' />

      <section className='mx-auto w-full max-w-leap-screen flex-1 bg-base-leap py-7 md:p-7'>{children}</section>

      <Skeleton className='w-full py-10'></Skeleton>
    </div>
  );
};

export default PageSkeleton;

import { Button, ModalProps, ProSubIcon, Skeleton, TokenIcon } from '@/components';
import { useToggle } from '@/hooks/toggle';
import { useCreateSubscriptionMutation, useGetLinkLaunchProductPricesQuery, usePayInvoiceMutation, useUpdateSubscriptionMutation } from '@/store/payment/payment.api';
import { getCurrencyFormat } from '@/utils/numbers';
import cn from '@/utils/style';
import { Dialog, Transition } from '@headlessui/react';
import { EyeIcon, StarIcon } from '@heroicons/react/24/solid';
import { FC, Fragment, PropsWithChildren, useCallback, useState } from 'react';
import PaymentMethodModal from '../PaymentMethodModal';

interface SubscriptionModalProps extends ModalProps {
  activeSubscriptionPrice?: Price;
  activeSubscription?: Subscription;
}

const SUBSCRIPTION_TIER_ICON_MAP: Record<LookupKeys, (props: React.ComponentProps<'svg'>) => JSX.Element> = {
  light: (props: React.ComponentProps<'svg'>) => <EyeIcon {...props} />,
  basic: (props: React.ComponentProps<'svg'>) => <StarIcon {...props} />,
  pro: (props: React.ComponentProps<'svg'>) => <ProSubIcon {...props} />,
};

interface CardHeaderProps extends PropsWithChildren {
  className?: string;
}

const CardHeader: FC<CardHeaderProps> = ({ className = 'text-white', children }) => {
  return (
    <div className='relative m-auto flex w-full justify-center'>
      <div className='relative flex'>
        <svg viewBox='0 0 15 32' fill='currentColor' xmlns='http://www.w3.org/2000/svg' className={cn('absolute -left-4 h-9 border-0', className)}>
          <path
            d='M8.50049 1.9882C10.4892 -0.000524759 12.6235 -0.0181859 13.4966 -0.000463348C14.0005 0.00976562 14.5 4.03929e-05 14.5 4.03929e-05V31.9882L0.500488 32.0001C0.500488 32.0001 3.14829 31.3523 4.50049 30.0001C5.85269 28.648 6.35583 27.7406 6.50065 26.0001C6.53303 25.611 6.50049 25.0001 6.50049 25.0001V7.98744C6.50049 7.98744 6.50049 7.4882 6.50049 6.9882C6.50049 6.11499 6.50049 3.9882 8.50049 1.9882Z'
            fill='currentColor'
          />
        </svg>
        {children}
        <svg viewBox='0 0 15 32' fill='currentColor' xmlns='http://www.w3.org/2000/svg' className={cn('absolute -right-4 h-9 border-0', className)}>
          <path
            d='M6.49951 1.9882C4.51079 -0.000524759 2.37648 -0.0181859 1.50342 -0.000463348C0.999512 0.00976562 0.5 4.03929e-05 0.5 4.03929e-05V31.9882L14.4995 32.0001C14.4995 32.0001 11.8517 31.3523 10.4995 30.0001C9.14731 28.648 8.64417 27.7406 8.49935 26.0001C8.46697 25.611 8.49951 25.0001 8.49951 25.0001V7.98744C8.49951 7.98744 8.49951 7.4882 8.49951 6.9882C8.49951 6.11499 8.49951 3.9882 6.49951 1.9882Z'
            fill='currentColor'
          />
        </svg>
      </div>
    </div>
  );
};

const PriceCard = ({ onClick, item, activePrice }: { onClick?: () => void; item: Price; activePrice?: Price }) => {
  const isActive = activePrice?.id === item.id;
  const isDowngrade = activePrice ? activePrice.unit_amount > item.unit_amount : false;
  const isUpgrade = activePrice ? activePrice.unit_amount < item.unit_amount : false;
  const TierIcon = SUBSCRIPTION_TIER_ICON_MAP[item.lookup_key];

  return (
    <section className='max-w-[25.5rem]'>
      <CardHeader className={cn('text-white', item.lookup_key === 'pro' && 'text-sky-600')}>
        <div className={cn('flex items-center gap-2 bg-white px-6 py-2', item.lookup_key === 'pro' && 'bg-sky-600 text-white', item.lookup_key === 'basic' && 'text-sky-600')}>
          <TierIcon className='w-5' />
          <p className={cn('m-auto w-fit font-semibold capitalize')}>{item.nickname}</p>
        </div>
      </CardHeader>

      <div className='rounded-xl bg-white p-6'>
        <div className='flex w-full items-center justify-center gap-2 py-9'>
          <TokenIcon className='w-14' />
          <p className='text-6xl font-bold'>
            {item.unit_amount}
            <span className='text-2xl'>/mo</span>
          </p>
        </div>
        <div className='flex flex-col items-center gap-3'>
          <p className='text-2xl font-bold'>{getCurrencyFormat(item.unit_amount)}</p>
          <p>{item.description}</p>
        </div>
        <Button onClick={onClick} className='mt-6 w-full' disabled={isActive} variant={isDowngrade ? 'outline-light' : 'primary'}>
          {isActive && 'Selected'}
          {isDowngrade && 'Downgrade'}
          {isUpgrade && 'Upgrade'}
          {!activePrice && 'Select'}
        </Button>
      </div>
    </section>
  );
};
// await updateSubscription({ id: activeSubscription.id, lookup_key: price.lookup_key }).unwrap();

const SubscriptionModal: FC<SubscriptionModalProps> = ({ isOpen, onClose, activeSubscriptionPrice, activeSubscription }) => {
  const { data: prices, isLoading, isFetching } = useGetLinkLaunchProductPricesQuery();
  const [updateSubscription, { isLoading: updatingSubscription }] = useUpdateSubscriptionMutation();
  const [subscribe, { isLoading: creatingSubscription }] = useCreateSubscriptionMutation();
  const [payInvoice, { isLoading: payingInvoice }] = usePayInvoiceMutation();

  const [invoice, setInvoice] = useState<Invoice | null>(null);
  const [selectedPrice, setSelectedPrice] = useState<Price>();

  const { value: showPaymentMethods, toggle: togglePaymenMethods } = useToggle();
  const ActiveSubscriptionIcon = SUBSCRIPTION_TIER_ICON_MAP[activeSubscriptionPrice?.lookup_key || 'light'];

  const handleSubscribe = useCallback(
    async (price: Price) => {
      togglePaymenMethods(true);
      if (!activeSubscription) {
        const newSubscriptionResponse = await subscribe({ lookup_key: price.lookup_key }).unwrap();
        setInvoice(newSubscriptionResponse.data.invoice);
      }

      setSelectedPrice(price);
    },
    [activeSubscription, subscribe, togglePaymenMethods, setInvoice],
  );

  const handleUpdateSubscription = useCallback(
    async (methodId: string) => {
      if (!selectedPrice || !activeSubscription) {
        return;
      }

      await updateSubscription({ id: activeSubscription.id, lookup_key: selectedPrice.lookup_key, payment_method_id: methodId });
    },
    [activeSubscription, selectedPrice, updateSubscription],
  );

  const handlePayInvoice = useCallback(
    async (methodId: string) => {
      if (!invoice) {
        return;
      }

      await payInvoice({ invoice_id: invoice.id, payment_method_id: methodId });
    },
    [invoice, payInvoice],
  );

  const handleSubmitPaymentMethod = useCallback(
    async (methodId: string) => {
      activeSubscription ? await handleUpdateSubscription(methodId) : await handlePayInvoice(methodId);
      togglePaymenMethods(false);
      onClose && onClose();
    },
    [activeSubscription, handleUpdateSubscription, handlePayInvoice, togglePaymenMethods, onClose],
  );

  const renderItems = (item: Price) => {
    const handlePriceClick = () => handleSubscribe(item);

    return <PriceCard key={item.id} item={item} onClick={handlePriceClick} activePrice={activeSubscriptionPrice} />;
  };

  return (
    <Fragment>
      {!showPaymentMethods && (
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog as='div' className='relative z-40' onClose={() => onClose && onClose()}>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className='fixed inset-0 bg-black bg-opacity-25' />
            </Transition.Child>
            <div className='fixed inset-0 overflow-y-auto'>
              <div className='flex h-screen justify-center p-4 text-center'>
                <Transition.Child
                  as={Fragment}
                  enter='ease-out duration-300'
                  enterFrom='opacity-0 scale-95'
                  enterTo='opacity-100 scale-100'
                  leave='ease-in duration-200'
                  leaveFrom='opacity-100 scale-100'
                  leaveTo='opacity-0 scale-95'
                >
                  <Dialog.Panel className='m-auto flex transform items-center gap-8 transition-all'>
                    {isLoading || isFetching ? (
                      <div className='flex items-center gap-8'>
                        <Skeleton className='h-96 w-96' />
                        <Skeleton className='h-96 w-96' />
                        <Skeleton className='h-96 w-96' />
                      </div>
                    ) : (
                      (prices?.results.filter((price) => price.nickname !== 'one-time') || []).map(renderItems)
                    )}
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}

      {showPaymentMethods && (
        <PaymentMethodModal
          isOpen={showPaymentMethods}
          onClose={() => togglePaymenMethods(false)}
          onMethodSelected={handleSubmitPaymentMethod}
          confirmButtonText='Buy'
          isLoading={creatingSubscription || payingInvoice || updatingSubscription}
        >
          <section className='mb-3'>
            <div className='mb-5 rounded-xl bg-slate-100 p-3'>
              <div className='flex items-center gap-2 '>
                <ActiveSubscriptionIcon className='w-5' />
                <p className='text-xl font-semibold  capitalize'>{selectedPrice?.nickname}</p>
              </div>

              <div className='my-2 h-0.5 w-full rounded-xl bg-white'></div>

              <div className='flex items-center justify-between'>
                <div className='flex items-center gap-2'>
                  <TokenIcon className='w-5' />
                  <p>{selectedPrice?.unit_amount}/mo</p>
                </div>
                <p className='font-semibold'>{getCurrencyFormat(selectedPrice?.unit_amount || 0)}</p>
              </div>
            </div>

            <p className='text-xl'>Select Payment Method</p>
          </section>
        </PaymentMethodModal>
      )}
    </Fragment>
  );
};

export default SubscriptionModal;

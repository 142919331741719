import { getScenarioTooltip, setScenarioTooltip } from '@/utils/localstorage';
import { useCallback, useMemo } from 'react';

const useTooltipConfig = (id: string) => {
  const hidden = useMemo(() => getScenarioTooltip(id), [id]);

  const hide = useCallback(() => {
    setScenarioTooltip({
      [id]: {
        hidden: true,
      },
    });
  }, [id]);

  return { hidden, hide };
};

export default useTooltipConfig;

const PersonSearchIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.2139 11.2041C12.423 11.2041 14.2139 9.41324 14.2139 7.2041C14.2139 4.99496 12.423 3.2041 10.2139 3.2041C8.00473 3.2041 6.21387 4.99496 6.21387 7.2041C6.21387 9.41324 8.00473 11.2041 10.2139 11.2041Z'
      fill='#94A3B8'
    />
    <path
      d='M10.5639 13.2141C7.83387 13.1141 2.21387 14.4741 2.21387 17.2041V18.2041C2.21387 18.7541 2.66387 19.2041 3.21387 19.2041H11.7539C9.28387 16.4441 10.5239 13.3141 10.5639 13.2141Z'
      fill='#94A3B8'
    />
    <path
      d='M19.6439 17.2241C20.1139 16.4241 20.3439 15.4541 20.1239 14.4041C19.7839 12.7641 18.4039 11.4541 16.7439 11.2441C14.1139 10.9041 11.8939 13.1141 12.2439 15.7441C12.4639 17.4041 13.7639 18.7841 15.4039 19.1241C16.4539 19.3441 17.4239 19.1141 18.2239 18.6441L20.0839 20.5041C20.4739 20.8941 21.1039 20.8941 21.4939 20.5041C21.8839 20.1141 21.8839 19.4841 21.4939 19.0941L19.6439 17.2241ZM16.2139 17.2041C15.1139 17.2041 14.2139 16.3041 14.2139 15.2041C14.2139 14.1041 15.1139 13.2041 16.2139 13.2041C17.3139 13.2041 18.2139 14.1041 18.2139 15.2041C18.2139 16.3041 17.3139 17.2041 16.2139 17.2041Z'
      fill='#94A3B8'
    />
  </svg>
);

export default PersonSearchIcon;

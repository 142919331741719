import { Button, ErrorMessage, SelectField, TextInputField, CombinationsField } from '@/components';
import { URLS_COUNT } from '@/constants/defaultValues';
import { selectCountryList } from '@/store/campaign/campaign.selector';
import { CombinationSchema } from '@/utils/combinations';
import { getCountryOptions } from '@/utils/countries';
import { Form, FormikProvider, useFormik } from 'formik';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

interface CombinationCampaignForm {
  onSubmit: (values: CombinationCampaignValues) => void;
  onCancel: () => void;
  isSubmitting: boolean;
  error?: APIError;
}

const combinationCampaignValidationSchema: yup.SchemaOf<CombinationCampaignValues> = yup.object({
  name: yup.string().required('Name is required'),
  client_domain: yup.string().required('Client domain is required'),
  url_count: yup.number().required(),
  country: yup.string().required(),
  keyword_count: yup.number().required(),
  combinations: yup.array().of(CombinationSchema).min(1, 'At least 1 Combination is required'),
  backlinks_profile: yup.bool().default(false),
});

const CombinationCampaignForm: FC<CombinationCampaignForm> = ({ onSubmit, onCancel, isSubmitting, error }) => {
  const countryList = useSelector(selectCountryList);
  const countriesOptions = useMemo(() => getCountryOptions(countryList), [countryList]);
  const formik = useFormik<CombinationCampaignValues>({
    onSubmit,
    initialValues: {
      name: '',
      client_domain: '',
      url_count: URLS_COUNT[0].value,
      country: countriesOptions[0].value,
      keyword_count: 10,
      combinations: [],
      backlinks_profile: true,
    },
    validationSchema: combinationCampaignValidationSchema,
  });
  const { values } = formik;

  return (
    <FormikProvider value={formik}>
      <Form>
        <div className='mt-2 flex flex-col gap-4 text-left'>
          <TextInputField name='name' placeholder='Campaign Name' label='Name' />
          <TextInputField name='client_domain' placeholder='http://www.client.com/' label='Client Domain' />
          <div className='flex'>
            <div className='w-1/2'>
              <SelectField className='my-2 w-64' optionsClassName='w-64' name='url_count' options={URLS_COUNT} label={`URLs: ${values.url_count}`} withMarker />
            </div>
            <div className='w-1/2'>
              <SelectField className='my-2 w-64' optionsClassName='w-64' name='country' options={countriesOptions} label='Country' withMarker />
            </div>
          </div>
          <CombinationsField name='combinations' />

          {error?.data.non_field_errors && <ErrorMessage className='mt-2'>{error.data.non_field_errors.join('. ')}</ErrorMessage>}

          <div className='my-2 flex justify-between gap-3'>
            <Button onClick={onCancel} isLoading={isSubmitting} variant='outline-light'>
              Cancel
            </Button>
            <Button type='submit' isLoading={isSubmitting}>
              Create Campaign
            </Button>
          </div>
        </div>
      </Form>
    </FormikProvider>
  );
};

export default CombinationCampaignForm;

import { AddScenarioIcon, ScaleIcon, Tooltip } from '@/components';
import { useToggle } from '@/hooks/toggle';
import { useCreateCampaignScenarioMutation, useGetCampaignConfigQuery, useSaveCampaignConfigMutation } from '@/store/campaign/campaign.api';
import cn from '@/utils/style';
import { ChevronUpIcon } from '@heroicons/react/24/solid';
import { FC, Fragment, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import AnalysisPageSkeleton from '../../AnalysisPageSkeleton';
import Scenario from './Scenario';
import { useSelector } from 'react-redux';
import { selectCampaign, selectScenarios } from '@/store/campaign/campaign.selector';
import CompareScenarioModal from '../CompareScenarioModal/CompareScenarioModal';

import usePersistTooltip from '@/hooks/localstorage';

const SCENARIO_TOOLTIP_ID = 'scenarios-expand';

const Scenarios: FC = () => {
  const { campaignId } = useParams() as { campaignId: string };
  const campaign = useSelector(selectCampaign);
  const scenarios = useSelector(selectScenarios);
  const { hide } = usePersistTooltip(SCENARIO_TOOLTIP_ID);

  const { data: campaignConfig } = useGetCampaignConfigQuery({ campaignId });
  const [saveCampaignConfig] = useSaveCampaignConfigMutation();
  const [createScenario] = useCreateCampaignScenarioMutation();

  const { value: isCompareScenarioModalOpen, toggle: toggleCompareScenarioModal } = useToggle();
  const [isExpanded, setIsExpanded] = useState(() => Boolean(campaignConfig?.data.is_scenario_panel_open));
  const isCampaignKickedOff = useMemo(() => campaign?.status === 'kicked_off', [campaign]);

  const handleAddNewScenario = useCallback(async () => {
    if (scenarios.length >= 3) return;

    await createScenario({ campaignId });
  }, [createScenario, campaignId, scenarios]);

  const handleToggle = useCallback(() => {
    saveCampaignConfig({ campaignId, config: { is_scenario_panel_open: !isExpanded } });
    setIsExpanded((old) => !old);
  }, [setIsExpanded, isExpanded, campaignId, saveCampaignConfig]);

  if (!scenarios.length) {
    return <AnalysisPageSkeleton />;
  }

  return (
    <Fragment>
      <div className='flex w-full gap-4 overflow-visible whitespace-nowrap py-4 xl:gap-6 xl:py-6'>
        {scenarios.map((scenario, idx) => (
          <Scenario key={`${scenario.id}-${idx}`} scenario={scenario as Scenario} isCollapsed={!isExpanded} readOnly={isCampaignKickedOff} />
        ))}

        {scenarios.length <= 2 && !isCampaignKickedOff && (
          <div
            className={cn(
              'flex w-72 shrink-0 items-center justify-center rounded-leap border-gray-300 bg-white p-4 shadow-lg',
              scenarios.length === 1 && 'cursor-pointer',
              !isExpanded && 'h-16 xl:h-20',
            )}
            onClick={scenarios.length === 1 ? handleAddNewScenario : undefined}
          >
            <button
              className={cn('flex cursor-pointer flex-col items-center justify-center gap-3', !isExpanded && 'flex-row')}
              onClick={scenarios.length === 2 ? handleAddNewScenario : undefined}
            >
              <AddScenarioIcon className={cn('w-10 text-sky-600', !isExpanded && 'w-7')} />
              <p className='font-bold'>Add Scenario</p>
            </button>
          </div>
        )}

        {scenarios.length >= 2 && (
          <div
            className={cn(
              'flex w-72 shrink-0 items-center justify-center rounded-leap border-gray-300 bg-white p-4 shadow-lg',
              scenarios.length === 1 && 'cursor-pointer',
              !isExpanded && 'h-16 xl:h-20',
            )}
            onClick={scenarios.length === 1 ? handleAddNewScenario : undefined}
          >
            <button className={cn('flex cursor-pointer flex-col items-center justify-center gap-3', !isExpanded && 'flex-row')} onClick={() => toggleCompareScenarioModal(true)}>
              <ScaleIcon className={cn('w-10 text-sky-600', !isExpanded && 'w-7')} />
              <p className='font-bold'>Review Selections</p>
            </button>
          </div>
        )}
        <div className='ml-auto'>
          <Tooltip id={SCENARIO_TOOLTIP_ID} content={<p>Click to Expand/Collapse Scenario Information</p>} showOnStart onClick={hide}>
            <ChevronUpIcon className={cn('w-7 cursor-pointer text-sky-500', !isExpanded && 'rotate-180')} onClick={handleToggle} />
          </Tooltip>
        </div>
      </div>

      {isCompareScenarioModalOpen && (
        <CompareScenarioModal
          isOpen={isCompareScenarioModalOpen}
          title='Review Selections'
          onClose={() => toggleCompareScenarioModal(false)}
          onConfirm={() => toggleCompareScenarioModal(false)}
        />
      )}
    </Fragment>
  );
};

export default Scenarios;

const ArticleIcon = () => (
  <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM10 14H5C4.45 14 4 13.55 4 13C4 12.45 4.45 12 5 12H10C10.55 12 11 12.45 11 13C11 13.55 10.55 14 10 14ZM13 10H5C4.45 10 4 9.55 4 9C4 8.45 4.45 8 5 8H13C13.55 8 14 8.45 14 9C14 9.55 13.55 10 13 10ZM13 6H5C4.45 6 4 5.55 4 5C4 4.45 4.45 4 5 4H13C13.55 4 14 4.45 14 5C14 5.55 13.55 6 13 6Z'
      fill='#94A3B8'
    />
  </svg>
);

export default ArticleIcon;

import { Input, LoadingScreen, Tooltip } from '@/components';
import { useRegenerateAnchorTextMutation, useUpdateUrlAnchorTextsMutation } from '@/store/campaign/campaign.api';
import { selectCampaign } from '@/store/campaign/campaign.selector';
import cn from '@/utils/style';
import { ArrowPathIcon, LockClosedIcon, LockOpenIcon } from '@heroicons/react/24/solid';
import { FilterFnOption, createColumnHelper } from '@tanstack/react-table';
import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

type AnchorTextTableMeta = {
  updateUrlAnchorTexts: (url: UrlAnchorText) => void;
};

type AnchorTextProps = {
  anchorText: UrlAnchorText;
  readOnly?: boolean;
  updateUrlAnchorTexts: (url: UrlAnchorText) => void;
};

const AnchortextActions: FC<AnchorTextProps> = ({ anchorText, readOnly = false, updateUrlAnchorTexts }) => {
  const { campaignId } = useParams() as { campaignId: string };
  const campaign = useSelector(selectCampaign);

  const [updateAnchorText, { isLoading: updating }] = useUpdateUrlAnchorTextsMutation();
  const [regenerateAnchorText, { isLoading: regenerating }] = useRegenerateAnchorTextMutation();

  const [isRegenerateDisabled, setIsGenerateDisabled] = useState(anchorText.status === 'locked');
  const isLoading = useMemo(() => regenerating || updating, [regenerating, updating]);

  const campaignStatus = useMemo(() => campaign?.status, [campaign]);

  useEffect(() => {
    if (campaignStatus === 'kicked_off') {
      setIsGenerateDisabled(true);
    }
  }, [campaignStatus, isRegenerateDisabled]);

  const handleToggleStatus = async () => {
    const updatedAnchorText = await updateAnchorText({
      campaign_id: Number(campaignId),
      url: anchorText.url,
      anchor_text: {
        id: anchorText.id,
        status: anchorText.status === 'created' ? 'locked' : 'created',
      },
    }).unwrap();

    setIsGenerateDisabled(updatedAnchorText.data.status === 'locked');
    updateUrlAnchorTexts(updatedAnchorText.data);
  };

  const handleRefreshAnchorText = async () => {
    const refreshedAnchorText = await regenerateAnchorText({
      campaign_id: Number(campaignId),
      anchor_text_id: anchorText.id,
      generated_anchor_text: anchorText.value,
      url: {
        id: anchorText.url.id,
        target_page: anchorText.url.target_page,
      },
    }).unwrap();

    updateUrlAnchorTexts({
      ...refreshedAnchorText.data,
      id: anchorText.id,
    });
  };

  const tooltipContent =
    (campaignStatus === 'kicked_off' && <p className='font-normal text-gray-500'>Cannot refresh while the campaign is kicked off</p>) ||
    (isRegenerateDisabled && <p className='font-normal text-gray-500'>Unlock the Anchor Text before Refreshing it</p>) ||
    (anchorText.match_type === 'exact' && <p className='font-normal text-gray-500'>Cannot refresh an exact match Anchor Text</p>);

  return (
    <Fragment>
      {isLoading && (
        <div className='absolute inset-0 z-10 flex h-full w-full items-center justify-center rounded-leap bg-gray-300 opacity-80'>
          <LoadingScreen containerClassName='py-0' />
        </div>
      )}
      <div className='flex items-center justify-center gap-3 font-bold text-sky-500'>
        <Tooltip id={`regenerate-${anchorText.id}`} place='top' content={tooltipContent || <p>Refresh Anchor Text</p>}>
          <button
            className={cn(
              'cursor-pointer',
              (isRegenerateDisabled || anchorText.match_type === 'exact' || campaignStatus === 'kicked_off' || readOnly) && 'cursor-not-allowed text-gray-400',
            )}
            disabled={isRegenerateDisabled || anchorText.match_type === 'exact' || readOnly || campaignStatus === 'kicked_off'}
            onClick={handleRefreshAnchorText}
          >
            <ArrowPathIcon className='w-4' />
          </button>
        </Tooltip>

        <Tooltip id={`lock-${anchorText.id}`} place='top' content={<p>Anchor Text Lock</p>}>
          <button
            className={cn('cursor-pointer', (readOnly || campaignStatus === 'kicked_off') && 'cursor-not-allowed text-gray-400')}
            onClick={handleToggleStatus}
            disabled={readOnly || campaignStatus === 'kicked_off'}
          >
            {anchorText.status === 'locked' ? <LockClosedIcon className='w-4' /> : <LockOpenIcon className='w-4' />}
          </button>
        </Tooltip>
      </div>
    </Fragment>
  );
};

const AnchorInput: FC<AnchorTextProps> = ({ anchorText, readOnly = false, updateUrlAnchorTexts }) => {
  const { campaignId } = useParams() as unknown as { campaignId: number };
  const [updateAnchorText, { isLoading }] = useUpdateUrlAnchorTextsMutation();
  const [inputVal, setInputVal] = useState(anchorText.value);

  const handleBlur = async () => {
    if (inputVal === anchorText.value) {
      return;
    }

    const updatedAnchorText = await updateAnchorText({
      campaign_id: campaignId,
      url: anchorText.url,
      anchor_text: {
        id: anchorText.id,
        value: inputVal,
        source: inputVal !== anchorText.value ? 'user' : 'openai',
      },
    }).unwrap();

    updateUrlAnchorTexts(updatedAnchorText.data);
  };

  return (
    <Fragment>
      {isLoading && (
        <div className='absolute inset-0 z-10 flex h-full w-full items-center justify-center rounded-leap bg-gray-300 opacity-80'>
          <LoadingScreen containerClassName='py-0' />
        </div>
      )}
      <Input
        className='w-full lowercase'
        value={inputVal}
        onChange={(val) => setInputVal(val)}
        onBlur={handleBlur}
        disabled={anchorText.status === 'locked' || readOnly}
        onKeyUp={(key) => key === 'Enter' && handleBlur()}
      />
    </Fragment>
  );
};

const columnHelper = createColumnHelper<UrlAnchorText>();

const useColumns = ({ readOnly = false }: { readOnly?: boolean }) => {
  const strFilter = 'stringFilter' as FilterFnOption<UrlAnchorText>;

  return [
    columnHelper.display({
      id: 'row-index',
      header: () => <p className='text-right'>Row #</p>,
      cell: ({ row }) => <p className='w-[40px] text-right'>{row.index + 1}</p>,
      size: 1,
      enableSorting: false,
    }),
    columnHelper.accessor('url.target_page', {
      id: 'url.target_page',
      header: () => <p className='text-left'>URL</p>,
      cell: ({ getValue }) => {
        return (
          <p className='w-[300px] overflow-hidden text-ellipsis whitespace-nowrap' title={getValue()}>
            {getValue()}
          </p>
        );
      },
      size: 300,
      filterFn: strFilter,
    }),
    columnHelper.accessor('match_type', {
      header: () => <p className='text-left'>Match Type</p>,
      cell: ({ getValue }) => <p>{getValue()}</p>,
      filterFn: strFilter,
      size: 1,
    }),
    columnHelper.accessor('keyword_text', {
      header: () => <p className='text-left'>Keyword</p>,
      cell: ({ getValue }) => {
        return (
          <p className='w-[150px] overflow-hidden text-ellipsis whitespace-nowrap' title={getValue()}>
            {getValue()}
          </p>
        );
      },
      filterFn: strFilter,
      size: 150,
    }),
    columnHelper.accessor('value', {
      header: () => <p className='text-left'>Anchor</p>,
      cell: ({ row, table }) => (
        <AnchorInput anchorText={row.original} updateUrlAnchorTexts={(table.options.meta as AnchorTextTableMeta).updateUrlAnchorTexts} readOnly={readOnly} />
      ),
      size: 700,
    }),
    columnHelper.display({
      id: 'actions',
      header: () => <p className='text-left'>Actions</p>,
      cell: ({ row, table }) => (
        <AnchortextActions anchorText={row.original} updateUrlAnchorTexts={(table.options.meta as AnchorTextTableMeta).updateUrlAnchorTexts} readOnly={readOnly} />
      ),
      size: 1,
    }),
  ];
};

export default useColumns;

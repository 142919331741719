import cn from '@/utils/style';
import { FC } from 'react';

interface SeparatorLineProps {
  className?: string;
  icon?: FC;
  iconText?: string;
  iconClassName?: string;
  lineClassName?: string;
  onIconClick?: () => void;
}

interface RenderIconProps {
  className?: string;
}

const SeparatorLine: FC<SeparatorLineProps> = ({ className, icon, iconText, iconClassName, lineClassName, onIconClick }) => {
  const RenderIcon: FC<RenderIconProps> | undefined = icon;

  return (
    <div className={cn('flex w-full', className)}>
      <div className={cn('my-auto h-px w-1/2 bg-gray-300', lineClassName)} />
      {RenderIcon && (
        <div className={cn('flex flex-shrink-0 items-center font-bold', onIconClick && 'cursor-pointer', iconClassName)} onClick={() => onIconClick && onIconClick()}>
          <RenderIcon className='mx-4 w-8' />
          {iconText && <p className='mr-4'>Add Tier</p>}
        </div>
      )}
      <div className={cn('my-auto h-px w-1/2 bg-gray-300', lineClassName)} />
    </div>
  );
};

export default SeparatorLine;

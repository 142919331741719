import { Button, Table } from '@/components';
import { useTable } from '@/hooks/table';
import { useDownloadScenarioExportCsvMutation } from '@/store/campaign/campaign.api';
import { ArrowDownTrayIcon, ArrowPathIcon } from '@heroicons/react/24/solid';
import { createColumnHelper } from '@tanstack/react-table';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

interface AnchorTextOVerviewTableProps {
  results: ScenarioAnchorsOverView[];
}

const OVERVIEW_COLUMNS: Record<AnchorTextOverviewTableColumns, string> = {
  exact: 'Exact',
  generic: 'Generic',
  custom: 'Custom',
  grand_total: 'Grand Total',
  partial: 'Partial',
};

const columnHelper = createColumnHelper<ScenarioAnchorsOverView>();

const columns = [
  columnHelper.accessor('match_type', {
    header: () => <p>Match Type</p>,
    cell: ({ getValue }) => <p className={'text-left'}>{OVERVIEW_COLUMNS[getValue()]}</p>,
  }),
  columnHelper.accessor('match_count', {
    header: () => <p className='text-right'>Match Count</p>,
    cell: ({ getValue }) => <p>{getValue().toLocaleString()}</p>,
  }),
  columnHelper.accessor('match_percentage', {
    header: () => <p className='text-right'>Match %</p>,
    cell: ({ getValue }) => <p>{getValue().toLocaleString()}%</p>,
  }),
  columnHelper.accessor('keyword_count', {
    header: () => <p className='text-right'>Keyword Count</p>,
    cell: ({ getValue }) => <p>{getValue().toLocaleString()}</p>,
  }),
];

const AnchorTextOVerviewTable: FC<AnchorTextOVerviewTableProps> = ({ results }) => {
  const { campaignId } = useParams() as { campaignId: string };
  const [downloadCSV, { isLoading: isCSVLoading }] = useDownloadScenarioExportCsvMutation();

  const [table] = useTable({
    data: results,
    columns: columns,
    tableOptions: {
      state: {
        rowSelection: {
          [results.length - 1]: true,
        },
      },
    },
  });

  const handleDownloadCsv = async () => {
    await downloadCSV({ campaignId: campaignId });
  };

  return (
    <div className='flex w-full flex-col gap-6 rounded-leap bg-white p-5 text-left text-gray-800 shadow-md'>
      <div className='flex flex-row items-center justify-between'>
        <p className='text-right text-xl leading-8'>Anchor Text Overview</p>
        <Button variant='outline-light' className='flex flex-nowrap items-center gap-2' onClick={handleDownloadCsv} disabled={isCSVLoading}>
          {isCSVLoading ? <ArrowPathIcon className='w-5 animate-spin text-gray-700' /> : <ArrowDownTrayIcon className='w-5 text-gray-700' />}
          Export CSV
        </Button>
      </div>
      <Table
        table={table}
        name='anchortextOverview'
        cellStyle='text-right py-3'
        rowStyle='last-of-type:font-bold last-of-type:bg-sky-100 last-of-type:hover:bg-sky-100'
        headerClass='py-2'
        tableClass='shadow-none mt-0'
      />
    </div>
  );
};

export default AnchorTextOVerviewTable;

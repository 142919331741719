import { ChartTooltipContent, RowCountInput, RowPercentageInput, Tooltip } from '@/components';
import { round } from '@/utils/numbers';
import cn from '@/utils/style';
import { StarIcon } from '@heroicons/react/20/solid';
import { Row, createColumnHelper } from '@tanstack/react-table';

type AnchorTextMatchTableMeta = {
  readOnly: boolean;
  isDisabled: boolean;
  updateMethodState: (id: number, field: keyof UrlAnchorTextTableColumns, value: number) => void;
  onSelectRow: ({ urlId, linksPerMonth }: { urlId: number; linksPerMonth: number }) => void;
};

const columnHelper = createColumnHelper<UrlAnchorTextTableColumns>();

const getIsValidMatch = (row: Row<UrlAnchorTextTableColumns>) => {
  const { exact_match, partial_match, generic_match, links_per_month } = row.original;
  const countValidMatch = exact_match + partial_match + generic_match === links_per_month;

  const exactMatchPercentage = (exact_match * 100) / links_per_month;
  const partialMatchPercentage = (partial_match * 100) / links_per_month;
  const genericMatchPercentage = (generic_match * 100) / links_per_month;

  const percentageValidMatch = round(exactMatchPercentage + partialMatchPercentage + genericMatchPercentage, 0) === 100;

  return [countValidMatch, percentageValidMatch];
};

const useAnchorTextMatchColumns = () => {
  return [
    columnHelper.accessor('links_per_month', {
      header: () => <p className='text-left'># of Links /mo</p>,
      cell: ({ row, getValue }) => {
        return (
          <div>
            <div className='absolute top-2 flex flex-nowrap items-center gap-2 whitespace-nowrap'>
              <p className='font-bold'>{row.original.target_page}</p>
            </div>
            <p className='text-left'>{getValue().toLocaleString()}</p>
          </div>
        );
      },
      size: 80,
    }),
    columnHelper.accessor('exact_match', {
      header: () => <p className='text-left'>Anchor Exact Match</p>,
      cell: ({ row, getValue, table }) => {
        const { updateMethodState, readOnly, isDisabled } = table.options.meta as AnchorTextMatchTableMeta;
        const { url_id } = row.original;

        const handleAfterBlur = (value: number) => {
          if (getValue() === value) {
            return;
          }

          updateMethodState(url_id, 'exact_match', value);
        };

        const [isCountValidMatch, isPercentageValidMatch] = getIsValidMatch(row);
        const clTooltipContent = { 'Exact Match': row.original.totals.cl.exact_match };
        const coTooltipContent = { 'Exact Match': row.original.totals.co.exact_match };

        return (
          <div className='flex gap-2'>
            <RowCountInput onAfterBlur={handleAfterBlur} disabled={isDisabled || readOnly} initialValue={getValue()} hasError={!isCountValidMatch} />
            <RowPercentageInput
              onAfterBlur={handleAfterBlur}
              disabled={isDisabled || readOnly}
              initialValue={getValue()}
              hasError={!isPercentageValidMatch}
              limit={row.original.links_per_month}
            />
            <div className='mx-2 flex w-44 flex-col items-start justify-center gap-1 text-xs'>
              <Tooltip id={`${row.id}-cl-exact`} content={<ChartTooltipContent hidePercentage data={clTooltipContent} title='Subject Exact Anchor Text' />}>
                Subj: {row.original.cl.exact_match}%
              </Tooltip>
              <Tooltip id={`${row.id}-co-exact`} content={<ChartTooltipContent hidePercentage data={coTooltipContent} title='Competitor Exact Anchor Text' />}>
                Comp: {row.original.co.exact_match}%
              </Tooltip>
            </div>
          </div>
        );
      },
    }),
    columnHelper.accessor('partial_match', {
      header: () => <p className='text-left'>Anchor Partial Match</p>,
      cell: ({ row, getValue, table }) => {
        const { updateMethodState, readOnly, isDisabled } = table.options.meta as AnchorTextMatchTableMeta;
        const { url_id } = row.original;

        const handleAfterBlur = (value: number) => {
          if (getValue() === value) {
            return;
          }

          updateMethodState(url_id, 'partial_match', value);
        };

        const [isCountValidMatch, isPercentageValidMatch] = getIsValidMatch(row);
        const clTooltipContent = { 'Partial Match': row.original.totals.cl.partial_match };
        const coTooltipContent = { 'Partial Match': row.original.totals.co.partial_match };

        return (
          <div className='flex gap-2'>
            <RowCountInput onAfterBlur={handleAfterBlur} disabled={isDisabled || readOnly} initialValue={getValue()} hasError={!isCountValidMatch} />
            <RowPercentageInput
              onAfterBlur={handleAfterBlur}
              disabled={isDisabled || readOnly}
              initialValue={getValue()}
              hasError={!isPercentageValidMatch}
              limit={row.original.links_per_month}
            />
            <div className='mx-2 flex w-44 flex-col items-start justify-center gap-1 text-xs'>
              <Tooltip id={`${row.id}-cl-partial`} content={<ChartTooltipContent hidePercentage data={clTooltipContent} title='Subject Partial Anchor Text' />}>
                Subj: {row.original.cl.partial_match}%
              </Tooltip>
              <Tooltip id={`${row.id}-co-partial`} content={<ChartTooltipContent hidePercentage data={coTooltipContent} title='Competitor Partial Anchor Text' />}>
                Comp: {row.original.co.partial_match}%
              </Tooltip>
            </div>
          </div>
        );
      },
    }),
    columnHelper.accessor('generic_match', {
      header: () => <p className='text-left'>Anchor Generic Match</p>,
      cell: ({ row, getValue, table }) => {
        const { updateMethodState, readOnly, isDisabled } = table.options.meta as AnchorTextMatchTableMeta;
        const { url_id } = row.original;

        const handleAfterBlur = (value: number) => {
          if (getValue() === value) {
            return;
          }

          updateMethodState(url_id, 'generic_match', value);
        };
        const [isCountValidMatch, isPercentageValidMatch] = getIsValidMatch(row);
        const clTooltipContent = { 'Generic Match': row.original.totals.cl.generic_match };
        const coTooltipContent = { 'Generic Match': row.original.totals.co.generic_match };

        return (
          <div className='flex gap-2'>
            <RowCountInput onAfterBlur={handleAfterBlur} disabled={isDisabled || readOnly} initialValue={getValue()} hasError={!isCountValidMatch} />
            <RowPercentageInput
              onAfterBlur={handleAfterBlur}
              disabled={isDisabled || readOnly}
              initialValue={getValue()}
              hasError={!isPercentageValidMatch}
              limit={row.original.links_per_month}
            />
            <div className='mx-2 flex w-44 flex-col items-start justify-center gap-1 text-xs'>
              <Tooltip id={`${row.id}-cl-generic`} content={<ChartTooltipContent hidePercentage data={clTooltipContent} title='Competitor Generic Anchor Text' />}>
                Subj: {row.original.cl.generic_match}%
              </Tooltip>
              <Tooltip id={`${row.id}-co-generic`} content={<ChartTooltipContent hidePercentage data={coTooltipContent} title='Competitor Generic Anchor Text' />}>
                Comp: {row.original.co.generic_match}%
              </Tooltip>
            </div>
          </div>
        );
      },
    }),
    columnHelper.display({
      id: 'match_type_by_keyword',
      header: () => <p className='text-left'>Match Type by Keyword</p>,
      cell: ({ row, table }) => {
        const { onSelectRow, isDisabled, readOnly } = table.options.meta as AnchorTextMatchTableMeta;
        const { url_id, links_per_month } = row.original;

        return (
          <div className='flex justify-center gap-2'>
            <div className={cn('flex cursor-pointer flex-row items-center justify-center rounded-full border-4 border-sky-500 p-[2px]', isDisabled || (readOnly && 'bg-sky-500 '))}>
              <StarIcon
                className={cn('w-4 text-sky-500', isDisabled || (readOnly && 'text-white'))}
                onClick={() => onSelectRow({ urlId: url_id, linksPerMonth: links_per_month })}
              />
            </div>
          </div>
        );
      },
    }),
  ];
};

export default useAnchorTextMatchColumns;

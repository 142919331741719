const APP_CONFIG: AppState = {
  tables: {
    campaigns: {
      visibility: {},
      sorting: [],
    },
    overview: {
      visibility: {},
      sorting: [],
    },
    users: {
      visibility: {},
      sorting: [],
    },
    keywords: {
      visibility: {},
      sorting: [],
    },
    competitors: {
      visibility: {},
      sorting: [],
    },
    organicResearch: {
      visibility: {},
      sorting: [],
    },
    compareScenario: {
      visibility: {},
      sorting: [],
    },
    priorityKeywordsContent: {
      visibility: {},
      sorting: [],
    },
    anchortextMatchContent: {
      visibility: {},
      sorting: [],
    },
    distributeanchorsByKeyword: {
      visibility: {},
      sorting: [],
    },
    billingHistory: {
      visibility: {},
      sorting: [],
    },
    usedCredits: {
      visibility: {},
      sorting: [],
    },
    keywordsAllocation: {
      visibility: {},
      sorting: [],
    },
    anchortextTable: {
      visibility: {},
      sorting: [],
    },
    anchortextOverview: {
      visibility: {},
      sorting: [],
    },
    anchortextUrlTable: {
      visibility: {},
      sorting: [],
    },
    advancedSettingsCtrValues: {
      visibility: {},
      sorting: [],
    },
    pullTopKeywords: {
      visibility: {},
      sorting: [],
    },
    pullTopKeywordsModal: {
      visibility: {},
      sorting: [],
    },
    companies: {
      visibility: {},
      sorting: [],
    },
    'access-requests': {
      visibility: {},
      sorting: [],
    },
    keywordsAnalysis: {
      visibility: {},
      sorting: [],
    },
    pocUrls: {
      visibility: {},
      sorting: [],
    },
    reportsTable: {
      visibility: {},
      sorting: [],
    },
  },
  navBar: {},
  tooltip: {},
  tutorial: {},
  sideBar: {
    isOpen: false,
  },
  sendgrid: [],
};

const KEYWORDS_COUNT: Array<OptionType<number>> = [
  { label: '10', value: 10 },
  { label: '25', value: 25 },
  { label: '50', value: 50 },
];

const URLS_COUNT: Array<OptionType<number>> = [
  { label: '10', value: 10 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
];

const URL_SELECTION_OPTIONS: Array<OptionType<UrlSelection>> = [
  { label: 'Select URLs from your domain', value: 'from_domain' },
  // { label: 'Enter your URLs manually', value: 'urls_manually' },
  { label: 'Enter your URLs and keywords manually', value: 'urls_kws_manually' },
];

const STRING_FILTERS_OPTIONS: Array<OptionType<StringFilters>> = [
  { label: 'Equals', value: 'exact' },
  { label: 'Contains', value: 'icontains' },
  { label: 'Does not contain', value: 'not_containing' },
];

const NUMERIC_FILTERS_OPTIONS: Array<OptionType<NumericFilters>> = [
  { label: 'Greater than', value: 'gt' },
  { label: 'Less than', value: 'lt' },
  { label: 'Equals', value: 'exact' },
  { label: 'Between', value: 'between' },
];

const NUMERIC_FILTERS: Array<NumericFilters> = ['gt', 'lt', 'exact', 'between'];
const STRING_FILTERS: Array<StringFilters> = ['exact', 'icontains', 'not_containing'];

const DEFAULT_TERM = 12;

const CAMPAIGNS_NOTIFICATION_TYPE: Array<CampaignNotificationActionType> = ['fetch_error', 'fetch_progress_update', 'fetch_started', 'fetch_done', 'new_access_request'];
const ANCHOR_TEXT_NOTIFICATION_TYPE: Array<AnchorTextNotificationActionType> = ['generate_anchors_task_update', 'generate_anchors_task_error', 'generate_anchors_task_done'];

const TARGET_RANK_OPTIONS: Array<OptionType<number>> = [
  {
    label: 'Position 1',
    value: 1,
  },
  {
    label: 'Position 2',
    value: 2,
  },
  {
    label: 'Position 4',
    value: 4,
  },
  {
    label: 'Position 20',
    value: 20,
  },
];

const TERM_OPTIONS: Array<OptionType<number>> = [
  {
    label: 'One-Time',
    value: 1,
  },
  {
    label: '3 Months',
    value: 3,
  },
  {
    label: '6 Months',
    value: 6,
  },
  {
    label: '12 Months',
    value: 12,
  },
];

const TERM_OPTIONS_JSON: Array<OptionType<keyof ROISummary>> = [
  {
    label: 'End of Term',
    value: 'at_end_of_project',
    months: 1,
  },
  {
    label: 'Term +6 Months',
    value: 'at_end_of_project_plus_6_months',
    months: 6,
  },
  {
    label: 'Term +12 Months',
    value: 'at_end_of_project_plus_12_months',
    months: 12,
  },
];

const STEPS = ['analysis', 'scenario-review', 'anchor-text-generator', 'anchor-text-results', 'summary'] as const;

const PERCENTAGE_FIELDS = ['difficulty'];

export {
  APP_CONFIG,
  KEYWORDS_COUNT,
  URLS_COUNT,
  STRING_FILTERS_OPTIONS,
  NUMERIC_FILTERS_OPTIONS,
  URL_SELECTION_OPTIONS,
  NUMERIC_FILTERS,
  STRING_FILTERS,
  DEFAULT_TERM,
  CAMPAIGNS_NOTIFICATION_TYPE,
  ANCHOR_TEXT_NOTIFICATION_TYPE,
  TARGET_RANK_OPTIONS,
  TERM_OPTIONS,
  TERM_OPTIONS_JSON,
  STEPS,
  PERCENTAGE_FIELDS,
};

import { Button } from '@/components';
import { FC } from 'react';

type OrganicResearchTableFooterProps = {
  onConfirm: () => void;
  onFetchAdditional: () => void;
  isLoading?: boolean;
  amount: number;
};

const OrganicResearchTableFooter: FC<OrganicResearchTableFooterProps> = ({ onConfirm, isLoading = false, amount, onFetchAdditional }) => {
  if (isLoading) {
    return null;
  }

  return (
    <div className='flex items-center justify-between'>
      <p>Selected - {amount} URLs</p>
      <div className='flex items-center justify-end gap-4'>
        <Button variant='outline-light' onClick={onFetchAdditional}>
          Fetch Additional
        </Button>
        <Button onClick={onConfirm}>Confirm URLs</Button>
      </div>
    </div>
  );
};

export default OrganicResearchTableFooter;

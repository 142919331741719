import { SVGProps } from 'react';

const ArchiveIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M17.54 2.73L16.15 1.05C15.88 0.71 15.47 0.5 15 0.5H3C2.53 0.5 2.12 0.71 1.84 1.05L0.46 2.73C0.17 3.07 0 3.52 0 4V16.5C0 17.6 0.9 18.5 2 18.5H16C17.1 18.5 18 17.6 18 16.5V4C18 3.52 17.83 3.07 17.54 2.73ZM9 15L3.5 9.5H7V7.5H11V9.5H14.5L9 15ZM2.12 2.5L2.93 1.5H14.93L15.87 2.5H2.12Z'
      fill='#64748B'
    />
  </svg>
);

export default ArchiveIcon;

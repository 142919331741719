import { selectScenarios } from '@/store/campaign/campaign.selector';
import { round } from '@/utils/numbers';
import { Table, createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import CloseToGapField from '../CloseToGapField';
import LinksToBuildField from '../LinksToBuildField';
import SelectUrlField from '../SelectUrlField';
import { getLinksToBuild } from '@/utils/urls';

type TableMetadata = {
  toggleUrl: (url: ScenarioUrl, scenarioId?: number) => void;
};

const columnHelper = createColumnHelper<ScenarioCompareV2>();

const ScenarioCompareColumns2 = ({ hasKeywords = false, hasAnchorsText = false }: { hasKeywords: boolean; hasAnchorsText: boolean }) => {
  const scenarios = useSelector(selectScenarios);

  const staticColumns = useMemo(
    () => [
      columnHelper.accessor('target_page', {
        header: () => <p className='text-left'>Target URL</p>,
        cell: ({ getValue }) => <p className='text-left'>{getValue()}</p>,
        size: 500,
      }),
      columnHelper.accessor('total_volume', {
        header: () => <p className='text-left'>Volume</p>,
        cell: ({ getValue, row }) => {
          if (row.original.target_page === 'Total') {
            return null;
          }

          return <p className='text-right'>{getValue()}</p>;
        },
        size: 1,
      }),
      columnHelper.accessor('lrd_gap', {
        header: () => <p className='text-left'>Gap</p>,
        cell: ({ getValue, row }) => {
          if (row.original.target_page === 'Total') {
            return null;
          }

          const lrdGap = getValue() > 0 ? round(getValue(), 2) : '-';
          return <p className='text-right'>{lrdGap}</p>;
        },
        size: 1,
      }),
      columnHelper.accessor('difficulty', {
        size: 1,
        header: () => <p className='text-right'>Difficulty</p>,
        cell: ({ getValue, row }) => {
          if (row.original.target_page === 'Total') {
            return null;
          }

          return <p className='text-right'>{round(getValue() * 100, 2)}%</p>;
        },
      }),
      columnHelper.accessor('avg_rank', {
        size: 1,
        header: () => <p className='text-right'>Average Rank</p>,
        cell: ({ getValue, row }) => {
          if (row.original.target_page === 'Total') {
            return null;
          }

          return <p className='text-right'>{round(getValue(), 2)}</p>;
        },
      }),
    ],
    [],
  );

  const scenarioColumns = useMemo(
    () =>
      scenarios.map((scenario) =>
        columnHelper.display({
          id: scenario.id.toString(),
          header: () => <p>{scenario.title}</p>,
          cell: ({ row, table }) => {
            const updateScenarioUrls = () => {
              const ltb = Number(
                getLinksToBuild({
                  closeToGap: 100,
                  lrdGap: row.original.lrd_gap,
                  projLength: scenario.proj_length,
                }),
              );

              const url: ScenarioUrl = { ...row.original, links_to_build: row.original.lrd_gap ? ltb : 0, close_to_gap: 100 };
              (table.options.meta as TableMetadata).toggleUrl(url, scenario.id);
            };

            return (
              <div className='flex flex-col items-end gap-1'>
                <SelectUrlField scenario={scenario} urlId={row.original.url_id} hasAnchorsText={hasAnchorsText} updateScenarioUrls={updateScenarioUrls} />
                <div className='flex items-center gap-2'>
                  <CloseToGapField
                    table={table as Table<unknown>}
                    rowId={row.id}
                    lrdGap={row.original.lrd_gap < 0 ? 0 : row.original.lrd_gap}
                    urlId={row.original.url_id}
                    hasKeywords={hasKeywords}
                    scenario={scenario}
                  />
                  <LinksToBuildField table={table as Table<unknown>} urlId={row.original.url_id} scenario={scenario} />
                </div>
              </div>
            );
          },
        }),
      ),
    [scenarios, hasKeywords, hasAnchorsText],
  );

  return [...staticColumns, ...scenarioColumns];
};

export default ScenarioCompareColumns2;

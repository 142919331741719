import { useCallback, useState } from 'react';

const useToggle = ({ initialValue = false } = {}) => {
  const [value, setValue] = useState(initialValue);

  const toggle = useCallback((newVal?: boolean) => setValue((oldValue) => newVal ?? !oldValue), []);
  const open = useCallback(() => setValue(true), []);
  const close = useCallback(() => setValue(false), []);

  return { value, toggle, open, close };
};

export { useToggle };

import { Button, Modal, PagesIncluded } from '@/components';
import { useDeleteCampaignAnchorTextsMutation, useGetCampaignConfigQuery } from '@/store/campaign/campaign.api';
import { selectCampaign, selectScenarios } from '@/store/campaign/campaign.selector';
import { setApprovedScenario, setIsDirty } from '@/store/campaign/campaign.slice';
import { AppDispatch } from '@/store/store';
import { FC, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ScenarioReviewCard from '../ScenarioReviewCard';
import { useParams } from 'react-router-dom';

interface ScenarioReviewBodyProps {
  selectedTab: 'campaignProposal' | 'pagesIncluded';
  selectedTerm: keyof ROISummary;
  readonly?: boolean;
}

const ScenarioReviewBody: FC<ScenarioReviewBodyProps> = ({ selectedTab, selectedTerm, readonly = false }) => {
  const { campaignId } = useParams() as { campaignId: string };
  const dispatch = useDispatch<AppDispatch>();
  const scenarios = useSelector(selectScenarios);
  const campaign = useSelector(selectCampaign);

  const { data: campaignConfig } = useGetCampaignConfigQuery({ campaignId });
  const [deleteAnchors] = useDeleteCampaignAnchorTextsMutation();

  const [idToApprove, setIdToApprove] = useState<number>();

  const handleOnSelect = useCallback(
    (scenarioId: number) => {
      if (campaignConfig?.data.has_anchor_texts) {
        return setIdToApprove(scenarioId);
      }

      dispatch(setIsDirty({ step: 'scenario-review', isDirty: true }));
      dispatch(setApprovedScenario({ scenarioId }));
    },
    [dispatch, campaignConfig],
  );

  const handleModalConfirm = useCallback(async () => {
    if (!campaign || !idToApprove) {
      return false;
    }

    await deleteAnchors({ campaignId: String(campaign.id) }).unwrap();

    dispatch(setIsDirty({ step: 'scenario-review', isDirty: true }));
    dispatch(setApprovedScenario({ scenarioId: idToApprove }));
    setIdToApprove(undefined);
  }, [campaign, deleteAnchors, dispatch, setIdToApprove, idToApprove]);

  const renderScenarios = useCallback(
    (scenario: Scenario) => <ScenarioReviewCard key={scenario.id} scenario={scenario} onSelect={handleOnSelect} roi={selectedTerm} readonly={readonly} />,
    [handleOnSelect, selectedTerm, readonly],
  );

  return (
    <div>
      {selectedTab === 'campaignProposal' && <section className='flex items-center justify-center gap-5'>{scenarios.map(renderScenarios)}</section>}
      {selectedTab === 'pagesIncluded' && (
        <div className='rounded-xl bg-white p-5 shadow-sm'>
          <PagesIncluded />
        </div>
      )}
      {idToApprove && (
        <Modal onClose={() => setIdToApprove(undefined)} isOpen={true} title=''>
          <div className='mb-4 flex w-[450px] flex-col items-center gap-4'>
            <h4 className='text-center'>Are you sure you want to approve this scenario?</h4>
            <p className='text-center'>If you do, all of your anchor text progress will be lost. This action cannot be undone.</p>
            <Button className='w-full' onClick={handleModalConfirm}>
              Continue and Override
            </Button>
            <Button className='w-full' onClick={() => setIdToApprove(undefined)}>
              Cancel
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ScenarioReviewBody;

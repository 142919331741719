import { Select } from '@/components';
import { TERM_OPTIONS } from '@/constants/defaultValues';
import { useGetCampaignConfigQuery, useSaveCampaignConfigMutation } from '@/store/campaign/campaign.api';
import { selectCampaignAnalysis, selectScenarios } from '@/store/campaign/campaign.selector';
import { setIsDirty } from '@/store/campaign/campaign.slice';
import { AppDispatch } from '@/store/store';
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import AnchorTextMatchTable from './AnchorTextMatchTable';
import GenerateAnchorTextButton from './GenerateAnchorTextButton';

const METHOD_PILLS: Array<OptionType<MatchMethods>> = [
  { label: 'Recommended', value: 'recommended' },
  { label: 'Bulk Edit', value: 'bulk_edit' },
  { label: 'Individual Edit', value: 'individual_edit' },
];

type AnchorTextMatchContentProps = {
  selectedMethod: MatchMethods;
  setSelectedMethod: (method: MatchMethods) => void;
  selectedMethodState: Map<MatchMethods, UrlAnchorTextTableColumns[]>;
  setSelectedMethodState: Dispatch<SetStateAction<Map<MatchMethods, UrlAnchorTextTableColumns[]>>>;
};

const AnchorTextMatchContent: FC<AnchorTextMatchContentProps> = ({ selectedMethod, selectedMethodState, setSelectedMethod, setSelectedMethodState }) => {
  const { campaignId } = useParams() as { campaignId: string };
  const dispatch = useDispatch<AppDispatch>();
  const scenarios = useSelector(selectScenarios);
  const campaignAnalysis = useSelector(selectCampaignAnalysis);

  const { data: configData, isLoading: configLoading } = useGetCampaignConfigQuery({ campaignId });
  const [saveCampaignConfig] = useSaveCampaignConfigMutation();

  const approvedScenario = useMemo(() => scenarios.find((s) => s.is_approved), [scenarios]);
  const [currentTerm, setCurrentTerm] = useState<number>(approvedScenario?.proj_length || 1);

  const [urlKeywordsDistributionState, setUrlKeywordsDistributionState] = useState(new Map<number, AnchorTextKeywordState[]>());

  useEffect(() => {
    const configKeywordsMap = new Map<number, CampaignConfigKeyword>();
    for (const keyword of configData?.data.keywords || []) {
      if (keyword.anchorsTotal !== 0 && keyword.priority !== 0) {
        configKeywordsMap.set(keyword.id, keyword);
      }
    }

    const keywordsMap = new Map<number, AnchorTextKeywordState[]>();
    for (const analysis of campaignAnalysis) {
      if (configKeywordsMap.has(analysis.keyword.id)) {
        const keyword = configKeywordsMap.get(analysis.keyword.id)!;
        const ditribution = {
          keyword_id: keyword.id,
          keyword: keyword.keyword,
          exact_match: 0,
          partial_match: 0,
          generic_match: 0,
          remaining: keyword.anchorsTotal,
        };

        if (keywordsMap.has(analysis.targetPageId)) {
          const urlAnalysisKeyword = keywordsMap.get(analysis.targetPageId) || [];
          urlAnalysisKeyword.push(ditribution);
          keywordsMap.set(analysis.targetPageId, urlAnalysisKeyword);
          continue;
        }

        keywordsMap.set(analysis.targetPageId, [ditribution]);
      }
    }

    setUrlKeywordsDistributionState(keywordsMap);
  }, [campaignAnalysis, configData?.data.keywords]);

  const handleMatchMethodChange = async (method: MatchMethods) => {
    dispatch(setIsDirty({ step: 'anchor-text-generator', isDirty: true }));
    setSelectedMethod(method);
    await saveCampaignConfig({ campaignId: campaignId, config: { selected_method: method } }).unwrap();
  };

  const handleTermChange = async (term: number) => {
    dispatch(setIsDirty({ step: 'anchor-text-generator', isDirty: true }));
    setCurrentTerm(term);
  };

  return (
    <div className='bg-white'>
      <div className='grid grid-cols-2 items-center gap-4 text-left'>
        <Select value={currentTerm} options={TERM_OPTIONS} onChange={handleTermChange} className='w-full' label='Term' />
        <Select value={selectedMethod} options={METHOD_PILLS} onChange={handleMatchMethodChange} className='w-full' label='Match Method' />
      </div>

      <div className='my-4 flex flex-col gap-4'>
        <AnchorTextMatchTable
          isLoading={configLoading}
          selectedMethod={selectedMethod}
          selectedMethodState={selectedMethodState}
          setSelectedMethodState={setSelectedMethodState}
          urlKeywordsDistributionState={urlKeywordsDistributionState}
          setUrlKeywordsDistributionState={setUrlKeywordsDistributionState}
        />
      </div>

      <div className='mb-2 mt-4 flex flex-row justify-end gap-4'>
        <GenerateAnchorTextButton selectedTerm={currentTerm} selectedMehthodUrls={selectedMethodState.get(selectedMethod) || []} distributions={urlKeywordsDistributionState} />
      </div>
    </div>
  );
};

export default AnchorTextMatchContent;

import { Skeleton } from '@/components';
import { PageSkeleton } from '@/layouts/Page';

const AnchorTextGeneratorPageSkeleton = () => {
  return (
    <PageSkeleton>
      <div className='flex flex-col gap-6'>
        <Skeleton className='h-10 w-full' />
        <Skeleton className='h-[500px] w-full' />
        <Skeleton className='h-[500px] w-full' />
      </div>
    </PageSkeleton>
  );
};

export default AnchorTextGeneratorPageSkeleton;

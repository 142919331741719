import { Avatar } from '@/components';
import { selectAuthUser } from '@/store/auth/auth.selector';
import { useDeleteUserMutation, useResendInviteMutation, useUpdateUserMutation } from '@/store/users/users.api';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/solid';
import { FC, Fragment, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import DeleteUserModal from '../DeleteUserModal';
import EditUserModal from '../EditUserModal';
import { ArrowPathIcon } from '@heroicons/react/20/solid';
import { getDateWithFormat } from '@/utils/dates';
import cn from '@/utils/style';

interface UserCardProps {
  user: User;
  onUserUpdate: (user: User) => void;
  onUserDelete: (user: User) => void;
  afterResendInvite: () => void;
}

const ROLE_OPTIONS: Record<UserRole, string> = {
  viewer: 'Member',
  manager: 'Admin',
  owner: 'Owner',
  creator: 'Owner',
};

const UserCard: FC<UserCardProps> = ({ user, onUserUpdate, onUserDelete, afterResendInvite }) => {
  const authUser = useSelector(selectAuthUser);

  const [updateUser, { error: updateError }] = useUpdateUserMutation();
  const [deleteUser, { error: deleteError }] = useDeleteUserMutation();
  const [resendInvite, { isLoading: isSendingInvite }] = useResendInviteMutation();

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const disabledDelete = useMemo(() => {
    const userIsOwnerOrCreator = user.company?.role === 'owner' || user.company?.role === 'creator';
    const disableAdminEdit = authUser?.company?.role === 'manager' && (userIsOwnerOrCreator || user.isSuperuser);
    const disableViewerDelete = authUser?.company?.role === 'viewer';

    return authUser?.id === user.id || user.isSuperuser || disableAdminEdit || disableViewerDelete;
  }, [authUser, user]);

  const disableEdit = useMemo(() => {
    const disableViewerEdit = authUser?.company?.role === 'viewer' && authUser.id !== user.id;
    const disableAdminEdit = authUser?.company?.role === 'manager' && user.company?.role === 'owner';

    return disableViewerEdit || disableAdminEdit;
  }, [authUser, user]);

  const handleEditConfirm = async (values: EditModalUserValues) => {
    const updatedUser = await updateUser({
      id: user.id,
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      company: {
        id: values.company?.id || '',
        role: values.company?.role || 'viewer',
      },
      config: values.config,
    }).unwrap();

    if (!updateError) {
      setIsEditModalOpen(false);
      onUserUpdate(updatedUser.data);
    }
  };

  const handleDeleteConfirm = async () => {
    await deleteUser({ id: user.id }).unwrap();

    if (!deleteError) {
      setIsDeleteModalOpen(false);
      onUserDelete(user);
    }
  };

  const handleResendInvite = async () => {
    await resendInvite({ id: user.id });
    afterResendInvite();
  };

  const invitationSendAndNotActive = user.config.invitation?.status === 'sent' && !user.isActive;

  return (
    <Fragment>
      <div className='group relative grid h-40 grid-rows-[0fr] gap-4 rounded-leap bg-white shadow-md'>
        <div
          className={cn(
            'z-10 flex w-full items-center justify-between overflow-hidden rounded-leap bg-gray-200 px-5 duration-150 group-hover:grid-rows-[1fr] group-hover:py-8',
            !invitationSendAndNotActive && 'hidden',
          )}
        >
          <span>
            {invitationSendAndNotActive && (
              <div className='flex items-center justify-center gap-4'>
                {user.config.invitation?.sent_at && (
                  <span>
                    The invitation was mailed on{' '}
                    {user.config.invitation.sent_at.length > 10 ? getDateWithFormat(user.config.invitation.sent_at, 'MMM dd') : user.config.invitation.sent_at}
                  </span>
                )}
                <button className='flex items-center gap-2' onClick={handleResendInvite}>
                  <ArrowPathIcon className='size-6 text-sky-500' />
                  <span className='text-sky-500'>Send Again</span>
                </button>
              </div>
            )}
          </span>
          <div className='flex items-center gap-2 text-gray-400'>
            <button onClick={() => setIsEditModalOpen(true)} disabled={disableEdit}>
              <PencilIcon className='w-6' />
            </button>
            <button onClick={() => setIsDeleteModalOpen(true)} disabled={disabledDelete} className='disabled:opacity-50'>
              <TrashIcon className='w-6 ' />
            </button>
          </div>
        </div>
        <div className='absolute w-full py-4'>
          <section className='flex w-full items-center justify-between px-4 '>
            <div className='flex w-3/4 gap-3 '>
              <Avatar user={user} displayName={false} className='h-12 w-12' />
              <div className='flex w-full flex-col items-start text-start'>
                <span className='w-3/4 truncate text-xl font-semibold leading-8'>{user.fullName}</span>
                <span className='w-3/4 truncate text-sm leading-6 text-gray-400'>{user.email}</span>
              </div>
            </div>
            <div className='flex items-center gap-2 text-gray-400'>
              <button onClick={() => setIsEditModalOpen(true)} disabled={disableEdit}>
                <PencilIcon className='w-6' />
              </button>
              <button onClick={() => setIsDeleteModalOpen(true)} disabled={disabledDelete} className='disabled:opacity-50'>
                <TrashIcon className='w-6' />
              </button>
            </div>
          </section>
          <section className='flex w-full flex-row justify-between gap-1 px-4 py-6 leading-6'>
            <div className='flex flex-col items-start'>
              <p>
                <span className='capitalize text-gray-400'>Company: </span>
                {user.company?.name || '-'}
              </p>
              <p>
                <span className='capitalize text-gray-400'>Role:</span> {user.company?.role ? ROLE_OPTIONS[user.company?.role] : '-'}
                {user.config.invitation?.accepted_at && <span className='mx-1'> | Joined {getDateWithFormat(user.config.invitation.accepted_at, 'MMM dd')}</span>}
              </p>
            </div>
            <div>{isSendingInvite && <ArrowPathIcon className='mx-auto w-6 animate-spin opacity-80' />}</div>
          </section>
        </div>
      </div>
      {isEditModalOpen && (
        <EditUserModal
          user={user}
          onConfirm={handleEditConfirm}
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          errorMessage={(updateError as APIError)?.data?.message}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteUserModal
          user={user}
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onConfirm={handleDeleteConfirm}
          errorMessage={(deleteError as APIError)?.data.message}
        />
      )}
    </Fragment>
  );
};

export default UserCard;

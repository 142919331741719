import { Button, FiltersProvider } from '@/components';
import { Page } from '@/layouts';
import { FC, useEffect, useState } from 'react';
import { Link, LoaderFunction, Outlet, redirect, useNavigate, useSearchParams } from 'react-router-dom';
import { CampaignsCards } from './components';
import cn from '@/utils/style';
import { CONFIG, getTutorialComplete } from '@/utils/localstorage';
type CampaignsDashboardQueryParams = 'active' | 'archive';

const Dashboard: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const activeTab = searchParams.get('tab') as CampaignsDashboardQueryParams | null;
  const [isTutorialComplete, setIsTutorialComplete] = useState(getTutorialComplete());

  useEffect(() => {
    const handleLocalStorageChange = (e: StorageEvent) => {
      if (e.key === CONFIG) {
        const appConfig = JSON.parse(e.newValue || '{}') as AppState | Record<string, never>;

        if (Object.keys(appConfig).length > 0) {
          const {
            tutorial: { done },
          } = appConfig;

          setIsTutorialComplete(done);
        }
      }
    };

    window.addEventListener('storage', handleLocalStorageChange);

    return () => {
      window.removeEventListener('storage', handleLocalStorageChange);
    };
  }, []);

  return (
    <>
      <Page>
        <Page.TopBar title='Campaigns' className='flex items-center'>
          <div className='flex w-full items-center justify-end gap-2'>
            {!isTutorialComplete && (
              <Link to={'/tour'}>
                <Button>Take Tour</Button>
              </Link>
            )}
            <div className='space-x-1 rounded-leap bg-white p-2'>
              <Button onClick={() => navigate('?tab=active')} className={cn(activeTab === 'archive' && 'bg-white text-gray-300 hover:bg-white active:bg-white')}>
                Active
              </Button>
              <Button onClick={() => navigate('?tab=archive')} className={cn(activeTab === 'active' && 'bg-white text-gray-300 hover:bg-white active:bg-white')}>
                Archive
              </Button>
            </div>
          </div>
        </Page.TopBar>
        <Page.Body className='overflow-hidden'>
          <FiltersProvider>
            <CampaignsCards key={activeTab} />
          </FiltersProvider>
        </Page.Body>
      </Page>
      <Outlet />
    </>
  );
};

const dashboardLoader: LoaderFunction = ({ request }) => {
  const { searchParams } = new URL(request.url);
  const tab = searchParams.get('tab');

  if (!tab || !['active', 'archive'].includes(tab)) {
    return redirect('?tab=active');
  }

  return null;
};

export default Dashboard;
export { dashboardLoader };

import { Modal, ModalProps } from '@/components';
import { FC } from 'react';

const RemoveImageModal: FC<ModalProps> = ({ isOpen, onConfirm, onClose, isLoading }) => {
  return (
    <Modal isOpen={isOpen} title='Are you sure you want to remove your profile picture?' onConfirm={onConfirm} onClose={onClose} className='max-w-modal' isLoading={isLoading} />
  );
};

export default RemoveImageModal;

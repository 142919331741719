import { Button } from '@/components';
import cn from '@/utils/style';
import { FC, PropsWithChildren } from 'react';

interface PaymentCardProps extends PropsWithChildren {
  selected: boolean;
  title: string;
  buttonText: string;
  onClick: () => void;
}

const PaymentCard: FC<PaymentCardProps> = ({ selected, title, buttonText, onClick, children }) => {
  return (
    <div className={cn('flex h-[180px] w-full flex-col justify-between rounded-xl bg-white p-5 text-left', selected && 'bg-gradient-to-r from-[#0193DE] to-[#0072AC]')}>
      <p className={cn(selected && 'text-white')}>{title}</p>
      {children}
      <Button variant={selected ? 'outline-light' : 'primary'} className='w-full' onClick={onClick}>
        {buttonText}
      </Button>
    </div>
  );
};

export default PaymentCard;

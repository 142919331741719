import { Input } from '@/components';
import { useField } from 'formik';
import { useState } from 'react';

const CTRInput = ({ ctrKey }: { ctrKey: string }) => {
  const [field, meta, helpers] = useField<string>(`ctr_values.${ctrKey}`);
  const { setValue } = helpers;
  const showError = !!meta.error && meta.touched;

  const initialValue = String(Math.round(Number(field.value) * 10000) / 100);
  const [localValue, setLocalValue] = useState(initialValue);

  const handleChange = (value: string) => {
    if (isNaN(Number(value))) {
      return;
    }

    setLocalValue(value);
  };

  const handleBlur = () => setValue(String(Math.round(Number(localValue) * 100) / 10000));

  return <Input onBlur={handleBlur} value={localValue} onChange={handleChange} suffix='%' className='bg-base-leap' hasError={showError} />;
};

export default CTRInput;

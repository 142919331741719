const RecentActorsIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M21 6V18C21 18.55 21.45 19 22 19C22.55 19 23 18.55 23 18V6C23 5.45 22.55 5 22 5C21.45 5 21 5.45 21 6ZM18 19C18.55 19 19 18.55 19 18V6C19 5.45 18.55 5 18 5C17.45 5 17 5.45 17 6V18C17 18.55 17.45 19 18 19ZM14 5H2C1.45 5 1 5.45 1 6V18C1 18.55 1.45 19 2 19H14C14.55 19 15 18.55 15 18V6C15 5.45 14.55 5 14 5ZM8 7.75C9.24 7.75 10.25 8.76 10.25 10C10.25 11.24 9.24 12.25 8 12.25C6.76 12.25 5.75 11.24 5.75 10C5.75 8.76 6.76 7.75 8 7.75ZM12.5 17H3.5V16.25C3.5 14.75 6.5 14 8 14C9.5 14 12.5 14.75 12.5 16.25V17Z'
      fill='#94A3B8'
    />
  </svg>
);

export default RecentActorsIcon;

import { SideBar } from '@/components';
import { FC, PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';

const SidebarLayout: FC<PropsWithChildren> = () => {
  return (
    <main className='flex h-screen w-full'>
      <SideBar />
      <Outlet />
    </main>
  );
};

export default SidebarLayout;

import { round } from '@/utils/numbers';
import cn from '@/utils/style';
import { FC, useMemo } from 'react';

interface BacklinkDADistributionTooltipContentProps {
  subject: DADistribution;
  competitor: DADistribution;
  title: string;
}

type BacklinkDATooltipColumns = {
  titles: Array<string>;
  subject: Array<string>;
  competitor: Array<string>;
};

const anchorTextBucketTitles: Record<keyof DADistribution, string> = {
  '1to150': '1 to 150',
  '150to300': '150 to 300',
  '300to500': '300 to 500',
  '500to750': '500 to 750',
  '750+': '750+',
};

const BacklinkDADistributionTooltipContent: FC<BacklinkDADistributionTooltipContentProps> = ({ subject, competitor, title }) => {
  const clientTotal = useMemo(() => Object.values(subject).reduce((total, val) => total + val, 0), [subject]);
  const competitorTotal = useMemo(() => Object.values(competitor).reduce((total, val) => total + val, 0), [competitor]);
  const columns = useMemo(() => {
    const initCols: BacklinkDATooltipColumns = {
      titles: [''],
      subject: ['Subject'],
      competitor: ['Competitor'],
    };

    return Object.keys(subject).reduce((cols, key) => {
      const typedKey = key as keyof DADistribution;
      cols.titles.push(anchorTextBucketTitles[typedKey]);
      cols.subject.push(`${round(clientTotal ? (subject[typedKey] * 100) / clientTotal : 0, 2)}%`);
      cols.competitor.push(`${round(competitorTotal ? (competitor[typedKey] * 100) / competitorTotal : 0, 2)}%`);

      return cols;
    }, initCols);
  }, [subject, competitor, clientTotal, competitorTotal]);

  const renderColumn = (col: string, columnIdx: number) => {
    const renderContent = (val: string | number, contentIdx: number) => {
      return (
        <div key={contentIdx} className={cn('flex items-center gap-4', columnIdx !== 0 ? 'justify-end' : 'mr-6')}>
          {columnIdx === 0 && contentIdx !== 0 && (
            <div
              className={cn(
                'h-2 w-2 rounded-full',
                contentIdx === 1 && 'bg-chart-0',
                contentIdx === 2 && 'bg-chart-1',
                contentIdx === 3 && 'bg-chart-2',
                contentIdx === 4 && 'bg-chart-3',
                contentIdx === 5 && 'bg-chart-4',
              )}
            ></div>
          )}
          <p className={cn(contentIdx === 0 && 'h-6')}>{val}</p>
        </div>
      );
    };

    return <div key={columnIdx}>{(columns[col as keyof BacklinkDATooltipColumns] || []).map(renderContent)}</div>;
  };

  return (
    <div>
      <p>{title}</p>
      <div className='flex items-center justify-between gap-2'>{Object.keys(columns).map(renderColumn)}</div>
    </div>
  );
};

export default BacklinkDADistributionTooltipContent;

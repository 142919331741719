import { Skeleton, VirtualizedTable } from '@/components';
import { useTable } from '@/hooks/table';
import { useGetCompanyUsedCreditsQuery } from '@/store/company/company.api';
import { getDateWithDefaultFormat } from '@/utils/dates';
import { createColumnHelper } from '@tanstack/react-table';
import { FC } from 'react';

const columnHelper = createColumnHelper<CompanyUsedCredits>();

const columns = [
  columnHelper.accessor('name', {
    header: () => <p className='pl-2 text-left'>Campaign</p>,
    cell: ({ getValue }) => <p className='text-left'>{getValue()}</p>,
  }),
  columnHelper.accessor('used_credits', {
    header: () => <p className='text-right'>Used Credits</p>,
    cell: ({ getValue }) => <p className='text-right'>{getValue()}</p>,
  }),
  columnHelper.accessor('date', {
    header: () => <p className='text-right'>Date</p>,
    cell: ({ getValue }) => <p className='text-right'>{getDateWithDefaultFormat(getValue())}</p>,
  }),
];

const UsedCredits: FC = () => {
  const { data: usedCredits, isLoading, isFetching } = useGetCompanyUsedCreditsQuery();

  const [table] = useTable({
    data: usedCredits?.results || [],
    columns: columns,
    tableCustomOptions: ['allowSort'],
  });

  if (isLoading || isFetching) {
    return <Skeleton className='h-96 w-full' />;
  }

  return (
    <div className='flex h-fit w-full flex-col gap-5 rounded-xl bg-white p-5'>
      <p className='text-left text-xl font-bold'>Used Credits</p>
      <VirtualizedTable
        table={table}
        name='usedCredits'
        className='max-h-[500px] min-h-fit'
        isLoading={isLoading}
        loadingText='Loading Credits History'
        tableClass='shadow-none'
        itemHeight={32}
      />
    </div>
  );
};

export default UsedCredits;

import { round } from '@/utils/numbers';
import cn from '@/utils/style';
import { FC, useMemo } from 'react';

interface AnchorTextTooltipContentProps {
  title: string;
  data: {
    subject: AnchorTextBuckets;
    competitor: AnchorTextBuckets;
    subjectTotal: number;
    competitorTotal: number;
  };
}

type AnchorTextTooltipColumns = {
  titles: Array<string>;
  subject: Array<string>;
  competitor: Array<string>;
};

const anchorTextBucketTitles: Record<keyof AnchorTextBuckets, string> = {
  partialMatch: 'Partial',
  exactMatch: 'Exact',
  other: 'Other',
};

const AnchorTextTooltipContent: FC<AnchorTextTooltipContentProps> = ({ title, data }) => {
  const { subject, competitor, subjectTotal, competitorTotal } = data;
  const columns = useMemo(() => {
    const initCols: AnchorTextTooltipColumns = {
      titles: [''],
      subject: ['Subject'],
      competitor: ['Competitor'],
    };

    return Object.keys(subject).reduce((cols, key) => {
      const typedKey = key as keyof AnchorTextBuckets;
      cols.titles.push(anchorTextBucketTitles[typedKey]);
      cols.subject.push(`${round(subjectTotal ? (subject[typedKey] * 100) / subjectTotal : 0, 2)}%`);
      cols.competitor.push(`${round(competitorTotal ? (competitor[typedKey] * 100) / competitorTotal : 0, 2)}%`);

      return cols;
    }, initCols);
  }, [subject, competitor, subjectTotal, competitorTotal]);

  const renderColumn = (col: string, columnIdx: number) => {
    const renderContent = (val: string | number, contentIdx: number) => {
      return (
        <div key={contentIdx} className={cn('flex items-center gap-4', columnIdx !== 0 ? 'justify-end' : 'mr-6')}>
          {columnIdx === 0 && contentIdx !== 0 && (
            <div className={cn('h-2 w-2 rounded-full', contentIdx === 1 && 'bg-chart-0', contentIdx === 2 && 'bg-chart-1', contentIdx === 3 && 'bg-chart-2')}></div>
          )}
          <p className={cn(contentIdx === 0 && 'h-6')}>{val}</p>
        </div>
      );
    };

    return <div key={columnIdx}>{(columns[col as keyof AnchorTextTooltipColumns] || []).map(renderContent)}</div>;
  };

  return (
    <div>
      <p>{title}</p>
      <div className='flex items-center justify-between gap-2'>{Object.keys(columns).map(renderColumn)}</div>
    </div>
  );
};

export default AnchorTextTooltipContent;

const SlowMotionVideoIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M9.89146 8.50474V15.5047C9.89146 15.9147 10.3615 16.1547 10.6915 15.9047L15.3615 12.4047C15.6315 12.2047 15.6315 11.8047 15.3615 11.6047L10.6915 8.10474C10.3615 7.85474 9.89146 8.09474 9.89146 8.50474ZM10.8915 3.23474C10.8915 2.59474 10.3015 2.10474 9.68146 2.24474C8.56146 2.50474 7.50146 2.94474 6.56146 3.54474C6.03146 3.88474 5.95146 4.64474 6.40146 5.09474C6.72146 5.41474 7.23146 5.49474 7.61146 5.25474C8.38146 4.76474 9.23146 4.40474 10.1515 4.20474C10.5915 4.10474 10.8915 3.69474 10.8915 3.23474ZM4.99146 6.51474C4.53146 6.06474 3.78146 6.13474 3.44146 6.67474C2.84146 7.61474 2.40146 8.67474 2.14146 9.79474C2.00146 10.4147 2.48146 11.0047 3.12146 11.0047C3.57146 11.0047 3.99146 10.7047 4.08146 10.2647C4.28146 9.35474 4.65146 8.49474 5.13146 7.73474C5.39146 7.34474 5.31146 6.83474 4.99146 6.51474ZM3.12146 13.0047C2.48146 13.0047 1.99146 13.5947 2.13146 14.2147C2.39146 15.3347 2.83146 16.3847 3.43146 17.3347C3.77146 17.8747 4.53146 17.9447 4.98146 17.4947C5.30146 17.1747 5.38146 16.6647 5.13146 16.2847C4.64146 15.5247 4.28146 14.6747 4.08146 13.7547C3.99146 13.3047 3.58146 13.0047 3.12146 13.0047ZM6.56146 20.4547C7.51146 21.0547 8.56146 21.4947 9.68146 21.7547C10.3015 21.8947 10.8915 21.4047 10.8915 20.7747C10.8915 20.3247 10.5915 19.9047 10.1515 19.8147C9.24146 19.6147 8.38146 19.2447 7.62146 18.7647C7.23146 18.5247 6.73146 18.5947 6.41146 18.9247C5.95146 19.3647 6.02146 20.1147 6.56146 20.4547ZM21.8915 12.0047C21.8915 16.7347 18.5915 20.7147 14.1615 21.7447C13.5415 21.8947 12.9415 21.4047 12.9415 20.7647C12.9415 20.3047 13.2515 19.9047 13.6915 19.7947C17.2415 18.9747 19.8915 15.7947 19.8915 12.0047C19.8915 8.21474 17.2415 5.03474 13.6915 4.21474C13.2515 4.11474 12.9415 3.70474 12.9415 3.24474C12.9415 2.60474 13.5415 2.11474 14.1615 2.26474C18.5915 3.29474 21.8915 7.27474 21.8915 12.0047Z'
      fill='#94A3B8'
    />
  </svg>
);

export default SlowMotionVideoIcon;

import { Avatar, Modal, ModalProps, SearchBarDebounce, SelectFilter } from '@/components';
import { INITIAL_PAGE_SIZE } from '@/constants/pagination';
import { selectAuthUser } from '@/store/auth/auth.selector';
import { useUsersListQuery } from '@/store/users/users.api';
import cn from '@/utils/style';
import { EyeIcon, PencilSquareIcon, UserCircleIcon, XCircleIcon } from '@heroicons/react/20/solid';
// import { EyeIcon, PencilSquareIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';

interface CollaboratorModalProps extends Omit<ModalProps, 'onConfirm'> {
  onConfirm: (collaborators: Array<CampaignUser>) => void;
  campaignId: number;
}

const COLLABORATOR_ROLE_OPTIONS: Array<OptionType<UserRole | 'no_access'>> = [
  {
    label: 'Can Edit',
    value: 'manager',
  },
  {
    label: 'Can View',
    value: 'viewer',
  },
  {
    label: 'No Access',
    value: 'no_access',
  },
];

const COLLABORATOR_FILTER_ROLES = [
  {
    label: 'All',
    value: 'all',
    icon: <UserCircleIcon className='h-4' />,
  },
  {
    label: 'Editor',
    value: 'manager',
    icon: <PencilSquareIcon className='h-4' />,
  },
  {
    label: 'Viewer',
    value: 'viewer',
    icon: <EyeIcon className='h-4' />,
  },
  {
    label: 'No Access',
    value: 'no_access',
    icon: <XCircleIcon className='h-4' />,
  },
];

const getDisplayedUsers = (users: User[], user: User | null, campaignId: number) => {
  return users
    .filter(({ id }) => id !== user?.id)
    .sort((a, b) => {
      const aRole = a.campaigns.find(({ id }) => id === campaignId)?.role;
      const bRole = b.campaigns.find(({ id }) => id === campaignId)?.role;

      if (['owner', 'creator'].includes(aRole || '')) {
        return -1;
      }

      if (['owner', 'creator'].includes(bRole || '')) {
        return 1;
      }

      return 0;
    });
};

const CollaboratorModal: FC<CollaboratorModalProps> = ({ isOpen, onClose, onConfirm, campaignId }) => {
  const user = useSelector(selectAuthUser);
  const [displayedUsers, setDisplayedUsers] = useState<Array<User>>([]);
  const [usersRole, setUsersRole] = useState<Record<string, UserRole | 'no_access'>>({});
  const [filterBy, setFilterBy] = useState<UserRole | 'no_access' | 'all' | undefined>(undefined);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const { inView } = useInView({ threshold: 0.1 });

  const rolesDirty = useMemo(() => {
    return displayedUsers.some((user) => {
      let role: UserRole | 'no_access' | undefined = user.campaigns.find((camp) => camp.id === campaignId)?.role;
      if (!role) {
        role = 'no_access';
      }
      return role !== usersRole[user.id];
    });
  }, [campaignId, displayedUsers, usersRole]);

  const {
    data: users,
    isLoading: usersLoading,
    isFetching,
  } = useUsersListQuery({
    search,
    page,
    page_size: 0,
    filter: {
      campaignId,
      role: filterBy,
    },
  });
  const hasNextPage = useMemo(() => users && users.next, [users]);

  const authUserRole = useMemo(() => {
    const role = user && (user.campaigns || []).find((camp) => camp.id === campaignId)?.role;

    return user?.isSuperuser ? 'Super User' : role || 'viewer';
  }, [campaignId, user]);

  useEffect(() => {
    if (!users?.results) {
      return;
    }

    setDisplayedUsers(getDisplayedUsers(users.results, user, campaignId));
  }, [campaignId, users?.results, user]);

  useEffect(() => {
    if (filterBy) {
      return;
    }
    setUsersRole(
      displayedUsers.reduce(
        (roles, user) => ({
          ...roles,
          [user.id]: user.campaigns.find((camp) => camp.id === campaignId)?.role || 'no_access',
        }),
        {},
      ),
    );
  }, [campaignId, displayedUsers, filterBy]);

  const handleSearch = useCallback((value: string) => {
    setPage(1);
    setSearch(value);
  }, []);

  // const changeUsersRoleTo = (role: UserRole | 'no_access') => {
  //   setUsersRole((prev) => {
  //     const newRoles: Record<string, UserRole | 'no_access'> = {};
  //     Object.keys(prev).forEach((key) => (newRoles[key] = role));

  //     return newRoles;
  //   });
  // };

  const handleCollaboratorConfirm = () => {
    const collaborators = Object.keys(usersRole)
      .filter((key) => usersRole[key] !== 'no_access')
      .map((userId) => ({
        id: Number(userId),
        role: usersRole[userId] as UserRole,
      }));

    onConfirm(collaborators);
  };

  const handleCollaboratorFilterChange = useCallback((value: string) => {
    setPage(1);
    if (value === 'all') {
      return setFilterBy(undefined);
    }
    setFilterBy(value as UserRole | 'no_access');
  }, []);

  const renderCollaborators = (collaborator: User) => {
    const collaboratorRole = collaborator.campaigns.find((campaign) => campaign.id === campaignId)?.role;
    const isOwnerOrCreator = collaboratorRole && (collaboratorRole === 'owner' || collaboratorRole === 'creator');

    const handleUserRoleChange = (value: string) => {
      setUsersRole((prev) => ({
        ...prev,
        [collaborator.id]: value as UserRole,
      }));
    };

    return (
      <div key={collaborator.id} className='flex w-full items-center justify-between py-1'>
        <Avatar user={collaborator} />
        {isOwnerOrCreator ? (
          <p>{collaboratorRole}</p>
        ) : (
          <SelectFilter
            options={COLLABORATOR_ROLE_OPTIONS}
            value={usersRole[collaborator.id]}
            onChange={handleUserRoleChange}
            optionsClassName='w-32 ml-11'
            className='w-44 pl-16'
          />
        )}
      </div>
    );
  };

  useEffect(() => {
    if (!isFetching && inView && hasNextPage) {
      setPage((old) => old + 1);
    }
  }, [hasNextPage, isFetching, inView]);

  if (!user) {
    return null;
  }

  const renderUsers = () => {
    if (usersLoading || isFetching) {
      return <p className='text-gray-500'>Loading users...</p>;
    }

    if (displayedUsers.length === 0) {
      return <p className='text-gray-500'>No users found</p>;
    }

    return displayedUsers.map((collaborator) => renderCollaborators(collaborator));
  };

  return (
    <Modal isOpen={isOpen} title='Collaborator Access' onClose={onClose} onConfirm={handleCollaboratorConfirm} className='min-w-modal'>
      <div className='flex flex-col gap-y-4'>
        <SearchBarDebounce initialValue='' onChange={handleSearch} delay={500} inputClassName='w-full' />
        <div className='flex items-center gap-4'>
          <p className='font-bold text-gray-500'>Filter By:</p>
          {COLLABORATOR_FILTER_ROLES.map((role) => {
            const isAll = role.value === 'all' && !filterBy;
            return (
              <button
                disabled={rolesDirty}
                key={role.value}
                className={cn(
                  'flex cursor-pointer items-center gap-1 text-sky-600 duration-300 disabled:opacity-50',
                  (isAll || filterBy === role.value) && 'underline-sky-600 underline underline-offset-4',
                )}
                onClick={() => handleCollaboratorFilterChange(role.value)}
              >
                {role.icon}
                <p>{role.label}</p>
              </button>
            );
          })}
        </div>

        <div className='flex items-center justify-between'>
          <Avatar user={user} />
          <p className='font-bold capitalize'>{authUserRole}</p>
        </div>

        <div className='h-96 overflow-y-auto'>{renderUsers()}</div>
        {/* <div ref={ref} className='h-8 '>
          {hasNextPage && <Loading className='mx-auto size-8 ' />}
        </div> */}
      </div>
    </Modal>
  );
};

export default CollaboratorModal;

import { Button, Modal, ModalProps } from '@/components';
import { FC } from 'react';

const ContinueWithoutAllocationsModal: FC<ModalProps> = ({ isOpen, onClose, onConfirm }) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen} title=''>
      <div className='mb-4 flex w-[450px] flex-col items-center gap-4'>
        <h4 className='text-center'>Continue without allocating Priority Keywords?</h4>
        <Button className='w-full' onClick={onConfirm}>
          Continue without allocating
        </Button>
        <Button className='w-full' onClick={onClose}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default ContinueWithoutAllocationsModal;

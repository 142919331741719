import { numberFilter, stringFilter } from '@/utils/filters';
import { parseSortFilters } from '@/utils/table';
import {
  ColumnDef,
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  PaginationState,
  Row,
  RowSelectionState,
  SortingState,
  Table,
  TableOptions,
  Updater,
  useReactTable,
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';

const useTableHandlersWithFilters = (filters: Partial<ExtraFilters>) => {
  const { page, page_size, sort } = filters;

  const sorting = useMemo<SortingState>(() => {
    if (sort && !Array.isArray(sort)) {
      filters.sort = [sort];
    }

    return (filters.sort ?? []).map(parseSortFilters);
  }, [filters, sort]);

  const pagination = useMemo<PaginationState>(
    () => ({
      pageIndex: Number(filters.page) - 1,
      pageSize: Number(filters.page_size),
    }),
    [filters.page, filters.page_size],
  );

  const handlePaginationChange = (updater: Updater<PaginationState>) => {
    if (typeof updater === 'function') {
      updater({
        pageIndex: Number(page),
        pageSize: Number(page_size),
      });
    }
  };

  const handleSortingChange = (updater: Updater<SortingState>) => {
    if (typeof updater === 'function') {
      updater((sort || []).map(parseSortFilters));
    }
  };

  return { sorting, pagination, handlePaginationChange, handleSortingChange };
};

type CustomOpts = 'allowSort' | 'allowResize' | 'allowSelect' | 'allowFilters' | 'allowExpand';
type TableCustomOptions = Array<CustomOpts>;

export interface TableV2Props<T> {
  data: Array<T>;
  columns: Array<ColumnDef<T, any>>; // eslint-disable-line @typescript-eslint/no-explicit-any
  tableCustomOptions?: Partial<TableCustomOptions>;
  tableOptions?: Partial<TableOptions<T>>;
  defaultSorting?: SortingState;
}

export type UseTableOptions<T> = Omit<TableV2Props<T>, 'data' | 'columns'>;

const useTable = <T extends unknown>({ data, columns, tableOptions, tableCustomOptions = [], defaultSorting = [] }: TableV2Props<T>): [Table<T>, Array<Row<T>>] => {
  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({});
  const [sortingState, setSortingState] = useState<SortingState>(defaultSorting);
  const [expandedState, setExpandedState] = useState<ExpandedState>({});

  const tableOpts = useMemo(() => {
    const opts = {
      ...tableOptions,
    };

    if (tableCustomOptions.includes('allowResize')) {
      opts.columnResizeMode = 'onChange';
    }

    if (tableCustomOptions.includes('allowSelect')) {
      opts.onRowSelectionChange = setSelectedRows;
      opts.enableRowSelection = true;
      opts.state = {
        ...opts.state,
        rowSelection: selectedRows,
      };
    }

    if (tableCustomOptions.includes('allowSort')) {
      opts.getSortedRowModel = getSortedRowModel();
      opts.enableSorting = true;
      opts.onSortingChange = setSortingState;
      opts.state = {
        ...opts.state,
        sorting: sortingState,
      };
    }

    if (tableCustomOptions.includes('allowFilters')) {
      opts.getFilteredRowModel = getFilteredRowModel();
      opts.enableColumnFilters = true;
      opts.enableGlobalFilter = true;
      opts.filterFns = {
        ...opts.filterFns,
        stringFilter: stringFilter,
        numberFilter: numberFilter,
      };
    }

    if (tableCustomOptions.includes('allowExpand')) {
      opts.getExpandedRowModel = getExpandedRowModel();
      opts.enableExpanding = true;
      opts.onExpandedChange = setExpandedState;
      opts.state = {
        ...opts.state,
        expanded: expandedState,
      };
    }

    return opts;
  }, [tableOptions, tableCustomOptions, sortingState, selectedRows, expandedState]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    ...tableOpts,
  });

  const { flatRows } = table.getRowModel();

  return [table, flatRows];
};

const useTableOptions = <T extends unknown>({ tableCustomOptions = [], defaultSorting = [], tableOptions }: UseTableOptions<T>) =>
  useMemo(
    () => ({
      tableCustomOptions,
      defaultSorting,
      tableOptions,
    }),
    [tableCustomOptions, defaultSorting, tableOptions],
  );

export { useTableHandlersWithFilters, useTable, useTableOptions };

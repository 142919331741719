import { useContext } from 'react';
import { TutorialContext } from '@/components/Tutorial/components';

const useTutorialContext = () => {
  const context = useContext(TutorialContext);

  if (!context) {
    throw new Error('useTutorialContext must be used within a TutorialProvider');
  }

  return context;
};

export default useTutorialContext;

import { Skeleton, VirtualizedTable } from '@/components';
import { useTable } from '@/hooks/table';
import { useGetInvoiceListQuery } from '@/store/payment/payment.api';
import { getDateWithDefaultFormat } from '@/utils/dates';
import { getCurrencyFormat } from '@/utils/numbers';
import { createColumnHelper } from '@tanstack/react-table';
import { FC } from 'react';

const columnHelper = createColumnHelper<Invoice>();

const columns = [
  columnHelper.accessor('type', {
    header: () => <p className='pl-2 text-left'>Type</p>,
    cell: ({ getValue }) => <p className='text-left'>{getValue()}</p>,
  }),
  columnHelper.accessor('credits', {
    header: () => <p className='text-right'>Credits</p>,
    cell: ({ getValue }) => <p className='text-right'>{getValue()}</p>,
  }),
  columnHelper.accessor('cost', {
    header: () => <p className='text-right'>Cost</p>,
    cell: ({ getValue }) => <p className='text-right'>{getCurrencyFormat(getValue())}</p>,
  }),
  columnHelper.accessor('date', {
    header: () => <p className='text-right'>Date</p>,
    cell: ({ getValue }) => <p className='text-right'>{getDateWithDefaultFormat(getValue())}</p>,
  }),
];

const BillingHistory: FC = () => {
  const { data: billingHistory, isLoading, isFetching } = useGetInvoiceListQuery();

  const [table] = useTable({
    data: billingHistory?.results || [],
    columns: columns,
    tableCustomOptions: ['allowSort'],
  });

  if (isLoading || isFetching) {
    return <Skeleton className='h-96 w-full' />;
  }

  return (
    <div className='flex h-fit w-full flex-col gap-5 rounded-xl bg-white p-5'>
      <p className='text-left text-xl font-bold'>Billing History</p>
      <VirtualizedTable
        table={table}
        name='billingHistory'
        className='max-h-[500px] min-h-fit'
        isLoading={isLoading}
        loadingText='Loading Billing History'
        tableClass='shadow-none'
        itemHeight={32}
      />
    </div>
  );
};

export default BillingHistory;

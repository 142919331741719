import { Button, Loading } from '@/components';
import { useGetUserNotificationsQuery, useMarkNotificationAsReadMutation } from '@/store/users/users.api';
import cn from '@/utils/style';
import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import Popover from '../Popover';
import Notification from './Notification';
import NotificationButton from './NotificationButton';
import { INITIAL_PAGE } from '@/constants/pagination';
import { useInView } from 'react-intersection-observer';

interface NotificationsPopoverProps {
  isSidebarOpen: boolean;
}

const NotificationsPopover: FC<NotificationsPopoverProps> = ({ isSidebarOpen }) => {
  const [page, setPage] = useState(INITIAL_PAGE);
  const { data: notifications, isFetching } = useGetUserNotificationsQuery({ page });

  const { ref, inView } = useInView({
    threshold: 0.1,
  });

  const hasNextPage = useMemo(() => notifications && notifications.next, [notifications]);

  const [markAsRead] = useMarkNotificationAsReadMutation();
  const hasPendingNotifications = useMemo(() => {
    if (notifications) {
      return notifications.results.some((n) => n.read_at === null);
    }
  }, [notifications]);

  const renderNotifications = (notification: LeapNotification) => {
    return <Notification notification={notification} key={notification.id} />;
  };

  const hasUnreadNotifications = notifications && notifications.results.some((notification) => !notification.read_at);

  const handleMarkAllAsRead = async () => {
    if (!notifications) {
      return false;
    }

    for (const notification of notifications.results.filter((i) => !i.read_at)) {
      await markAsRead({ notification });
    }
  };

  useEffect(() => {
    if (!isFetching && inView && hasNextPage) {
      setPage((old) => old + 1);
    }
  }, [hasNextPage, isFetching, inView]);

  return (
    <Popover
      title={<NotificationButton hasPendingNotifications={hasPendingNotifications} isSidebarOpen={isSidebarOpen} />}
      titleClassName='w-full'
      showClose
      inner={<h2 className='my-2 ml-2 text-xl font-semibold'>Notifications</h2>}
      className={cn(
        'inset-0 -top-[400px] h-[490px] w-[400px] overflow-visible rounded-2xl border-0 px-0 py-4 leading-6 text-black shadow-lg duration-100 2xl:h-[510px]',
        isSidebarOpen ? 'ml-[250px]' : 'ml-[100px]',
      )}
    >
      {notifications && notifications.results && (
        <div className='relative'>
          {notifications.results.length > 0 && (
            <Fragment>
              <div className='h-[365px] overflow-scroll'>
                {notifications.results.map(renderNotifications)}
                <div ref={ref}>{hasNextPage && <Loading className='mx-auto mb-2 mt-4 w-8' />}</div>
              </div>
              <div className='px-4 pt-4'>
                <Button className='mb-0 mt-auto w-full' disabled={!hasUnreadNotifications} onClick={handleMarkAllAsRead} variant='outline-light'>
                  Mark all as read
                </Button>
              </div>
            </Fragment>
          )}
          {!notifications.results.length && <p className='mx-auto mt-12 text-center text-sm'>No notifications</p>}
          <div className='absolute -left-2 bottom-2 h-5 w-5 origin-center rotate-45 bg-white shadow-lg' />
        </div>
      )}
    </Popover>
  );
};

export default NotificationsPopover;

const INITIAL_PAGE = 1;
const INITIAL_PAGE_SIZE = 10;
const INITIAL_ORDERING = '-created_at';
const PAGINATION_STEPS = [
  {
    label: '10',
    value: 10,
  },
  {
    label: '20',
    value: 20,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
];

export { INITIAL_PAGE, INITIAL_PAGE_SIZE, PAGINATION_STEPS, INITIAL_ORDERING };

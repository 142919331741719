import { SVGProps } from 'react';

const ProSubIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox='0 0 28 28' fill='currentColor' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g id='vip 1'>
        <path
          id='Vector'
          d='M3.30609 5.97015L8.18806 9.16532L13.0538 2.47829C13.1613 2.33037 13.3032 2.20979 13.4678 2.1266C13.6324 2.04341 13.8148 2 13.9999 2C14.1851 2 14.3675 2.04341 14.5321 2.1266C14.6967 2.20979 14.8386 2.33037 14.9461 2.47829L19.8118 9.16532L24.6938 5.97015C24.8786 5.84947 25.0947 5.78324 25.3165 5.77928C25.5384 5.77532 25.7568 5.8338 25.9459 5.94781C26.135 6.06181 26.2869 6.22659 26.3836 6.42265C26.4805 6.6187 26.518 6.83787 26.492 7.05423L24.5822 22.9924C24.5488 23.27 24.4129 23.5259 24.2002 23.7116C23.9874 23.8973 23.7126 24 23.4279 24H4.57191C4.28721 24 4.01242 23.8973 3.79967 23.7116C3.58691 23.5259 3.45099 23.27 3.41767 22.9924L1.50789 7.05309C1.48209 6.83682 1.51985 6.61782 1.61672 6.42194C1.71359 6.22606 1.86551 6.06148 2.05457 5.94763C2.24363 5.83376 2.46192 5.77538 2.68368 5.77938C2.90544 5.78337 3.12141 5.84957 3.30609 5.97015ZM13.9999 17.1532C14.6165 17.1532 15.2078 16.9128 15.6438 16.4847C16.0798 16.0567 16.3247 15.4762 16.3247 14.871C16.3247 14.2657 16.0798 13.6852 15.6438 13.2572C15.2078 12.8291 14.6165 12.5887 13.9999 12.5887C13.3834 12.5887 12.7921 12.8291 12.3561 13.2572C11.9201 13.6852 11.6752 14.2657 11.6752 14.871C11.6752 15.4762 11.9201 16.0567 12.3561 16.4847C12.7921 16.9128 13.3834 17.1532 13.9999 17.1532Z'
          fill='currentColor'
        />
      </g>
    </svg>
  );
};

export default ProSubIcon;

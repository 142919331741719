import { SeparatorLine, Skeleton } from '@/components';
import { PageSkeleton } from '@/layouts/Page';
import { FC } from 'react';

const RefineSkeleton = () => (
  <div className='flex w-full flex-row items-center justify-between pb-6'>
    <Skeleton className='h-10 w-64' />
    <div className='flex items-center gap-5'>
      <Skeleton className='h-10 w-40' />
      <Skeleton className='h-10 w-40' />
      <Skeleton className='h-10 w-28' />
    </div>
  </div>
);

const ScenarioSkeleton = () => <Skeleton className='h-44 w-72' />;

const AnalysisPageSkeleton: FC = () => {
  return (
    <PageSkeleton>
      <div className='w-full'>
        <RefineSkeleton />
        <SeparatorLine className='my-[13px] animate-pulse' />
        <div className='flex items-center gap-6 py-8'>
          <ScenarioSkeleton />
          <ScenarioSkeleton />
          <ScenarioSkeleton />
          <ScenarioSkeleton />
        </div>
        <Skeleton className='h-[700px]' />
      </div>
    </PageSkeleton>
  );
};

export default AnalysisPageSkeleton;

import Button from '@/components/Button';
import cn from '@/utils/style';
import { FC } from 'react';
import { TooltipRenderProps } from 'react-joyride';

interface TutorialTooltipProps extends TooltipRenderProps {
  stepsCount: number;
}

const TutorialTooltip: FC<TutorialTooltipProps> = ({ step, tooltipProps, stepsCount, backProps, index, primaryProps, isLastStep }) => {
  return (
    <div {...tooltipProps} className='relative flex w-full min-w-[430px] max-w-md flex-col gap-1 rounded-lg bg-white p-6'>
      <p>
        Step {index + 1} of {stepsCount}
      </p>
      {step.title && <h3 className='font-bold'>{step.title}</h3>}
      <p className='leading-6'>{step.content}</p>
      <footer className={cn('mt-6 flex items-center', index > 0 ? 'justify-between' : 'justify-end')}>
        {index > 0 && (
          <button {...backProps} className='text-sky-600'>
            Previous
          </button>
        )}
        <Button {...primaryProps}>{isLastStep ? 'Finish' : 'Next'}</Button>
      </footer>
    </div>
  );
};

export default TutorialTooltip;

import { useField } from 'formik';
import { Select, Label, ErrorMessage } from '@/components';
import { SelectProps } from '../Select/Select';

type selectFieldProps<T> = Omit<SelectProps<T>, 'value' | 'onChange'> & {
  name: string;
  label?: string;
};

const SelectField = <T extends unknown>({ name, label, ...selectProps }: selectFieldProps<T>) => {
  const [field, meta, helpers] = useField<T>(name);
  const { setValue } = helpers;
  const showError = !!meta.error && meta.touched;

  return (
    <div className='flex w-full flex-col gap-2'>
      {label && <Label>{label}</Label>}
      <Select {...selectProps} value={field.value} onChange={setValue}></Select>
      {showError && <ErrorMessage>{meta.error}</ErrorMessage>}
    </div>
  );
};

export default SelectField;

import store from '@/store';
import usersAPI from '@/store/users/users.api';
import { Fragment, Suspense, useEffect } from 'react';
import { Await, LoaderFunction, defer, useLoaderData, useLocation, useNavigate } from 'react-router-dom';
import { NewUserModal, UsersCards } from '../../components';
import { useToggle } from '@/hooks/toggle';
import UsersPageSkeleton from './UsersPageSkeleton';

const UsersPage = () => {
  const { data, error } = useLoaderData() as PageLoaderDefData<APIListResponse<User>>;
  const { value: isNewUserModalOpen, toggle: toggleNewUserModal } = useToggle();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname.includes('new-user')) {
      toggleNewUserModal(true);
      return;
    }

    toggleNewUserModal(false);
  }, [location, toggleNewUserModal]);

  return (
    <Fragment>
      <Suspense fallback={<UsersPageSkeleton />}>
        <Await
          resolve={data}
          errorElement={
            error && (
              <p>
                Something went wrong, {error.status} - {error.data.message}
              </p>
            )
          }
        >
          {(data: Page<User>) => {
            const users = data?.results || [];

            return (
              <Fragment>
                <UsersCards users={users} />
                {isNewUserModalOpen && <NewUserModal isOpen={isNewUserModalOpen} onClose={() => navigate('/user-management/users')} />}
              </Fragment>
            );
          }}
        </Await>
      </Suspense>
    </Fragment>
  );
};

const usersPageLoader: LoaderFunction = async () => {
  try {
    return defer({ data: store.dispatch(usersAPI.endpoints.usersList.initiate({ page_size: 100, page: 1 })).unwrap() });
  } catch (e) {
    return {
      error: e as APIError,
    };
  }
};

export { usersPageLoader };
export default UsersPage;

import { createContext, Dispatch, SetStateAction } from 'react';

interface KeywordContextState {
  priorities: Array<Priority>;
  setPriorities: Dispatch<SetStateAction<Array<Priority>>>;
  changePriority: (data: Priority) => void;

  changeAllPriorities: (rows: Array<RefineCampaignKeyword>, value: number) => void;
}

const initialState: KeywordContextState = {
  priorities: [],
  setPriorities: () => {},
  changePriority: () => {},
  changeAllPriorities: () => {},
};

const KeywordContext = createContext(initialState);

export default KeywordContext;
export { initialState };

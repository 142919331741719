import visaLogo from '@/assets/images/visa-icon.svg';
import { TokenIcon } from '@/components';
import { useToggle } from '@/hooks/toggle';
import {
  useGetPaymentMethodsQuery,
  useGetSubscriptionListQuery,
  useUpdateCustomerDefaultPaymentMethodMutation,
  useCreateInvoiceMutation,
  useGetLinkLaunchProductPricesQuery,
} from '@/store/payment/payment.api';
import { FC, Fragment, useCallback, useMemo, useState } from 'react';
import { PaymentCard, PaymentMethodModal, PurchaseModal, SubscriptionModal, PurchaseCreditsModal } from './components';
import PaymentHeaderSkeleton from './PaymentHeaderSkeleton';
import { useGetCurrentUserQuery } from '@/store/users/users.api';

const PaymentHeader: FC = () => {
  const { data: user } = useGetCurrentUserQuery();
  const { data: subscriptions, isLoading: loadingSubscriptions, isFetching: fetchingSubscriptions } = useGetSubscriptionListQuery();
  const { data: paymentMethods, isLoading: loadingPaymentMethods, isFetching: fetchingPaymentMethods } = useGetPaymentMethodsQuery();
  const { data: linkLaunchPrices } = useGetLinkLaunchProductPricesQuery();
  const [updateDefaultMethod, { isLoading }] = useUpdateCustomerDefaultPaymentMethodMutation();
  const [createInvoice, { isLoading: isInvoiceCreating }] = useCreateInvoiceMutation();

  const { value: isPurchaseModalOpen, toggle: togglePurchaseModal } = useToggle();
  const { value: isSubscriptionModalOpen, toggle: toggleSubscriptionModal } = useToggle();
  const { value: isPaymenMethodModalOpen, toggle: togglePaymenMethodModal } = useToggle();
  const { value: isPurchaseCreditsModalOpen, toggle: togglePurchaseCreditsModal } = useToggle();

  const defaultPaymentMethod = useMemo(() => paymentMethods?.results.find((pm) => pm.default), [paymentMethods]);
  const activeSubscription = useMemo(() => subscriptions?.results.find((sub) => sub.status === 'active'), [subscriptions]);
  const activeSubscriptionPrice = useMemo(() => activeSubscription?.items.find((item) => item.active), [activeSubscription?.items]);

  const [creditsInvoiceId, setCreditsInvoiceId] = useState<string>('');
  const [creditsQuantity, setCreditsQuantity] = useState<number>();

  const oneTimePrice = useMemo(() => linkLaunchPrices?.results.find((price) => (price.lookup_key as string) === 'one-time'), [linkLaunchPrices]);

  const CARDS = useMemo(
    () =>
      [
        {
          id: 'balance',
          title: 'Credits Balance',
          Content: () => (
            <div className='flex items-center gap-4'>
              <TokenIcon className='w-10 text-white' />
              <p className='text-4xl font-bold text-white'>{user?.company?.credits?.dataforseo || '-'}</p>
            </div>
          ),
          onClick: () => togglePurchaseModal(true),
          buttonText: 'Purchase Additional Credits',
          selected: true,
        },
        {
          id: 'subscription',
          title: 'My Subscription',
          Content: () => <p className='text-4xl font-bold capitalize'>{activeSubscriptionPrice?.nickname || 'No Subscription Found'}</p>,
          onClick: () => toggleSubscriptionModal(true),
          buttonText: 'View Other Options',
          selected: false,
        },
        {
          id: 'payment',
          title: 'Payment method',
          Content: () => (
            <div className='flex w-full items-center justify-between rounded-xl bg-slate-100 p-3'>
              {defaultPaymentMethod ? (
                <Fragment>
                  <p>Card ending in {defaultPaymentMethod?.card.last4}</p>
                  <img src={visaLogo} alt='visa' />
                </Fragment>
              ) : (
                <p>No default payment method</p>
              )}
            </div>
          ),
          onClick: () => togglePaymenMethodModal(true),
          buttonText: 'Change',
          selected: false,
        },
      ] as const,
    [togglePurchaseModal, toggleSubscriptionModal, togglePaymenMethodModal, defaultPaymentMethod, activeSubscriptionPrice, user?.company?.credits?.dataforseo],
  );

  const handleBuyCredits = async (quantity: number) => {
    if (!oneTimePrice) {
      return;
    }

    const invoice = await createInvoice({ quantity, price: oneTimePrice.id }).unwrap();

    setCreditsInvoiceId(invoice.data.id);
    setCreditsQuantity(quantity);

    togglePurchaseModal(false);
    togglePurchaseCreditsModal(true);
  };

  const handleDefaultMethodSelected = useCallback(
    async (methodId: string) => {
      await updateDefaultMethod({ id: methodId });
      togglePaymenMethodModal(false);
    },
    [updateDefaultMethod, togglePaymenMethodModal],
  );

  const renderCards = ({ id, Content, buttonText, onClick, title, selected }: typeof CARDS[number]) => {
    return (
      <PaymentCard key={id} buttonText={buttonText} onClick={onClick} selected={selected} title={title}>
        <Content />
      </PaymentCard>
    );
  };

  if (loadingSubscriptions || fetchingSubscriptions || loadingPaymentMethods || fetchingPaymentMethods) {
    return <PaymentHeaderSkeleton />;
  }

  return (
    <Fragment>
      <section className='grid grid-cols-3 gap-6 pb-6'>{CARDS.map(renderCards)}</section>

      {isPurchaseModalOpen && <PurchaseModal isOpen={isPurchaseModalOpen} isLoading={isInvoiceCreating} onBuy={handleBuyCredits} onClose={() => togglePurchaseModal(false)} />}
      {isSubscriptionModalOpen && (
        <SubscriptionModal
          isOpen={isSubscriptionModalOpen}
          onClose={() => toggleSubscriptionModal(false)}
          activeSubscription={activeSubscription}
          activeSubscriptionPrice={activeSubscriptionPrice}
        />
      )}
      {isPurchaseCreditsModalOpen && (
        <PurchaseCreditsModal
          isOpen={isPurchaseCreditsModalOpen && creditsInvoiceId !== ''}
          onClose={() => togglePurchaseCreditsModal(false)}
          invoiceId={creditsInvoiceId}
          quantity={creditsQuantity || 0}
        />
      )}
      {isPaymenMethodModalOpen && (
        <PaymentMethodModal isOpen={isPaymenMethodModalOpen} onClose={() => togglePaymenMethodModal(false)} onMethodSelected={handleDefaultMethodSelected} isLoading={isLoading} />
      )}
    </Fragment>
  );
};

export default PaymentHeader;

import { FC, memo } from 'react';
import { Modal } from '@/components';
import { ModalProps } from '@/components/Modal';

const DeleteModal: FC<ModalProps> = ({ isOpen, onClose, onConfirm, isLoading, errorMessage }) => {
  return (
    <Modal isOpen={isOpen} containerClass='w-72' title='Confirm' onClose={onClose} onConfirm={onConfirm} isLoading={isLoading} errorMessage={errorMessage}>
      <p>Delete Scenario?</p>
    </Modal>
  );
};

export default memo(DeleteModal);

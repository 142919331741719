import { Button } from '@/components';
import { useToggle } from '@/hooks/toggle';
import { Fragment } from 'react';
import PresentationModal from '../PresentationModal';

const ScenarioReviewHeader = () => {
  const { value: isPresentationModalOpen, toggle: togglePresentationModal } = useToggle();

  return (
    <Fragment>
      <div className='flex flex-col'>
        <div className='mb-8 flex w-full items-center justify-between'>
          <h2 className='font-semibold'>Proposal Review</h2>
          <Button onClick={() => togglePresentationModal(true)}>Presentation Mode</Button>
        </div>
      </div>
      {isPresentationModalOpen && <PresentationModal isOpen={isPresentationModalOpen} onClose={() => togglePresentationModal(false)} />}
    </Fragment>
  );
};

export default ScenarioReviewHeader;

import { Page } from '@/layouts';
import campaignAPI, { useCreateLirCampaignMutation, usePatchCampaignMutation } from '@/store/campaign/campaign.api';
import { useGetCurrentUserQuery } from '@/store/users/users.api';
import store from '@/store/store';
import { getDateWithDefaultFormat } from '@/utils/dates';
import { FC, Suspense, useEffect, useMemo } from 'react';
import { Await, LoaderFunction, defer, useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { AnalysisFooter, AnalysisTopbar } from '../../components';
import FinalizeSectionPageSkeleton from './FinalizeSectionPageSkeleton';
import { AnchorTextOverview, CampaignInsights, KeywordsSummary } from './components';
import { useSteps } from '@/hooks/steps';
import { STEPS } from '@/constants/defaultValues';
import { useToggle } from '@/hooks/toggle';
import KickOffModal from './components/KickOffModal';
import { LIR_CONNECTION_ENABLED } from '@/constants/application';

const FinalizeSectionWrapper: FC<{ campaign: CampaignJSON; scenario?: Scenario; keywords: Keyword[] }> = ({ campaign, scenario, keywords }) => {
  const { campaignId } = useParams() as { campaignId: string };
  const { data: user } = useGetCurrentUserQuery();
  const navigate = useNavigate();
  const { previousStep } = useSteps();
  const { toggle: toggleKickOffModal, value: kickOffModalIsOpen } = useToggle();

  const [createLirCampaign] = useCreateLirCampaignMutation();
  const [patchCampaign, { isLoading: isCampaignPatching }] = usePatchCampaignMutation();

  const priorityKeywords = useMemo(() => keywords.filter((kw) => kw.priority === 1), [keywords]);
  const linkImpactReportEnabled = LIR_CONNECTION_ENABLED && user && user.company?.internal;

  useEffect(() => {
    if (!scenario) {
      navigate(`../${STEPS.at(1)}`);
    }
  }, [scenario, navigate]);

  const handlePrevious = () => navigate(`../${previousStep}`);

  const handleKickOff = async (urls: Array<LIRUrl> = [], keywords: Array<Keyword> = []) => {
    await patchCampaign({ campaignId, data: { status: 'kicked_off' } }).unwrap();

    if (urls.length) {
      const response = await createLirCampaign({ campaignId, urls, keywords }).unwrap();
      const a = document.createElement('a');
      a.href = response.data.url_redirect;
      a.target = '_blank';
      a.click();
      a.remove();
    }

    navigate('/');
  };

  const controlUrls = useMemo(() => {
    if (!campaign || !scenario) {
      return [];
    }
    return campaign.urls.filter((url) => !scenario.urls.some((i) => i.url_id === url.id));
  }, [campaign, scenario]);

  const campaignUrls = useMemo(() => {
    if (!campaign || !scenario) {
      return [];
    }
    return campaign.urls.filter((url) => scenario.urls.some((i) => i.url_id === url.id));
  }, [campaign, scenario]);

  if (!scenario) {
    return <p className='mx-2 my-1'>Redirecting...</p>;
  }

  if (!campaign) {
    return <p>Campaign not found!</p>;
  }

  if (isCampaignPatching) {
    return <FinalizeSectionPageSkeleton />;
  }

  return (
    <Page>
      <AnalysisTopbar onSave={() => navigate('/')} isDirty={false} />
      <Page.Body>
        {kickOffModalIsOpen && (
          <KickOffModal
            campaignUrls={campaignUrls}
            controlUrls={controlUrls}
            keywords={priorityKeywords}
            isOpen={kickOffModalIsOpen}
            onClose={() => toggleKickOffModal(false)}
            onConfirm={handleKickOff}
          />
        )}
        <h2 className='mb-6 text-left font-semibold'>Campaign Summary</h2>
        <div className='flex gap-6'>
          <div className='flex w-full max-w-sm flex-col gap-6'>
            <CampaignInsights scenario={scenario} />
            <KeywordsSummary scenario={scenario} keywords={priorityKeywords} />
          </div>
          <div className='flex w-full max-w-4xl flex-col justify-between gap-6'>
            <div className='flex w-full justify-between rounded-leap bg-white p-5 text-left text-xl leading-8 text-gray-800 shadow-md'>
              <p>Status</p>
              <b className='text-green-700'>{scenario.approved_at ? `Approved on ${getDateWithDefaultFormat(scenario.approved_at)}` : 'Approved'}</b>
            </div>
            <AnchorTextOverview />
          </div>
        </div>
      </Page.Body>
      <Page.Footer>
        <AnalysisFooter
          onNext={linkImpactReportEnabled ? () => toggleKickOffModal(true) : handleKickOff}
          onPrevious={handlePrevious}
          nextText={campaign.status === 'kicked_off' ? 'Go to Dashboard' : 'Kick Off Campaign'}
        />
      </Page.Footer>
    </Page>
  );
};

const FinalizeSectionPage: FC = () => {
  const { data, error } = useLoaderData() as PageLoaderDefData<FinalizeSectionPageLoaderData>;

  const errorElement = (error: APIError) => (
    <p>
      Something went wrong, {error?.status} - {error?.data.message}
    </p>
  );

  return (
    <Suspense fallback={<FinalizeSectionPageSkeleton />}>
      <Await resolve={data} errorElement={error && errorElement(error)}>
        {(data: FinalizeSectionPageLoaderData) => {
          const [campaign, scenarios, keywords] = data;
          const scenario = scenarios.results.find((scenario) => scenario.is_approved);

          return <FinalizeSectionWrapper campaign={campaign} scenario={scenario} keywords={keywords.results} />;
        }}
      </Await>
    </Suspense>
  );
};

type FinalizeSectionPageLoaderData = [CampaignJSON, APIListResponse<Scenario>, APIListResponse<Keyword>];

const finalizeSectionPageLoader: LoaderFunction = async ({ params }) => {
  const { campaignId } = params as { campaignId: string };
  const scenarioPromise = store.dispatch(campaignAPI.endpoints.getCampaignScenarios.initiate({ campaignId }));
  const campaignPromise = store.dispatch(campaignAPI.endpoints.getCampaign.initiate({ campaignId, include: ['urls'] }));
  const keywordsPromise = store.dispatch(campaignAPI.endpoints.getCampaignKeywords.initiate({ campaignId }));

  try {
    const data = Promise.all([campaignPromise.unwrap(), scenarioPromise.unwrap(), keywordsPromise.unwrap()]);
    return defer({ data });
  } catch (error) {
    return {
      error: error as APIError,
    };
  } finally {
    scenarioPromise.unsubscribe();
    campaignPromise.unsubscribe();
    keywordsPromise.unsubscribe();
  }
};

export default FinalizeSectionPage;
export { finalizeSectionPageLoader };

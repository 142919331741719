import { ErrorMessage, Input } from '@/components';
import { FC, Fragment, useEffect, useState } from 'react';
import * as yup from 'yup';

interface ShareInputProps {
  onInvitationAdded: (email: string) => void;
  isLoading: boolean;
  invitations: Array<string>;
}

const emailSchema = yup.string().email();

const ShareInput: FC<ShareInputProps> = ({ onInvitationAdded, isLoading, invitations }) => {
  const [invitationListValue, setInvitationListValue] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (invitationListValue.trim() === '') {
      setError('');
    }
  }, [invitationListValue]);

  const handleInvitationListChange = (val: string) => setInvitationListValue(val);
  const handleCommaSeparator = async (key: string) => {
    if ([',', 'Enter'].includes(key)) {
      const parsedEmail = invitationListValue.trim().replace(',', '');

      try {
        const email = await emailSchema.validate(parsedEmail);

        if (email) {
          if (invitations.includes(email)) {
            setError(`${email} already in invitation list`);
            return;
          }

          setInvitationListValue('');
          onInvitationAdded(email);
        }
      } catch (error) {
        setError((error as yup.ValidationError).errors.join(', '));
      }
    }
  };

  return (
    <Fragment>
      <Input
        value={invitationListValue}
        onChange={handleInvitationListChange}
        containerClassName='w-full'
        className='w-full border-none shadow-none hover:border-none focus:!border-none focus:!ring-0'
        onKeyUp={handleCommaSeparator}
        disabled={isLoading}
      />
      {Boolean(error) && <ErrorMessage>{error}</ErrorMessage>}
    </Fragment>
  );
};

export default ShareInput;

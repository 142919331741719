import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

const ErrorFallback: FC = () => {
  const navigate = useNavigate();

  return (
    <div onClick={() => navigate('/')} className='mx-2 cursor-pointer p-2'>
      Something went wrong, please reload the app.
    </div>
  );
};

export default ErrorFallback;

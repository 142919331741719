import { SelectFilter } from '@/components';
import { TERM_OPTIONS_JSON } from '@/constants/defaultValues';
import { useGetCurrentUserQuery } from '@/store/users/users.api';
import cn from '@/utils/style';
import { FC, Fragment, useState } from 'react';
import ScenarioReviewBody from '../ScenarioReviewBody';

const ScenarioReviewTabs: FC = () => {
  const { data: currentUser } = useGetCurrentUserQuery();
  const [selectedTab, setSelectedTab] = useState<'campaignProposal' | 'pagesIncluded'>('campaignProposal');
  const [selectedTerm, setSelectedTerm] = useState<keyof ROISummary>('at_end_of_project_plus_12_months');

  return (
    <Fragment>
      <section className='flex items-center justify-between pb-6'>
        <div className='w-fit rounded-lg bg-white p-1'>
          <button
            onClick={() => setSelectedTab('campaignProposal')}
            className={cn('px-6 py-2 font-medium text-slate-400', selectedTab === 'campaignProposal' && 'rounded-lg bg-sky-600 text-white')}
          >
            Campaign Proposal
          </button>
          <button
            onClick={() => setSelectedTab('pagesIncluded')}
            className={cn('px-6 py-2 font-medium text-slate-400', selectedTab === 'pagesIncluded' && 'rounded-lg bg-sky-600 text-white')}
          >
            Pages Included
          </button>
        </div>
        {selectedTab === 'campaignProposal' && currentUser?.company?.config.roi_visibility && (
          <SelectFilter label='ROI At' options={TERM_OPTIONS_JSON} value={selectedTerm} onChange={setSelectedTerm} className='flex-nowrap' />
        )}
      </section>
      <ScenarioReviewBody selectedTab={selectedTab} selectedTerm={selectedTerm} />
    </Fragment>
  );
};

export default ScenarioReviewTabs;

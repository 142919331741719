import { Button, Modal, ModalProps, TextInputField } from '@/components';
import { usePatchCampaignMutation } from '@/store/campaign/campaign.api';
import { Form, FormikProvider, useFormik } from 'formik';
import { FC } from 'react';
import * as yup from 'yup';

interface EditCampaignNameValues {
  campaign_name: string;
}

interface EditCampaignNameModalProps extends ModalProps {
  initialValue: string;
  campaignId: number;
}

const EditCampaignNameSchema: yup.SchemaOf<EditCampaignNameValues> = yup.object({
  campaign_name: yup.string().required('Campaign Name is required'),
});

const EditCampaignNameModal: FC<EditCampaignNameModalProps> = ({ isOpen, onClose, initialValue, campaignId }) => {
  const [patchCampaign, { isLoading }] = usePatchCampaignMutation();

  const EditCampaignNameForm = useFormik<EditCampaignNameValues>({
    onSubmit: async ({ campaign_name }) => {
      const data = await patchCampaign({ campaignId: `${campaignId}`, data: { name: campaign_name } }).unwrap();

      if (!data) {
        return null;
      }

      onClose && onClose();
    },
    initialValues: {
      campaign_name: initialValue,
    },
    validationSchema: EditCampaignNameSchema,
  });

  const handleSubmit = () => EditCampaignNameForm.handleSubmit();

  return (
    <Modal
      isOpen={isOpen}
      title='Edit Name'
      onClose={onClose}
      className='w-modal'
      footer={
        <Button isLoading={isLoading} onClick={handleSubmit}>
          Save
        </Button>
      }
    >
      <FormikProvider value={EditCampaignNameForm}>
        <Form>
          <TextInputField name='campaign_name' label='Campaign Name' />
        </Form>
      </FormikProvider>
    </Modal>
  );
};

export default EditCampaignNameModal;

import { Skeleton } from '@/components';

const SkeletonInput = () => (
  <div className='flex w-full flex-col gap-2'>
    <Skeleton className='h-5 w-40 rounded-md bg-white' />
    <Skeleton className='h-10 w-full bg-white py-px' />
  </div>
);

export const EditUserModalSkeleton = () => (
  <div className='flex flex-col gap-4'>
    <SkeletonInput />
    <SkeletonInput />
    <SkeletonInput />
    <SkeletonInput />
    <SkeletonInput />
  </div>
);

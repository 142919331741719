import api from '../api';

const reportsAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getReports: build.query<{ results: LIRReport[] }, void>({
      query: () => ({
        url: 'reports/',
      }),
      providesTags: ['LIRReports'],
    }),
    createReport: build.mutation<{ data: CreateReportResponse }, CreateReportArgs>({
      query: ({ name, description, companies_ids, file }) => {
        return {
          method: 'POST',
          url: 'reports/',
          body: {
            name,
            description,
            companies_ids,
            file,
          },
        };
      },
    }),
    deleteReport: build.mutation<void, number>({
      query: (id) => ({
        method: 'DELETE',
        url: `reports/${id}/`,
      }),
      invalidatesTags: ['LIRReports'],
    }),
  }),
});

const { useGetReportsQuery, useCreateReportMutation, useDeleteReportMutation } = reportsAPI;

export { useGetReportsQuery, useCreateReportMutation, useDeleteReportMutation };

import { Pill } from '@/components';
import { MAP_FIELD_TO_TITLE } from '@/utils/campaigns';
import { getSymbolFromFilter } from '@/utils/filters';
import { useCallback } from 'react';

interface FiltersPillsProps<T> {
  filters: Array<FilterValue<T>>;
  onFilterRemove: (filter: FilterValue<T>) => void;
}

const FiltersPills = <T extends unknown>({ filters, onFilterRemove }: FiltersPillsProps<T>) => {
  const renderPills = useCallback(
    (filter: FilterValue<T>, index: number) => {
      if (filter.value === '') {
        return null;
      }

      return (
        <Pill key={index} showClose onClick={() => onFilterRemove(filter)} className='w-fit'>
          {MAP_FIELD_TO_TITLE[filter.field as EveryFilter]} {getSymbolFromFilter(filter.filter)} {filter.value}
        </Pill>
      );
    },
    [onFilterRemove],
  );

  return <div className='my-2 flex items-center gap-2'>{filters.map(renderPills)}</div>;
};

export default FiltersPills;

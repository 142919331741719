import { ErrorMessage, Input, NumberInputField, SeparatorLine } from '@/components';
import { PlusCircleIcon } from '@heroicons/react/24/solid';
import { useField } from 'formik';
import { FC, Fragment, useCallback, useState } from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';

interface TierFieldsProps {
  name: string;
  defaultCostSelected: DefaultCostSelectedType;
  defaultCostPerLink: number;
}

const TierFields: FC<TierFieldsProps> = ({ name, defaultCostSelected, defaultCostPerLink }) => {
  const [field, meta, { setValue }] = useField<Array<TierValues>>(name);
  const [errorMessage, setErrorMessage] = useState('');

  const handleAddTier = useCallback(() => {
    setErrorMessage('');
    const { value: default_cost_tiers } = field;
    const tiers = [...default_cost_tiers];

    const cost = tiers.at(-1)?.cost || defaultCostPerLink || 0;
    const from = tiers.length > 0 ? Number(tiers.at(-1)?.to) + 1 : 0;

    setValue([...tiers, { cost: Number(cost), from: Number(from), to: '' }]);
  }, [field, setErrorMessage, defaultCostPerLink, setValue]);

  const handleDelete = useCallback(() => {
    const value = [...field.value.slice(0, -1)];

    if (value.length) {
      value[value.length - 1] = { ...value[value.length - 1], to: '' };
    }

    setValue(value);
  }, [field, setValue]);

  const customSelected = defaultCostSelected === 'custom';
  const showMetaError = !!meta.error && meta.touched;
  const addEnabled =
    customSelected &&
    (((field.value.length === 0 || field.value.at(-1)?.to) && field.value.length > 0 && Number(field.value.at(-1)?.to) > Number(field.value.at(-1)?.from)) ||
      field.value.length == 0);

  return (
    <Fragment>
      {field.value.length > 0 &&
        field.value.map((i, index) => {
          const isLast = index + 1 === field.value.length;

          return (
            <div key={index} className='mb-6 mt-4 flex'>
              <div className='my-1'>
                <p className='text-left'>Cost</p>
                <NumberInputField
                  disabled={!customSelected}
                  name={`${name}[${index}].cost`}
                  placeholder='Enter cost'
                  suffix='$ for one link'
                  className='bg-base-leap'
                  containerClassName='my-2'
                />
              </div>
              <div className='mx-2 my-1 w-1/4'>
                <p className='text-left'>From</p>
                <Input onChange={() => {}} value={field.value[index].from} disabled placeholder='Enter quantity' className='bg-base-leap' containerClassName='my-2' />
              </div>
              <div className='my-1 w-1/4'>
                <p className='text-left'>To</p>
                <NumberInputField name={`${name}[${index}].to`} disabled={!isLast || !customSelected} placeholder='Max' className='bg-base-leap' containerClassName='my-2' />
              </div>
              {index + 1 === field.value.length && customSelected && <TrashIcon className='mb-5 ml-2 mt-auto w-6 cursor-pointer text-red-500' onClick={handleDelete} />}
            </div>
          );
        })}
      <SeparatorLine
        lineClassName={addEnabled ? 'bg-blue-500' : 'text-gray-300'}
        icon={PlusCircleIcon}
        iconText='Add Tier'
        onIconClick={addEnabled ? handleAddTier : undefined}
        iconClassName={addEnabled ? 'text-blue-500' : 'text-gray-300'}
      />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      {showMetaError && <ErrorMessage>{meta.error}</ErrorMessage>}
    </Fragment>
  );
};

export default TierFields;

import { Modal, ModalProps } from '@/components';

interface DeleteReportModalProps extends ModalProps {
  reportName: string;
}

const DeleteReportModal = ({ isOpen, footer, reportName }: DeleteReportModalProps) => {
  return (
    <Modal isOpen={isOpen} title='Delete Report' className='max-w-modal' footer={footer}>
      <div className='gap-4-2 flex flex-col items-center'>
        <p>This action cannot be undone.</p>
        <p>Are you sure you want to delete this report:</p>
        <span className='font-bold'>{reportName}</span>
      </div>
    </Modal>
  );
};

export default DeleteReportModal;

import { Button, Modal, ModalProps, ErrorMessage as Error } from '@/components';
import { BASE_API_URL } from '@/constants/application';
import { selectAuthToken } from '@/store/auth/auth.selector';
import { useLazyGetCurrentUserQuery } from '@/store/users/users.api';
import cn from '@/utils/style';
import { ErrorMessage, Form, FormikProvider, useFormik } from 'formik';
import { ChangeEvent, FC, Fragment, useState } from 'react';
import { useSelector } from 'react-redux';

interface UploadImageFormValues {
  profile_picture_url: string;
}

const UploadImageModal: FC<ModalProps> = ({ isOpen, onClose }) => {
  const token = useSelector(selectAuthToken);
  const [fetchCurrentUser] = useLazyGetCurrentUserQuery();
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState('');

  const handleUploadProfilePicture = async (values: UploadImageFormValues) => {
    const authHeader = `Bearer ${token}`;
    const formData = new FormData();
    formData.append('profile_picture_url', values.profile_picture_url);

    setIsUploading(true);
    try {
      await fetch(`${BASE_API_URL}users/profile-picture/`, {
        headers: {
          Authorization: authHeader,
        },
        method: 'POST',
        body: formData,
      });
      await fetchCurrentUser();
    } catch (error) {
      setError('Something went wrong when uploading your profile picture. Please try again.');
    }
    setIsUploading(false);
    onClose && onClose();
  };

  const Image = () => {
    const formik = useFormik({
      onSubmit: handleUploadProfilePicture,
      initialValues: {
        profile_picture_url: '',
      },
    });

    const handleIcon = (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files && e.target.files[0];
      if (file && file.size / 1024 / 1024 < 2) {
        setError('');
        formik.setFieldValue('profile_picture_url', file);
      } else {
        setError('Image size must be of 2MB or less');
      }
    };

    return (
      <Fragment>
        <FormikProvider value={formik}>
          <Form>
            <div className='flex flex-col gap-y-2 text-left '>
              <input
                type='file'
                accept='image/jpeg, image/png, image/jpg, image/webp'
                required
                onChange={(e) => handleIcon(e)}
                className={cn(
                  'w-full rounded-lg border border-gray-200 p-2 shadow-sm outline-none hover:border-gray-300 focus:border-blue-500 focus:ring-2',
                  'read-only:bg-slate-100 read-only:outline-none read-only:hover:border-gray-200 read-only:focus:border-gray-200 read-only:focus:ring-0',
                  'placeholder:text-slate-400',
                )}
              />
              <div className='flex gap-2'>
                <Button variant='secondary' onClick={onClose} className='w-1/2'>
                  Cancel
                </Button>
                <Button type='submit' variant='primary' isLoading={isUploading} className='w-1/2 border border-gray-500' disabled={error.length !== 0}>
                  Save
                </Button>
              </div>
              <div className='text-danger'>
                <ErrorMessage name='profile_image' />
              </div>
            </div>
          </Form>
        </FormikProvider>
      </Fragment>
    );
  };
  return (
    <Modal title='Upload Profile Photo' isOpen={isOpen} onClose={onClose}>
      <Image />
      {error && <Error>{error}</Error>}
    </Modal>
  );
};

export default UploadImageModal;

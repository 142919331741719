import { FC, Fragment, PropsWithChildren, ReactElement, useState } from 'react';
import { PlacesType, Tooltip as ReactTooltip, VariantType } from 'react-tooltip';
import cn from '@/utils/style';
import useTooltipConfig from '@/hooks/localstorage';

interface TooltipProps extends PropsWithChildren {
  id: string;
  content: ReactElement | null;
  place?: PlacesType;
  opacity?: number;
  variant?: VariantType;
  className?: string;
  showOnStart?: boolean;
  clickable?: boolean;
  onClose?: () => void;
  onClick?: () => void;
}

const Tooltip: FC<TooltipProps> = ({ children, id, place = 'bottom', clickable = true, content, className, showOnStart, onClose, onClick, variant, opacity }) => {
  const { hidden } = useTooltipConfig(id);
  const [tooltipState, setTooltipState] = useState(showOnStart);

  const handleTooltipClick = () => {
    if (showOnStart) {
      setTooltipState(false);
    }

    if (onClick) {
      onClick();
    }
  };

  if (hidden) {
    return (
      <span className='block' data-tooltip-id={id}>
        {children}
      </span>
    );
  }

  return (
    <Fragment>
      <span className='block' data-tooltip-id={id}>
        {children}
      </span>
      <ReactTooltip
        variant={variant}
        opacity={opacity}
        id={id}
        place={place}
        clickable={clickable}
        hidden={showOnStart ? !tooltipState : undefined}
        className={cn('z-10 rounded-md bg-gray-50 text-black shadow-md', className)}
        isOpen={showOnStart ? tooltipState : undefined}
        afterHide={onClose}
      >
        {content && (
          <span className='whitespace-normal' onClick={handleTooltipClick}>
            {content}
          </span>
        )}
      </ReactTooltip>
    </Fragment>
  );
};

export default Tooltip;

import { FC } from 'react';
import linklaunch from '../../assets/images/linklaunch.png';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { Button } from '@/components';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '@/store/auth/auth.slice';
import { AppDispatch } from '@/store/store';

const ResetPasswordSuccessPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const handleGoToLogin = () => {
    dispatch(logout());
    navigate('/login');
  };

  return (
    <div className='flex w-screen flex-col items-center gap-y-10 pt-48'>
      <div className='flex w-full max-w-2xl items-center justify-center gap-5 rounded-lg bg-white px-4 py-6 shadow-xl'>
        <img src={linklaunch} className='w-96 py-6' />
      </div>
      <div className='flex w-full max-w-2xl flex-col items-center gap-4 rounded-lg bg-white px-4 py-6 shadow-xl'>
        <CheckCircleIcon className='w-28 text-green-500' />
        <h2 className='font-bold'>SUCCESS!</h2>
        <p>Your password was changed successfully</p>
        <Button onClick={handleGoToLogin}>Go to Login</Button>
      </div>
    </div>
  );
};

export default ResetPasswordSuccessPage;

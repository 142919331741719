import { Button, Loading, Modal, ModalProps } from '@/components';
import store from '@/store';
import companyAPI from '@/store/company/company.api';
import { FC, useMemo } from 'react';
import { ActionFunctionArgs, SubmitOptions, redirect, useNavigate, useNavigation, useParams, useSearchParams, useSubmit } from 'react-router-dom';

const DeleteCompanyModal: FC<ModalProps> = ({ isOpen }) => {
  const { companyId } = useParams() as { companyId: string };
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const navigation = useNavigation();
  const submit = useSubmit();
  const isUpdating = navigation.state === 'submitting' || navigation.state === 'loading';

  const companyName = searchParams.get('name') ?? '';

  const submitOptions: SubmitOptions = useMemo(() => ({ action: `/user-management/companies/delete-company/${companyId}`, method: 'delete' }), [companyId]);

  const handleConfirmModal = () => {
    submit(null, submitOptions);
  };

  return (
    <Modal
      isOpen={isOpen}
      title='Delete Company'
      className='min-w-modal'
      footer={<DeleteCompanyModalFooter isSubmitting={isUpdating} onClose={() => navigate('/user-management/companies')} onConfirm={handleConfirmModal} />}
    >
      <div className='flex flex-col items-center gap-y-2'>
        <p>This action cannot be undone.</p>
        <p>
          Are you sure you want to delete the company: <span className='font-bold'>{companyName}</span>
        </p>
      </div>
    </Modal>
  );
};

const DeleteCompanyModalFooter: FC<{ isSubmitting: boolean; onClose: () => void; onConfirm: () => void }> = ({ onClose, isSubmitting, onConfirm }) => (
  <div className={'flex w-full items-center gap-6'}>
    <Button variant='outline-light' onClick={onClose} disabled={isSubmitting} className='w-full'>
      Cancel
    </Button>
    <Button onClick={onConfirm} disabled={isSubmitting} className='w-full whitespace-nowrap'>
      {isSubmitting ? <Loading className='mx-auto h-5 w-5' /> : 'Confirm'}
    </Button>
  </div>
);

const deleteCompanyAction = async ({ params }: ActionFunctionArgs) => {
  const companyId = (params as { companyId: string }).companyId;
  try {
    await store.dispatch(companyAPI.endpoints.deleteCompany.initiate({ companyId })).unwrap();
    // if (resp.error) throw new Error(resp.error.data.message);

    return redirect('/user-management/companies');
  } catch (e) {
    return {
      error: e as APIError,
    };
  }
};

export { deleteCompanyAction };

export default DeleteCompanyModal;

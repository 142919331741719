import cn from '@/utils/style';
import { RadioGroup } from '@headlessui/react';
import { FC } from 'react';

interface PaymentMethodOptionProps {
  paymentMethod: PaymentMethod;
}

const PaymentMethodOption: FC<PaymentMethodOptionProps> = ({ paymentMethod }) => {
  return (
    <RadioGroup.Option value={paymentMethod.id} className='relative flex cursor-pointer bg-slate-100 p-3 focus:outline-none'>
      {({ checked }) => {
        return (
          <div className='flex w-full items-center justify-between'>
            <div className='flex gap-3'>
              <div className={cn('flex h-6 w-6 items-center justify-center rounded-full border border-sky-600 bg-white', checked && 'bg-sky-600')}>
                {checked && <div className='m-auto h-3 w-3 rounded-full bg-white'></div>}
              </div>
              <div className='flex items-center'>
                <RadioGroup.Description as='p'>{`Card ending in ${paymentMethod.card.last4}`}</RadioGroup.Description>
              </div>
            </div>
            <div>{paymentMethod.card.brand}</div>
          </div>
        );
      }}
    </RadioGroup.Option>
  );
};

export default PaymentMethodOption;

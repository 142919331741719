import cn from '@/utils/style';
import { Menu as HeadlessMenu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import { FC, Fragment, ReactNode, useMemo } from 'react';

interface MenuProps {
  items: Array<Item>;
  direction?: 'top' | 'bottom' | 'left' | 'right';
  menuIcon?: ReactNode;
  className?: string;
  containerClassName?: string;
}

interface Item {
  label: string | ReactNode;
  onClick: () => void;
  icon?: ReactNode;
  className?: string;
  disabled?: boolean;
}

const Menu: FC<MenuProps> = ({ items, menuIcon, className, direction = 'right', containerClassName }) => {
  const renderItems = (item: Item, idx: number) => {
    return (
      <HeadlessMenu.Item key={idx}>
        <div
          className={cn('w-full whitespace-nowrap px-4 py-2 text-left hover:bg-gray-100', item.disabled ? 'cursor-not-allowed bg-gray-100' : 'cursor-pointer', item.className)}
          onClick={item.disabled ? undefined : item.onClick}
        >
          {item.icon ? (
            <div className='flex items-center gap-2'>
              {item.icon}
              {item.label}
            </div>
          ) : (
            <span>{item.label}</span>
          )}
        </div>
      </HeadlessMenu.Item>
    );
  };

  const menuDirection = useMemo(() => {
    return {
      top: `-translate-y-[calc(100%+${36 * items.length}px)]`,
      bottom: `translate-y-[calc(100%-${36 * items.length}px)]`,
      left: `-translate-x-[calc(100%+${36 * items.length}px)]`,
      right: `translate-x-[calc(100%-${36 * items.length}px)]`,
    };
  }, [items]);

  return (
    <HeadlessMenu as='div' className={cn('relative', className)}>
      <HeadlessMenu.Button>{menuIcon || <EllipsisVerticalIcon className={cn('w-8 cursor-pointer rounded-full p-1')} />}</HeadlessMenu.Button>
      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <HeadlessMenu.Items
          className={cn(
            'absolute right-0 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
            menuDirection[direction],
            containerClassName,
          )}
        >
          {items.map(renderItems)}
        </HeadlessMenu.Items>
      </Transition>
    </HeadlessMenu>
  );
};

export default Menu;

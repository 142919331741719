import { Skeleton } from '@/components';
import { PageSkeleton } from '@/layouts/Page';
import { FC } from 'react';

const FinalizeSectionPageSkeleton: FC = () => {
  return (
    <PageSkeleton>
      <Skeleton className='h-12 w-64 ' />
      <div className='mt-8 flex h-full w-full gap-6'>
        <div className='flex flex-col gap-6'>
          <Skeleton className='h-[60%] w-96' />
          <Skeleton className='h-32 w-96' />
        </div>
        <div className='flex w-full flex-col gap-6'>
          <Skeleton className='h-12 w-full' />
          <Skeleton className='h-96 w-full' />
          <Skeleton className='h-32 w-full' />
        </div>
      </div>
    </PageSkeleton>
  );
};

export default FinalizeSectionPageSkeleton;
1;

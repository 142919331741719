const GOOGLE_SHEETS_URL = 'https://docs.google.com/spreadsheets/d';

const getGoogleSheetsUrl = (id: string) => {
  if (!id || id.length === 0) {
    return '';
  }

  return `${GOOGLE_SHEETS_URL}/${id}`;
};

export { getGoogleSheetsUrl };

import { hasFlag } from 'country-flag-icons';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';

const getCountryFlag = (country: Country) => {
  const upperCaseCountryKey = country.key.toUpperCase();
  return hasFlag(upperCaseCountryKey) ? getUnicodeFlagIcon(upperCaseCountryKey) : upperCaseCountryKey;
};

const getCountryOptions = (countryList: Array<Country>) => {
  return countryList.map((country) => {
    const countryFlag = getCountryFlag(country);

    return { label: `${countryFlag} - ${country.value}`, value: country.key };
  });
};

export { getCountryOptions, getCountryFlag };

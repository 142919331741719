import cn from '@/utils/style';
import { FC, useMemo } from 'react';

interface VelocityTooltipContentProps {
  client: Velocity;
  competitor: Velocity;
  title: string;
}

type VelocityColumns = 'titles' | 'client' | 'competitor' | 'difference';
type VelocityTooltipColumns = Record<VelocityColumns, Array<string | number>>;

const velocityTitles: Record<keyof Velocity, string> = {
  '3months': '3 Months',
  '6months': '6 Months',
};

const VelocityTooltipContent: FC<VelocityTooltipContentProps> = ({ title, client, competitor }) => {
  const columns = useMemo(() => {
    const initCols: VelocityTooltipColumns = {
      titles: [''],
      client: ['Subject'],
      competitor: ['Comp. (avg)'],
      difference: ['diff.'],
    };

    return Object.keys(client).reduce((cols, key) => {
      const typedKey = key as keyof Velocity;
      const clientKey = Math.ceil(client[typedKey]);
      const competitorKey = Math.ceil(competitor[typedKey]);

      cols.titles.push(velocityTitles[typedKey]);
      cols.client.push(clientKey);
      cols.competitor.push(competitorKey);
      cols.difference.push(clientKey - competitorKey);

      return cols;
    }, initCols);
  }, [client, competitor]);

  const renderColumn = (col: string, columnIdx: number) => {
    const renderContent = (val: string | number, contentIdx: number) => {
      return (
        <div key={contentIdx} className={cn('flex items-center gap-4', columnIdx !== 0 ? 'justify-end' : 'mr-6')}>
          {columnIdx === 0 && contentIdx !== 0 && (
            <div className={cn('h-2 w-2 rounded-full', contentIdx === 1 && 'bg-chart-0', contentIdx === 2 && 'bg-chart-1', contentIdx === 3 && 'bg-chart-2')}></div>
          )}
          <p className={cn(contentIdx === 0 && 'h-6')}>{val.toLocaleString()}</p>
        </div>
      );
    };

    return <div key={columnIdx}>{(columns[col as keyof VelocityTooltipColumns] || []).map(renderContent)}</div>;
  };

  return (
    <div>
      <p>{title}</p>
      <div className='flex items-center justify-between gap-2'>{Object.keys(columns).map(renderColumn)}</div>
    </div>
  );
};

export default VelocityTooltipContent;

const XLogoIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M13.9027 10.4686L21.3482 2H19.5838L13.1189 9.3532L7.95547 2H2L9.8082 13.1193L2 22H3.76443L10.5915 14.2348L16.0445 22H22L13.9027 10.4686ZM11.4861 13.2173L10.695 12.1101L4.40018 3.29967H7.11025L12.1902 10.4099L12.9813 11.5172L19.5847 20.7594H16.8746L11.4861 13.2173Z'
      fill='#94A3B8'
    />
  </svg>
);

export default XLogoIcon;

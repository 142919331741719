import { getDateWithFormat } from '@/utils/dates';
import cn from '@/utils/style';
import { FC } from 'react';
import NotificationIcon from './NotificationIcon';

const Notification: FC<{ notification: LeapNotification }> = ({ notification }) => {
  return (
    <div className={cn('flex w-full flex-wrap items-center justify-between border-b bg-blue-100 bg-opacity-25 px-2 pb-2 pt-4 text-sm', notification.read_at && 'bg-white')}>
      <div className='flex w-full items-center'>
        <NotificationIcon notification={notification} className='mr-1 mt-1 text-white' />
        <h2 className='text-xl font-semibold'>{notification.data.subject || 'Message'}</h2>
      </div>
      <div className='mb-1 flex w-full'>
        <p className='mx-2 flex-grow text-sm font-semibold'>{notification.data.message || notification.data.data.message}</p>
        <div className='ml-auto flex items-end justify-end whitespace-nowrap'>
          <strong className='text-sm text-gray-400'>{getDateWithFormat(notification.created_at, 'h:MM aa MMM. dd')}</strong>
        </div>
      </div>
    </div>
  );
};

export default Notification;

import { Button } from '@/components';
import { Page } from '@/layouts';
import { selectAuthUser } from '@/store/auth/auth.selector';
import cn from '@/utils/style';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const tabs = ['companies', 'users', 'access-requests'];

const UserManagementPage: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const parsedPath = useMemo(() => location.pathname.split('/'), [location]);
  const authUser = useSelector(selectAuthUser);

  const activeTab = useMemo(() => {
    if (parsedPath.includes('users')) {
      return 'users';
    }
    if (parsedPath.includes('companies')) {
      return 'companies';
    }
    if (parsedPath.includes('access-requests')) {
      return 'access-requests';
    }
  }, [parsedPath]);

  const inactiveTabs = useMemo(() => tabs.filter((tab) => tab !== activeTab), [activeTab]);

  return (
    <Page>
      <Page.TopBar title='User Management' className='flex items-center'>
        <div className='rounded-leap bg-white p-2'>
          <Button onClick={() => navigate('users')} className={cn(inactiveTabs.includes('users') && 'bg-white text-gray-300 hover:bg-white active:bg-white')}>
            Users
          </Button>
          {authUser?.isSuperuser && (
            <Button onClick={() => navigate('companies')} className={cn(inactiveTabs.includes('companies') && 'bg-white text-gray-300 hover:bg-white active:bg-white')}>
              Companies
            </Button>
          )}
          {authUser?.isSuperuser && (
            <Button onClick={() => navigate('access-requests')} className={cn(inactiveTabs.includes('access-requests') && 'bg-white text-gray-300 hover:bg-white active:bg-white')}>
              Access Requests
            </Button>
          )}
        </div>
      </Page.TopBar>
      <Page.Body>
        <Outlet />
      </Page.Body>
    </Page>
  );
};

export default UserManagementPage;

import { FC } from 'react';
import { Button, Modal, ToastCard } from '@/components';
import { PlusCircleIcon } from '@heroicons/react/20/solid';
import CollapsableCard from '../CollapsableCards/CollapsableCard';
import { useParams } from 'react-router-dom';
import { useCreateURLSummaryMutation } from '@/store/poc/poc.api';
import SummaryCard from '../SummaryCard';
import { toast } from 'react-toastify';

interface TargetAudienceModalProps {
  isOpen: boolean;
  onClose: () => void;
  audiences: URLTargetAudience[];
  summary?: LCRSummary;
}

interface ModalFooterProps {
  allowCreate: boolean;
}

const ModalFooter: FC<ModalFooterProps> = ({ allowCreate }) => {
  const { urlId } = useParams() as { urlId: string };
  const [createURLSummary, { isLoading }] = useCreateURLSummaryMutation();

  return (
    <div className='p-6'>
      <Button
        className='flex items-center bg-transparent px-0 text-sky-600 hover:bg-transparent hover:text-sky-700 active:bg-transparent disabled:bg-transparent disabled:px-2'
        onClick={async () => {
          await createURLSummary(urlId);
          toast(<ToastCard message='Summary generation initiated' />, { type: 'success', autoClose: 2000 });
        }}
        isLoading={isLoading}
        disabled={!allowCreate}
      >
        <PlusCircleIcon className='mr-1 size-6' /> Create Summary
      </Button>
    </div>
  );
};

const TargetAudienceModal: FC<TargetAudienceModalProps> = ({ isOpen, onClose, audiences, summary }) => (
  <Modal
    isOpen={isOpen}
    onClose={() => onClose()}
    title='All Target Audience Questions'
    dialogPanelclassName='w-modal-xl p-0 gap-y-0'
    containerClass='p-6'
    className='max-h-[70vh] overflow-y-auto bg-base-leap'
    footer={<ModalFooter allowCreate={!summary} />}
  >
    {summary && <SummaryCard summary={summary} />}
    <div className='p-6'>
      {audiences?.map((audience) =>
        audience.purchase_stakeholders.map((purchaseStakeholder) => (
          <CollapsableCard key={`purchase-stakeholder-modal-${purchaseStakeholder.id}`} content={<h4>{purchaseStakeholder.name}</h4>} containerClass='bg-white first-of-type:mt-0'>
            <ul className='mt-4 list-outside list-disc marker:text-sky-600'>
              {purchaseStakeholder.stakeholder_questions.map((stakeholderQuestion) => (
                <li key={`stakeholder-question-modal-${stakeholderQuestion.id}`}>{stakeholderQuestion.question}</li>
              ))}
            </ul>
          </CollapsableCard>
        )),
      )}
    </div>
  </Modal>
);

export default TargetAudienceModal;

import { Skeleton } from '@/components';

const CompaniesPageSkeleton = () => {
  return (
    <div className='w-full'>
      <div className='mb-4 flex w-full justify-between '>
        <Skeleton className='h-10 w-[35.5%] animate-pulse' />
        <Skeleton className='h-10 w-[14%] animate-pulse' />
      </div>
      <Skeleton className='h-[calc(100vh-4rem)] w-full' />
    </div>
  );
};

export default CompaniesPageSkeleton;

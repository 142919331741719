import { Page } from '@/layouts';
import { LIRImportModal } from './components';
import { useToggle } from '@/hooks/toggle';
import { Button } from '@/components';
import { useSelector } from 'react-redux';
import { selectAuthUser } from '@/store/auth/auth.selector';
import { Fragment } from 'react';
import ReportsTable from './components/ReportsTable';

const LIRImportPage = () => {
  const { value: isLIRImportModalOpen, toggle: toggleLIRImportModal } = useToggle();
  const authUser = useSelector(selectAuthUser);

  return (
    <Page>
      <Page.TopBar title='Link Impact Report'>{authUser?.isSuperuser && <Button onClick={() => toggleLIRImportModal(true)}>Import LIR</Button>}</Page.TopBar>
      <Page.Body>
        <Fragment>
          {isLIRImportModalOpen && <LIRImportModal isOpen={isLIRImportModalOpen} onClose={() => toggleLIRImportModal(false)} />}
          <ReportsTable />
        </Fragment>
      </Page.Body>
    </Page>
  );
};

export default LIRImportPage;

import { SVGProps } from 'react';

const LinkIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width='28' height='29' viewBox='0 0 28 29' fill='currentColor' xmlns='http://www.w3.org/2000/svg' {...props}>
      <rect y='0.5' width='28' height='28' rx='14' fill='#0284C7' />
      <g clipPath='url(#clip0_1977_28206)'>
        <path
          d='M11.6664 10.0344C9.54543 12.2288 10.0083 15.8705 12.4378 17.4837C12.5178 17.5369 12.6243 17.5264 12.6931 17.4592C13.2045 16.9594 13.6372 16.4753 14.0161 15.8597C14.0741 15.7655 14.038 15.6433 13.9408 15.5906C13.5702 15.3898 13.2014 15.0134 12.9939 14.6157L12.9936 14.6159C12.745 14.1207 12.6603 13.5657 12.7919 12.9924C12.7921 12.9925 12.7922 12.9925 12.7924 12.9925C12.9439 12.2586 13.7317 11.5759 14.3335 10.9445C14.3322 10.9441 14.331 10.9437 14.3297 10.9432L16.5844 8.64209C17.4829 7.72505 18.9608 7.71748 19.8686 8.62531C20.7856 9.52378 20.8008 11.0092 19.9023 11.9262L18.5367 13.3305C18.4734 13.3955 18.4529 13.4903 18.4825 13.576C18.797 14.4879 18.8743 15.7736 18.6636 16.745C18.6577 16.7722 18.6912 16.79 18.7107 16.7701L21.6172 13.8035C23.474 11.9085 23.4582 8.82305 21.5822 6.94704C19.6677 5.03253 16.5509 5.04846 14.6561 6.98239L11.678 10.0219C11.6741 10.0261 11.6703 10.0303 11.6664 10.0344Z'
          fill='white'
        />
        <path
          d='M17.1005 17.9022C17.1005 17.9023 17.1004 17.9024 17.1003 17.9025C17.1022 17.9017 17.1039 17.901 17.1058 17.9002C17.699 16.8155 17.8157 15.5715 17.5377 14.3588L17.5365 14.3601L17.5351 14.3595C17.2711 13.2794 16.5468 12.2069 15.5632 11.5465C15.4785 11.4897 15.3434 11.4963 15.2641 11.5604C14.7658 11.9633 14.2781 12.4799 13.9563 13.1392C13.9057 13.2427 13.9436 13.3671 14.0432 13.4249C14.4167 13.6417 14.754 13.9592 14.9798 14.3807L14.9802 14.3804C15.1562 14.6781 15.3296 15.2431 15.2173 15.8501C15.2172 15.8501 15.2171 15.8501 15.217 15.8501C15.1122 16.6548 14.2995 17.393 13.6535 18.0582L13.6538 18.0585C13.162 18.5614 11.9119 19.8358 11.4113 20.3472C10.5129 21.2642 9.02749 21.2794 8.11049 20.3809C7.19349 19.4825 7.17833 17.9971 8.0768 17.0801L9.4465 15.6715C9.50858 15.6077 9.52968 15.5149 9.50207 15.4302C9.19794 14.4971 9.11462 13.2404 9.30658 12.2701C9.31193 12.243 9.27866 12.2257 9.25935 12.2454L6.39653 15.1674C4.52073 17.0819 4.53663 20.199 6.43191 22.0942C8.34632 23.9699 11.4473 23.9382 13.323 22.0238C13.9747 21.2949 16.7641 18.7123 17.1005 17.9022Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_1977_28206'>
          <rect width='18' height='18' fill='white' transform='translate(5 5.5)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LinkIcon;

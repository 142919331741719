import { useField } from 'formik';
import { Label, ErrorMessage } from '@/components';
import Autocomplete, { AutocompleteProps } from '../Autocomplete/Autocomplete';

type AutocompleteFieldProps<T> = Omit<AutocompleteProps<T>, 'value' | 'onSelect'> & {
  name: string;
  label?: string;
};

const AutocompleteField = <T extends unknown>({ name, label, ...autocompleteProps }: AutocompleteFieldProps<T>) => {
  const [field, meta, helpers] = useField<T>(name);
  const { setValue } = helpers;
  const showError = !!meta.error && meta.touched;
  return (
    <div className='flex w-full flex-col gap-2'>
      {label && <Label>{label}</Label>}
      <Autocomplete {...autocompleteProps} value={field.value} onSelect={setValue} />
      {showError && <ErrorMessage>{meta.error}</ErrorMessage>}
    </div>
  );
};

export default AutocompleteField;

import { FC, Fragment, Suspense, useEffect } from 'react';
import { Page } from '@/layouts';
import apiPOC, { useGetURLSummaryQuery, useMarkURLAsReadMutation } from '@/store/poc/poc.api';
import { Await, defer, LoaderFunction, useAsyncValue, useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { Button } from '@/components';
import { CollapsableCards, TargetAudienceModal } from './components';
import { URLAudienceSkeleton, URLDetailSkeleton, URLDetailTopBarSkeleton } from './URLDetailPageSkeleton';
import { useToggle } from '@/hooks/toggle';
import store from '@/store';
import CollapsableCard from './components/CollapsableCards/CollapsableCard';

type URLDetailPageData = [LCRURL];

const ReadedURLChecker: FC = () => {
  const [urlDetail] = useAsyncValue() as URLDetailPageData;
  const [markUrlAsRead] = useMarkURLAsReadMutation();

  useEffect(() => {
    if (!urlDetail.read_at && urlDetail.status === 'completed') {
      markUrlAsRead(String(urlDetail.id));
    }
  }, [urlDetail, markUrlAsRead]);

  return null;
};

const URLDetailPage: FC = () => {
  const navigate = useNavigate();
  const { data, error } = useLoaderData() as PageLoaderDefData<URLDetailPageData>;
  const { urlId } = useParams() as { urlId: string };
  const { data: summary } = useGetURLSummaryQuery(urlId);
  const { value: isTargetAudienceModalOpen, open, close } = useToggle();
  const errorMessage = `something went wrong, ${error?.status} - ${error?.data.message}`;

  return (
    <Page>
      <Suspense>
        <Await resolve={data}>
          <ReadedURLChecker />
        </Await>
      </Suspense>
      <Suspense fallback={<URLDetailTopBarSkeleton />}>
        <Await resolve={data}>{([urlDetail]: [LCRURL]) => <Page.TopBar title={urlDetail.url} className='flex items-center' goBack={() => navigate(-1)} />}</Await>
      </Suspense>
      <Page.Body>
        <Suspense fallback={<URLDetailSkeleton />}>
          <Await resolve={data} errorElement={error && <p>{errorMessage}</p>}>
            {([urlDetail]: [LCRURL]) => (
              <div className='flex items-baseline gap-x-6 text-start'>
                <CollapsableCard content={<h4>Page Offering</h4>} containerClass='bg-white w-full py-6' isOpen>
                  <p className='mt-2'>{urlDetail.offering}</p>
                </CollapsableCard>
                <CollapsableCard content={<h4>Buyer Wants to Achieve</h4>} containerClass='bg-white w-full py-6' isOpen>
                  <p className='mt-2'>{urlDetail.buyer_wants_to_achieve}</p>
                </CollapsableCard>
              </div>
            )}
          </Await>
        </Suspense>
        <div className='my-10 flex items-center justify-between text-left'>
          <h4>Target Audience</h4>
          <Button onClick={() => open()}>All Target Audience Questions</Button>
        </div>
        <Suspense fallback={<URLAudienceSkeleton />}>
          <Await resolve={data} errorElement={error && <p>{errorMessage}</p>}>
            {([urlDetail]: [LCRURL]) => (
              <Fragment>
                <CollapsableCards audiences={urlDetail.target_audience || []} />
                <TargetAudienceModal isOpen={isTargetAudienceModalOpen} onClose={close} audiences={urlDetail.target_audience || []} summary={summary} />
              </Fragment>
            )}
          </Await>
        </Suspense>
      </Page.Body>
    </Page>
  );
};

const URLDetailPageLoader: LoaderFunction = async ({ params }) => {
  const { urlId } = params as { urlId: string };

  const URLDetailPromise = store.dispatch(apiPOC.endpoints.getURLDetail.initiate({ urlIdOrHash: urlId, include: ['target_audience'] }));

  try {
    const data = Promise.all([URLDetailPromise.unwrap()]);

    return defer({ data });
  } catch (e) {
    return {
      error: e as APIError,
    };
  } finally {
    URLDetailPromise.unsubscribe();
  }
};

export default URLDetailPage;

export { URLDetailPageLoader };

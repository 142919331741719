import { FC } from 'react';
import CollapsableCard from './CollapsableCard';

const AudienceContent: FC<{ audience: URLTargetAudience }> = ({ audience }) => (
  <div className='text-left'>
    <h4 className='mb-2'>{audience.name}</h4>
    <p>{audience.description}</p>
  </div>
);

const PurchaseStakeholderContent: FC<{ purchaseStakeholder: PurchaseStakeholder }> = ({ purchaseStakeholder }) => (
  <div className='w-full text-left'>
    <div className='mb-2 flex items-center justify-between'>
      <h5>Stakeholder: {purchaseStakeholder.name}</h5>
      <div className='flex items-center gap-4 [&>p>span]:text-slate-500'>
        <p>
          <span>Veto Power</span> {purchaseStakeholder.veto_power}
        </p>
        <p>
          <span>Proximity to benefit</span> {purchaseStakeholder.proximity_to_benefit}
        </p>
        <p>
          <span>Importance day-today</span> {purchaseStakeholder.importance_day_to_day}
        </p>
        <p>
          <span>Participate in research</span> {purchaseStakeholder.likelihood_participating_in_research}
        </p>
      </div>
    </div>
    <p>{purchaseStakeholder.description}</p>
  </div>
);

const StakeholderQuestionContent: FC<{ stakeholderQuestion: StakeholderQuestion }> = ({ stakeholderQuestion }) => (
  <div className='text-left'>
    <h5 className='mb-2'>Question: {stakeholderQuestion.question}</h5>
    <div className='flex gap-4'>
      <div className='[&>p]:mb-2 [&>p]:text-slate-500'>
        <p>Tasks</p>
        <p>Next Steps</p>
        <p>Tools</p>
        <p>Techniques</p>
      </div>
      <div className='[&>p]:mb-2'>
        <p>{stakeholderQuestion.tasks.join(', ')}</p>
        <p>{stakeholderQuestion.next_steps.join(', ')}</p>
        <p>{stakeholderQuestion.tools.join(', ')}</p>
        <p>{stakeholderQuestion.techniques.join(', ')}</p>
      </div>
    </div>
  </div>
);

const WorseCaseScenarioCard: FC<{ worseCaseScenario: WorseCaseScenario }> = ({ worseCaseScenario }) => (
  <div className='mt-4 rounded-leap bg-red-100 p-4'>
    <h5 className='mb-2'>{worseCaseScenario.description}</h5>
    <div className='[&>div>p>span]:text-slate-500'>
      <div className='mb-2 flex gap-4'>
        <p className='min-w-[120px]'>
          <span>Feelings</span>
        </p>
        <p>{worseCaseScenario.feelings.join(', ')}</p>
      </div>
      <div className='mb-2 flex gap-4'>
        <p className='min-w-[120px]'>
          <span>How to ease</span>
        </p>
        <p>{worseCaseScenario.how_to_ease}</p>
      </div>
      <div className='mb-2 flex gap-4'>
        <p className='min-w-[120px]'>
          <span>How to accept</span>
        </p>
        <p>{worseCaseScenario.how_to_accept}</p>
      </div>
    </div>
  </div>
);

const ExpertQuestionCard: FC<{ expertQuestion: ExpertQuestion }> = ({ expertQuestion }) => (
  <div className='mt-4 rounded-leap bg-orange-100 p-4'>
    <p>{expertQuestion.question}</p>
  </div>
);

const CollapsableCards: FC<{ audiences: URLTargetAudience[] }> = ({ audiences }) => (
  <div>
    {audiences.map((audience) => (
      <CollapsableCard key={`audience-${audience.id}`} content={<AudienceContent audience={audience} />} containerClass='bg-white'>
        {audience.purchase_stakeholders?.map((purchaseStakeholder) => (
          <CollapsableCard
            key={`purchase-stakeholder-${purchaseStakeholder.id}`}
            content={<PurchaseStakeholderContent purchaseStakeholder={purchaseStakeholder} />}
            containerClass='bg-sky-100'
          >
            {purchaseStakeholder.stakeholder_questions.map((stakeholderQuestion) => (
              <CollapsableCard
                key={`stakeholder-question-${stakeholderQuestion.id}`}
                content={<StakeholderQuestionContent stakeholderQuestion={stakeholderQuestion} />}
                containerClass='bg-white'
              >
                <div className='mt-6 text-left'>
                  <h5>Worse Case Scenarios</h5>
                  {stakeholderQuestion.worse_case_scenarios.map((worseCaseScenario) => (
                    <WorseCaseScenarioCard worseCaseScenario={worseCaseScenario} key={`worse-case-scenario-${worseCaseScenario.id}`} />
                  ))}
                </div>
                <div className='mt-6 text-left'>
                  <h5 className='mb-2'>Expert {stakeholderQuestion.expert_questions.at(0)?.expert.name} Questions</h5>
                  <p className='text-slate-500'>{stakeholderQuestion.expert_questions.at(0)?.expert.description}</p>
                  {stakeholderQuestion.expert_questions?.map((expertQuestion) => (
                    <ExpertQuestionCard expertQuestion={expertQuestion} key={`expert-question-${expertQuestion.id}`} />
                  ))}
                </div>
              </CollapsableCard>
            ))}
          </CollapsableCard>
        ))}
      </CollapsableCard>
    ))}
  </div>
);

export default CollapsableCards;

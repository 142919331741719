import { CheckIcon, CloudArrowUpIcon } from '@heroicons/react/20/solid';
import Button from '../Button';
import { ChangeEvent } from 'react';
import cn from '@/utils/style';

interface UploadButtonProps {
  title: string;
  accept: string;
  uploadButtonRef: React.RefObject<HTMLInputElement>;
  onClick: () => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  fileUploaded?: boolean;
}

const UploadButton = ({ title, accept, uploadButtonRef, onChange, onClick, className, fileUploaded }: UploadButtonProps) => {
  return (
    <Button className={cn('flex flex-nowrap items-center gap-2', className)} variant='outline-light' onClick={onClick}>
      <CloudArrowUpIcon className='w-6' />
      <p className='whitespace-nowrap text-center'>{title}</p>
      {fileUploaded ? <CheckIcon className='size-6 text-emerald-500' /> : <p className='w-6'></p>}
      <input id='file' type='file' accept={accept} onChange={onChange} className='hidden' ref={uploadButtonRef} />
    </Button>
  );
};

export default UploadButton;

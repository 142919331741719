import cn from '@/utils/style';
import { FC, HTMLProps } from 'react';

export interface CheckboxProps extends HTMLProps<HTMLInputElement> {
  className?: string;
}

const Checkbox: FC<CheckboxProps> = ({ className, disabled, ...rest }) => {
  return (
    <input
      type='checkbox'
      disabled={disabled}
      {...rest}
      className={cn(
        'border-1 h-5 w-5 cursor-pointer rounded-md border-sky-600 text-sky-600 ring-0',
        'checked:bg-sky-600',
        'focus:ring-0 focus:ring-offset-0',
        'disabled:cursor-not-allowed disabled:text-gray-500 disabled:checked:bg-gray-500 disabled:checked:text-gray-500',
        className,
      )}
    />
  );
};

export default Checkbox;

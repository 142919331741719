import { Form, FormikProvider, useFormik } from 'formik';
import { FC } from 'react';
import TextInputField from '../TextInputField';
import Button from '../Button';
import ErrorMessage from '../ErrorMessage';
import * as yup from 'yup';

interface ChangePasswordFormProps {
  onSubmit: (values: ChangePasswordFormValues) => void;
  isLoading: boolean;
  error?: APIError;
}

const ChangePasswordValidationSchema: yup.SchemaOf<ChangePasswordFormValues> = yup.object({
  current_password: yup.string().required('Password is required'),
  new_password: yup.string().required('Password is required').min(8, 'Password must be at least 8 characters'),
  new_password_confirm: yup
    .string()
    .oneOf([yup.ref('new_password'), null], 'Passwords must match')
    .required(),
});

const ChangePasswordForm: FC<ChangePasswordFormProps> = ({ onSubmit, isLoading, error }) => {
  const formik = useFormik<ChangePasswordFormValues>({
    onSubmit: onSubmit,
    initialValues: {
      current_password: '',
      new_password: '',
      new_password_confirm: '',
    },
    validationSchema: ChangePasswordValidationSchema,
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <div className='flex flex-col gap-y-5 text-left '>
          <TextInputField name='current_password' label='Current Password' type='password' disabled={isLoading} />
          <TextInputField name='new_password' label='New Password' type='password' disabled={isLoading} />
          <TextInputField name='new_password_confirm' label='Confirm Your New Password' type='password' disabled={isLoading} />
          <Button type='submit' isLoading={isLoading} className='w-full'>
            Confirm
          </Button>
          {error && <ErrorMessage>{error.data?.message || 'Something went wrong, try again later...'}</ErrorMessage>}
        </div>
      </Form>
    </FormikProvider>
  );
};

export default ChangePasswordForm;

import { Menu, Skeleton, Modal, Button } from '@/components';
import WarningMessage from '@/components/WarningMessage';
import { useDeleteCampaignScenarioMutation, useDeleteCampaignAnchorTextsMutation, useLazyGetCampaignScenariosQuery } from '@/store/campaign/campaign.api';
import { selectLinksToBuildState, selectScenarios, selectSelectedScenario } from '@/store/campaign/campaign.selector';
import { setSelectedScenario } from '@/store/campaign/campaign.slice';
import { AppDispatch } from '@/store/store';
import { getBudgetValue } from '@/utils/campaigns';
import { getCurrencyFormat } from '@/utils/numbers';
import cn from '@/utils/style';
import { FC, memo, useCallback, useMemo, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DeleteModal from './DeleteModal';
import EditModal from './EditModal';
import { updateCampaignScenarioMutation } from '@/store/reducers';
import { CheckBadgeIcon } from '@heroicons/react/24/solid';

interface ScenarioProps {
  scenario: Scenario;
  isCollapsed?: boolean;
  readOnly?: boolean;
}

const SCENARIO_TITLES_MAP: { [key in keyof ScenarioContent]: string } = {
  term: 'Term (months)',
  costPerLink: 'Cost/Link',
  monthlyCost: 'Monthly Cost',
  linksPerMonth: 'Links Per Month',
};

const EMPTY_VALUE = '-';

const Scenario: FC<ScenarioProps> = ({ scenario, isCollapsed = false, readOnly = false }) => {
  const { campaignId } = useParams() as { campaignId: string };
  const dispatch = useDispatch<AppDispatch>();
  const scenarios = useSelector(selectScenarios);
  const selectedScenario = useSelector(selectSelectedScenario);
  const linksToBuildState = useSelector(selectLinksToBuildState);

  const [deleteAnchors] = useDeleteCampaignAnchorTextsMutation();
  const [fetchScenarios] = useLazyGetCampaignScenariosQuery();

  const [deleteScenario, { isLoading: deletingScenario }] = useDeleteCampaignScenarioMutation();
  const [updateScenarioError, setUpdateScenarioError] = useState<APIError>();
  const [deleteScenarioError, setDeleteScenarioError] = useState<APIError>();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [isScenarioUpdating, setIsScenarioUpdating] = useState(false);

  const scenarioContent = useMemo(() => {
    const linksPerMonth = Object.values(linksToBuildState[scenario.id]).reduce((sum, ltb) => sum + Number(ltb), 0);
    const formattedLinksPerMonth = Math.max(0, linksPerMonth);

    return {
      term: scenario.proj_length,
      costPerLink: getCurrencyFormat(scenario.cost_per_link),
      linksPerMonth: formattedLinksPerMonth.toLocaleString(),
      monthlyCost: getCurrencyFormat(formattedLinksPerMonth * scenario.cost_per_link),
    };
  }, [scenario, linksToBuildState]);

  const isBudgetExceeded = useMemo(
    () => Boolean(scenario.budget) && scenario.links_per_month * scenario.cost_per_link > (scenario.budget || 0),
    [scenario.links_per_month, scenario.cost_per_link, scenario.budget],
  );

  const isSelected = useMemo(() => selectedScenario?.id === scenario.id, [selectedScenario, scenario]);
  const isDraft = useMemo(() => scenario.id === undefined, [scenario]);
  const menuItems = useMemo(() => {
    if (scenarios.length === 1) return [{ label: 'Edit Scenario', onClick: () => setIsEditModalOpen(true) }];

    return [
      { label: 'Edit Scenario', onClick: () => setIsEditModalOpen(true) },
      { label: 'Delete Scenario', onClick: () => setIsDeleteModalOpen(true) },
    ];
  }, [scenarios.length]);

  const renderContent = useCallback(
    (key: string, idx: number) => {
      const typedKey = key as keyof ScenarioContent;
      const showWarning = typedKey === 'monthlyCost' && isBudgetExceeded;

      return (
        <div key={`${key}-${idx}`} className='flex items-center justify-between'>
          <p>{SCENARIO_TITLES_MAP[typedKey]}</p>

          <div className='flex flex-row gap-1'>
            {showWarning && (
              <WarningMessage
                tooltip={{
                  id: `budget-${scenario.id}`,
                  content: "Oops! It looks like you've exceeded your budget for this scenario",
                }}
              />
            )}
            <p>{scenarioContent[typedKey] || EMPTY_VALUE}</p>
          </div>
        </div>
      );
    },
    [scenarioContent, isBudgetExceeded, scenario.id],
  );

  const handleScenarioClick = useCallback(() => dispatch(setSelectedScenario(scenario.id)), [dispatch, scenario]);

  const handleEditConfirm = async (values: EditingScenarioValues, isDirty: boolean) => {
    if (!isDirty) {
      setIsEditModalOpen(false);
      return;
    }
    setIsScenarioUpdating(true);
    try {
      await dispatch(
        updateCampaignScenarioMutation({
          campaignId,
          scenario: { ...scenario, ...values, budget: getBudgetValue(values.budget) },
        }),
      );

      await fetchScenarios({ campaignId });

      setIsEditModalOpen(false);
    } catch (err) {
      setUpdateScenarioError(err as APIError);
    } finally {
      setIsScenarioUpdating(false);
    }
  };

  const handleDeleteConfirm = useCallback(async () => {
    if (scenarios.length <= 1) {
      return;
    }

    try {
      await deleteScenario({ campaignId, scenario });
      setIsDeleteModalOpen(false);
    } catch (err) {
      setDeleteScenarioError(err as APIError);
    }
  }, [scenarios, campaignId, scenario, deleteScenario, setIsDeleteModalOpen, setDeleteScenarioError]);

  const handleApprovedScenarioDeleteConfirm = useCallback(async () => {
    await deleteAnchors({ campaignId }).unwrap();
    await handleDeleteConfirm();
  }, [campaignId, deleteAnchors, handleDeleteConfirm]);

  if (scenario.id === undefined) {
    return <Skeleton className={cn('h-40 w-72', isCollapsed && 'h-20')} />;
  }

  return (
    <div
      key={scenario.id}
      className={cn('relative flex w-72 cursor-pointer flex-col rounded-leap border-gray-300 bg-white p-3 shadow-lg xl:p-4', isSelected && 'border-2 border-sky-500')}
      onClick={isSelected ? undefined : handleScenarioClick}
    >
      <div className={cn('mb-2 flex w-full items-center', isCollapsed && 'mb-0')}>
        <p className='w-50 overflow-hidden text-ellipsis whitespace-nowrap text-lg font-semibold'>{scenario.title}</p>
        {scenario.is_approved && <CheckBadgeIcon className='mx-2 w-6 text-green-500' />}
        {!readOnly && <Menu className='ml-auto' containerClassName='z-10 -right-3 xl:-right-3.5' items={menuItems} />}
      </div>
      {!isCollapsed && !isDraft && Object.keys(scenarioContent).map(renderContent)}

      {isEditModalOpen && (
        <EditModal
          scenario={scenario}
          isOpen={isEditModalOpen}
          onConfirm={handleEditConfirm}
          onClose={() => setIsEditModalOpen(false)}
          errorMessage={updateScenarioError?.data.message}
          isLoading={isScenarioUpdating}
        />
      )}
      {isDeleteModalOpen && (
        <Fragment>
          {scenario.is_approved ? (
            <Modal onClose={() => setIsDeleteModalOpen(false)} isOpen={isDeleteModalOpen} title=''>
              <div className='mb-4 flex w-[450px] flex-col items-center gap-4'>
                <h4 className='text-center'>Are you sure you want to delete this scenario?</h4>
                <p className='text-center'>If you do, all of your anchor text progress will be lost. This action cannot be undone.</p>
                <Button className='w-full' onClick={handleApprovedScenarioDeleteConfirm}>
                  Continue and Override
                </Button>
                <Button className='w-full' onClick={() => setIsDeleteModalOpen(false)}>
                  Cancel
                </Button>
              </div>
            </Modal>
          ) : (
            <DeleteModal
              isOpen={isDeleteModalOpen}
              onConfirm={handleDeleteConfirm}
              onClose={() => setIsDeleteModalOpen(false)}
              errorMessage={deleteScenarioError?.data.message}
              isLoading={deletingScenario}
            />
          )}
        </Fragment>
      )}
    </div>
  );
};

export default memo(Scenario);

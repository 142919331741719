import { FiltersModal, FiltersPills, SearchBarDebounce, Table } from '@/components';
import { NUMERIC_FILTERS_OPTIONS, STRING_FILTERS_OPTIONS } from '@/constants/defaultValues';
import { useTable } from '@/hooks/table';
import { selectTablesConfig } from '@/store/app/app.selector';
import { setTableConfig } from '@/store/app/app.slice';
import { selectScenarios } from '@/store/campaign/campaign.selector';
import { AppDispatch } from '@/store/store';
import { getColumnStateFromFilters, getFiltersFromColumnState } from '@/utils/filters';
import { FunnelIcon } from '@heroicons/react/24/solid';
import { ColumnFiltersState, FilterFnOption, Row, createColumnHelper } from '@tanstack/react-table';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface PagesIncludedProps {
  showScenariosNames?: boolean;
}

const COLUMN_FILTERS: Partial<ScenarioUrlsFilters> = {
  target_page: STRING_FILTERS_OPTIONS,
  total_volume: NUMERIC_FILTERS_OPTIONS,
  lrd_gap: NUMERIC_FILTERS_OPTIONS,
};

const pagesIncludedGlobalFilter = (row: Row<ScenarioUrl>, _columnId: keyof ScenarioUrl, filterValue: string) => {
  return row.original.target_page.includes(filterValue);
};

const columnHelper = createColumnHelper<ScenarioUrl>();

const usePageIncludedColumns = (scenarios: Array<Scenario>, showScenariosNames: boolean) => {
  const strFilter = 'stringFilter' as FilterFnOption<ScenarioUrl>;
  const numFilter = 'numberFilter' as FilterFnOption<ScenarioUrl>;

  const urlCols = useMemo(
    () => [
      columnHelper.accessor('target_page', {
        header: () => <p className='text-left'>Target URL</p>,
        cell: ({ getValue }) => (
          <p className='w-[500px] overflow-hidden text-ellipsis whitespace-nowrap text-left' title={getValue()}>
            {getValue()}
          </p>
        ),
        filterFn: strFilter,
      }),
      columnHelper.accessor('total_volume', {
        header: () => <p className='whitespace-nowrap text-right'>Search Volume</p>,
        cell: ({ getValue }) => <p className='text-right'>{getValue().toLocaleString()}</p>,
        size: 1,
        filterFn: numFilter,
      }),
      columnHelper.accessor('lrd_gap', {
        header: () => <p className='whitespace-nowrap text-right'>Domain Gap</p>,
        cell: ({ getValue }) => {
          const lrdGap = getValue() > 0 ? Math.ceil(getValue()).toLocaleString() : '-';
          return <p className='text-right'>{lrdGap}</p>;
        },
        size: 1,
        filterFn: numFilter,
      }),
    ],
    [],
  );

  const scenariosCols = useMemo(() => {
    if (!showScenariosNames) {
      return [];
    }

    return scenarios.map((scenario) =>
      columnHelper.display({
        id: scenario.id.toString(),
        header: () => <p className='whitespace-nowrap text-right'>{scenario.title}</p>,
        cell: ({ row }) => {
          const dataUrl = scenario.urls.find((url) => url.url_id === row.original.url_id);

          return (
            <span className='flex items-center justify-end gap-5'>
              <p>{dataUrl?.links_to_build?.toLocaleString() || '-'}</p>
            </span>
          );
        },
      }),
    );
  }, [scenarios, showScenariosNames]);

  return [...urlCols, ...scenariosCols];
};

const PagesIncluded: FC<PagesIncludedProps> = ({ showScenariosNames = true }) => {
  const dispatch = useDispatch<AppDispatch>();
  const scenarios = useSelector(selectScenarios);
  const tableConfig = useSelector(selectTablesConfig);

  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const [columnFiltersState, setColumnFiltersState] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');

  const filtersFromColumnState = useMemo(() => getFiltersFromColumnState(columnFiltersState) as Array<ScenarioPagesFilterValue>, [columnFiltersState]);
  const uniqueUrls = useMemo(() => {
    const urls = scenarios.flatMap((scenario) => scenario.urls);
    const uniqueUrlsMap = urls.reduce((uurls: Map<number, ScenarioUrl>, url) => {
      if (uurls.has(url.url_id)) {
        return uurls;
      }

      uurls.set(url.url_id, url);
      return uurls;
    }, new Map());

    return Array.from(uniqueUrlsMap.values());
  }, [scenarios]);

  const pagesIncludedColumns = usePageIncludedColumns(scenarios, showScenariosNames);
  const [table] = useTable({
    data: uniqueUrls,
    columns: pagesIncludedColumns,
    tableCustomOptions: ['allowSort', 'allowFilters'],
    defaultSorting: tableConfig['overview'].sorting,
    tableOptions: {
      onColumnFiltersChange: setColumnFiltersState,
      onGlobalFilterChange: setGlobalFilter,
      globalFilterFn: pagesIncludedGlobalFilter as FilterFnOption<ScenarioUrl>,
      state: {
        columnFilters: columnFiltersState,
        globalFilter: globalFilter,
      },
    },
  });

  useEffect(() => {
    dispatch(setTableConfig({ name: 'overview', config: { ...tableConfig['overview'], sorting: table.options.state.sorting } }));
  }, [table.options.state.sorting]); //eslint-disable-line

  const handleConfirm = useCallback((values: ScenarioPagesFiltersFormValues) => {
    setColumnFiltersState(getColumnStateFromFilters(values.filters));
    setIsFiltersModalOpen(false);
  }, []);

  const handleFilterRemove = useCallback((filter: ScenarioPagesFilterValue) => {
    setColumnFiltersState((oldState) => oldState.filter((i) => i.id !== filter.field));
  }, []);

  return (
    <div className='mt-2 flex flex-col'>
      <div className='mb-2 ml-1 flex flex-col gap-5'>
        <div className='flex w-full items-center gap-4'>
          <SearchBarDebounce onChange={(value) => setGlobalFilter(value)} inputClassName='w-[25.75rem] h-10' />
          <button onClick={() => setIsFiltersModalOpen(true)} className='rounded-lg border p-2'>
            <FunnelIcon className='w-5' />
          </button>
        </div>
        {filtersFromColumnState.length > 0 ? <FiltersPills filters={filtersFromColumnState} onFilterRemove={handleFilterRemove} /> : null}
      </div>
      <Table table={table} name='compareScenario' tableClass='shadow-none' cellStyle='border-b-0' />
      {isFiltersModalOpen && (
        <FiltersModal
          isOpen={isFiltersModalOpen}
          onClose={() => setIsFiltersModalOpen(false)}
          columnFilters={COLUMN_FILTERS}
          initialValue={filtersFromColumnState}
          onConfirm={handleConfirm}
        />
      )}
    </div>
  );
};

export default PagesIncluded;

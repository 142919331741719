import { Skeleton } from '@/components';

const AccessRequestsPageSkeleton = () => {
  return (
    <div className='w-full'>
      <Skeleton className='h-[calc(100vh-4rem)] w-full' />
    </div>
  );
};

export default AccessRequestsPageSkeleton;

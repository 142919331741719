import { ColumnSort, HeaderGroup, Row as RowType, Table as TableType } from '@tanstack/react-table';
import cn from '@/utils/style';
import { useCallback } from 'react';
import { FilterColumns, Header, Pagination, Row } from './components';

interface TableProps<T> {
  table: TableType<T>;
  name: Tables;
  allowColumnToggle?: boolean;
  tableClass?: string;
  headerClass?: string;
  containerClass?: string;
  rowStyle?: string;
  cellStyle?: string;
  isLoading?: boolean;
  totalRows?: number;
  loadingText?: string;
  onPageChange?: (pageIndex: number, pageSize: number) => void;
  onSortChange?: (columnSort: ColumnSort) => void;
  tableFooter?: JSX.Element;
  renderSubComponent?: (row: RowType<T>) => JSX.Element;
}

const noop = () => {};

const Table = <T extends unknown>({
  table,
  allowColumnToggle = false,
  tableClass,
  headerClass,
  rowStyle,
  cellStyle,
  isLoading = false,
  name,
  onPageChange,
  onSortChange,
  containerClass = '',
  totalRows = 1,
  loadingText = 'Loading campaigns...',
  tableFooter,
  renderSubComponent,
}: TableProps<T>) => {
  const renderHeaders = useCallback(
    (headerGroup: HeaderGroup<T>) => <Header key={headerGroup.id} className={headerClass} headerGroup={headerGroup} onSortChange={onSortChange} />,
    [headerClass, onSortChange],
  );

  const renderRows = useCallback(
    (row: RowType<T>) => <Row key={row.id} row={row} className={rowStyle} cellStyle={cellStyle} renderSubComponent={renderSubComponent} />,
    [rowStyle, cellStyle, renderSubComponent],
  );

  const tableContent = useCallback(() => {
    if (table.getRowModel().rows.length === 0 || isLoading) {
      return (
        <tr>
          <td className='py-2 text-center' colSpan={table.getCenterTotalSize()}>
            {isLoading ? loadingText : 'No data available with those filters'}
          </td>
        </tr>
      );
    }

    return table.getRowModel().rows.map(renderRows);
  }, [isLoading, renderRows, table, loadingText]);

  return (
    <div className={cn('flex flex-col', containerClass)}>
      {allowColumnToggle && (
        <div className='self-end'>
          <FilterColumns table={table} name={name} />
        </div>
      )}
      <table className={cn('w-full rounded-md tabular-nums shadow-lg', tableClass)}>
        <thead className='sticky top-0 z-10 shadow-sm'>{table.getHeaderGroups().map(renderHeaders)}</thead>
        <tbody className='bg-white'>{tableContent()}</tbody>
        {tableFooter && <tfoot className='sticky bottom-0 z-10'>{tableFooter}</tfoot>}
      </table>
      {table.getRowModel().rows.length !== 0 && !isLoading && table.options.manualPagination && (
        <Pagination table={table} onPageChange={onPageChange || noop} totalRows={totalRows} />
      )}
    </div>
  );
};

export default Table;

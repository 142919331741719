const XCarouselIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2 22C2 21.4477 2.44772 21 3 21H7C7.55228 21 8 21.4477 8 22C8 22.5523 7.55228 23 7 23H3C2.44772 23 2 22.5523 2 22ZM9 22C9 21.4477 9.44772 21 10 21H14C14.5523 21 15 21.4477 15 22C15 22.5523 14.5523 23 14 23H10C9.44772 23 9 22.5523 9 22ZM16 22C16 21.4477 16.4477 21 17 21H21C21.5523 21 22 21.4477 22 22C22 22.5523 21.5523 23 21 23H17C16.4477 23 16 22.5523 16 22Z'
      fill='#94A3B8'
    />
    <path
      d='M13.5222 7.77491L19.4785 1H18.0671L12.8952 6.88256L8.76437 1H4L10.2466 9.89547L4 17H5.41155L10.8732 10.7878L15.2356 17H20L13.5222 7.77491ZM5.92015 2.03974H8.0882L18.0677 16.0075H15.8997L5.92015 2.03974Z'
      fill='#94A3B8'
    />
  </svg>
);

export default XCarouselIcon;

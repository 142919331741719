import { WS_URL } from '@/constants/application';
import { ANCHOR_TEXT_NOTIFICATION_TYPE, CAMPAIGNS_NOTIFICATION_TYPE } from '@/constants/defaultValues';
import { selectAuthToken } from '@/store/auth/auth.selector';
import { base62Encode } from '@/utils/baseX';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReadyState } from 'react-use-websocket';
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';

type ConnectionStatus = 'Connecting' | 'Open' | 'Closing' | 'Closed' | 'Uninstantiated';

const CONNECTION_STATUS: Record<ReadyState, ConnectionStatus> = {
  [ReadyState.CONNECTING]: 'Connecting',
  [ReadyState.OPEN]: 'Open',
  [ReadyState.CLOSING]: 'Closing',
  [ReadyState.CLOSED]: 'Closed',
  [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
};

const useLastMessageFromWebSocket = (token?: string) => {
  const authToken = useSelector(selectAuthToken);

  const { lastMessage, readyState } = useWebSocket(`${WS_URL}?t=${base62Encode(token || authToken || '')}`, {
    shouldReconnect: () => true,
    share: true,
  });

  return { lastMessage, connectionStatus: CONNECTION_STATUS[readyState] };
};

const useCampaignsNotifications = () => {
  const { lastMessage, connectionStatus } = useLastMessageFromWebSocket();
  const [notification, setNotification] = useState<CampaignNotification>();

  useEffect(() => {
    if (lastMessage) {
      const { action, message, subject, icon, data }: CampaignNotification = JSON.parse(lastMessage.data);

      if (CAMPAIGNS_NOTIFICATION_TYPE.includes(action)) {
        setNotification({ action, message, subject, icon, data });
      }
    }
  }, [lastMessage]);

  return { notification, connectionStatus };
};

const useAnchorTextNotification = () => {
  const { lastMessage, connectionStatus } = useLastMessageFromWebSocket();
  const [notification, setNotification] = useState<AnchorTextNotification>();

  useEffect(() => {
    if (lastMessage) {
      const { action, message, subject, icon, data }: AnchorTextNotification = JSON.parse(lastMessage.data);

      if (ANCHOR_TEXT_NOTIFICATION_TYPE.includes(action)) {
        setNotification({ action, message, subject, icon, data });
      }
    }
  }, [lastMessage]);

  return { notification, connectionStatus };
};

export { useLastMessageFromWebSocket, useCampaignsNotifications, useAnchorTextNotification };

import { Button, Checkbox, Modal, ModalProps } from '@/components';
import { useToggle } from '@/hooks/toggle';
import cn from '@/utils/style';
import { RadioGroup } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { FC, useMemo, useState } from 'react';

interface KickOffModalProps extends Omit<ModalProps, 'onConfirm'> {
  onConfirm: (urls: Array<LIRUrl>, keywords: Array<Keyword>) => void;
  campaignUrls: Array<Url>;
  controlUrls: Array<Url>;
  keywords: Array<Keyword>;
}

const KickOffModal: FC<KickOffModalProps> = ({ isOpen, onClose, onConfirm, campaignUrls, controlUrls, keywords }) => {
  const [needImpactReport, setNeedImpactReport] = useState<boolean>(false);
  const [includeAllKws, setIncludeAllKws] = useState<boolean>(false);
  const [kws, setKws] = useState(keywords.map((kw) => ({ ...kw, include: false })));
  const { toggle: togglePriorityKwsModal, value: priorityKwsModalIsOpen } = useToggle({ initialValue: false });
  const [pages, setPages] = useState<Array<LIRUrl>>([]);
  const [includedKeywords, setIncludedKeywords] = useState<Array<Keyword>>([]);

  const hasKeywordsIncluded = useMemo(() => kws.some((kw) => kw.include), [kws]);

  const isConfirmButtonDisabled = useMemo(() => {
    if (needImpactReport) {
      return pages.filter((p) => p.type === 'control').length === 0 || pages.filter((p) => p.type === 'campaign').length === 0;
    }

    return false;
  }, [needImpactReport, pages]);

  const renderUrls = (url: Url, pages: Array<LIRUrl>, setPages: React.Dispatch<React.SetStateAction<Array<LIRUrl>>>, type: 'campaign' | 'control') => {
    const isChecked = pages.some((p) => p.id === url.id);

    const addOrRemove = () => {
      if (isChecked) {
        return setPages((old) => old.filter((p) => p.id !== url.id));
      }

      setPages((old) => [...old, { id: url.id, url: url.url, type }]);
    };

    return (
      <div key={url.id} className='flex items-center gap-2'>
        <Checkbox checked={isChecked} onChange={addOrRemove} />
        <p className='line-clamp-1 text-ellipsis break-all' title={url.url}>
          {url.url}
        </p>
      </div>
    );
  };

  const renderCampaignUrls = (url: Url) => {
    return renderUrls(url, pages, setPages, 'campaign');
  };

  const renderControlUrls = (url: Url) => {
    return renderUrls(url, pages, setPages, 'control');
  };

  const handleIncludeAllKws = () => {
    setIncludeAllKws((old) => !old);
    setKws((old) => old.map((kw) => ({ ...kw, include: !includeAllKws })));
  };

  const handleConfirmIncludeKws = () => {
    setIncludedKeywords(kws.filter((kw) => kw.include));
    togglePriorityKwsModal(false);
  };

  const handlePriorityKwsModalClose = () => {
    togglePriorityKwsModal(false);
    if (hasKeywordsIncluded) {
      return;
    }
    setIncludeAllKws(false);
    setKws(keywords.map((kw) => ({ ...kw, include: false })));
  };

  const toggleKeywordInclude = (kwId: number) => {
    setKws((prevKws) =>
      prevKws.map((kw) => {
        if (kw.id === kwId) {
          return { ...kw, include: !kw.include };
        }
        return kw;
      }),
    );
  };

  const removeKwDisplayed = (kwId: number) => {
    setIncludedKeywords((prevKws) => prevKws.filter((kw) => kw.id !== kwId));
    toggleKeywordInclude(kwId);
  };

  const renderSelectedKeywords = (keyword: Keyword) => (
    <div key={keyword.id} className='flex w-fit flex-nowrap items-center gap-2 whitespace-nowrap rounded-lg bg-blue-100 px-3 py-2'>
      <p>{keyword.text}</p>
      <button onClick={() => removeKwDisplayed(keyword.id)}>
        <XMarkIcon className='w-5' />
      </button>
    </div>
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} title='Kick off Campaign' className='max-w-modal-xl'>
      <div className='flex flex-col gap-4'>
        <p>Do you want to create an Impact Report?</p>
        <RadioGroup value={needImpactReport} onChange={setNeedImpactReport}>
          <div className='flex gap-4'>
            <RadioGroup.Option value={true}>
              {({ checked }) => {
                return (
                  <div className='flex w-full items-center justify-between'>
                    <div className='flex gap-3'>
                      <div className={cn('flex h-6 w-6 items-center justify-center rounded-full border border-sky-600 bg-white', checked && 'bg-sky-600')}>
                        {checked && <div className='m-auto h-3 w-3 rounded-full bg-white'></div>}
                      </div>
                      <div className='flex items-center'>
                        <RadioGroup.Description as='p'>Yes</RadioGroup.Description>
                      </div>
                    </div>
                  </div>
                );
              }}
            </RadioGroup.Option>
            <RadioGroup.Option value={false}>
              {({ checked }) => {
                return (
                  <div className='flex w-full items-center justify-between'>
                    <div className='flex gap-3'>
                      <div className={cn('flex h-6 w-6 items-center justify-center rounded-full border border-sky-600 bg-white', checked && 'bg-sky-600')}>
                        {checked && <div className='m-auto h-3 w-3 rounded-full bg-white'></div>}
                      </div>
                      <div className='flex items-center'>
                        <RadioGroup.Description as='p'>No</RadioGroup.Description>
                      </div>
                    </div>
                  </div>
                );
              }}
            </RadioGroup.Option>
          </div>
        </RadioGroup>

        {needImpactReport && (
          <div className='p flex flex-col gap-2'>
            <p>Confirm Campaign Pages:</p>
            <div className='flex h-32 max-h-36 w-[600px] flex-col gap-2 overflow-y-auto rounded-lg bg-slate-100 p-2'>{campaignUrls.map(renderCampaignUrls)}</div>
            <p>Select Control Pages:</p>
            <div className='flex h-32 max-h-36 w-[600px] flex-col gap-2 overflow-y-auto rounded-lg bg-slate-100 p-2'>
              {controlUrls.length > 0 ? controlUrls.map(renderControlUrls) : <p className='text-gray-300'>No data available</p>}
            </div>
            <div className='flex gap-2 py-2'>
              <div className='flex flex-col gap-2'>
                <div className='flex w-full flex-row gap-2' onClick={() => togglePriorityKwsModal()}>
                  <Checkbox checked={hasKeywordsIncluded} readOnly />
                  <label className='cursor-pointer text-blue-400 hover:text-blue-500'>Include Priority Keywords?</label>
                </div>
                {includedKeywords.length > 0 && (
                  <div className='flex max-h-24 w-[600px] flex-wrap items-center gap-3 overflow-y-auto rounded-lg bg-slate-100 p-4'>
                    {includedKeywords.map(renderSelectedKeywords)}
                  </div>
                )}
              </div>

              {priorityKwsModalIsOpen && (
                <Modal title='Priority Keywords' isOpen={true} onClose={handlePriorityKwsModalClose} onConfirm={handleConfirmIncludeKws} className='w-[400px]'>
                  <div className='flex gap-1 pb-2 pl-2'>
                    <Checkbox checked={includeAllKws} onChange={handleIncludeAllKws} />
                    <label>Include all Keywords?</label>
                  </div>
                  <div className='flex h-fit max-h-[500px] flex-col gap-1 overflow-auto p-2'>
                    {kws.map((kw) => (
                      <div key={kw.id} className='flex items-center gap-2'>
                        <Checkbox
                          checked={kw.include}
                          onChange={() => {
                            toggleKeywordInclude(kw.id);
                            setIncludeAllKws(false);
                          }}
                        />
                        <p>{kw.text}</p>
                      </div>
                    ))}
                  </div>
                </Modal>
              )}
            </div>
          </div>
        )}

        <div className='flex justify-between gap-4'>
          <Button variant='outline-light' onClick={onClose}>
            Cancel
          </Button>
          {needImpactReport ? (
            <Button onClick={() => onConfirm(pages, includedKeywords)} disabled={isConfirmButtonDisabled}>
              Kick off and create Impact Report
            </Button>
          ) : (
            <Button onClick={() => onConfirm([], [])}>Kick Off Campaign</Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default KickOffModal;

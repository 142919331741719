import { LoadingScreen } from '@/components';
import store from '@/store';
import campaignAPI from '@/store/campaign/campaign.api';
import { FC, Suspense, useState } from 'react';
import { Await, LoaderFunction, defer, useLoaderData, useParams } from 'react-router-dom';
import { ScenarioReviewBody, ScenarioReviewProvider } from '../Campaigns/pages/ScenarioReviewPage/components';
import cn from '@/utils/style';

interface AnalysisPresentationPageParams {
  campaignId: string;
  term?: keyof ROISummary;
}

const AnalysisPresentationPage: FC = () => {
  const { term } = useParams() as { term: keyof ROISummary };
  const { data, error } = useLoaderData() as PageLoaderDefData<AnalysisPresentationPageData>;
  const [selectedTab, setSelectedTab] = useState<'campaignProposal' | 'pagesIncluded'>('campaignProposal');

  const handleCampaignProposalClick = () => setSelectedTab('campaignProposal');
  const handlePagesIncludedClick = () => setSelectedTab('pagesIncluded');

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Await
        resolve={data}
        errorElement={
          error && (
            <p>
              something went wrong, {error?.status} - {error?.data.message}
            </p>
          )
        }
      >
        {(data: AnalysisPresentationPageData) => {
          const [campaign] = data;

          return (
            <div className='flex w-full flex-col items-center'>
              <header className='flex w-full items-center justify-center bg-presentation-background bg-cover bg-center bg-no-repeat'>
                <div className='z-10 py-14 text-sky-600'>
                  <p className='text-xl font-semibold'>Powered by</p>
                  <p className='text-4xl font-bold'>Linklaunch</p>
                </div>
              </header>

              <section className='flex w-full max-w-leap-screen flex-col gap-8 px-16 pt-12'>
                <div className='flex w-full items-center justify-between'>
                  <p className='text-2xl font-bold'>{campaign?.name}</p>
                  <div className='w-fit rounded-lg bg-white p-1'>
                    <button
                      onClick={handleCampaignProposalClick}
                      className={cn('px-6 py-2 font-medium', selectedTab === 'campaignProposal' ? 'rounded-lg bg-sky-600 text-white' : 'text-slate-400')}
                    >
                      Campaign Proposal
                    </button>
                    <button
                      onClick={handlePagesIncludedClick}
                      className={cn('px-6 py-2 font-medium', selectedTab === 'pagesIncluded' ? 'rounded-lg bg-sky-600 text-white' : 'text-slate-400')}
                    >
                      Pages Included
                    </button>
                  </div>
                </div>
                <ScenarioReviewProvider>
                  <ScenarioReviewBody readonly selectedTab={selectedTab} selectedTerm={term} />
                </ScenarioReviewProvider>
              </section>
            </div>
          );
        }}
      </Await>
    </Suspense>
  );
};

type AnalysisPresentationPageData = [CampaignJSON, APIListResponse<Scenario>];

const analysisPresentationPageLoader: LoaderFunction = async ({ params }) => {
  const { campaignId } = params as unknown as AnalysisPresentationPageParams;

  const searchParams = new URLSearchParams(document.location.search);
  const viewToken = searchParams.get('vt') || undefined;

  const campaignPromise = store.dispatch(campaignAPI.endpoints.getCampaign.initiate({ campaignId, viewToken }));
  const scenariosPromise = store.dispatch(campaignAPI.endpoints.getCampaignScenarios.initiate({ campaignId, viewToken }));

  try {
    const data = Promise.all([campaignPromise.unwrap(), scenariosPromise.unwrap()]);

    return defer({ data });
  } catch (error) {
    return {
      error: error as APIError,
    };
  } finally {
    campaignPromise.unsubscribe();
    scenariosPromise.unsubscribe();
  }
};

export { analysisPresentationPageLoader };
export type { AnalysisPresentationPageParams };
export default AnalysisPresentationPage;

import { SCREEN_SIZES } from '@/constants/layout';
import { useFilterContext } from '@/hooks/filters';
import { useScreenSize } from '@/hooks/screenSize';
import useTutorialContext from '@/hooks/tutorial';
import { useVirtualizer } from '@/hooks/virtualizer';
import { useCampaignsNotifications } from '@/hooks/websocket';
import { useGetCampaignListQuery, useLazyGetCampaignListQuery } from '@/store/campaign/campaign.api';
import { useUpdateUserConfigMutation } from '@/store/users/users.api';
import { getCampaignAnalysisStep, getUpdatedCampaignList } from '@/utils/campaigns';
import { VirtualItem } from '@tanstack/react-virtual';
import { FC, Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import DashboardSkeleton from '../../DashboardSkeleton';
import CampaignsCardsFilters from '../CampaignsCardsFilters';
import CampaignCard from './CampaignCard';
import { INITIAL_PAGE } from '@/constants/pagination';
import { useInView } from 'react-intersection-observer';
import DashboardWithoutCampaigns from '../DashboardWithoutCampaigns';

const CampaignsCards: FC = () => {
  const navigate = useNavigate();
  const { updateTutorialState } = useTutorialContext();
  const { filters } = useFilterContext();
  const [fetchedPages, setFetchedPages] = useState<Array<number>>([]);
  const [searchParams] = useSearchParams();
  const isArchived = useMemo(() => searchParams.get('tab') === 'archive', [searchParams]);

  const addFetchedPages = useCallback((value: number) => {
    return setFetchedPages((old) => [...new Set([...old, value])]);
  }, []);

  const parentRef = useRef<HTMLDivElement>(null);

  const { width } = useScreenSize();

  const [page, setPage] = useState(Number(INITIAL_PAGE));

  const [pollingInterval, setPollingInterval] = useState(0);
  const {
    data: campaigns,
    isLoading,
    isFetching,
    isSuccess,
    isError,
  } = useGetCampaignListQuery({ ...filters, page, include: ['country', 'urls'], isArchived }, { pollingInterval });
  const [fetchCampaign] = useLazyGetCampaignListQuery();
  const [updateUserConfig] = useUpdateUserConfigMutation();
  const [inTableCamapigns, setInTableCampaigns] = useState<Array<CampaignJSON>>([]);
  const { notification: campaignNotification, connectionStatus } = useCampaignsNotifications();

  const hasNextPage = useMemo(() => campaigns && Boolean(campaigns.next), [campaigns]);
  const count = useMemo(() => (campaigns ? campaigns.results.length : 0), [campaigns]);
  const cardSize = useMemo(() => (width >= SCREEN_SIZES.xxl ? 172 : 148), [width]);

  const { ref, inView } = useInView();

  const shouldShowEmptyDashboard = useMemo(
    () => campaigns && campaigns.results.length === 0 && !isLoading && !filters.name__icontains && !filters.status,
    [campaigns, isLoading, filters],
  );

  const fetchCampaignsManually = useCallback(
    async (page: number) => {
      const { results } = await fetchCampaign({ ...filters, page, include: ['country'] }).unwrap();

      for (const camp of results) {
        setInTableCampaigns((oldCampaigns) => getUpdatedCampaignList(oldCampaigns, camp));
      }
    },
    [fetchCampaign, filters],
  );

  useEffect(() => {
    if (isSuccess) {
      updateTutorialState({ run: true, tourActive: true });
    }
  }, [isSuccess, updateTutorialState]);

  useEffect(() => {
    if (campaignNotification) {
      setInTableCampaigns((oldCampaigns) => getUpdatedCampaignList(oldCampaigns, campaignNotification.data));
    }
  }, [campaignNotification]);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (['Closed', 'Uninstantiated'].includes(connectionStatus)) {
        for (const page of fetchedPages) {
          await fetchCampaignsManually(page);
        }
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [connectionStatus, fetchCampaignsManually, fetchedPages]);

  useEffect(() => {
    setPollingInterval(0);
    if (campaigns) {
      setInTableCampaigns(campaigns.results);

      if (campaigns.results.some((i) => i.last_run_status !== 'done')) {
        setPollingInterval(10000);
      }
    }
  }, [campaigns]);

  useEffect(() => {
    addFetchedPages(page);
  }, [page, addFetchedPages]);

  useEffect(() => {
    setPage(INITIAL_PAGE);
    addFetchedPages(INITIAL_PAGE);
  }, [filters, addFetchedPages]);

  const [virtualizer, items, { paddingTop, paddingBottom }] = useVirtualizer({
    count: hasNextPage ? count + 1 : count,
    getScrollElement: () => parentRef.current,
    estimateSize: () => cardSize,
  });

  useEffect(() => {
    if (hasNextPage && !isFetching && !isLoading && !isError && inView && campaigns && campaigns.next) {
      setPage(campaigns.next);
    }
  }, [hasNextPage, isFetching, items, inView, isLoading, isError, campaigns]);

  const renderCards = ({ index, key }: VirtualItem<Element>) => {
    if (!campaigns) {
      return null;
    }

    const campaign = inTableCamapigns.at(index);
    const isRunDone = campaign && campaign.last_run_status === 'done';

    const handleCampaignClick = async () => {
      if (campaign && campaign.id && isRunDone) {
        await updateUserConfig({ last_edited_campaign: campaign.id });
        const step = getCampaignAnalysisStep(campaign);
        const route = generatePath('campaigns/:campaignId/:step', { campaignId: campaign.id.toString(), step });
        navigate(route);
      }
    };

    if (!isArchived && campaign && campaign.is_archived) {
      return null;
    }

    return (
      <Fragment key={`${campaign ? campaign.id : '?'}|${key}`}>
        {campaign ? (
          <div
            className='my-2 flex w-full flex-row flex-nowrap items-center justify-between rounded-leap bg-white px-5 shadow-md first-of-type:my-0'
            style={{ height: `${cardSize}px` }}
          >
            {campaign && <CampaignCard campaign={campaign} onClick={handleCampaignClick} isDone={isRunDone} index={index} />}
          </div>
        ) : (
          <Fragment />
        )}
      </Fragment>
    );
  };

  if (isLoading) {
    return <DashboardSkeleton />;
  }

  if (shouldShowEmptyDashboard) {
    return <DashboardWithoutCampaigns />;
  }

  return (
    <Fragment>
      <CampaignsCardsFilters pageSetter={setPage} isFetching={isFetching} />
      <div ref={parentRef} className='flex max-h-[92%] w-full flex-col flex-nowrap overflow-y-auto'>
        <div className='relative w-full px-2' style={{ paddingTop, paddingBottom, height: `${virtualizer.getTotalSize()}px` }}>
          {items.map(renderCards)}
          {hasNextPage && (
            <div
              ref={ref}
              style={{ height: `${cardSize}px` }}
              className='my-2 flex w-full flex-row flex-nowrap items-center justify-between rounded-leap bg-white px-5 shadow-md first-of-type:my-0'
            >
              <p className='m-auto w-full'>{hasNextPage && 'Loading more...'}</p>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default CampaignsCards;

import { FiltersModal, FiltersPills, PriorityLegend, RefineKeywordsTable, SearchBarDebounce } from '@/components';
import { NUMERIC_FILTERS_OPTIONS, STRING_FILTERS_OPTIONS } from '@/constants/defaultValues';
import { useToggle } from '@/hooks/toggle';
import { getColumnStateFromFilters, getFiltersFromColumnState } from '@/utils/filters';
import { FunnelIcon } from '@heroicons/react/24/solid';
import { ColumnFiltersState, FilterFnOption, Row } from '@tanstack/react-table';
import { FC, Fragment, useCallback, useMemo, useState } from 'react';
import { getKeywordsColumns } from '@/components/RefineKeywordsTable';
import { useDispatch, useSelector } from 'react-redux';
import { selectKeywords } from '@/store/campaign/campaign.selector';
import { AppDispatch } from '@/store/store';
import { setKeywords, updateKeyword } from '@/store/campaign/campaign.slice';
import { useScreenSize } from '@/hooks/screenSize';
import { useTableOptions } from '@/hooks/table';

type RefineKeywordsContentProps = {
  isLoading: boolean;
};

const COLUMN_FILTERS: Partial<RefineKeywordsColumnFilters> = {
  text: STRING_FILTERS_OPTIONS,
  target_page: STRING_FILTERS_OPTIONS,
  campaign_rank: NUMERIC_FILTERS_OPTIONS,
  search_volume: NUMERIC_FILTERS_OPTIONS,
  difficulty: NUMERIC_FILTERS_OPTIONS,
  rootDomainDiff: NUMERIC_FILTERS_OPTIONS,
};

const keywordsGlobalFilter = (row: Row<RefineCampaignKeyword>, _columnId: keyof RefineCampaignKeyword, filterValue: string) => {
  const { text, target_page } = row.original;
  return text.includes(filterValue) || target_page.includes(filterValue);
};

const RefineKeywordsContent: FC<RefineKeywordsContentProps> = ({ isLoading }) => {
  const keywords = useSelector(selectKeywords);
  const dispatch = useDispatch<AppDispatch>();
  const [columnFiltersState, setColumnFiltersState] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const { value: isFiltersModalOpen, toggle: toggleFiltersModal } = useToggle();

  const filtersFromColumnState = useMemo(() => getFiltersFromColumnState(columnFiltersState) as AnalysisFilterValue[], [columnFiltersState]);

  const handleConfirm = useCallback(
    (values: AnalysisFiltersFormValues) => {
      setColumnFiltersState(getColumnStateFromFilters(values.filters));
      toggleFiltersModal(false);
    },
    [toggleFiltersModal],
  );

  const handleFilterRemove = useCallback((filter: AnalysisFilterValue) => {
    setColumnFiltersState((oldState) => oldState.filter((i) => i.id !== filter.field));
  }, []);

  const { width } = useScreenSize();

  const columns = useMemo(() => getKeywordsColumns(), []);

  const tableOptions = useTableOptions<RefineCampaignKeyword>({
    tableCustomOptions: ['allowResize', 'allowSort', 'allowFilters', 'allowSelect'],
    defaultSorting: [{ id: 'search_volume', desc: true }],
    tableOptions: {
      defaultColumn: {
        minSize: 100,
      },
      onGlobalFilterChange: setGlobalFilter,
      onColumnFiltersChange: setColumnFiltersState,
      globalFilterFn: keywordsGlobalFilter as FilterFnOption<RefineCampaignKeyword>,
      state: {
        globalFilter: globalFilter,
        columnFilters: columnFiltersState,
      },
      meta: {
        size: (width * 0.753) / columns.length,
        minSize: 100,
        maxSize: 800,
        updateData: (rowIndex: number, columnId: keyof RefineCampaignKeyword, value: number | boolean) => {
          dispatch(
            updateKeyword({
              ...keywords[rowIndex],
              [columnId]: value,
            }),
          );
        },
        updateEveryRowData: (columnId: keyof RefineCampaignKeyword, value: number | boolean) => {
          const newKws = keywords.map((kw) => ({ ...kw, [columnId]: value }));
          dispatch(setKeywords(newKws));
        },
      },
    },
  });

  if (isLoading) {
    return null;
  }

  return (
    <Fragment>
      <div className='mb-4 flex w-full items-center gap-4'>
        <div className='flex w-full items-center gap-2'>
          <SearchBarDebounce onChange={(value) => setGlobalFilter(value)} inputClassName='w-full h-10' className='w-full' />
          <button onClick={() => toggleFiltersModal(true)} className='rounded-lg border p-2'>
            <FunnelIcon className='w-5' />
          </button>
        </div>
      </div>
      <FiltersPills filters={filtersFromColumnState} onFilterRemove={handleFilterRemove} />
      <RefineKeywordsTable data={keywords} allowResize loadingText='Loading keywords...' className='w-full' itemHeight={39.5} tableOptions={tableOptions} />
      <PriorityLegend />
      {isFiltersModalOpen && (
        <FiltersModal
          isOpen={isFiltersModalOpen}
          onClose={() => toggleFiltersModal(false)}
          columnFilters={COLUMN_FILTERS}
          initialValue={filtersFromColumnState}
          onConfirm={handleConfirm}
        />
      )}
    </Fragment>
  );
};

export default RefineKeywordsContent;

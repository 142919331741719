const HelpCenterIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM12.01 18C11.31 18 10.75 17.44 10.75 16.74C10.75 16.03 11.31 15.49 12.01 15.49C12.72 15.49 13.26 16.03 13.26 16.74C13.25 17.43 12.72 18 12.01 18ZM15.02 10.6C14.26 11.71 13.54 12.06 13.15 12.77C13.05 12.95 12.99 13.09 12.96 13.4C12.91 13.85 12.51 14.18 12.06 14.18H12C11.48 14.18 11.07 13.74 11.12 13.22C11.15 12.88 11.23 12.53 11.42 12.19C11.91 11.32 12.84 10.8 13.38 10.03C13.95 9.22 13.63 7.7 12.01 7.7C11.3 7.7 10.83 8.06 10.54 8.49C10.29 8.85 9.85 9.02 9.44 8.85C8.91 8.64 8.72 8 9.04 7.54C9.65 6.65 10.67 6 11.99 6C13.47 6 14.48 6.67 15 7.52C15.44 8.24 15.7 9.59 15.02 10.6Z'
      fill='#94A3B8'
    />
  </svg>
);

export default HelpCenterIcon;

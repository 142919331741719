import { Button, Input, Label, OrganicResearchModal } from '@/components';
import { useToggle } from '@/hooks/toggle';
import { FC, Fragment, useState } from 'react';

interface DomainInputFieldProps {
  name: string;
  country: string;
  disabled?: boolean;
}

const DomainInputField: FC<DomainInputFieldProps> = ({ name, country, disabled = false }) => {
  const [domainInputValue, setDomainInputValue] = useState('');
  const { value: isOrganicResearchModalOpen, toggle: toggleOrganicResearchModal } = useToggle();

  const handleEnterKey = (key: string) => {
    if (key === 'Enter' && domainInputValue.trim() !== '') {
      toggleOrganicResearchModal(true);
    }
  };

  return (
    <Fragment>
      <div className='flex w-full flex-col gap-2'>
        <Label>Your domain</Label>
        <div className='flex w-full items-center gap-4'>
          <div className='w-full'>
            <Input value={domainInputValue} onChange={setDomainInputValue} className='w-full' placeholder='linklaunch.com' disabled={disabled} onKeyUp={handleEnterKey} />
          </div>
          <Button onClick={() => toggleOrganicResearchModal(true)} className='whitespace-nowrap' disabled={domainInputValue.trim() === '' || disabled}>
            Fetch pages
          </Button>
        </div>
      </div>

      {isOrganicResearchModalOpen && (
        <OrganicResearchModal name={name} isOpen={isOrganicResearchModalOpen} onClose={() => toggleOrganicResearchModal(false)} url={domainInputValue.trim()} country={country} />
      )}
    </Fragment>
  );
};

export default DomainInputField;

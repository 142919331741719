import { Button } from '@/components';
import { useScreenSize } from '@/hooks/screenSize';
import { useDownloadScenarioExportCsvMutation } from '@/store/campaign/campaign.api';
import { ArrowDownTrayIcon, ArrowPathIcon } from '@heroicons/react/20/solid';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { FC, useMemo } from 'react';
import Drawer from 'react-modern-drawer';
import { useParams } from 'react-router-dom';

import 'react-modern-drawer/dist/index.css';
import AnchorTextCard from './AnchorTextCard';

type AnchorTextDrawerProps = {
  selectedUrl: UrlAnchorTextTableColumns | null;
  onClose: () => void;
  urlAnchorTexts: UrlAnchorText[];
  updateUrlAnchorText: (data: UrlAnchorText) => void;
};

const AnchorTextDrawer: FC<AnchorTextDrawerProps> = ({ selectedUrl, onClose, urlAnchorTexts, updateUrlAnchorText }) => {
  const { campaignId } = useParams() as { campaignId: string };
  const screenSize = useScreenSize();
  const [downloadCSV, { isLoading: isCSVLoading }] = useDownloadScenarioExportCsvMutation();

  const anchorListHeight = useMemo(() => screenSize.height - 170, [screenSize.height]);
  const selectedAnchorTexts = useMemo(() => urlAnchorTexts.filter((uat) => uat.url.id === selectedUrl?.url_id), [urlAnchorTexts, selectedUrl]);

  if (!selectedUrl) {
    return null;
  }

  const renderAnchorTexts = (item: UrlAnchorText, idx: number) => <AnchorTextCard key={idx} anchorText={item} idx={idx} updateUrlAnchorText={updateUrlAnchorText} />;
  const handleDownloadCsv = async () => await downloadCSV({ campaignId });

  return (
    <Drawer open={true} direction='right' enableOverlay={false} size='350px'>
      <div className='relative flex flex-col items-start pt-4'>
        <div className='w-full px-4 text-left'>
          <XMarkIcon className='ml-auto w-6 cursor-pointer pb-2' onClick={onClose} />
          <div className='flex flex-row items-center justify-between'>
            <h3>Anchor Text</h3>
            <Button variant='outline-light' className='flex flex-nowrap items-center gap-2' onClick={handleDownloadCsv} disabled={isCSVLoading}>
              {isCSVLoading ? <ArrowPathIcon className='w-5 animate-spin text-gray-700' /> : <ArrowDownTrayIcon className='w-5 text-gray-700' />}
              Export CSV
            </Button>
          </div>
        </div>
        <p className='p-4 text-sm font-bold text-gray-500'>{selectedUrl.target_page}</p>
        <div style={{ height: `${anchorListHeight}px` }} className='w-full overflow-auto'>
          {selectedAnchorTexts.map(renderAnchorTexts)}
        </div>
      </div>
    </Drawer>
  );
};

export default AnchorTextDrawer;

import { Loading, Modal } from '@/components';
import { FC, useMemo } from 'react';
import { useGetCampaignQuery } from '@/store/campaign/campaign.api';
import { useParams } from 'react-router-dom';

interface FailsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CampaignFailsModal: FC<FailsModalProps> = ({ isOpen, onClose }) => {
  const { campaignId } = useParams() as { campaignId: string };
  const { data: campaign, isLoading } = useGetCampaignQuery({ campaignId });

  const { failedKeywords, failedUrls } = useMemo(() => {
    if (!campaign?.last_run) {
      return { failedKeywords: [], failedUrls: [] };
    }
    return {
      failedKeywords: campaign.last_run.failed_keywords,
      failedUrls: campaign.last_run.failed_urls,
    };
  }, [campaign]);

  const renderItem = (entry: string[], index: number) => {
    const [data, reason] = entry;
    return (
      <li key={index}>
        {data} : {reason}
      </li>
    );
  };

  return (
    <Modal isOpen={isOpen} title='Fails' onClose={onClose}>
      {isLoading && (
        <div className='grid place-content-center'>
          <Loading />
        </div>
      )}
      {failedKeywords.length > 0 && (
        <div className='py-2'>
          <h2 className='pb-1 text-lg font-semibold'>Failed Keywords</h2>
          <ul className='max-h-60 list-inside list-decimal overflow-y-auto rounded-lg bg-sky-100 p-2'>{failedKeywords.map((entry, index) => renderItem(entry, index))}</ul>
        </div>
      )}
      {failedUrls.length > 0 && (
        <div className='py-2'>
          <h2 className='pb-1 text-lg font-semibold'>Failed URLs</h2>
          <ul className='max-h-60 list-inside list-decimal overflow-y-auto rounded-lg bg-sky-100 p-2'>{failedUrls.map((entry, index) => renderItem(entry, index))}</ul>
        </div>
      )}
    </Modal>
  );
};

export default CampaignFailsModal;

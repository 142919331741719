const AirplaneModeActiveIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M21.48 13.7674L13.5 9.06738V3.56738C13.5 2.73738 12.83 2.06738 12 2.06738C11.17 2.06738 10.5 2.73738 10.5 3.56738V9.06738L2.52 13.7674C2.2 13.9474 2 14.2974 2 14.6674C2 15.3674 2.67 15.8674 3.34 15.6774L10.5 13.5774V19.0674L8.24 20.4174C8.09 20.5074 8 20.6774 8 20.8474V21.4274C8 21.7574 8.31 21.9974 8.62 21.9174L11.54 21.1874L12 21.0674L12.38 21.1574L12.8 21.2674L14.7 21.7474L15.37 21.9174C15.69 21.9974 15.99 21.7574 15.99 21.4274V20.8474C15.99 20.6674 15.9 20.5074 15.75 20.4174L13.5 19.0674V13.5674L20.66 15.6674C21.33 15.8674 22 15.3674 22 14.6674C22 14.2974 21.8 13.9474 21.48 13.7674Z'
        fill='#94A3B8'
      />
    </svg>
  );
};

export default AirplaneModeActiveIcon;

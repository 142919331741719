import cn from '@/utils/style';
import { ChangeEventHandler, FC, FocusEvent } from 'react';

interface TextareaProps {
  value: string;
  onChange: (value: string) => void;
  onBlur?: (e: FocusEvent<HTMLTextAreaElement>) => void;
  rows?: number;
  placeholder?: string;
  hasError?: boolean;
  className?: string;
}

const Textarea: FC<TextareaProps> = ({ value, onChange, rows = 3, placeholder, hasError = false, className, onBlur }) => {
  const handleChange: ChangeEventHandler<HTMLTextAreaElement> = ({ target }) => {
    onChange(target.value);
  };

  return (
    <textarea
      className={cn(
        'w-full rounded-lg border border-gray-200 p-2 shadow-md outline-none hover:border-gray-300 focus:border-blue-500 focus:ring-2',
        hasError && 'border-red-600 ring-red-300 hover:border-red-600 focus:border-red-600',
        className,
      )}
      onBlur={onBlur}
      value={value}
      onChange={handleChange}
      rows={rows}
      placeholder={placeholder}
    />
  );
};

export default Textarea;
export type { TextareaProps };

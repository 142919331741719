import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import TutorialContext, { TutorialValues, tutorialInitialValues } from './TutorialContext';

const TutorialProvider: FC<PropsWithChildren> = ({ children }) => {
  const [tutorialState, setTutorialState] = useState(tutorialInitialValues.tutorialState);

  const updateTutorialState = useCallback((state: Partial<TutorialValues>) => {
    setTutorialState((oldState) => ({ ...oldState, ...state }));
  }, []);

  const values = useMemo(
    () => ({
      tutorialState,
      updateTutorialState,
    }),
    [tutorialState, updateTutorialState],
  );

  return <TutorialContext.Provider value={values}>{children}</TutorialContext.Provider>;
};

export default TutorialProvider;

import cn from '@/utils/style';
import { Tab } from '@headlessui/react';
import { FC } from 'react';

interface TabsProps {
  tabs: Array<TabsContent>;
}

const Tabs: FC<TabsProps> = ({ tabs }) => {
  const renderLabels = (tab: TabsContent, index: number) => {
    return (
      <Tab
        key={`${tab.label}-${index}`}
        className={({ selected }) =>
          cn(
            'whitespace-nowrap px-6 py-2 text-slate-400 opacity-100 outline-none focus:ring-0',
            selected && 'rounded-lg bg-sky-600 text-white',
            tab.disabled && 'cursor-not-allowed opacity-50',
          )
        }
        disabled={tab.disabled}
      >
        {tab.label}
      </Tab>
    );
  };

  const renderPanels = (tab: TabsContent, index: number) => {
    return <Tab.Panel key={`${tab.label}-${index}`}>{tab.content}</Tab.Panel>;
  };

  return (
    <Tab.Group>
      <div className='flex items-center justify-between'>
        <Tab.List className='flex w-min flex-nowrap items-center rounded-lg bg-slate-100 p-1 font-bold'>{tabs.map(renderLabels)}</Tab.List>
      </div>
      <Tab.Panels>{tabs.map(renderPanels)}</Tab.Panels>
    </Tab.Group>
  );
};

export default Tabs;

import { Button, KeyConfigIcon, Label, Switch, TextInputField, Textarea } from '@/components';
import { TrashIcon } from '@heroicons/react/24/solid';
import { ClipboardEvent, FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import PullTopKeywordsModal from '../PullTopKeywordsModal';
import { useToggle } from '@/hooks/toggle';
import { PencilIcon } from '@heroicons/react/20/solid';
import { useField } from 'formik';
import useDebounce from '@/hooks/debounce';
import cn from '@/utils/style';
import { trimmer, UrlValidator } from '@/utils/urls';
const getSingleCombination = (data: string, keywords: string[]) => {
  const urls = data.split('\n').filter(trimmer);

  if (urls.length !== 1) {
    return null;
  }

  const [url, ...kws] = urls[0].split(/,|\t/).map(trimmer);

  const combinationKws = kws.length ? kws : keywords;

  return { url, keywords: combinationKws };
};

type CombinationProps = {
  field: string;
  onDelete?: () => void;
  disabled?: boolean;
  hasError?: boolean;
  onSwitch?: (index: number, toggled: boolean) => void;
  onError?: (hasError: boolean) => void;
  index?: number;
  initialSwitchState?: boolean;
  onPaste?: (e: ClipboardEvent<HTMLInputElement>) => void;
};

type KeywordOptions = 'pull_top_keywords' | 'enter_keywords';

const Combination: FC<CombinationProps> = ({ field, onDelete, hasError, disabled, onSwitch, index, onError, onPaste, initialSwitchState }) => {
  const { value: isPullTopKeywordsModalOpen, toggle: togglePullTopKeywordsModal } = useToggle();
  const { value: isTopKeywordsSwitchToggleOn, toggle: toggleTopKeywordsSwitch } = useToggle({ initialValue: initialSwitchState });
  // const { value: isLocaleSwitchToggleOn, toggle: toggleLocaleSwitch } = useToggle();
  const { value: isTopKeywordsAccordionOpen, toggle: toggleTopKeywordsAccordion } = useToggle();

  // const [autocompleteQuery, setAutocompleteQuery] = useState('');
  // const debouncedAutocompleteQuery = useDebounce(autocompleteQuery, 750);
  // const { data, isLoading, isFetching } = useGetLocationsQuery({ name: debouncedAutocompleteQuery }, { skip: debouncedAutocompleteQuery.length < 3 });

  const [{ value: combination }, , { setValue }] = useField<Combination>(field);

  const { keywords: combinationKeywords } = combination;

  const [manuallyAddedUrl, setManuallyAddedUrl] = useState('');

  const debouncedUrl = useDebounce(combination.url.trim(), 750);

  const [selectedKeywordMethod, setSelectedKeywordMethod] = useState<KeywordOptions | undefined>();

  const hasKeywords = useMemo(() => combination.keywords.length > 0, [combination]);

  const isValidUrl = useMemo(() => UrlValidator.isValidSync(debouncedUrl), [debouncedUrl]);

  const showWarning = useMemo(() => {
    return combination.url.trim().length !== 0 && isValidUrl && combination.keywords.length === 0 && !isTopKeywordsSwitchToggleOn;
  }, [combination, isValidUrl, isTopKeywordsSwitchToggleOn]);

  useEffect(() => {
    onError && onError(false);
  }, [isTopKeywordsSwitchToggleOn, onError]);

  const visibleKeywords = useMemo(() => {
    const keywords = combination.keywords;
    if (isTopKeywordsAccordionOpen) {
      return keywords.join(', ');
    }
    return keywords.slice(0, 10).join(', ');
  }, [combination, isTopKeywordsAccordionOpen]);

  const handlePullTopKeywordsConfirm = () => {
    setSelectedKeywordMethod('pull_top_keywords');
    togglePullTopKeywordsModal(false);
  };

  const handleDeleteKeywords = () => {
    toggleTopKeywordsSwitch(false);
    toggleTopKeywordsAccordion(false);
    setSelectedKeywordMethod(undefined);

    setValue({ ...combination, keywords: [] });
  };

  const handleKeywordsInputOnChange = (value: string) => {
    setManuallyAddedUrl(value);
  };

  // const handleLocaleSwitch = useCallback(() => {
  //   if (isLocaleSwitchToggleOn) {
  //     setValue({ ...combination, location_id: undefined });
  //     setAutocompleteQuery('');
  //   }
  //   toggleLocaleSwitch();
  // }, [isLocaleSwitchToggleOn, combination, toggleLocaleSwitch]); // eslint-disable-line react-hooks/exhaustive-deps

  // const handleLocationOnSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => setAutocompleteQuery(e.target.value), []);

  const handleKeywordsInputOnBlur = (e: React.FocusEvent<HTMLTextAreaElement, Element>) => {
    const { value } = e.target;
    const newKws = value
      .split(/,|\n/)
      .map(trimmer)
      .reduce((acc, kw) => {
        if (kw.length > 0) {
          acc.push(kw);
        }
        return acc;
      }, [] as string[]);
    setValue({ ...combination, keywords: newKws });
  };

  useEffect(() => {
    setManuallyAddedUrl(combination.keywords.join(', '));
  }, [combination.keywords]);

  useEffect(() => {
    const emptyKeywords = combination.keywords.length === 0;

    toggleTopKeywordsSwitch(emptyKeywords);

    if (onSwitch && index !== undefined) {
      onSwitch(index, emptyKeywords);
    }
  }, [combination.keywords.length, toggleTopKeywordsSwitch, onSwitch, index]);

  const getHandleOnPaste = useCallback(
    (combinationKeywords: string[]) => (e: ClipboardEvent<HTMLInputElement>) => {
      e.preventDefault();

      const singleCombination = getSingleCombination(e.clipboardData.getData('text'), combinationKeywords);

      if (singleCombination) {
        return setValue(singleCombination);
      }

      return onPaste && onPaste(e);
    },
    [setValue, onPaste],
  );

  return (
    <div className='flex flex-col gap-3 px-5'>
      <div className='flex justify-between'>
        <div className='flex items-center gap-2'>
          <Label>URL</Label>
          {onDelete && (
            <button onClick={onDelete}>
              <TrashIcon className='w-5 cursor-pointer' />
            </button>
          )}
        </div>
        <div className='flex items-center gap-2'>
          {/* <span>Local</span> */}
          {/* <Switch value={isLocaleSwitchToggleOn} onChange={handleLocaleSwitch} /> */}
          <span>Top Keywords</span>
          <Switch
            value={isTopKeywordsSwitchToggleOn}
            onChange={() => {
              onSwitch && index !== undefined && onSwitch(index, !isTopKeywordsSwitchToggleOn);
              toggleTopKeywordsSwitch();
            }}
            disabled={hasKeywords || (!isValidUrl && !isTopKeywordsSwitchToggleOn)}
          />
        </div>
      </div>

      <TextInputField
        onPaste={getHandleOnPaste(combinationKeywords)}
        name={`${field}.url`}
        placeholder='https://www.client.com/'
        className={cn(hasError && 'border-red-500')}
        disabled={disabled}
      />
      {hasError && <span className='text-left text-sm text-red-500'>The URL and keyword/s already exist</span>}
      {!isValidUrl && debouncedUrl.length !== 0 && <span className=' text-left text-sm text-red-500'>Invalid URL, use the format https://client.com/</span>}
      {/* 
      {isLocaleSwitchToggleOn && (
        <AutocompleteField
          optionsClassName='text-left'
          onSearch={handleLocationOnSearch}
          loading={isLoading || isFetching}
          options={formatLocations(data?.results)}
          name={`${field}.location_id`}
          placeholder='Select Local'
        />
      )} */}

      {isTopKeywordsSwitchToggleOn && (
        <Fragment>
          <div className='flex w-full justify-center gap-4 '>
            <Button
              onClick={() => togglePullTopKeywordsModal(true)}
              variant='secondary'
              className='flex w-full items-center justify-center gap-2 font-light'
              disabled={!isValidUrl}
            >
              <KeyConfigIcon className='size-5' />
              Select Keywords
            </Button>
            <Button
              disabled={!isValidUrl}
              onClick={() => setSelectedKeywordMethod('enter_keywords')}
              variant='secondary'
              className='flex w-full items-center justify-center gap-2 font-light'
            >
              <PencilIcon className='size-5' />
              Enter Keywords
            </Button>
          </div>
        </Fragment>
      )}

      <div className='flex w-full items-center gap-4'>
        {selectedKeywordMethod === 'enter_keywords' && !isTopKeywordsSwitchToggleOn && (
          <section className='w-full rounded-lg bg-slate-200 px-3 py-2 text-left'>
            <Textarea value={manuallyAddedUrl} onChange={handleKeywordsInputOnChange} onBlur={handleKeywordsInputOnBlur} />
          </section>
        )}
        {isPullTopKeywordsModalOpen && (
          <PullTopKeywordsModal field={field} isOpen={isPullTopKeywordsModalOpen} onClose={() => togglePullTopKeywordsModal(false)} onConfirm={handlePullTopKeywordsConfirm} />
        )}
        {selectedKeywordMethod === 'pull_top_keywords' && hasKeywords && (
          <section className='w-full rounded-lg bg-slate-200 px-3 py-2 text-left'>
            <button onClick={() => toggleTopKeywordsAccordion()}>
              <p className={'overflow-hidden text-left'}>{visibleKeywords}</p>
            </button>
          </section>
        )}
        {hasKeywords && !isTopKeywordsAccordionOpen && selectedKeywordMethod === 'pull_top_keywords' && (
          <button onClick={() => togglePullTopKeywordsModal(true)}>
            <PencilIcon className='w-5 cursor-pointer' />
          </button>
        )}

        {(isTopKeywordsAccordionOpen || selectedKeywordMethod === 'enter_keywords') && !isTopKeywordsSwitchToggleOn && (
          <button onClick={handleDeleteKeywords}>
            <TrashIcon className='w-5 cursor-pointer' />
          </button>
        )}
      </div>
      {showWarning && <p className='mb-2 text-center text-red-500'>Please Select or Enter Keywords</p>}
    </div>
  );
};

export default Combination;

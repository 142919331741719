import { Button, Input, Label } from '@/components';
import { useAttachPaymentToCustomerMutation } from '@/store/payment/payment.api';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { FC, FormEventHandler, useState } from 'react';

interface NewPaymentMethodFormProps {
  onConfirm: () => void;
  onClose: () => void;
}

const NewPaymentMethodForm: FC<NewPaymentMethodFormProps> = ({ onClose, onConfirm }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [attach] = useAttachPaymentToCustomerMutation();

  const [billingName, setBillingName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handlePayment: FormEventHandler = async (event) => {
    event.preventDefault();
    const cardDetail = elements?.getElement('cardNumber');

    if (!stripe || !cardDetail) {
      return;
    }

    try {
      const paymentMethod = await stripe.createPaymentMethod({
        type: 'card',
        card: cardDetail,
        billing_details: {
          name: billingName,
        },
      });

      if (paymentMethod.paymentMethod?.id) {
        await attach({ id: paymentMethod.paymentMethod?.id });
        onConfirm();
      }
    } catch (error) {
      setErrorMessage(`error adding payment method, try again later ${error}`);
    }
  };

  return (
    <form onSubmit={handlePayment} className='flex flex-col gap-4'>
      <div className='flex flex-col gap-2'>
        <Label>Card holder Name</Label>
        <Input value={billingName} onChange={(value) => setBillingName(value)} placeholder='Enter your name' />
      </div>
      <div className='flex flex-col gap-2'>
        <Label>Card Number</Label>
        <CardNumberElement
          className='w-full rounded-lg border border-gray-200 px-3 py-2 text-base shadow-sm outline-none hover:border-gray-300 focus:border-blue-500 focus:ring-2'
          options={{ showIcon: true, placeholder: 'Enter card number', iconStyle: 'solid' }}
        />
      </div>
      <div className='flex items-center gap-2'>
        <div className='flex w-full flex-col gap-2'>
          <Label>Exp. Date</Label>
          <CardExpiryElement className='w-full rounded-lg border border-gray-200 px-3 py-2 text-base shadow-sm outline-none hover:border-gray-300 focus:border-blue-500 focus:ring-2' />
        </div>

        <div className='flex w-full flex-col gap-2'>
          <Label>CVC</Label>
          <CardCvcElement className='w-full rounded-lg border border-gray-200 px-3 py-2 text-base shadow-sm outline-none hover:border-gray-300 focus:border-blue-500 focus:ring-2' />
        </div>
      </div>
      {errorMessage && <p className='py-2 text-center'>{errorMessage}</p>}
      <footer className='mt-6 flex w-full space-x-3'>
        <Button className='w-full' variant='outline-light' onClick={onClose}>
          cancel
        </Button>
        <Button className='w-full' type='submit'>
          Add Card
        </Button>
      </footer>
    </form>
  );
};

export default NewPaymentMethodForm;

const ShortVideosIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M2 8.66667V17C2 19.75 4.25 22 7 22H17C19.75 22 22 19.75 22 17V8.66667H2ZM17 7H22C22 4.25 19.75 2 17 2H14.5L17 7ZM11.1667 7H15.3333L12.8333 2H8.66667L11.1667 7ZM2 7H9.5L7 2C4.25 2 2 4.25 2 7ZM10.3333 17.8333C9.91667 17.8333 9.5 17.4167 9.5 17V12C9.5 11.6667 9.66667 11.4167 9.91667 11.25C10.1667 11.0833 10.5 11.0833 10.75 11.25L14.9167 13.75C15.1667 13.9167 15.3333 14.1667 15.3333 14.5C15.3333 14.8333 15.1667 15.0833 14.9167 15.25L10.75 17.75C10.6667 17.8333 10.5 17.8333 10.3333 17.8333Z'
      fill='#94A3B8'
    />
  </svg>
);

export default ShortVideosIcon;

import { Modal } from '@/components';
import { setTutorialComplete } from '@/utils/localstorage';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

const FULLPAGE_TOUR_URL = 'https://app.sharefable.com/live/demo/link-launch-create-a-n-k9br7irl7l7pe3a2';

// Empty history length is 2 because this route (/tour) is children of '/' and both get pushed to history
const EMPTY_HISTORY_LENGTH = 2;
const isNewTab = (historyLength: number) => historyLength === EMPTY_HISTORY_LENGTH;

const Tour = () => {
  const navigate = useNavigate();

  const historyLength = useMemo(() => window.history.length, []);

  useEffect(() => {
    if (isNewTab(historyLength)) {
      setTutorialComplete(true);
      return window.location.replace(FULLPAGE_TOUR_URL);
    }
  }, [historyLength]);

  const handleOnClose = useCallback(() => {
    setTutorialComplete(true);
    window.dispatchEvent(
      new StorageEvent('storage', {
        key: 'CONFIG',
        newValue: JSON.stringify({ tutorial: { done: true } }),
      }),
    );
    navigate('/');
  }, [navigate]);

  return (
    <Modal isOpen onClose={handleOnClose} className='relative h-[85dvh] w-[95dvw]'>
      <iframe
        src='https://app.sharefable.com/embed/demo/link-launch-create-a-n-k9br7irl7l7pe3a2/linklaunch-o2hyw3dt4cy9ziko/1723746810baead328b0931'
        className='h-full w-full'
        title='Tour'
      />
    </Modal>
  );
};
export default Tour;

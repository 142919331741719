import store from '@/store';
import { LoaderFunction, redirect } from 'react-router-dom';

const linkingContextReportLoader: LoaderFunction = () => {
  const authUser = store.getState().auth.user;

  if (!authUser?.config.show_linking_context_report && !authUser?.isSuperuser) {
    return redirect('/');
  }

  return null;
};

export default linkingContextReportLoader;

import { Button } from '@/components';
import { FC } from 'react';

interface ReadOnlyProps {
  onNext: () => void;
  onPrevious?: () => void;
  nextButtonText?: string;
  previousButtonText?: string;
  isNextButtonDisabled?: boolean;
  isPreviousButtonDisabled?: boolean;
}

const ReadOnlyFooter: FC<ReadOnlyProps> = ({ onNext, onPrevious, nextButtonText = 'Next', previousButtonText = 'Previous' }) => {
  return (
    <footer className='fixed bottom-0 right-0 z-20 flex h-footer w-full flex-row items-center justify-center gap-6 bg-white px-4'>
      <div className='flex w-full min-w-leap-screen max-w-leap-screen items-center justify-end'>
        <div className='flex flex-nowrap items-center gap-2'>
          <Button variant='outline-light' onClick={onPrevious} disabled={!onPrevious}>
            {previousButtonText}
          </Button>
          <Button onClick={onNext} disabled={!onNext}>
            {nextButtonText}
          </Button>
        </div>
      </div>
    </footer>
  );
};

export default ReadOnlyFooter;

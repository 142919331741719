import { SVGProps } from 'react';

const AnchorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='currentColor' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M18 14.5828L17.9192 11.5046H17.9194C17.9146 11.3455 17.8188 11.2006 17.6678 11.1242C17.5167 11.0477 17.333 11.0512 17.1855 11.1333L14.3421 12.7377C14.2445 12.7923 14.1708 12.8772 14.1342 12.9772C14.0976 13.0772 14.1004 13.186 14.1421 13.2844C14.184 13.3827 14.2619 13.4641 14.3623 13.5145L14.9127 13.7926C14.1037 14.907 12.8761 15.7042 11.4676 16.0295V7.61575C12.3285 7.2056 12.9436 6.45853 13.142 5.58217C13.3405 4.70578 13.1011 3.79348 12.4905 3.09844C11.8798 2.40355 10.963 2 9.9945 2C9.02599 2 8.10914 2.40352 7.49854 3.09844C6.88791 3.79345 6.64851 4.70578 6.84696 5.58217C7.0454 6.45856 7.66052 7.2056 8.52143 7.61575V16.0295C7.11888 15.7033 5.89633 14.9089 5.08942 13.7993L5.63978 13.5212H5.63965C5.7401 13.4709 5.81815 13.3894 5.85987 13.2911C5.90158 13.1928 5.9044 13.0841 5.8678 12.984C5.83121 12.884 5.75763 12.7991 5.65987 12.7444L2.82018 11.14C2.67264 11.0579 2.48903 11.0545 2.33789 11.1309C2.1869 11.2074 2.09105 11.3522 2.08632 11.5115L2.00021 14.5827C1.99688 14.6883 2.03412 14.7916 2.10526 14.8743C2.17628 14.957 2.27685 15.0137 2.38881 15.0346C2.50091 15.0554 2.61722 15.0389 2.71741 14.9881L3.37053 14.6591V14.659C4.32741 16.0312 5.75465 17.0669 7.42362 17.6C9.09271 18.1333 10.9071 18.1333 12.5764 17.6C14.2455 17.0669 15.6727 16.0312 16.6295 14.659L17.2826 14.988V14.9881C17.3828 15.0389 17.4991 15.0554 17.6112 15.0346C17.7232 15.0137 17.8237 14.957 17.8947 14.8743C17.9659 14.7916 18.0031 14.6883 17.9998 14.5827L18 14.5828ZM10.0002 6.03332C9.6966 6.03332 9.4055 5.92188 9.19077 5.72338C8.97619 5.52499 8.85553 5.25575 8.85553 4.97513C8.85553 4.6944 8.9762 4.42528 9.19077 4.22676C9.40549 4.02837 9.69658 3.91682 10.0002 3.91682C10.3039 3.91682 10.595 4.02838 10.8097 4.22676C11.0243 4.42527 11.145 4.69439 11.145 4.97513C11.145 5.25574 11.0243 5.52498 10.8097 5.72338C10.595 5.92189 10.3039 6.03332 10.0002 6.03332Z'
      fill='currentColor'
    />
  </svg>
);

export default AnchorIcon;

import { Modal, ModalProps, SuccessModal, TextInputField } from '@/components';
import { useCreateCompanyMutation } from '@/store/company/company.api';
import { uppercaseFirstChar } from '@/utils/text';
import { Form, FormikProvider, useFormik } from 'formik';
import { FC, Fragment, useMemo } from 'react';

import * as yup from 'yup';

interface ErrorResponse {
  data: ErrorResponseData;
}

interface ErrorResponseData {
  name: Array<string>;
}

const newCompanyValidationSchema: yup.SchemaOf<NewCompanyValues> = yup.object({
  name: yup.string().required(),
});

const getErrorMessage = (error: ErrorResponse) => {
  return Object.keys(error.data)
    .map((i) => error.data[i as keyof ErrorResponseData].map(uppercaseFirstChar).join(' '))
    .join(' | ');
};

const NewCompanyModal: FC<ModalProps> = ({ isOpen, onClose }) => {
  const [addCompany, { isLoading, isSuccess, error }] = useCreateCompanyMutation();

  const errorMessage = useMemo(() => {
    if (!error) return '';

    return getErrorMessage(error as ErrorResponse);
  }, [error]);

  const formik = useFormik<NewCompanyValues>({
    onSubmit: async (values) => await addCompany({ name: values.name }),
    initialValues: { name: '' },
    validationSchema: newCompanyValidationSchema,
  });

  const handleClose = () => onClose && onClose();

  return (
    <Fragment>
      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        title='New Company'
        className='min-w-modal'
        confirmButtonText='Register'
        onConfirm={formik.handleSubmit}
        errorMessage={errorMessage}
        isLoading={isLoading}
      >
        <FormikProvider value={formik}>
          <Form>
            <div className='flex w-full flex-col items-end gap-y-5 text-left'>
              <TextInputField name='name' label='Name' placeholder='Enter company name' />
            </div>
          </Form>
        </FormikProvider>
      </Modal>
      {isSuccess && <SuccessModal isOpen={isSuccess} message='New Company was successfully created' onConfirm={handleClose} />}
    </Fragment>
  );
};

export default NewCompanyModal;

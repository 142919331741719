import { useEffect } from 'react';
import AccessRequestsPageSkeleton from './AccessRequestsPageSkeleton';
import { ErrorFallback } from '@/components';
import { useBetaAccessRequestsQuery } from '@/store/users/users.api';
import AccessRequestsTable from './AccessRequestsTable';
import { useSelector } from 'react-redux';
import { selectAuthUser } from '@/store/auth/auth.selector';
import { useNavigate } from 'react-router-dom';
import { useLastMessageFromWebSocket } from '@/hooks/websocket';

const AccessRequestsPage = () => {
  const { data, isLoading, isError, refetch } = useBetaAccessRequestsQuery();
  const { lastMessage } = useLastMessageFromWebSocket();
  const user = useSelector(selectAuthUser);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user?.isSuperuser) {
      navigate('/');
    }
  }, [user, navigate]);

  useEffect(() => {
    if (lastMessage) {
      const { action } = JSON.parse(lastMessage.data);

      if (action === 'new_access_request') {
        refetch();
      }
    }
  }, [lastMessage, refetch]);

  if (isLoading) {
    return <AccessRequestsPageSkeleton />;
  }

  if (isError) {
    return <ErrorFallback />;
  }

  if (!data) {
    return null;
  }

  return (
    <div className='h-full'>
      <AccessRequestsTable access_requests={data.results} />
    </div>
  );
};

export default AccessRequestsPage;

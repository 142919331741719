import { createSlice } from '@reduxjs/toolkit';

type CompanyState = {
  company: Company | null;
};

const initialState: CompanyState = {
  company: null,
};

const companySlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    setCompany(state, action) {
      state.company = action.payload;
    },
  },
});

export default companySlice;

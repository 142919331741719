import { SVGProps } from 'react';

const KeyConfigIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox='0 0 25 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.374 19C9.92999 20.724 8.363 22 6.5 22C4.293 22 2.5 20.208 2.5 18C2.5 15.792 4.293 14 6.5 14C8.363 14 9.92999 15.275 10.374 17H21.5C21.766 17 22.02 17.105 22.207 17.293C22.395 17.48 22.5 17.735 22.5 18C22.5 18.552 22.053 19 21.5 19H20.5V21C20.5 21.552 20.052 22 19.5 22C18.948 22 18.5 21.552 18.5 21V19H16.5V21C16.5 21.552 16.052 22 15.5 22C14.948 22 14.5 21.552 14.5 21V19H10.374ZM6.5 16C7.604 16 8.5 16.896 8.5 18C8.5 19.104 7.604 20 6.5 20C5.397 20 4.5 19.104 4.5 18C4.5 16.896 5.397 16 6.5 16ZM22.5 14V4C22.5 3.448 22.052 3 21.5 3C20.948 3 20.5 3.448 20.5 4V14C20.5 14.552 20.948 15 21.5 15C22.052 15 22.5 14.552 22.5 14ZM14.5 14V3C14.5 2.448 14.052 2 13.5 2C12.948 2 12.5 2.448 12.5 3V14C12.5 14.552 12.948 15 13.5 15C14.052 15 14.5 14.552 14.5 14ZM18.5 14V6.5C18.5 5.948 18.052 5.5 17.5 5.5C16.948 5.5 16.5 5.948 16.5 6.5V14C16.5 14.552 16.948 15 17.5 15C18.052 15 18.5 14.552 18.5 14ZM10.5 12V8C10.5 7.448 10.052 7 9.5 7C8.948 7 8.5 7.448 8.5 8V12C8.5 12.552 8.948 13 9.5 13C10.052 13 10.5 12.552 10.5 12Z'
      fill='#currentColor'
    />
  </svg>
);

export default KeyConfigIcon;

import { ModalProps, TokenIcon } from '@/components';
import { useGetLinkLaunchProductPricesQuery, usePayInvoiceMutation } from '@/store/payment/payment.api';
import { getCurrencyFormat } from '@/utils/numbers';
import { FC, Fragment, useCallback, useMemo } from 'react';
import PaymentMethodModal from '../PaymentMethodModal';

interface PurchaseCreditsModal extends ModalProps {
  invoiceId: string;
  quantity: number;
}

const PurchaseCreditsModal: FC<PurchaseCreditsModal> = ({ isOpen, onClose, invoiceId, quantity }) => {
  const { data: prices } = useGetLinkLaunchProductPricesQuery();
  const [payInvoice, { isLoading: payingInvoice }] = usePayInvoiceMutation();

  const oneTimePrice = useMemo(() => prices?.results.find((price) => (price.lookup_key as string) === 'one-time'), [prices]);

  const handlePayInvoice = useCallback(
    async (methodId: string) => {
      if (!invoiceId) {
        return;
      }

      await payInvoice({ invoice_id: invoiceId, payment_method_id: methodId });
    },
    [invoiceId, payInvoice],
  );

  const handleSubmitPaymentMethod = useCallback(
    async (methodId: string) => {
      await handlePayInvoice(methodId);
      onClose && onClose();
    },
    [handlePayInvoice, onClose],
  );

  return (
    <Fragment>
      <PaymentMethodModal isOpen={isOpen} onClose={onClose} onMethodSelected={handleSubmitPaymentMethod} confirmButtonText='Buy' isLoading={payingInvoice}>
        <section className='mb-3'>
          <div className='mb-5 rounded-xl bg-slate-100 p-3'>
            <div className='flex items-center gap-2 '>
              <TokenIcon className='w-5' />
              <p className='text-xl font-semibold  capitalize'>{oneTimePrice?.nickname}</p>
            </div>

            <div className='my-2 h-0.5 w-full rounded-xl bg-white'></div>

            <div className='flex items-center justify-between'>
              <div className='flex items-center gap-2'>
                <TokenIcon className='w-5' />
                <p>{quantity}</p>
              </div>
              <p className='font-semibold'>{getCurrencyFormat(oneTimePrice?.unit_amount || 0)}</p>
            </div>
          </div>

          <p className='text-xl'>Select Payment Method</p>
        </section>
      </PaymentMethodModal>
    </Fragment>
  );
};

export default PurchaseCreditsModal;

import { SelectFilter, SearchBarDebounce, Button, ErrorMessage } from '@/components';
import { useFilterContext } from '@/hooks/filters';
import { useLazyGetCurrentUserQuery } from '@/store/users/users.api';
import { trackEvent } from '@/utils/fullstory';
import { getUserHasCredits } from '@/utils/users';
import { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

type CampaignStatusOptions = AnalysisStep | 'all' | 'kicked_off';
type CampaignSortOptions = 'created_at' | '-created_at' | 'last_edited_campaigns';
const sorts: Array<OptionType<CampaignSortOptions>> = [
  {
    label: 'Oldest',
    value: 'created_at',
  },
  {
    label: 'Most Recent',
    value: '-created_at',
  },
  {
    label: 'Last Edited',
    value: 'last_edited_campaigns',
  },
];

const statuses: Array<OptionType<CampaignStatusOptions>> = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Link Gap Analysis',
    value: 'analysis',
  },
  {
    label: 'Proposal Review',
    value: 'scenario-review',
  },
  {
    label: 'Anchor Text Generator',
    value: 'anchor-text-generator',
  },
  {
    label: 'Final Summary',
    value: 'summary',
  },
  {
    label: 'Kicked Off',
    value: 'kicked_off',
  },
];

interface CampaignsCardsFiltersProps {
  pageSetter: (page: number) => void;
  isFetching?: boolean;
}

const CampaignsCardsFilters: FC<CampaignsCardsFiltersProps> = ({ pageSetter, isFetching }) => {
  const { filters, updateFilters, removeFilter } = useFilterContext();
  const sortFilterValue = useMemo(() => [filters.sort].flat(), [filters.sort]);
  const navigate = useNavigate();

  const handleSearchChange = useCallback(
    (value: string) => {
      if (value.trim() === '') {
        removeFilter('name__icontains');
        return;
      }
      pageSetter(1);
      updateFilters({ name__icontains: value.trim(), page: 1 });
    },
    [updateFilters, removeFilter, pageSetter],
  );

  const handleStatusChange = useCallback(
    (value: CampaignStatusOptions) => {
      pageSetter(1);
      updateFilters({
        status: value === 'all' ? undefined : value,
        page: 1,
        page_size: 5,
      });
    },
    [updateFilters, pageSetter],
  );

  const handleSortChange = useCallback(
    (value: CampaignSortOptions) => {
      pageSetter(1);
      updateFilters({
        page: 1,
        page_size: 5,
        sort: [value],
      });
    },
    [updateFilters, pageSetter],
  );

  const [hasCredits, setHasCredits] = useState(true);
  const [fetchUser, { isLoading }] = useLazyGetCurrentUserQuery();
  const handleNewCampaign = useCallback(async () => {
    trackEvent({ name: 'New Campaign Button Click', properties: {} });

    const user = await fetchUser().unwrap();

    if (getUserHasCredits(user)) {
      navigate('new-campaign');
      return;
    }

    setHasCredits(false);
  }, [navigate, fetchUser]);

  return (
    <div className='flex w-full flex-row flex-nowrap items-center justify-between px-2 pb-6'>
      <SearchBarDebounce onChange={handleSearchChange} delay={500} isFetching={isFetching} inputClassName='w-[25.75rem] h-10' />
      <div className='flex flex-row flex-nowrap items-center gap-4'>
        <SelectFilter
          label='Campaign Status'
          options={statuses}
          value={filters.status || 'all'}
          onChange={handleStatusChange}
          className='flex-nowrap'
          optionsClassName='bg-white'
        />
        <SelectFilter label='Sort By' options={sorts} value={(sortFilterValue[0] || '-created_at') as CampaignSortOptions} onChange={handleSortChange} className='flex-nowrap' />
        <Button onClick={handleNewCampaign} isLoading={isLoading}>
          New Campaign
        </Button>
        {!hasCredits && <ErrorMessage>You have no credits left. Please contact your account manager to purchase more credits.</ErrorMessage>}
      </div>
    </div>
  );
};

export default CampaignsCardsFilters;

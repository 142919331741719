import { StarIcon } from '@heroicons/react/24/solid';
import { FC } from 'react';

interface PriorityLegendProps {
  message?: string;
  messageClass?: string;
}

const PriorityLegend: FC<PriorityLegendProps> = ({ message, messageClass }) => (
  <div className='mb-2 flex w-full flex-row items-center gap-4 rounded-b-lg bg-sky-100 px-4 py-2'>
    <p className='text-sm'>Keyword Priority:</p>
    <div className='flex flex-row flex-nowrap items-center gap-2'>
      <div className='flex w-fit flex-row items-center justify-center rounded-full border-4 border-sky-500 bg-sky-500 p-[2px]'>
        <StarIcon className='w-4 text-white' />
      </div>
      <p className='text-sm'>Priority</p>
    </div>
    <div className='flex flex-row flex-nowrap items-center gap-2'>
      <div className='flex w-fit flex-row items-center justify-center rounded-full border-4 border-amber-400 bg-amber-400 p-[2px]'>
        <StarIcon className='w-4 text-white' />
      </div>
      <p className='text-sm'>High Priority</p>
    </div>
    {message && <p className={messageClass}>{message}</p>}
  </div>
);

export default PriorityLegend;

import { Button, Loading, VirtualizedTable } from '@/components';
import { useTable } from '@/hooks/table';
import { useDeleteReportMutation, useGetReportsQuery } from '@/store/reports/reports.api';
import useReportsTableColumns from './ReportsTableColumns';
import DeleteReportModal from '../DeleteReportModal';
import { useToggle } from '@/hooks/toggle';
import { useCallback, useState } from 'react';

const ReportsTable = () => {
  const columns = useReportsTableColumns();
  const { data: reports, isLoading: reportsLoading } = useGetReportsQuery();
  const [deleteReport, { isLoading: reportsDeleting }] = useDeleteReportMutation();
  const { value: isDeleteModalOpen, toggle: toggleDeleteModal } = useToggle();
  const [currentReport, setCurrentReport] = useState<LIRReport | null>(null);

  const handleOnConfirm = useCallback(async () => {
    if (!currentReport) return;

    await deleteReport(currentReport.id);
    toggleDeleteModal(false);
  }, [currentReport, deleteReport, toggleDeleteModal]);

  const [table] = useTable({
    columns,
    data: reports?.results || [],
    tableOptions: {
      meta: {
        setCurrentReport,
        toggleDeleteModal,
      },
    },
  });

  return (
    <>
      <VirtualizedTable
        isLoading={reportsLoading}
        loadingText={'Loading LIR Reports...'}
        containerClass='max-h-[100%] w-full rounded-leap bg-white p-4'
        table={table}
        name='reportsTable'
      />
      <DeleteReportModal
        isOpen={isDeleteModalOpen}
        footer={
          <div className={'mt-4 flex w-full items-center gap-6'}>
            <Button variant='outline-light' onClick={() => toggleDeleteModal(false)} disabled={reportsDeleting} className='w-full'>
              Cancel
            </Button>
            <Button onClick={handleOnConfirm} disabled={reportsDeleting} className='w-full whitespace-nowrap'>
              {reportsDeleting ? <Loading className='mx-auto h-5 w-5' /> : 'Confirm'}
            </Button>
          </div>
        }
        reportName={currentReport?.name || ''}
      />
    </>
  );
};

export default ReportsTable;

import { SVGProps } from 'react';

const DuplicateIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='24' height='19' viewBox='0 0 24 19' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M16 5.5H14V8.5H11V10.5H14V13.5H16V10.5H19V8.5H16V5.5ZM2 9.5C2 6.71 3.64 4.3 6.01 3.18V1.02C2.52 2.26 0 5.59 0 9.5C0 13.41 2.52 16.74 6.01 17.98V15.82C3.64 14.7 2 12.29 2 9.5ZM15 0.5C10.04 0.5 6 4.54 6 9.5C6 14.46 10.04 18.5 15 18.5C19.96 18.5 24 14.46 24 9.5C24 4.54 19.96 0.5 15 0.5ZM15 16.5C11.14 16.5 8 13.36 8 9.5C8 5.64 11.14 2.5 15 2.5C18.86 2.5 22 5.64 22 9.5C22 13.36 18.86 16.5 15 16.5Z'
      fill='#64748B'
    />
  </svg>
);

export default DuplicateIcon;

import { useSteps } from '@/hooks/steps';
import cn from '@/utils/style';
import { CheckIcon } from '@heroicons/react/24/solid';
import { FC } from 'react';

const AnalysisSteps: FC<{ onClick: (data: AnalysisStepObject) => void | Promise<void> }> = ({ onClick }) => {
  const { analysisSteps } = useSteps();

  const renderStep = (data: AnalysisStepObject) => {
    const { isDisabled, number, label, isDone, canNavigate, isActive } = data;

    return (
      <div key={`${number}`} className='flex items-center gap-3 '>
        {number > 1 && <div className='h-px w-9 bg-slate-300'></div>}
        <div className={cn('flex h-6 w-6 items-center justify-center rounded-full bg-gray-400  text-white', canNavigate && 'bg-sky-500', isDisabled && 'bg-gray-400')}>
          {isDone ? <CheckIcon className='w-4' stroke='#ffffff' strokeWidth={2} /> : number}
        </div>
        <button
          className={cn('text-xs text-sky-500', canNavigate || (isDisabled && 'cursor-pointer'), isActive && 'font-bold')}
          onClick={() => onClick(data)}
          disabled={!canNavigate || isDisabled}
        >
          {label}
        </button>
      </div>
    );
  };

  return <div className='flex flex-row flex-nowrap items-center gap-3 whitespace-nowrap'>{analysisSteps.map(renderStep)}</div>;
};

export default AnalysisSteps;

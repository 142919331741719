import { Menu as HeadlessMenu, Transition } from '@headlessui/react';
import { selectTablesConfig } from '@/store/app/app.selector';
import { setTableConfig } from '@/store/app/app.slice';
import { AppDispatch } from '@/store/store';
import { PlayIcon } from '@heroicons/react/20/solid';
import { Table } from '@tanstack/react-table';
import { Fragment, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Switch from '../Switch';

interface TableVisibilityMenuProps<T> {
  table: Table<T>;
  name: Tables;
  labels: Record<string, string>;
}
const TableVisibilityMenu = <T extends unknown>({ table, name, labels }: TableVisibilityMenuProps<T>) => {
  const dispatch = useDispatch<AppDispatch>();
  const tableConfig = useSelector(selectTablesConfig);

  const handleColumnVisibilityChange = useCallback(
    (id: string) => {
      const currentVisibilityState = tableConfig[name].visibility;
      const columnVisibility = id in currentVisibilityState ? !currentVisibilityState[id] : false;
      const visibility = { ...currentVisibilityState, [id]: columnVisibility };

      dispatch(setTableConfig({ name, config: { visibility } }));
    },
    [dispatch, name, tableConfig],
  );

  const menuItems = useMemo(() => {
    const headers = table.getAllColumns();
    const options = headers.map((header) => ({
      id: header.id,
      label: labels[header.id],
      onClick: () => handleColumnVisibilityChange(header.id),
    }));

    return options;
  }, [handleColumnVisibilityChange, table, labels]);

  const renderItems = ({ label, onClick, id }: { label: string; onClick: () => void; id: string }) => {
    // Here i check for undefined because the redux store initializes as empty object, so i want to set the default value to true
    const switchValue = tableConfig[name].visibility[id] === undefined ? true : tableConfig[name].visibility[id];
    return (
      <div className={'flex w-full cursor-pointer justify-between gap-4 whitespace-nowrap px-4 py-2 text-left hover:bg-gray-100'} key={`${name}-${id}`} id={`${name}-${id}`}>
        <span className=' text-sm font-semibold'>{label}</span>
        <Switch onChange={onClick} value={switchValue} />
      </div>
    );
  };

  return (
    <>
      <HeadlessMenu as='div' className={'relative'}>
        <HeadlessMenu.Button>
          {
            <div className='flex items-center gap-2'>
              <span className='text-sm font-semibold text-gray-400'>Filter Columns</span>
              <PlayIcon className={'ml-1 w-3 rotate-90 text-gray-400 group-data-[headlessui-state=open]:rotate-[270deg]'} />
            </div>
          }
        </HeadlessMenu.Button>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <HeadlessMenu.Items
            className={
              'absolute right-0 z-20 mt-2 max-h-64 origin-top-right divide-y divide-gray-100 overflow-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
            }
          >
            {menuItems.map(renderItems)}
          </HeadlessMenu.Items>
        </Transition>
      </HeadlessMenu>
    </>
  );
};

export default TableVisibilityMenu;

import cn from '@/utils/style';
import { FC, PropsWithChildren } from 'react';

interface LabelProps extends PropsWithChildren {
  className?: string;
}

const Label: FC<LabelProps> = ({ children, className = '' }) => {
  return <label className={cn('ml-1 font-bold', className)}>{children}</label>;
};

export default Label;

const round = (num: number, decimals: number) => +num.toFixed(decimals);

const getNumericValue = (val: string) => {
  const numericVal = Number(val);

  if (Number.isNaN(numericVal)) {
    return undefined;
  }

  return numericVal > 100 ? 100 : numericVal < 0 ? 0 : numericVal;
};

const isNotNumeric = (val: number) => isNaN(val) && !val;

const cutPercentage = (val: number) => {
  return Math.round(val > 100 ? 100 : val < 0 ? 0 : val);
};

const getCurrencyFormat = (value: number) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 }).format(value);
};

const getRandomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min) + min);
};

const countDecimals = (value: number) => {
  if (Math.floor(value) !== value) {
    return value.toString().split('.')[1].length || 0;
  }

  return 0;
};

export { round, getNumericValue, getCurrencyFormat, getRandomNumber, countDecimals, cutPercentage, isNotNumeric };

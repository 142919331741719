import Bugsnag from '@bugsnag/browser';
import { AnyAction, configureStore, isRejected, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { UnknownAsyncThunkRejectedAction } from '@reduxjs/toolkit/dist/matchers';
import api from './api';
import apiPOC from './poc/poc.api';
import { logout } from './auth/auth.slice';
import reducers from './reducers';

const addBugsnagExtra = (extra: Record<string, unknown>) => {
  Object.entries(extra).forEach(([key, value]) => {
    if (value && typeof value === 'object') {
      addBugsnagExtra(value as Record<string, unknown>);
    } else {
      Bugsnag.addMetadata(key, value as Record<string, unknown>);
    }
  });
};

const errorHandlerMiddleware: Middleware = (_api: MiddlewareAPI) => (next) => (action) => {
  try {
    if (isRejected(action)) {
      const rejectedAction = action as UnknownAsyncThunkRejectedAction;
      const actionError = action.error;

      // Ignore these errors, I can't disabled them.
      if (action.error.message.includes('condition callback returning false.')) {
        return next(action);
      }

      if (rejectedAction.meta) {
        addBugsnagExtra(rejectedAction.meta);
      }

      if (rejectedAction.payload) {
        Bugsnag.addMetadata('payload', { payload: rejectedAction.payload });
      }

      Bugsnag.addMetadata('actionType', { actionType: rejectedAction.type });

      const error = new Error(actionError.message);
      error.stack = actionError.stack;
      error.name = action.meta.arg?.endpointName ? `${actionError.name}-RequestError-${action.meta.arg.endpointName}` : actionError.name;

      Bugsnag.notify(error);
    }
  } catch (e) {
    Bugsnag.notify(e as Error);
  }

  return next(action);
};

const authTokenMiddleware: Middleware =
  ({ dispatch }: MiddlewareAPI) =>
  (next) =>
  (action) => {
    if (isRejected(action) && action.payload?.status === 401 && action.meta.arg.endpointName !== 'login') {
      return dispatch(logout() as unknown as AnyAction);
    }

    return next(action);
  };

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(authTokenMiddleware, api.middleware, apiPOC.middleware, errorHandlerMiddleware),
});

type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;
type AppStore = typeof store;

export default store;
export type { RootState, AppDispatch, AppStore };

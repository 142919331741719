const QuizIcon = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M15 18H2V5C2 4.45 1.55 4 1 4C0.45 4 0 4.45 0 5V18C0 19.1 0.9 20 2 20H15C15.55 20 16 19.55 16 19C16 18.45 15.55 18 15 18Z' fill='#94A3B8' />
    <path
      d='M18 0H6C4.9 0 4 0.9 4 2V14C4 15.1 4.9 16 6 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM12.01 13C11.42 13 10.96 12.53 10.96 11.95C10.96 11.36 11.43 10.91 12.01 10.91C12.6 10.91 13.05 11.36 13.05 11.95C13.04 12.53 12.6 13 12.01 13ZM14.51 6.83C13.88 7.76 13.28 8.04 12.95 8.64C12.87 8.78 12.82 8.9 12.79 9.13C12.74 9.52 12.43 9.81 12.04 9.81H12.01C11.57 9.81 11.22 9.43 11.26 8.99C11.29 8.71 11.35 8.42 11.51 8.15C11.92 7.42 12.69 6.99 13.14 6.35C13.62 5.67 13.35 4.41 12 4.41C11.39 4.41 10.99 4.73 10.74 5.11C10.55 5.4 10.17 5.5 9.85 5.36C9.43 5.18 9.25 4.66 9.51 4.29C10.02 3.55 10.87 3 11.99 3C13.22 3 14.07 3.56 14.5 4.26C14.87 4.87 15.08 5.99 14.51 6.83Z'
      fill='#94A3B8'
    />
  </svg>
);

export default QuizIcon;

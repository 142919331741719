const MobileFriendlyIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M18.7208 1H8.72082C7.62082 1 6.72082 1.9 6.72082 3V5C6.72082 5.55 7.17082 6 7.72082 6C8.27082 6 8.72082 5.55 8.72082 5V4H18.7208V20H8.72082V19C8.72082 18.45 8.27082 18 7.72082 18C7.17082 18 6.72082 18.45 6.72082 19V21C6.72082 22.1 7.62082 23 8.72082 23H18.7208C19.8208 23 20.7208 22.1 20.7208 21V3C20.7208 1.9 19.8208 1 18.7208 1ZM6.73082 13.47L4.81082 11.55C4.46082 11.2 3.89082 11.2 3.54082 11.55C3.19082 11.9 3.19082 12.47 3.54082 12.82L6.01082 15.29C6.40082 15.68 7.03082 15.68 7.42082 15.29L13.2708 9.44C13.6208 9.09 13.6208 8.52 13.2708 8.17C12.9208 7.82 12.3508 7.82 12.0008 8.17L6.73082 13.47Z'
      fill='#94A3B8'
    />
  </svg>
);

export default MobileFriendlyIcon;

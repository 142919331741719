import { FiltersProvider } from '@/components';
import { useSteps } from '@/hooks/steps';
import { Page } from '@/layouts';
import store from '@/store';
import campaignAPI from '@/store/campaign/campaign.api';
import { selectScenarios, selectSteps } from '@/store/campaign/campaign.selector';
import { setIsDirty } from '@/store/campaign/campaign.slice';
import companyAPI from '@/store/company/company.api';
import { FC, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ActionFunction, Await, LoaderFunction, Outlet, SubmitOptions, defer, redirect, useLoaderData, useNavigation, useParams, useSubmit } from 'react-router-dom';
import { AnalysisTopbar } from '../../components';
import AnalysisFooter from '../../components/AnalysisFooter';
import AnalysisPageSkeleton from './AnalysisPageSkeleton';
import { OverviewTable, RefineCampaign, Scenarios } from './components';
import { areScenariosValid } from '@/utils/scenarios';
import { updateCampaignScenarioMutation } from '@/store/reducers';

type AnalysisPageData = [Company, CampaignJSON, Scenario, AnalysisData];
type AnalysisActionData = {
  scenarios: string;
  nextStep: string;
  isDraft: string;
};

export const analysisPageFetchInitiate = ({ companyId, campaignId }: { companyId: string; campaignId: string }) => {
  const companyPromise = store.dispatch(companyAPI.endpoints.getCompanyById.initiate({ companyId }));
  const campaignPromise = store.dispatch(campaignAPI.endpoints.getCampaign.initiate({ campaignId }));
  const scenariosPromise = store.dispatch(campaignAPI.endpoints.getCampaignScenarios.initiate({ campaignId }));
  const campaignAnalysisPromise = store.dispatch(
    campaignAPI.endpoints.getCampaignAnalysis.initiate(
      {
        campaignId,
        group_by: 'client_url',
        filter_excluded_domains: true,
        filter_excluded_keywords: true,
        sort_urls: 'asc',
        show_hidden: true,
      },
      { forceRefetch: true },
    ),
  );

  return [companyPromise, campaignPromise, scenariosPromise, campaignAnalysisPromise];
};

const analysisPageLoader: LoaderFunction = async ({ params }) => {
  const { campaignId } = params as AnalyisisPageParams;
  const authUser = store.getState().auth.user;
  if (!authUser?.company) {
    return null;
  }

  const [companyPromise, campaignPromise, scenariosPromise, campaignAnalysisPromise] = analysisPageFetchInitiate({
    companyId: authUser.company.id,
    campaignId,
  });

  try {
    const data = Promise.all([companyPromise.unwrap(), campaignPromise.unwrap(), scenariosPromise.unwrap(), campaignAnalysisPromise.unwrap(), companyPromise.unwrap()]);

    return defer({ data });
  } catch (e) {
    return {
      campaignId,
      error: e as APIError,
    };
  } finally {
    campaignPromise.unsubscribe();
    companyPromise.unsubscribe();
    scenariosPromise.unsubscribe();
    campaignAnalysisPromise.unsubscribe();
  }
};

const analysisPageAction: ActionFunction = async ({ params, request }) => {
  const { campaignId } = params as AnalyisisPageParams;
  const dt = await request.formData();
  const { scenarios, isDraft, nextStep } = Object.fromEntries(dt) as AnalysisActionData;

  try {
    const scenariosToUpdate = (JSON.parse(scenarios) as Scenario[]).map(async (scenario) => {
      return await store.dispatch(updateCampaignScenarioMutation({ campaignId, scenario }));
    });
    const updatedScenarios = await Promise.all(scenariosToUpdate);
    store.dispatch(setIsDirty({ step: 'analysis', isDirty: false }));

    if (!updatedScenarios) {
      throw { data: { message: 'Failed to update scenarios' } };
    }

    if (isDraft === 'true') {
      return redirect(`/campaigns/${campaignId}/analysis`);
    }

    await store.dispatch(campaignAPI.endpoints.saveCampaignConfig.initiate({ campaignId, config: { user_progress: { last_step: 'scenario-review' } } }));
    return redirect(`/campaigns/${campaignId}/${nextStep}`);
  } catch (e) {
    return {
      campaignId,
      error: e as APIError,
    };
  }
};

const AnalysisPage: FC = () => {
  const { campaignId } = useParams() as { campaignId: string };
  const { data, error } = useLoaderData() as PageLoaderDefData<AnalysisPageData>;
  const { nextStep } = useSteps();
  const navigation = useNavigation();
  const submit = useSubmit();

  const scenarios = useSelector(selectScenarios);
  const isDirty = useSelector(selectSteps).analysis.isDirty;

  const [scenariosError, setScenariosError] = useState<APIError>();
  const submitAction: SubmitOptions = useMemo(() => ({ method: 'post', action: `/campaigns/${campaignId}/analysis` }), [campaignId]);
  const isSubmitting = useMemo(() => ['submitting', 'loading'].includes(navigation.state), [navigation.state]);
  const errorMessage = `something went wrong, ${error?.status} - ${error?.data.message}`;

  const isScenarioReviewDisabled = useMemo(() => !areScenariosValid(scenarios), [scenarios]);

  useEffect(() => {
    if (isScenarioReviewDisabled) {
      return setScenariosError({ data: { message: 'All scenarios must have at least one URL, and at least one link to build' } } as APIError);
    }
    setScenariosError(undefined);
  }, [isScenarioReviewDisabled]);

  const handleSaveDraft = useCallback(async () => {
    submit(
      {
        scenarios: JSON.stringify(scenarios),
        isDraft: 'true',
        nextStep,
      },
      submitAction,
    );
  }, [submit, scenarios, nextStep, submitAction]);

  const handleNext = useCallback(async () => {
    submit(
      {
        scenarios: JSON.stringify(scenarios),
        isDraft: 'false',
        nextStep,
      },
      submitAction,
    );
  }, [submit, scenarios, nextStep, submitAction]);

  if (isSubmitting) {
    return <AnalysisPageSkeleton />;
  }

  return (
    <Suspense fallback={<AnalysisPageSkeleton />}>
      <Await resolve={data} errorElement={error && <p>{errorMessage}</p>}>
        <Page>
          <AnalysisTopbar onSave={handleSaveDraft} isDirty={isDirty} />
          <Page.Body>
            <RefineCampaign />
            <Scenarios />
            <FiltersProvider>
              <OverviewTable />
            </FiltersProvider>
          </Page.Body>
          <Page.Footer>
            <AnalysisFooter saveDisabled={isScenarioReviewDisabled} nextDisabled={isScenarioReviewDisabled} onSave={handleSaveDraft} error={scenariosError} onNext={handleNext} />
          </Page.Footer>
          <Outlet />
        </Page>
      </Await>
    </Suspense>
  );
};

export default AnalysisPage;
export { analysisPageAction, analysisPageLoader };

import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { FC } from 'react';
import Button from '../Button';
import Modal from '../Modal';

interface SuccessModalProps {
  isOpen: boolean;
  message: string;
  onConfirm: () => void;
}

const SuccessModal: FC<SuccessModalProps> = ({ isOpen, message, onConfirm }) => {
  return (
    <Modal isOpen={isOpen} onClose={onConfirm}>
      <div>
        <div className='flex flex-col items-center justify-center gap-y-2'>
          <div className='flex items-center gap-2'>
            <CheckCircleIcon className='h-8 w-8 text-green-500' />
            <p className='text-2xl'>Success!</p>
          </div>
          <p className='text-xl'>{message}</p>
        </div>
        <div className='mt-10 text-center'>
          <Button onClick={onConfirm}>OK</Button>
        </div>
      </div>
    </Modal>
  );
};

export default SuccessModal;

import { SVGProps } from 'react';

const AlertIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width='28' height='28' viewBox='0 0 28 28' fill='currentColor' xmlns='http://www.w3.org/2000/svg' {...props}>
      <rect width='28' height='28' rx='14' fill='#0284C7' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.6873 19.6774L13.7749 21.7651C14.026 22.0161 14.026 22.4255 13.7749 22.6766L12.9136 23.5379C12.6626 23.7889 12.2531 23.7889 12.0021 23.5379L9.42452 20.9603L11.4071 19.8156C11.5003 19.7688 11.5937 19.7228 11.6873 19.6774ZM24.2139 11.7665C24.4843 11.8545 24.6322 12.145 24.5443 12.4154C24.4564 12.6858 24.1659 12.8337 23.8955 12.7458L22.0205 12.1366C21.7501 12.0487 21.6021 11.7582 21.69 11.4878C21.7779 11.2174 22.0685 11.0694 22.3389 11.1574L24.2139 11.7665ZM22.0904 9.00847C21.8438 9.15087 21.5284 9.06635 21.3861 8.81971C21.2437 8.57311 21.3282 8.25772 21.5748 8.11536L23.2684 7.13752C23.515 6.99512 23.8304 7.07964 23.9727 7.32624C24.1151 7.57288 24.0306 7.88823 23.784 8.03063L22.0904 9.00847ZM19.2407 4.68341C19.2997 4.40527 19.5731 4.22768 19.8513 4.28672C20.1294 4.34576 20.307 4.61912 20.248 4.8973L19.838 6.82574C19.779 7.10388 19.5056 7.28147 19.2275 7.22243C18.9493 7.16339 18.7717 6.89002 18.8308 6.61189L19.2407 4.68341ZM13.8511 5.2747C13.3585 5.55907 13.1882 6.19483 13.4726 6.68738L19.1248 16.4774C19.4092 16.97 20.0449 17.1403 20.5375 16.8559C21.0301 16.5715 21.2004 15.9358 20.916 15.4432L18.9931 12.1127L19.2445 11.9676C20.1205 11.4618 20.4234 10.3313 19.9177 9.45526C19.4119 8.57925 18.2813 8.27632 17.4053 8.78207L17.154 8.92717L15.2638 5.65317C14.9794 5.1607 14.3437 4.99033 13.8511 5.2747ZM4.25108 18.5004L4.70152 19.2806C5.46735 20.6071 7.17919 21.0658 8.50567 20.2999L10.4728 19.1642L6.78705 12.7804L4.81994 13.9161C3.4935 14.6819 3.03481 16.3937 3.80064 17.7202L4.25108 18.5004ZM7.65885 12.2368C9.79285 10.7711 11.4357 9.14077 12.7101 7.42928L18.0684 16.7102C15.949 16.9581 13.7157 17.5657 11.3794 18.681L7.65885 12.2368Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default AlertIcon;

import { Button, NetworkLinkIcon } from '@/components';
import { Link } from 'react-router-dom';

const DashboardWithoutCampaigns = () => {
  return (
    <div className='flex h-full items-center justify-center rounded-leap bg-white shadow-md'>
      <div className=' flex flex-col items-center gap-8  '>
        <NetworkLinkIcon />
        <div className='flex flex-col gap-2'>
          <h1 className='text-2xl text-slate-800'>You don&apos;t have any campaigns yet!</h1>
          <p className='text-base text-slate-500'>Create your first campaign that you will manage.</p>
        </div>
        <Link to='/new-campaign'>
          <Button className='h-[40px] px-6 py-2 text-sm font-bold'>Create First Campaign</Button>
        </Link>
      </div>
    </div>
  );
};
export default DashboardWithoutCampaigns;

const PopularStoresIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5 3H14.17C14.7 3 15.21 3.21 15.58 3.59L20.41 8.42C20.79 8.79 21 9.3 21 9.83V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3ZM14.5462 17.4093L12 15.8747L9.45383 17.4032C8.98754 17.6845 8.41695 17.2687 8.53966 16.7429L9.21455 13.8571L6.96287 11.9128C6.5518 11.5582 6.77267 10.8856 7.31259 10.8428L10.276 10.5922L11.4355 7.87143C11.6441 7.37619 12.3559 7.37619 12.5645 7.87143L13.724 10.5983L16.6874 10.849C17.2273 10.8918 17.4482 11.5643 17.0371 11.9189L14.7855 13.8632L15.4603 16.749C15.583 17.2748 15.0125 17.6906 14.5462 17.4093Z'
      fill='#94A3B8'
    />
  </svg>
);

export default PopularStoresIcon;

import { Skeleton } from '@/components';
import { PageSkeleton } from '@/layouts/Page';

const SkeletonInput = () => (
  <div className='flex w-full flex-col gap-2'>
    <Skeleton className='h-5 w-40 rounded-md bg-white' />
    <Skeleton className='h-10 w-full bg-white py-px' />
  </div>
);

const SkeletonButton = () => <Skeleton className='mb-px h-10 w-36 bg-white' />;

const NewCampaignSkeleton = () => {
  return (
    <PageSkeleton>
      <div className=' flex w-1/2 flex-col gap-4 rounded-md border bg-white p-6 shadow-sm'>
        <Skeleton className='h-8 w-96' />
        <div className='flex w-full flex-row flex-wrap gap-4 lg:flex-nowrap'>
          <SkeletonInput />
          <SkeletonInput />
        </div>
        <div className='flex w-full flex-row flex-wrap gap-4 lg:flex-nowrap'>
          <SkeletonInput />
          <SkeletonInput />
        </div>
        <div className='flex w-full flex-row flex-wrap gap-4 lg:flex-nowrap'>
          <SkeletonInput />
          <SkeletonInput />
        </div>
        <SkeletonInput />
        <Skeleton className='p-4 '>
          <div className='flex w-full items-end gap-4'>
            <SkeletonInput />
            <SkeletonButton />
          </div>
        </Skeleton>
        <div className='flex items-center gap-2'>
          <Skeleton className='size-6' />
          <Skeleton className='h-5 w-40' />
        </div>
      </div>
    </PageSkeleton>
  );
};

export default NewCampaignSkeleton;

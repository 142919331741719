import { useField } from 'formik';
import { FC, useMemo } from 'react';
import ErrorMessage from '../ErrorMessage';
import Input, { InputProps } from '../Input';
import Label from '../Label';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import { useToggle } from '@/hooks/toggle';

type TextInputFieldProps = Omit<InputProps, 'onChange' | 'value'> & {
  name: string;
  label?: string;
};

const TextInputField: FC<TextInputFieldProps> = ({ name, label, type, ...textInputProps }) => {
  const { value: showPassword, toggle: toggleShowPassword } = useToggle();
  const [field, meta, helpers] = useField<string>(name);
  const { setValue } = helpers;
  const showError = !!meta.error && meta.touched;

  const ShowHideIcon = useMemo(() => (showPassword ? EyeSlashIcon : EyeIcon), [showPassword]);
  const inputType = useMemo(() => {
    if (type === 'password') {
      return showPassword ? 'text' : 'password';
    }

    return type;
  }, [type, showPassword]);

  const handleChange = (value: string) => {
    setValue(value);
  };

  return (
    <div className='relative flex w-full flex-col gap-2'>
      {label && <Label>{label}</Label>}
      <Input {...textInputProps} value={field.value} onChange={handleChange} hasError={showError} type={inputType} />
      {type === 'password' && <ShowHideIcon className='absolute bottom-2 right-2 w-6 cursor-pointer text-gray-400' onClick={() => toggleShowPassword()} />}
      {showError && <ErrorMessage>{meta.error}</ErrorMessage>}
    </div>
  );
};

export default TextInputField;

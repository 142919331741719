import { CHART_COLORS } from '@/utils/table';
import { FC, PropsWithChildren, memo } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';

interface ColumnPieChartProps extends PropsWithChildren {
  data: Array<{
    name: string;
    value: number;
  }>;
}

const ColumnPieChart: FC<ColumnPieChartProps> = ({ data, children }) => {
  return (
    <div className={'flex w-full items-center gap-1'}>
      <ResponsiveContainer width={children ? '33%' : '100%'} height={50} className='duration-200 ease-linear hover:scale-110'>
        <PieChart>
          <Pie data={data} innerRadius={10} outerRadius={15} paddingAngle={5} dataKey='value' isAnimationActive={false}>
            {data.map((_, index) => (
              <Cell key={`cell-${index}`} fill={CHART_COLORS[index]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      {children ? <div className='w-2\3'>{children}</div> : null}
    </div>
  );
};

export default memo(ColumnPieChart);

import { Skeleton } from '@/components';

const AnalysisCardSkeleton = () => (
  <>
    <div className='flex w-full space-x-6  p-4'>
      <div className='w-full space-y-6'>
        <div className='flex gap-6'>
          <Skeleton className='h-32 w-96' />
          <Skeleton className='h-32 w-96' />
        </div>
        <div className='space-y-6'>
          <Skeleton className='h-64 w-full' />
        </div>
      </div>
      <div className='w-full space-y-6'>
        <Skeleton className='h-48 w-full' />
        <Skeleton className='h-48 w-full' />
      </div>
    </div>
    <div className='flex w-full space-x-6 px-4'>
      <Skeleton className='h-16 w-1/3' />
      <Skeleton className='h-16 w-1/3' />
      <Skeleton className='h-16 w-1/3' />
    </div>
  </>
);

export default AnalysisCardSkeleton;

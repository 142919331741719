import { Transition } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/24/solid';
import { FC, Fragment, PropsWithChildren } from 'react';
import SeparatorLine from '../SeparatorLine';
import cn from '@/utils/style';

interface CollapsableProps extends PropsWithChildren {
  isOpen: boolean;
  onToggle: () => void;
  content?: JSX.Element | null;
}

const Collapsable: FC<CollapsableProps> = ({ isOpen, onToggle, content, children }) => {
  return (
    <Fragment>
      <div className={cn('flex flex-row flex-nowrap gap-4 pr-4', content ? 'items-start' : 'items-center')}>
        <ChevronUpIcon className={cn('w-7 cursor-pointer text-slate-400', !isOpen && 'rotate-180')} onClick={onToggle} />
        <div className='w-full'>
          {content ? <>{content}</> : <SeparatorLine />}
          <Transition
            as={Fragment}
            enter='transform transition duration-200'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transform duration-200 transition ease-in-out'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
            show={isOpen}
          >
            {children}
          </Transition>
        </div>
      </div>
    </Fragment>
  );
};

export default Collapsable;

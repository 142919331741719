import { useField } from 'formik';
import { FC } from 'react';
import ErrorMessage from '../ErrorMessage';
import Input, { InputProps } from '../Input';
import Label from '../Label';

type NumberInputFieldProps = Omit<InputProps, 'onChange' | 'value'> & {
  name: string;
  label?: string;
  optional?: boolean;
};

const NumberInputField: FC<NumberInputFieldProps> = ({ name, label, optional = false, ...textInputProps }) => {
  const [field, meta, helpers] = useField<string>(name);
  const { setValue } = helpers;
  const showError = !!meta.error && meta.touched;

  const handleChange = (value: string) => {
    if (isNaN(Number(value))) {
      return;
    }

    setValue(value);
  };

  return (
    <div className='flex w-full flex-col gap-2'>
      {(label || optional) && (
        <div className='flex flex-row gap-1'>
          {label && <Label>{label}</Label>}
          {optional && <span className='text-sm text-gray-400'>(optional)</span>}
        </div>
      )}
      <Input {...textInputProps} value={field.value} onChange={handleChange} hasError={showError} />
      {showError && <ErrorMessage>{meta.error}</ErrorMessage>}
    </div>
  );
};

export default NumberInputField;

import api from '../api';
import usersAPI, { deserializeUser } from '../users/users.api';
import store from '../store';
import companySlice from './company.slice';
import BugsnagPerformance from '@bugsnag/browser-performance';

const companyAPI = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    createCompany: build.mutation<APIResponse<Company>, NewCompanyValues>({
      query: (data) => ({
        method: 'POST',
        url: 'companies/',
        body: data,
      }),
      invalidatesTags: ['companiesList'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            companyAPI.util.updateQueryData('getCompanyList', undefined, (prevData) => {
              return [...prevData, data.data];
            }),
          );
        } catch (error) {
          api.util.invalidateTags(['companiesList']);
        }
      },
    }),
    getCompanyList: build.query<Array<Company>, void>({
      query: () => ({
        method: 'GET',
        url: 'companies/',
      }),
      providesTags: () => ['companiesList'],
      async onQueryStarted(_, { queryFulfilled }) {
        const bugsnagSpan = BugsnagPerformance.startSpan('getCompanyList');
        await queryFulfilled;
        bugsnagSpan.end();
      },
      transformResponse: (data: APIListResponse<Company>) => {
        return data.results;
      },
    }),
    updateCompany: build.mutation<Company, { companyId: string; data: { name: string } }>({
      query: ({ companyId, data }) => ({
        method: 'PATCH',
        url: `companies/${companyId}/`,
        body: data,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            companyAPI.util.updateQueryData('getCompanyList', undefined, (draft) => {
              const newCompanies = draft.map((company) => (company.id === data.id ? data : company));
              Object.assign(draft, newCompanies);
            }),
          );
        } catch {
          companyAPI.util.invalidateTags(['companiesList']);
        }
      },
    }),
    deleteCompany: build.mutation<APIResponse<Company>, { companyId: string }>({
      query: ({ companyId }) => ({
        method: 'DELETE',
        url: `companies/${companyId}/`,
      }),
      invalidatesTags: () => ['companiesList'],
    }),
    getCompanyById: build.query<Company, { companyId: string }>({
      query: ({ companyId }) => ({
        method: 'GET',
        url: `companies/${companyId}/`,
      }),
      async onQueryStarted(_queryArgs, { dispatch, queryFulfilled }) {
        try {
          const res = await queryFulfilled;

          dispatch(companySlice.actions.setCompany(res.data));
        } catch (error) {
          return; // component will handle error
        }
      },
    }),
    getCompanyUsersList: build.query<APIListResponse<User>, { companyId: string }>({
      query: ({ companyId }) => ({
        method: 'GET',
        url: `companies/${companyId}/users/`,
      }),
      transformResponse: (data: APIListResponse<UserJSON>) => {
        return {
          ...data,
          results: data.results.map((usr) => deserializeUser(usr)),
        };
      },
      providesTags: ['companyUsersList'],
    }),
    getCompanyUsedCredits: build.query<APIListResponse<CompanyUsedCredits>, { companyId: string } | void>({
      query: (props) => {
        let companyId = props?.companyId;

        if (!companyId) {
          companyId = store.getState().auth.user?.company?.id;
        }

        return {
          method: 'GET',
          url: `companies/${companyId}/used-credits/`,
        };
      },
    }),
    updateCompanyConfig: build.mutation<APIResponse<CompanyConfig>, { companyId: string; data: Partial<CompanyConfig> }>({
      query: ({ companyId, data }) => {
        return {
          method: 'PATCH',
          url: `companies/${companyId}/config/`,
          body: data,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const {
            data: { data },
          } = await queryFulfilled;
          dispatch(
            usersAPI.util.updateQueryData('getCurrentUser', undefined, (draft) => {
              if (draft?.company) {
                draft.company.config = data;
              }
            }),
          );
        } catch {
          usersAPI.util.invalidateTags(['currentUser']);
        }
      },
    }),
  }),
});

const {
  useCreateCompanyMutation,
  useGetCompanyListQuery,
  useGetCompanyByIdQuery,
  useGetCompanyUsersListQuery,
  useGetCompanyUsedCreditsQuery,
  useUpdateCompanyConfigMutation,
  useUpdateCompanyMutation,
  useDeleteCompanyMutation,
} = companyAPI;

export {
  useUpdateCompanyMutation,
  useCreateCompanyMutation,
  useGetCompanyByIdQuery,
  useGetCompanyUsersListQuery,
  useGetCompanyListQuery,
  useGetCompanyUsedCreditsQuery,
  useUpdateCompanyConfigMutation,
  useDeleteCompanyMutation,
};

export default companyAPI;

import { useField } from 'formik';
import { FC } from 'react';
import { Label, Textarea, ErrorMessage } from '..';
import type { TextareaProps } from '../Textarea';
import cn from '@/utils/style';

type TextareaInputFieldProps = Omit<TextareaProps, 'value' | 'onChange'> & {
  name: string;
  label?: string;
  containerClass?: string;
};

const TextareaInputField: FC<TextareaInputFieldProps> = ({ name, label, containerClass, ...textareaInputProps }) => {
  const [field, meta, helpers] = useField<string>(name);
  const { setValue } = helpers;
  const showError = !!meta.error && meta.touched;

  return (
    <div className={cn('flex flex-col gap-2', containerClass)}>
      {label && <Label className='text-left'>{label}</Label>}
      <Textarea {...textareaInputProps} value={field.value} onChange={setValue} hasError={showError} />
      {showError && <ErrorMessage>{meta.error}</ErrorMessage>}
    </div>
  );
};

export default TextareaInputField;

import { Button, Input, Modal } from '@/components';
import { useDeleteCampaignAnchorTextsMutation, useSaveCampaignConfigMutation } from '@/store/campaign/campaign.api';
import { selectCampaign, selectCloseToGapState } from '@/store/campaign/campaign.selector';
import { updateCampaignScenarioMutation } from '@/store/reducers';
import { AppDispatch } from '@/store/store';
import { getNumericValue } from '@/utils/numbers';
import { Table } from '@tanstack/react-table';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

type CloseToGapFieldProps<T> = {
  url?: ScenarioUrl;
  table: Table<T>;
  scenario: Scenario;
  rowId: string;
  urlId: number;
  lrdGap: number;
  hasKeywords?: boolean;
  hasAnchorsText?: boolean;
};

type TableMetadata = {
  toggleUrl: (url: ScenarioUrl) => void;
  setUrlCloseToGap: ({ url_id, close_to_gap, scenarioId, url }: { url_id: number; close_to_gap: number; scenarioId?: number; url?: ScenarioUrl }) => void;
};

//  get current scenario id and filter if it's approved

const CloseToGapField = <T extends unknown>({ url, scenario, hasKeywords, table, urlId, lrdGap, hasAnchorsText }: CloseToGapFieldProps<T>) => {
  const { campaignId } = useParams() as { campaignId: string };
  const gapValState = useSelector(selectCloseToGapState);
  const campaign = useSelector(selectCampaign);
  const dispatch = useDispatch<AppDispatch>();

  const [updateCampaignConfig] = useSaveCampaignConfigMutation();
  const [deleteAnchors] = useDeleteCampaignAnchorTextsMutation();

  const [gapVal, setGapVal] = useState(() => (scenario?.id ? gapValState[scenario.id] : {}));
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const readOnly = campaign?.status === 'kicked_off';
  const isSelected = useMemo(() => {
    const targetUrls = scenario?.urls.map((i) => i.url_id);
    return targetUrls?.includes(urlId);
  }, [scenario, urlId]);

  const handleModalConfirm = useCallback(async () => {
    await deleteAnchors({ campaignId }).unwrap();

    if (scenario.is_approved) {
      await dispatch(updateCampaignScenarioMutation({ campaignId, scenario: { ...scenario, is_approved: false } }));
    }
    await updateCampaignConfig({ campaignId, config: { user_progress: { last_step: 'analysis' } } });
  }, [campaignId, deleteAnchors, updateCampaignConfig, scenario, dispatch]);

  const handleChange = useCallback(
    (val: string) => {
      if (scenario.is_approved || hasAnchorsText) {
        return setConfirmModalOpen(true);
      }

      const numericVal = getNumericValue(val);

      if (!numericVal && val.trim() !== '') {
        return null;
      }

      setGapVal((i) => ({ ...i, [urlId]: numericVal?.toString() || '' }));
    },
    [urlId, scenario, hasAnchorsText],
  );

  const handleBlur = useCallback(async () => {
    const stateVal = scenario.id && gapValState[scenario?.id];
    if (gapVal[urlId] === (stateVal && stateVal[urlId])) {
      return;
    }

    if (hasKeywords) {
      await updateCampaignConfig({ campaignId, config: { keywords: [] } }).unwrap();
    }

    (table.options.meta as TableMetadata).setUrlCloseToGap({
      close_to_gap: Number(gapVal[urlId]),
      url_id: urlId,
      scenarioId: scenario.id,
      url,
    });
  }, [gapValState, gapVal, hasKeywords, campaignId, updateCampaignConfig, urlId, table.options.meta, scenario?.id, url]);

  const handleEnter = useCallback(
    (key: string) => {
      if (key === 'Enter') {
        handleBlur();
      }
    },
    [handleBlur],
  );

  return (
    <Fragment>
      <Input
        value={gapVal[urlId.toString()] || ''}
        onChange={handleChange}
        onBlur={handleBlur}
        readonly={!isSelected || readOnly}
        className='text-right'
        onKeyUp={handleEnter}
        disabled={lrdGap <= 0 || readOnly}
        suffix='%'
        placeholder='Value'
      />
      {confirmModalOpen && (
        <Modal onClose={() => setConfirmModalOpen(false)} isOpen={confirmModalOpen} title=''>
          <div className='mb-4 flex w-[450px] flex-col items-center gap-4'>
            <h4 className='text-center'>Are you sure you want to change this scenario?</h4>
            <p className='text-center'>If you do, all of your anchor text progress will be lost. This action cannot be undone.</p>
            <Button className='w-full' onClick={handleModalConfirm}>
              Continue and Override
            </Button>
            <Button className='w-full' onClick={() => setConfirmModalOpen(false)}>
              Cancel
            </Button>
          </div>
        </Modal>
      )}
    </Fragment>
  );
};

export default CloseToGapField;

import { QuerySubState, RootState } from '@reduxjs/toolkit/dist/query/core/apiState';

const getQueryArgs = (getState: () => RootState<any, any, 'api'>, endpointName: string) => {
  return Object.values(getState().api.queries).find((query) => query?.endpointName === endpointName && query.status !== 'uninitialized');
};

const getPOCQueryArgs = (getState: () => RootState<any, any, 'apiPoc'>, endpointName: string) => {
  return Object.values(getState().apiPoc.queries).find((query) => query?.endpointName === endpointName && query.status !== 'uninitialized');
};

const getFilteredQueryArgs = (getState: () => RootState<any, any, 'api'>, endpointName: string, callback: (query: QuerySubState<any> | undefined) => boolean) => {
  return Object.values(getState().api.queries).find((query) => query?.endpointName === endpointName && query.status !== 'uninitialized' && callback(query));
};

export { getQueryArgs, getPOCQueryArgs, getFilteredQueryArgs };

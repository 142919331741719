import { Input } from '@/components';
import { isNotNumeric, round } from '@/utils/numbers';
import { isNotEmpty } from '@/utils/text';
import { FC, useCallback, useState } from 'react';

interface RowCountInputProps {
  disabled?: boolean;
  onAfterBlur?: (val: number) => void;
  initialValue: number;
  hasError?: boolean;
}

const RowCountInput: FC<RowCountInputProps> = ({ disabled = false, onAfterBlur, initialValue, hasError = false }) => {
  const [inputVal, setInputVal] = useState<number>(initialValue);

  const handleExactMatchChange = useCallback((val: string) => {
    const numericVal = round(Number(val), 0);

    if (isNotNumeric(numericVal) && isNotEmpty(val)) {
      return null;
    }

    setInputVal(numericVal);
  }, []);

  const handleBlur = useCallback(
    (val: string) => {
      const numericVal = round(Number(val), 0);

      if (isNotNumeric(numericVal) && isNotEmpty(val)) {
        return null;
      }

      onAfterBlur && onAfterBlur(numericVal);
    },
    [onAfterBlur],
  );

  const handleEnter = useCallback(
    (key: string, value: string) => {
      if (key === 'Enter') {
        handleBlur(value);
      }
    },
    [handleBlur],
  );

  return (
    <Input value={inputVal} onChange={handleExactMatchChange} disabled={disabled} onBlur={handleBlur} onKeyUp={handleEnter} hasError={hasError} className='w-full text-right' />
  );
};

export default RowCountInput;

const getStateItemUpdater = <T>(item: T, finder: (a: T, b: T) => boolean) => {
  return (current: Array<T>) => {
    const safeCopy = [...current];

    const itemIndex = safeCopy.findIndex((i) => finder(i, item));

    if (itemIndex === -1) {
      safeCopy.push(item);
      return safeCopy;
    }

    safeCopy[itemIndex] = item;

    return safeCopy;
  };
};

export { getStateItemUpdater };

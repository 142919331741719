import { CHART_COLORS } from '@/utils/table';
import { FC, useMemo } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';

interface AnchorTextOverviewGraphProps {
  results: ScenarioAnchorsOverView[];
}

type ParsedData = { name: AnchorTextOverviewTableColumns; value: number };

const AnchorTextOverviewGraph: FC<AnchorTextOverviewGraphProps> = ({ results }) => {
  const parsedData = useMemo<Array<ParsedData>>(() => {
    const charData = results.map((item) => ({
      name: item.match_type,
      value: item.match_percentage,
    }));

    // we dont' need to show grand_total in chart
    return charData.filter((item) => item.name !== 'grand_total');
  }, [results]);

  const renderBreakdown = (item: ParsedData, index: number) => {
    return (
      <div key={index} className='flex items-center justify-between border-b border-b-slate-300 py-1 last-of-type:border-b-transparent'>
        <div className='flex items-center gap-2 py-1'>
          <div className='mr-2 h-4 w-4 rounded-full' style={{ backgroundColor: CHART_COLORS[index] }} />
          <b className='capitalize'>{item.name}</b>
        </div>
        <p>{item.value}%</p>
      </div>
    );
  };

  return (
    <div className='flex w-full items-center justify-center gap-6 rounded-leap bg-white p-5 text-left text-gray-800 shadow-md'>
      <ResponsiveContainer className='max-w-[135px]' height={135}>
        <PieChart>
          <Pie data={parsedData} innerRadius={48} outerRadius={68} paddingAngle={5} dataKey='value'>
            {parsedData.map((_, index) => (
              <Cell key={`cell-${index}`} fill={CHART_COLORS[index]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <div className='flex w-full flex-col gap-5'>
        <p className='text-xl leading-8'>Match Breakdown</p>
        <div>{parsedData.map(renderBreakdown)}</div>
      </div>
    </div>
  );
};

export default AnchorTextOverviewGraph;

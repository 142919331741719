import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Fragment, useCallback } from 'react';
import Label from '../Label';
import cn from '@/utils/style';

interface SelectProps<T> {
  value: T;
  options: Array<OptionType<T>>;
  onChange: (value: T) => void;
  label?: string;
  className?: string;
  withMarker?: boolean;
  optionsClassName?: string;
  disabled?: boolean;
}

const Select = <T extends unknown>({ options, value, onChange, label, className = '', withMarker = false, optionsClassName = '', disabled = false }: SelectProps<T>) => {
  const getSelectedLabel = useCallback(
    (value: T) => {
      const opt = options.find((opt) => opt.value === value);
      return opt ? opt.label : '';
    },
    [options],
  );

  const renderOptions = (option: { label: string; value: T }) => {
    const renderContent = (selected: boolean) => (
      <>
        <p className={cn('z-10 block font-normal', selected && 'font-medium')}>{String(option.label)}</p>
        {withMarker && selected && (
          <span className='absolute inset-y-0 left-0 flex items-center pl-3 text-blue-500'>
            <CheckIcon className='h-5 w-5' />
          </span>
        )}
      </>
    );

    return (
      <Listbox.Option
        key={String(option.value)}
        value={option.value}
        className={({ active }) => cn('relative p-2 text-gray-900', withMarker && 'pl-10 pr-4', active && 'bg-gray-200')}
      >
        {({ selected }) => renderContent(selected)}
      </Listbox.Option>
    );
  };

  return (
    <div className='relative flex flex-col gap-2'>
      {label && <Label>{label}</Label>}
      <Listbox value={value} onChange={onChange} disabled={disabled}>
        <div>
          <Listbox.Button
            className={cn(
              'w-full rounded-lg border border-gray-200 bg-white p-2 py-[10px] outline-none hover:border-gray-300 focus:border-blue-500 focus:ring-2',
              disabled && 'bg-gray-200 hover:border-gray-200',
              className,
            )}
          >
            <div className='flex items-center justify-between'>
              <p>{getSelectedLabel(value)}</p>
              <ChevronUpDownIcon className='h-5 w-5 text-gray-400' />
            </div>
          </Listbox.Button>
          <Transition as={Fragment} leave='transition ease-in duration-100' leaveFrom='opacity-100' leaveTo='opacity-0'>
            <Listbox.Options
              className={cn('absolute z-50 mt-1 block max-h-60 w-full overflow-auto rounded-lg bg-white p-2 py-1 text-base ring-1 ring-black ring-opacity-5', optionsClassName)}
            >
              {options.map(renderOptions)}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

export default Select;
export type { SelectProps };

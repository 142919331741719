import { RowCountInput, RowPercentageInput } from '@/components';
import { round } from '@/utils/numbers';
import cn from '@/utils/style';
import { Row, createColumnHelper } from '@tanstack/react-table';

const columnHelper = createColumnHelper<AnchorTextKeywordState>();

const getIsValidMatch = (row: Row<AnchorTextKeywordState>) => {
  const { exact_match, partial_match, generic_match, remaining } = row.original;
  const countValidMatch = exact_match + partial_match + generic_match === remaining;

  const exactMatchPercentage = (exact_match * 100) / remaining;
  const partialMatchPercentage = (partial_match * 100) / remaining;
  const genericMatchPercentage = (generic_match * 100) / remaining;

  const percentageValidMatch = round(exactMatchPercentage + partialMatchPercentage + genericMatchPercentage, 0) === 100;

  return [countValidMatch, percentageValidMatch];
};

type KeywordAnchorTextMatchTableMeta = {
  updater: (keywordId: number, val: number, field: keyof AnchorTextMatches) => void;
};

const useKeywordAnchorTextMatchColumns = ({ isLoading }: { isLoading: boolean }) => {
  return [
    columnHelper.accessor('keyword', {
      header: () => <p className='text-left'>Keyword</p>,
      cell: ({ row }) => (
        <div className='flex flex-nowrap items-center gap-2 whitespace-nowrap'>
          <p className='overflow-hidden text-ellipsis font-bold'>{row.original.keyword}</p>
        </div>
      ),
    }),
    columnHelper.accessor('exact_match', {
      header: () => <p className='text-left'>Anchor Exact Match</p>,
      cell: ({ row, table }) => {
        const handleAfterBlur = (val: number) => (table.options.meta as KeywordAnchorTextMatchTableMeta).updater(row.original.keyword_id, val, 'exact_match');
        const [isCountValidMatch, isPercentageValidMatch] = getIsValidMatch(row);

        return (
          <div className='flex gap-2'>
            <RowCountInput disabled={isLoading} onAfterBlur={handleAfterBlur} initialValue={row.original.exact_match} hasError={!isCountValidMatch} />
            <RowPercentageInput
              hasError={!isPercentageValidMatch}
              disabled={isLoading}
              initialValue={row.original.exact_match}
              limit={row.original.remaining}
              onAfterBlur={handleAfterBlur}
            />
          </div>
        );
      },
    }),
    columnHelper.accessor('partial_match', {
      header: () => <p className='text-left'>Anchor Partial Match</p>,
      cell: ({ row, table }) => {
        const handleAfterBlur = (val: number) => (table.options.meta as KeywordAnchorTextMatchTableMeta).updater(row.original.keyword_id, val, 'partial_match');
        const [isCountValidMatch, isPercentageValidMatch] = getIsValidMatch(row);

        return (
          <div className='flex gap-2'>
            <RowCountInput disabled={isLoading} onAfterBlur={handleAfterBlur} initialValue={row.original.partial_match} hasError={!isCountValidMatch} />
            <RowPercentageInput
              disabled={isLoading}
              limit={row.original.remaining}
              onAfterBlur={handleAfterBlur}
              initialValue={row.original.partial_match}
              hasError={!isPercentageValidMatch}
            />
          </div>
        );
      },
    }),
    columnHelper.accessor('generic_match', {
      header: () => <p className='text-left'>Anchor Generic Match</p>,
      cell: ({ row, table }) => {
        const handleAfterBlur = (val: number) => (table.options.meta as KeywordAnchorTextMatchTableMeta).updater(row.original.keyword_id, val, 'generic_match');
        const [isCountValidMatch, isPercentageValidMatch] = getIsValidMatch(row);

        return (
          <div className='flex gap-2'>
            <RowCountInput disabled={isLoading} onAfterBlur={handleAfterBlur} initialValue={row.original.generic_match} hasError={!isCountValidMatch} />
            <RowPercentageInput
              hasError={!isPercentageValidMatch}
              disabled={isLoading}
              limit={row.original.remaining}
              onAfterBlur={handleAfterBlur}
              initialValue={row.original.generic_match}
            />
          </div>
        );
      },
    }),
    columnHelper.accessor('remaining', {
      header: () => <p className='w-full text-center'>Remaining</p>,
      cell: ({ row }) => {
        const { remaining, exact_match, partial_match, generic_match } = row.original;
        const remainingCount = remaining - exact_match - partial_match - generic_match;
        return (
          <div className='flex flex-nowrap items-center justify-center gap-2 whitespace-nowrap'>
            <p className={cn('font-bold', remainingCount < 0 && 'text-red-600')}>{remainingCount}</p>
          </div>
        );
      },
    }),
  ];
};

export default useKeywordAnchorTextMatchColumns;

const ManageSearchIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M6.2041 8.50151H3.2041C2.6541 8.50151 2.2041 8.05151 2.2041 7.50151C2.2041 6.95151 2.6541 6.50151 3.2041 6.50151H6.2041C6.7541 6.50151 7.2041 6.95151 7.2041 7.50151C7.2041 8.05151 6.7541 8.50151 6.2041 8.50151ZM6.2041 11.5015H3.2041C2.6541 11.5015 2.2041 11.9515 2.2041 12.5015C2.2041 13.0515 2.6541 13.5015 3.2041 13.5015H6.2041C6.7541 13.5015 7.2041 13.0515 7.2041 12.5015C7.2041 11.9515 6.7541 11.5015 6.2041 11.5015ZM20.0841 17.7915L16.9641 14.6715C16.1041 15.2315 15.0741 15.5515 13.9641 15.4915C11.5941 15.3815 9.5641 13.5315 9.2441 11.1815C8.8041 7.83151 11.6941 5.00151 15.0741 5.57151C17.0241 5.90151 18.6441 7.42151 19.0741 9.35151C19.4041 10.8115 19.0841 12.1715 18.3741 13.2515L21.5041 16.3815C21.8941 16.7715 21.8941 17.4015 21.5041 17.7915C21.1141 18.1815 20.4741 18.1815 20.0841 17.7915ZM17.2041 10.5015C17.2041 8.85151 15.8541 7.50151 14.2041 7.50151C12.5541 7.50151 11.2041 8.85151 11.2041 10.5015C11.2041 12.1515 12.5541 13.5015 14.2041 13.5015C15.8541 13.5015 17.2041 12.1515 17.2041 10.5015ZM3.2041 18.5015H11.2041C11.7541 18.5015 12.2041 18.0515 12.2041 17.5015C12.2041 16.9515 11.7541 16.5015 11.2041 16.5015H3.2041C2.6541 16.5015 2.2041 16.9515 2.2041 17.5015C2.2041 18.0515 2.6541 18.5015 3.2041 18.5015Z'
      fill='#94A3B8'
    />
  </svg>
);

export default ManageSearchIcon;

import baseX from 'base-x';

const base62 = baseX('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz');

const base62Decode = (string: string) => String.fromCharCode(...base62.decode(string));

const base62Encode = (string: string) =>
  base62.encode(
    Array(string.length)
      .fill(null)
      .map((i, index) => string.charCodeAt(index)),
  );

export { base62Decode, base62Encode };

import { Modal, ModalProps, Table } from '@/components';
import { useTable } from '@/hooks/table';
import { useGetCampaignConfigQuery } from '@/store/campaign/campaign.api';
import { selectCampaignAnalysis, selectLinksToBuildState, selectScenarios } from '@/store/campaign/campaign.selector';
import { setScenarios, setUrlCloseToGap, setUrlLinksToBuild, updateSelectedScenarioUrls } from '@/store/campaign/campaign.slice';
import { selectCompany } from '@/store/company/company.selector';
import { AppDispatch } from '@/store/store';
import { getCurrencyFormat } from '@/utils/numbers';
import { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ScenarioCompareColumns2 from './getScenarioCompareColumns2';

const CompareScenarioModal: FC<ModalProps> = ({ isOpen, onClose, title, isLoading, onConfirm }) => {
  const { campaignId } = useParams() as { campaignId: string };
  const dispatch = useDispatch<AppDispatch>();

  const scenarios = useSelector(selectScenarios);
  const campaignAnalysis = useSelector(selectCampaignAnalysis);
  const company = useSelector(selectCompany);

  // default data to rollback on cancel
  const defaultScenarios = useMemo(() => scenarios, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { data: config } = useGetCampaignConfigQuery({ campaignId });

  // make a map from analysis, for clear and fast access
  const campaignAnalysisMap = useMemo(() => {
    const map = new Map<number, AnalysisData>();
    for (const analysis of campaignAnalysis) {
      map.set(analysis.targetPageId, analysis);
    }

    return map;
  }, [campaignAnalysis]);

  const compareData = useMemo(() => {
    const urls = scenarios.flatMap((s) => s.urls);
    const compareDataMap = urls.reduce((st: Map<number, ScenarioCompareV2>, curr) => {
      if (st.has(curr.url_id)) {
        return st;
      }

      const urlAnalysis = campaignAnalysisMap.get(curr.url_id);
      if (!urlAnalysis) {
        return st;
      }

      st.set(curr.url_id, {
        ...curr,
        difficulty: urlAnalysis.difficulty,
        avg_rank: urlAnalysis.campaignRank,
      });

      return st;
    }, new Map());

    return Array.from(compareDataMap.values());
  }, [campaignAnalysis]); // eslint-disable-line react-hooks/exhaustive-deps

  const scenarioCompareColumns = ScenarioCompareColumns2({
    hasKeywords: Boolean(config?.data.keywords?.length),
    hasAnchorsText: Boolean(config?.data.has_anchor_texts),
  });
  const [table] = useTable({
    data: compareData,
    columns: scenarioCompareColumns,
    tableCustomOptions: ['allowSort'],
    tableOptions: {
      meta: {
        toggleUrl: (url: ScenarioUrl, scenarioId: number) => {
          dispatch(updateSelectedScenarioUrls({ url, company, scenarioId }));
        },
        setUrlLinksToBuild: ({ url_id, links_to_build, scenarioId }: { url_id: number; links_to_build: number; scenarioId: number }) => {
          dispatch(setUrlLinksToBuild({ url_id, links_to_build, company, scenarioId }));
        },
        setUrlCloseToGap: ({ url_id, close_to_gap, scenarioId }: { url_id: number; close_to_gap: number; scenarioId: number }) => {
          dispatch(setUrlCloseToGap({ url_id, close_to_gap, company, scenarioId }));
        },
      },
    },
  });

  const handleClose = () => {
    dispatch(setScenarios(defaultScenarios));
    onClose && onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} onConfirm={onConfirm} title={title} isLoading={isLoading}>
      <Table
        table={table}
        name='compareScenario'
        isLoading={isLoading}
        loadingText='loading scenarios...'
        tableClass='shadow-none'
        cellStyle='pb-2 px-2 border-b border-gray-200'
        tableFooter={<TableFooter />}
      />
    </Modal>
  );
};

const TableFooter: FC = () => {
  const linksToBuild = useSelector(selectLinksToBuildState);
  const scenarios = useSelector(selectScenarios);

  const renderTotal = (scenario: Scenario) => {
    const currentScenarioLTBState = linksToBuild[scenario.id] || {};
    const totalLinks = Object.values(currentScenarioLTBState).reduce((total, ltbValue) => {
      const numericLtbValue = Number(ltbValue);
      if (isNaN(numericLtbValue) || numericLtbValue < 0) {
        return total;
      }
      return total + Number(ltbValue);
    }, 0);

    return (
      <td className='whitespace-nowrap bg-sky-100 px-4 text-left text-slate-800' key={scenario.id}>
        <span className='font-normal text-slate-500'>{totalLinks} links</span> {getCurrencyFormat(totalLinks * scenario.cost_per_link)}
      </td>
    );
  };

  return (
    <tr className='[&>*:first-child]:rounded-bl-xl [&>*:last-child]:rounded-br-xl'>
      <td className='bg-sky-100 px-4 py-3 text-left font-semibold'>Total Links Per Month/Monthly Cost</td>
      <td className='bg-sky-100'></td>
      <td className='bg-sky-100'></td>
      <td className='bg-sky-100'></td>
      <td className='bg-sky-100'></td>
      {scenarios.map(renderTotal)}
    </tr>
  );
};

export default CompareScenarioModal;

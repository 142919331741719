import { createContext } from 'react';
import { Step } from 'react-joyride';

interface TutorialValues {
  run: boolean;
  stepIndex: number;
  steps: Array<Step>;
  tourActive: boolean;
}

interface TutorialContextValues {
  tutorialState: TutorialValues;
  updateTutorialState: (state: Partial<TutorialValues>) => void;
}

const tutorialInitialValues: TutorialContextValues = {
  tutorialState: {
    run: false,
    stepIndex: 0,
    steps: [],
    tourActive: false,
  },
  updateTutorialState: () => {},
};

const TutorialContext = createContext(tutorialInitialValues);

export default TutorialContext;
export { tutorialInitialValues };
export type { TutorialValues };

import { FullStory, isInitialized } from '@fullstory/browser';

type TrackEventProps = {
  name: string;
  properties: Record<string, unknown>;
  schema?: Record<string, unknown>;
};

const setIdentity = async (user: User) => {
  try {
    FullStory('setIdentity', {
      uid: user.id,
      properties: {
        email: user.email,
      },
    });
  } catch (e) {
    console.log('FullStory not initialized.', e);
  }
};

const trackEvent = (props: TrackEventProps) => {
  if (isInitialized()) {
    FullStory('trackEvent', { ...props });
  }
};

export { trackEvent, setIdentity };

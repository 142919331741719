import { FC, useEffect } from 'react';
import { TypeOptions, toast } from 'react-toastify';
import { ToastCard } from '@/components';
import { useLastMessageFromWebSocket } from '@/hooks/websocket';
import usersAPI from '@/store/users/users.api';
import store from '@/store';

const NOTIFICATION_TYPE: Record<NotificationActionType, TypeOptions> = {
  success_message: 'success',
  info_message: 'info',
  error_message: 'error',
};

const SocketConnector: FC = () => {
  const { lastMessage, connectionStatus } = useLastMessageFromWebSocket();

  useEffect(() => {
    if (lastMessage) {
      const { action, message, subject }: NotificationData = JSON.parse(lastMessage.data);

      // only for TOAST notifications
      if (Object.keys(NOTIFICATION_TYPE).includes(action)) {
        toast(<ToastCard message={message} subject={subject} />, {
          type: NOTIFICATION_TYPE[action],
        });
        store.dispatch(usersAPI.endpoints.getUserNotifications.initiate({ page: 1, page_size: 100 }));
      }
    }
  }, [lastMessage]);

  useEffect(() => {
    // notify if WS failed to connect
    if (connectionStatus === 'Closing' || connectionStatus === 'Uninstantiated') {
      toast(<ToastCard message={`WS Error - ${connectionStatus}`} />, {
        type: 'error',
      });
    }
  }, [connectionStatus]);

  return null;
};

export default SocketConnector;

import { Tabs } from '@/components';
import { useLazyGetUrlAnchorTextsQuery, useSaveCampaignConfigMutation } from '@/store/campaign/campaign.api';
import { selectCampaignAnalysis, selectScenarios } from '@/store/campaign/campaign.selector';
import { setIsDirty } from '@/store/campaign/campaign.slice';
import { AppDispatch } from '@/store/store';
import { getStateItemUpdater } from '@/utils/arrayStateItem';
import { getParsedAnalysisData } from '@/utils/campaigns';
import { ArrowPathIcon } from '@heroicons/react/24/solid';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import AnchorTextsTable from '../AnchorTextsTable';
import UrlAnchorTextTable from '../UrlAnchorTextTable';
import { useAnchorTextNotification } from '@/hooks/websocket';

type AnchorTextResultsTabsProps = {
  isLoading?: boolean;
};

const AnchorTextResultsTabs: FC<AnchorTextResultsTabsProps> = ({ isLoading = false }) => {
  const { campaignId } = useParams() as { campaignId: string };
  const dispatch = useDispatch<AppDispatch>();
  const campaignAnalysis = useSelector(selectCampaignAnalysis);
  const scenarios = useSelector(selectScenarios);

  const [saveCampaignConfig] = useSaveCampaignConfigMutation();
  const [getUrlAnchorText] = useLazyGetUrlAnchorTextsQuery();

  const [urlAnchorTexts, setUrlAnchorTexts] = useState<UrlAnchorText[]>([]);
  const [isAnchorsLoading, setIsAnchorsLoading] = useState(false);
  const { notification } = useAnchorTextNotification();

  const scenario = useMemo(() => scenarios.find(({ is_approved }) => is_approved), [scenarios]);
  const parsedAnalysisData = useMemo(
    () => (campaignAnalysis && scenario ? getParsedAnalysisData({ data: campaignAnalysis, scenario: scenario }) : []),
    [campaignAnalysis, scenario],
  );

  const fetchAnchorTexts = useCallback(async () => {
    setIsAnchorsLoading(true);

    const newAnchorTexts = await Promise.all(
      parsedAnalysisData.map(async (url) => {
        return await getUrlAnchorText({
          campaign_id: Number(campaignId),
          url: {
            target_page: url.target_page,
            id: url.url_id,
          },
        }).unwrap();
      }),
    );

    const anchorTexts = newAnchorTexts.flatMap(({ results }) => results).sort((a, b) => a.id - b.id);

    setUrlAnchorTexts(anchorTexts);

    await saveCampaignConfig({ campaignId: campaignId, config: { has_anchor_texts: anchorTexts.length > 0 } }).unwrap();
    setIsAnchorsLoading(false);
  }, [campaignId, parsedAnalysisData, getUrlAnchorText, saveCampaignConfig]);

  useEffect(() => {
    if (!isLoading || notification?.action === 'generate_anchors_task_done') {
      fetchAnchorTexts();
    }
  }, [fetchAnchorTexts, isLoading, notification]);

  const handleUpdateUrlAnchorText = useCallback(
    (url: UrlAnchorText) => {
      dispatch(setIsDirty({ step: 'anchor-text-generator', isDirty: true }));
      setUrlAnchorTexts(getStateItemUpdater(url, (a, b) => a.id === b.id));
    },
    [dispatch],
  );

  const tabs: Array<TabsContent> = [
    {
      label: 'URLs',
      content: (
        <UrlAnchorTextTable
          urlAnchorsTextData={parsedAnalysisData}
          urlAnchorTexts={urlAnchorTexts}
          isLoading={isAnchorsLoading}
          updateUrlAnchorTexts={handleUpdateUrlAnchorText}
          scenario={scenario}
        />
      ),
    },
    {
      label: 'Anchor Text',
      content: <AnchorTextsTable urlAnchorTexts={urlAnchorTexts} isLoading={isAnchorsLoading} updateUrlAnchorTexts={handleUpdateUrlAnchorText} />,
      disabled: urlAnchorTexts.length === 0,
    },
  ];

  return <Tabs tabs={tabs} />;
};

export default AnchorTextResultsTabs;

import { FC, PropsWithChildren, useState } from 'react';
import { Collapsable } from '@/components';
import cn from '@/utils/style';

interface CollapsableCardProps extends PropsWithChildren {
  content: JSX.Element | null;
  containerClass?: string;
  isOpen?: boolean;
}

const CollapsableCard: FC<CollapsableCardProps> = ({ children, content, containerClass, isOpen }) => {
  const [toggle, setToggle] = useState(isOpen || false);

  return (
    <div className={cn('mt-6 rounded-leap p-4 shadow-sm', containerClass)}>
      <Collapsable isOpen={toggle} onToggle={() => setToggle((prevState) => !prevState)} content={content}>
        <div>{children}</div>
      </Collapsable>
    </div>
  );
};

export default CollapsableCard;

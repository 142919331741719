const ROUTES = {
  analysis: '/analysis',
  campaigns: 'campaigns',
  dashboard: '/',
  login: 'login',
  newCampaign: 'new-campaign',
  newCombinationCampaign: 'new-combination-campaign',
  newCompany: 'new-company',
  newUrlCampaign: 'new-url-campaign',
  newUser: 'new-user',
  paymentStatus: '/payment-status',
  settings: '/settings',
  linkingContextReport: '/linking-context-report',
  subscription: '/subscription',
  users: '/users',
  accessRequests: 'access-requests',
};

export default ROUTES;

import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

interface ToastCardProps {
  message: string;
  subject?: string;
}

const ToastCard: FC<ToastCardProps> = ({ message, subject }) => {
  const navigate = useNavigate();
  const handleNotificationClick = () => {
    navigate('/notifications');
  };

  return (
    <div onClick={handleNotificationClick}>
      {subject && <p className='mb-2 font-bold'>{subject}</p>}
      <p>{message}</p>
    </div>
  );
};

export default ToastCard;

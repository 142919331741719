import { Skeleton } from '@/components';
import { FC } from 'react';

const DomainInputFieldSkeleton: FC = () => {
  return (
    <div className='mt-8'>
      <div className='flex h-8 w-full items-center justify-between '>
        <Skeleton className='h-full w-[300px]' />
        <Skeleton className='h-full w-56' />
      </div>
      <Skeleton className='mt-3 h-[300px] w-full' />
    </div>
  );
};

export default DomainInputFieldSkeleton;

import { RadioGroup } from '@headlessui/react';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { FC, Fragment } from 'react';
import PaymentMethodOption from './PaymentMethodOption';
import { Button } from '@/components';

interface PaymentMethodListProps {
  paymentMethods: Array<PaymentMethod>;
  onAddMethod: () => void;
  value: string;
  onChange: (val: string) => void;
  onClose?: () => void;
  onConfirm: () => void;
  confirmButtonText: string;
}

const PaymentMethodList: FC<PaymentMethodListProps> = ({ paymentMethods, onAddMethod, value, onChange, onClose, onConfirm, confirmButtonText }) => {
  const renderPaymentMethods = (paymentMethod: PaymentMethod) => {
    return <PaymentMethodOption key={paymentMethod.id} paymentMethod={paymentMethod} />;
  };

  return (
    <Fragment>
      <RadioGroup value={value} onChange={onChange}>
        <div className='space-y-2 first-child-first:rounded-t-lg'>{paymentMethods.map(renderPaymentMethods)}</div>
      </RadioGroup>
      <button className='mt-2 flex w-full items-center justify-between rounded-b-lg bg-slate-100 p-3 text-sky-600' onClick={onAddMethod}>
        <div className='flex items-center gap-3'>
          <PlusCircleIcon className='w-6' />
          <p>Add Payment Method</p>
        </div>
        <ChevronRightIcon className='w-6' />
      </button>
      <div className='mt-6 flex w-full items-center space-x-2'>
        <Button variant='outline-light' className='w-full' onClick={onClose}>
          Cancel
        </Button>
        <Button className='w-full' onClick={onConfirm}>
          {confirmButtonText}
        </Button>
      </div>
    </Fragment>
  );
};

export default PaymentMethodList;

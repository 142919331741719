import { Select, SeparatorLine, Switch, ToastCard } from '@/components';
import { TARGET_RANK_OPTIONS, TERM_OPTIONS } from '@/constants/defaultValues';
import { useGetCurrentUserQuery } from '@/store/users/users.api';
import { useUpdateCompanyConfigMutation } from '@/store/company/company.api';
import { FC, useCallback } from 'react';
import { toast } from 'react-toastify';
import { CTRConfig, CltvCvrForm, DefaultCostLinkForm } from './components';

const BasicSettings: FC = () => {
  const { data: user, isLoading: isLoadingUser } = useGetCurrentUserQuery();
  const [updateCompanyConfig, { isLoading: isUpdating }] = useUpdateCompanyConfigMutation();

  const handleUpdateConfig = useCallback(
    async (data: Partial<CompanyConfig>) => {
      if (!user?.company) {
        return;
      }

      try {
        await updateCompanyConfig({ companyId: user.company.id, data }).unwrap();
        toast(<ToastCard message='Saved' />, { type: 'success' });
      } catch (error) {
        toast(<ToastCard message='Error' />, { type: 'error' });
      }
    },
    [updateCompanyConfig, user?.company],
  );

  const handleKeyChange = useCallback(
    async (key: keyof CompanyConfig, value: string | number | boolean) => {
      if (!user?.company) {
        return false;
      }
      await updateCompanyConfig({ companyId: user.company.id, data: { [key]: value } });
    },
    [user, updateCompanyConfig],
  );

  if (!user?.company?.config) {
    return (
      <div className='mx-4 md:mx-0'>
        <h1 className='text-left font-bold'>Settings</h1>
        <SeparatorLine className='mb-6 mt-[34px]' />
        <p>Company data missing!</p>
      </div>
    );
  }

  return (
    <div className='mx-4 md:mx-0'>
      <div className='flex'>
        <div className='w-1/2 pr-4'>
          <div className='flex w-full items-start justify-between gap-4 rounded-leap bg-white px-4 py-6 shadow-md'>
            <p className='text-xl font-bold'>ROI</p>
            <Switch value={user.company.config.roi_visibility} disabled={isLoadingUser || isUpdating || !user} onChange={(value) => handleKeyChange('roi_visibility', value)} />
          </div>

          <div className='my-4 flex'>
            <div className='mr-2 w-1/2 rounded-leap bg-white px-4 shadow-md'>
              <p className='mt-4 text-left text-xl font-bold'>Default Term</p>
              <p className='mb-2 mt-6 text-left'>Term</p>
              <Select
                value={user.company.config.default_proj_length || ''}
                className='mb-8'
                onChange={(selection) => handleKeyChange('default_proj_length', selection)}
                options={TERM_OPTIONS}
                withMarker
              />
            </div>
            <div className='ml-2 w-1/2 rounded-leap bg-white px-4 shadow-md'>
              <p className='mt-4 text-left text-xl font-bold'>Target Rank</p>
              <p className='mb-2 mt-6 text-left'>Target Rank</p>
              <Select
                value={user.company.config.default_target_rank || ''}
                className='mb-8'
                onChange={(selection) => handleKeyChange('default_target_rank', selection)}
                options={TARGET_RANK_OPTIONS}
                withMarker
              />
            </div>
          </div>

          <DefaultCostLinkForm company={user.company} isLoading={isUpdating} onSubmit={handleUpdateConfig} />
          <CltvCvrForm company={user.company} isLoading={isUpdating} onSubmit={handleUpdateConfig} />

          {/* <div className='flex w-full items-start justify-between gap-4 rounded-leap bg-white py-6 px-4 shadow-md'>
            <p className='text-xl font-bold'>View Walkthrough Tooltips</p>
            <Switch
              value={user.company.config.walkthrough_visibility}
              disabled={isLoadingUser || isUpdating || !user}
              onChange={(value) => handleKeyChange('walkthrough_visibility', value)}
            />
          </div> */}
        </div>
        <CTRConfig company={user.company} onSubmit={handleUpdateConfig} />
      </div>
    </div>
  );
};

export default BasicSettings;

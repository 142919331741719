const ShareLocationIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.1033 20.7648C13.1033 21.4048 13.6933 21.8948 14.3133 21.7548C15.4333 21.4948 16.4933 21.0548 17.4333 20.4548C17.9633 20.1148 18.0433 19.3548 17.5933 18.9048C17.2733 18.5848 16.7633 18.5048 16.3833 18.7448C15.6133 19.2348 14.7633 19.5948 13.8533 19.7948C13.4033 19.8948 13.1033 20.3048 13.1033 20.7648Z'
        fill='#94A3B8'
      />
      <path
        d='M4.11328 11.9948C4.11328 8.20476 6.76328 5.02476 10.3133 4.20476C10.7533 4.10476 11.0633 3.69476 11.0633 3.24476C11.0633 2.60476 10.4633 2.11476 9.84328 2.26476C5.41328 3.28476 2.11328 7.25476 2.11328 11.9948C2.11328 16.7348 5.41328 20.7048 9.84328 21.7348C10.4633 21.8848 11.0633 21.3948 11.0633 20.7548C11.0633 20.2948 10.7533 19.8948 10.3133 19.7948C6.76328 18.9648 4.11328 15.7848 4.11328 11.9948Z'
        fill='#94A3B8'
      />
      <path
        d='M20.8733 10.9948C21.5133 10.9948 22.0033 10.4048 21.8633 9.78476C21.6033 8.66476 21.1633 7.61476 20.5633 6.66476C20.2233 6.12476 19.4633 6.05476 19.0133 6.50476C18.6933 6.82476 18.6133 7.33476 18.8633 7.71476C19.3533 8.47476 19.7133 9.32476 19.9133 10.2448C20.0033 10.6948 20.4133 10.9948 20.8733 10.9948Z'
        fill='#94A3B8'
      />
      <path
        d='M17.4333 3.54476C16.4833 2.94476 15.4333 2.50476 14.3133 2.24476C13.6933 2.10476 13.1033 2.59476 13.1033 3.22476C13.1033 3.67476 13.4033 4.09476 13.8433 4.18476C14.7533 4.38476 15.6133 4.75476 16.3733 5.23476C16.7633 5.47476 17.2633 5.40476 17.5833 5.07476C18.0433 4.63476 17.9733 3.88476 17.4333 3.54476Z'
        fill='#94A3B8'
      />
      <path
        d='M19.0033 17.4848C19.4533 17.9348 20.2133 17.8648 20.5533 17.3248C21.1533 16.3848 21.5933 15.3248 21.8533 14.2048C21.9933 13.5848 21.5033 12.9948 20.8733 12.9948C20.4233 12.9948 20.0033 13.2948 19.9133 13.7348C19.7133 14.6448 19.3433 15.5048 18.8633 16.2648C18.6033 16.6548 18.6833 17.1648 19.0033 17.4848Z'
        fill='#94A3B8'
      />
      <path
        d='M16.0833 11.0948C16.0833 8.60476 14.1833 6.99476 12.0833 6.99476C9.98328 6.99476 8.08328 8.60476 8.08328 11.0948C8.08328 12.6048 9.18328 14.3748 11.3933 16.3948C11.7833 16.7548 12.3733 16.7548 12.7733 16.3948C14.9833 14.3648 16.0833 12.6048 16.0833 11.0948ZM12.0833 11.9948C11.4933 11.9948 11.0133 11.5148 11.0133 10.9248C11.0133 10.3348 11.4933 9.85476 12.0833 9.85476C12.6733 9.85476 13.1533 10.3348 13.1533 10.9248C13.1533 11.5148 12.6733 11.9948 12.0833 11.9948Z'
        fill='#94A3B8'
      />
    </svg>
  );
};

export default ShareLocationIcon;

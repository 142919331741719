import { VirtualizedTable } from '@/components';
import { useTable } from '@/hooks/table';
import { useToggle } from '@/hooks/toggle';
import { selectCampaign } from '@/store/campaign/campaign.selector';
import { Dispatch, FC, Fragment, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import AnchorTextBulkEdit from '../AnchorTextBulkEdit';
import DistributeAnchorsByKeywordModal from './DistributeAnchorsByKeywordModal';
import useAnchorTextMatchColumns from './useAnchorTextMatchColumns';
import { getBulkMatchValues } from '@/utils/campaigns';

type MatchMethodMap = Map<MatchMethods, UrlAnchorTextTableColumns[]>;
type UrlKeywordsDistributionStateMap = Map<number, AnchorTextKeywordState[]>;

type AnchorTextMatchTableProps = {
  isLoading?: boolean;
  selectedMethod: MatchMethods;
  selectedMethodState: MatchMethodMap;
  setSelectedMethodState: Dispatch<SetStateAction<MatchMethodMap>>;
  urlKeywordsDistributionState: UrlKeywordsDistributionStateMap;
  setUrlKeywordsDistributionState: Dispatch<SetStateAction<UrlKeywordsDistributionStateMap>>;
};

const AnchorTextMatchTable: FC<AnchorTextMatchTableProps> = ({
  isLoading = false,
  selectedMethod,
  selectedMethodState,
  setSelectedMethodState,
  urlKeywordsDistributionState,
  setUrlKeywordsDistributionState,
}) => {
  const campaign = useSelector(selectCampaign);

  const [urlId, setUrlId] = useState<number>();
  const [linksPerMonth, setLinksPerMonth] = useState<number>();
  const { value: isDistributedByKeywordModalOpen, toggle: toggleDistributeByKeywordModal } = useToggle();

  const isCampaignKickedOff = useMemo(() => campaign?.status === 'kicked_off', [campaign]);

  const handleSelectRow = ({ urlId, linksPerMonth }: { urlId: number; linksPerMonth: number }) => {
    setUrlId(urlId);
    setLinksPerMonth(linksPerMonth);
    toggleDistributeByKeywordModal(true);
  };

  const updateMethodState = useCallback(
    (id: number, type: keyof AnchorTextMatches, value: number) => {
      const newSt = new Map(selectedMethodState);
      const newUrlsValues = (newSt.get(selectedMethod) || []).map((url) => {
        if (url.url_id !== id) {
          return url;
        }

        return { ...url, [type]: value };
      });
      newSt.set(selectedMethod, newUrlsValues);
      setSelectedMethodState(newSt);
    },
    [selectedMethod, selectedMethodState, setSelectedMethodState],
  );

  const bulkUpdateMethodState = useCallback(
    (values: AnchorTextMatches) => {
      const newSt = new Map(selectedMethodState);
      const newUrlsValues = (newSt.get(selectedMethod) || []).map((url) => {
        const { exact_match: exactPercent, partial_match: partialPercent, generic_match: genericPercent } = values;

        const bulkMatchValues = getBulkMatchValues({ exactPercent, partialPercent, genericPercent }, url.links_per_month);

        return { ...url, exact_match: bulkMatchValues.exact.total, generic_match: bulkMatchValues.generic.total, partial_match: bulkMatchValues.partial.total };
      });

      newSt.set(selectedMethod, newUrlsValues);
      setSelectedMethodState(newSt);
    },
    [selectedMethod, selectedMethodState, setSelectedMethodState],
  );

  const tableData = useMemo(() => selectedMethodState.get(selectedMethod) || [], [selectedMethod, selectedMethodState]);
  const tableColumns = useAnchorTextMatchColumns();
  const [table] = useTable({
    data: tableData,
    columns: tableColumns,
    tableOptions: {
      meta: {
        updateMethodState: updateMethodState,
        onSelectRow: handleSelectRow,
        readOnly: isCampaignKickedOff,
        isDisabled: isLoading || selectedMethod !== 'individual_edit',
      },
    },
  });

  const handleConfirmDistributeByKeyword = (keywords: AnchorTextKeywordState[]) => {
    if (!urlId) {
      return;
    }

    urlKeywordsDistributionState.set(urlId, keywords);
    setUrlKeywordsDistributionState((prev) => {
      const newMap = new Map(prev);
      newMap.set(urlId, keywords);
      return newMap;
    });

    const { exactMatches, partialMatches, genericMatches } = keywords.reduce(
      (acc, kw) => {
        acc.exactMatches += kw.exact_match;
        acc.partialMatches += kw.partial_match;
        acc.genericMatches += kw.generic_match;
        return acc;
      },
      { exactMatches: 0, partialMatches: 0, genericMatches: 0 },
    );

    const methodState = selectedMethodState.get(selectedMethod)?.find((url) => url.url_id === urlId);
    if (methodState) {
      setSelectedMethodState((old) => {
        const newMap = new Map(old);
        const newUrls = newMap.get(selectedMethod)?.map((url) => {
          if (url.url_id !== urlId) {
            return url;
          }

          return {
            ...url,
            exact_match: exactMatches,
            partial_match: partialMatches,
            generic_match: genericMatches,
          };
        });

        newMap.set(selectedMethod, newUrls || []);
        return newMap;
      });
    }

    toggleDistributeByKeywordModal(false);
    setUrlId(undefined);
    setLinksPerMonth(undefined);
  };

  const handleResetDistributeByKeyword = () => {
    if (!urlId) {
      return;
    }

    // reset distribution state
    setUrlKeywordsDistributionState((prev) => {
      const newMap = new Map(prev);
      const kws = newMap.get(urlId) || [];
      for (const keyword of kws) {
        keyword.exact_match = 0;
        keyword.partial_match = 0;
        keyword.generic_match = 0;
      }

      newMap.set(urlId, kws);
      return newMap;
    });

    const recommendedState = selectedMethodState.get('recommended')?.find((url) => url.url_id === urlId)?.recommended;
    if (recommendedState) {
      setSelectedMethodState((old) => {
        const newMap = new Map(old);
        const newUrls = newMap.get(selectedMethod)?.map((url) => {
          if (url.url_id !== urlId) {
            return url;
          }

          return {
            ...url,
            exact_match: recommendedState.exact_match,
            partial_match: recommendedState.partial_match,
            generic_match: recommendedState.generic_match,
          };
        });
        newMap.set(selectedMethod, newUrls || []);
        return newMap;
      });
    }

    // updateMethodState(selectedRowRecommended);
    setUrlId(undefined);
    setLinksPerMonth(undefined);
  };

  const handleCloseDistributeByKeyword = () => {
    toggleDistributeByKeywordModal(false);
    setUrlId(undefined);
    setLinksPerMonth(undefined);
  };

  return (
    <Fragment>
      {selectedMethod === 'bulk_edit' && <AnchorTextBulkEdit readOnly={isCampaignKickedOff} onChange={bulkUpdateMethodState} value={selectedMethodState.get('bulk_edit') || []} />}
      <VirtualizedTable
        loadingText='Loading URLs...'
        isLoading={isLoading}
        table={table}
        name='anchortextMatchContent'
        cellStyle='pt-10 xl:pt-8'
        headerClass='text-sm px-1'
        itemHeight={100}
        className='min-h-fit'
      />
      {isDistributedByKeywordModalOpen && urlId && linksPerMonth && (
        <DistributeAnchorsByKeywordModal
          isOpen={isDistributedByKeywordModalOpen}
          onClose={handleCloseDistributeByKeyword}
          onConfirm={handleConfirmDistributeByKeyword}
          onReset={handleResetDistributeByKeyword}
          urlKeywords={urlKeywordsDistributionState.get(urlId) || []}
        />
      )}
    </Fragment>
  );
};

export default AnchorTextMatchTable;

import { Button, ErrorMessage, Modal, ModalProps } from '@/components';
import { CombinationsField } from '@/components/AddUrlCombinationManuallyModal';
import { useAddCampaignUrlMutation, useLazyGetCampaignQuery } from '@/store/campaign/campaign.api';
import { AppDispatch } from '@/store/store';
import { useField } from 'formik';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useCampaignsNotifications } from '@/hooks/websocket';
import { setIsDirty } from '@/store/campaign/campaign.slice';
import api from '@/store/api';
import { parseCombinations } from '@/utils/urls';

interface AddUrlCombinationManuallyModalProps extends ModalProps {}

type CombinationWithSwitchMap = Record<number, Combination & { toggled: boolean }>;

const AddUrlCombinationURLsManually = ({ isOpen, onClose }: AddUrlCombinationManuallyModalProps) => {
  const { campaignId } = useParams() as { campaignId: string };
  const dispatch = useDispatch<AppDispatch>();

  const [{ value }] = useField<string>('urls_list');
  const [{ value: combinations }, , { setValue }] = useField<Combination[]>('combinations');

  const [fetchCampaign] = useLazyGetCampaignQuery();
  const { connectionStatus } = useCampaignsNotifications();

  const [hasError, setHasError] = useState(false);
  const [isUpdatingCampaign, setIsUpdatingCampaign] = useState(false);
  const [fetchError, setFetchError] = useState('');

  const [combinationsWithSwitch, setCombinationsWithSwitch] = useState<CombinationWithSwitchMap>(
    combinations.reduce((acc, combination, index) => {
      return {
        ...acc,
        [index]: {
          ...combination,
          toggled: false,
        },
      };
    }, {}) || {},
  );

  const handleCombinationWithSwitch = useCallback((callback: (prev: CombinationWithSwitchMap) => CombinationWithSwitchMap) => {
    setCombinationsWithSwitch(callback);
  }, []);

  const initialCombinations = useMemo(() => {
    return value.split(',').map((url) => {
      return {
        url,
        keywords: [],
      };
    });
  }, [value]);

  const [addUrl, { isLoading, isError }] = useAddCampaignUrlMutation();

  const fetchCampaignStatusManually = useCallback(async () => {
    const resp = await fetchCampaign({ campaignId }).unwrap();

    if (resp.last_run_status === 'error') {
      setIsUpdatingCampaign(false);
      setFetchError('Failed to fetch new URL data');
    }
  }, [campaignId, fetchCampaign]);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (isUpdatingCampaign) {
        await fetchCampaignStatusManually();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [isUpdatingCampaign, connectionStatus, fetchCampaignStatusManually]);

  const hasEmptyCombinations = useMemo(() => {
    return combinations.some((c, i) => {
      const hasValidUrl = c.url.trim() !== '';
      const hasKeywords = c.keywords.length > 0;
      const isToggledValid = combinationsWithSwitch[i]?.toggled;

      return hasValidUrl && (isToggledValid || hasKeywords);
    });
  }, [combinations, combinationsWithSwitch]);

  const handleOnError = useCallback((hasError: boolean) => {
    setHasError(hasError);
  }, []);

  const handleAddKeyword = async () => {
    const { status } = await addUrl({ id: campaignId, combinations }).unwrap();

    if (status === 201) {
      dispatch(setIsDirty({ step: 'analysis', isDirty: true }));
      setIsUpdatingCampaign(true);
      dispatch(api.util.invalidateTags(['campaignAnalysis', 'campaignScenarios', 'campaign']));
      setValue([{ url: '', keywords: [] }]);
      if (onClose) {
        onClose();
      }

      return true;
    }

    return setHasError(true);
  };

  return (
    <Fragment>
      <Modal isOpen={isOpen} onClose={onClose} title='Combinations'>
        {isError || (fetchError && <ErrorMessage className='pb-4'> {fetchError ? fetchError : 'Something went wrong'}</ErrorMessage>)}
        <CombinationsField
          name='combinations'
          initialCombinations={initialCombinations}
          hasError={hasError}
          onError={handleOnError}
          handleCombinationWithSwitch={handleCombinationWithSwitch}
          combinationsWithSwitch={combinationsWithSwitch}
        />
        <div className='my-2 flex justify-between gap-3'>
          <Button onClick={onClose} className='w-1/2' variant='outline-light'>
            Cancel
          </Button>
          <Button
            className='w-1/2'
            onClick={handleAddKeyword}
            disabled={isLoading || isUpdatingCampaign || !parseCombinations(combinations).length || hasError || !hasEmptyCombinations}
            isLoading={isLoading || isUpdatingCampaign}
          >
            Add URL
          </Button>
        </div>
      </Modal>
    </Fragment>
  );
};

export default AddUrlCombinationURLsManually;

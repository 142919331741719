import { FC } from 'react';

const UnreadMark: FC = () => {
  return (
    <div className='absolute right-0 top-0'>
      <svg className='h-4 w-4' width='10' height='11' viewBox='0 0 10 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <circle cx='5' cy='5.5' r='4' fill='#EF4444' stroke='white' strokeWidth='2' />
      </svg>
    </div>
  );
};

export default UnreadMark;

import { FiltersModal, FiltersPills, Modal, ModalProps, SearchBarDebounce, VirtualizedTable } from '@/components';
import { competitorsTableColumns, useCompetitorsTableData } from '@/components/CompetitorsTableColumns';
import { NUMERIC_FILTERS_OPTIONS, STRING_FILTERS_OPTIONS } from '@/constants/defaultValues';
import { useScreenSize } from '@/hooks/screenSize';
import { useTable } from '@/hooks/table';
import { selectTablesConfig } from '@/store/app/app.selector';
import { setIsDirty } from '@/store/campaign/campaign.slice';
import { AppDispatch } from '@/store/store';
import { arrayStrFilter, getColumnStateFromFilters, getFiltersFromColumnState } from '@/utils/filters';
import { FunnelIcon } from '@heroicons/react/24/solid';
import { ColumnFiltersState, FilterFnOption, Row } from '@tanstack/react-table';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

interface CompetitorsModalProps extends Omit<ModalProps, 'onConfirm'> {
  onConfirm: ({ id, domains }: { id: string; domains: Array<CampaignCompetitorDomain> }) => void;
  targetPageId?: string;
}

type SubjectSummary = {
  avg_da: number;
  avg_pa: number;
  backlinks: number;
  domain: string;
  number_of_keywords: number;
  ranking_pages_number: number;
  ref_domains: number;
  type: 'client' | 'competitor';
};

export const SubjectSummary = ({ summary }: { summary: SubjectSummary }) => {
  return (
    <tr className='flex hover:bg-gray-100 last-child-first:rounded-bl-md last-child-last:rounded-br-md'>
      <td
        className='rounded-bl-xl bg-sky-100'
        style={{
          width: 'calc(var(--header-id-size) * 1px)',
        }}
      ></td>
      <td
        className='bg-sky-100 px-4 py-2 text-left'
        style={{
          width: 'calc(var(--header-domain-size) * 1px)',
        }}
      >
        {summary.domain}
      </td>
      <td
        className='bg-sky-100 px-4 py-2 text-right'
        style={{
          width: 'calc(var(--header-ranking_pages_number-size) * 1px)',
        }}
      >
        {summary.ranking_pages_number.toLocaleString()}
      </td>
      <td
        className=' bg-sky-100 px-4 py-2 text-right'
        style={{
          width: 'calc(var(--header-number_of_keywords-size) * 1px)',
        }}
      >
        {summary.number_of_keywords.toLocaleString()}
      </td>
      <td
        className='bg-sky-100 px-4 py-2 text-right'
        style={{
          width: 'calc(var(--header-avg_da-size) * 1px)',
        }}
      >
        {summary.avg_da.toLocaleString()}
      </td>
      <td
        className='bg-sky-100 px-4 py-2 text-right'
        style={{
          width: 'calc(var(--header-avg_pa-size) * 1px)',
        }}
      >
        {summary.avg_pa.toLocaleString()}
      </td>
      <td
        className='bg-sky-100 px-4 py-2 text-right'
        style={{
          width: 'calc(var(--header-ref_domains-size) * 1px)',
        }}
      >
        {summary.ref_domains.toLocaleString()}
      </td>
      <td
        className='rounded-br-xl bg-sky-100 py-2 pr-10 text-right'
        style={{
          width: 'calc(var(--header-backlinks-size) * 1px)',
        }}
      >
        {summary.backlinks.toLocaleString()}
      </td>
    </tr>
  );
};

const COLUMN_FILTERS: Partial<RefineCompetitorsFilters> = {
  domain: STRING_FILTERS_OPTIONS,
  ranking_pages_number: NUMERIC_FILTERS_OPTIONS,
  number_of_keywords: NUMERIC_FILTERS_OPTIONS,
  avg_da: NUMERIC_FILTERS_OPTIONS,
  avg_pa: NUMERIC_FILTERS_OPTIONS,
  ref_domains: NUMERIC_FILTERS_OPTIONS,
  backlinks: NUMERIC_FILTERS_OPTIONS,
  target_pages: STRING_FILTERS_OPTIONS,
};

const competitorsGlobalFilter = (row: Row<CampaignCompetitorDomain>, _columnId: keyof AnalysisData, filterValue: string) => {
  return row.original.domain.includes(filterValue) || row.original.urls.some((url) => url.includes(filterValue));
};

const CompetitorsModal: FC<CompetitorsModalProps> = ({ onClose, isOpen, onConfirm, targetPageId }) => {
  const { campaignId } = useParams() as { campaignId: string };
  const { width } = useScreenSize();
  const dispatch = useDispatch<AppDispatch>();
  const tableConfig = useSelector(selectTablesConfig);

  const { isFetching, loadingDomains, competitorsDomains, competitorTableData, clientExtraHeader } = useCompetitorsTableData({ campaignId, filters: { url_id: targetPageId } });
  const isLoading = useMemo(() => loadingDomains || isFetching, [loadingDomains, isFetching]);

  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const [columnFiltersState, setColumnFiltersState] = useState<ColumnFiltersState>([]);
  const [globalFilterValue, setGlobalFilterValue] = useState('');

  const filtersFromColumnState = useMemo(() => getFiltersFromColumnState(columnFiltersState) as Array<RefineCompetitorsFilterValue>, [columnFiltersState]);

  const [table, flatRows] = useTable({
    data: competitorTableData,
    columns: competitorsTableColumns,
    tableCustomOptions: ['allowSelect', 'allowFilters', 'allowResize', 'allowSort'],
    defaultSorting: tableConfig['competitors'].sorting,
    tableOptions: {
      meta: {
        size: (width * 1.07) / competitorsTableColumns.length,
      },
      onColumnFiltersChange: setColumnFiltersState,
      onGlobalFilterChange: setGlobalFilterValue,
      globalFilterFn: competitorsGlobalFilter as FilterFnOption<CampaignCompetitorDomain>,
      filterFns: {
        arrayStrFilter: arrayStrFilter,
      },
      state: {
        columnVisibility: {
          target_pages: false,
        },
        columnFilters: columnFiltersState,
        globalFilter: globalFilterValue,
      },
    },
  });

  const handleDomainsConfirm = useCallback(async () => {
    if (isLoading || !competitorsDomains) {
      return false;
    }

    const domainsWithExclude = competitorsDomains.results.map((compDomain) => {
      const row = flatRows.find((r) => r.original.domain === compDomain.domain);

      return {
        ...compDomain,
        excluded: row ? !row.getIsSelected() : false,
      };
    });
    dispatch(setIsDirty({ step: 'analysis', isDirty: true }));

    return onConfirm({ id: campaignId, domains: domainsWithExclude });
  }, [campaignId, competitorsDomains, flatRows, onConfirm, dispatch, isLoading]);

  useEffect(() => {
    if (!competitorsDomains) {
      return;
    }

    flatRows.forEach((row) => {
      const competitorDomain = competitorsDomains.results.find((i) => i.domain === row.original.domain);

      if (competitorDomain) {
        row.toggleSelected(!competitorDomain.excluded);
      }
    });
  }, [competitorsDomains]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleConfirm = useCallback((values: RefineCompetitorsFiltersFormValues) => {
    setColumnFiltersState(getColumnStateFromFilters(values.filters));
    setIsFiltersModalOpen(false);
  }, []);

  const handleFilterRemove = (filter: RefineCompetitorsFilterValue) => {
    setColumnFiltersState((oldState) => oldState.filter((i) => i.id !== filter.field));
  };

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      title='Refine Competitors'
      onConfirm={handleDomainsConfirm}
      dialogPanelclassName='2xl:w-[80%] 2xl:max-w-[80%]'
      footerClassName='w-fit ml-auto'
    >
      <div className='flex flex-col gap-2'>
        <div className='flex w-[100%] items-center gap-2'>
          <SearchBarDebounce onChange={(value) => setGlobalFilterValue(value)} disabled={isLoading} className='w-full' inputClassName='w-full h-10' />
          <button onClick={() => setIsFiltersModalOpen(true)} className='rounded-lg border p-2' disabled={isLoading}>
            <FunnelIcon className='w-5' />
          </button>
        </div>
        {filtersFromColumnState.length > 0 && <FiltersPills filters={filtersFromColumnState} onFilterRemove={handleFilterRemove} />}
        <VirtualizedTable
          allowResize
          table={table}
          name='competitors'
          loadingText={'Loading domains...'}
          isLoading={isLoading}
          itemHeight={40}
          tableExtraHeader={clientExtraHeader ? <SubjectSummary summary={clientExtraHeader} /> : undefined}
          tableClass='shadow-none'
        />
        {isFiltersModalOpen && (
          <FiltersModal
            isOpen={isFiltersModalOpen}
            onClose={() => setIsFiltersModalOpen(false)}
            columnFilters={COLUMN_FILTERS}
            initialValue={filtersFromColumnState}
            onConfirm={handleConfirm}
          />
        )}
      </div>
    </Modal>
  );
};

export default CompetitorsModal;

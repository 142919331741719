import { FiltersModal, FiltersPills, SearchBarDebounce, VirtualizedTable } from '@/components';
import { STRING_FILTERS_OPTIONS } from '@/constants/defaultValues';
import { useTable } from '@/hooks/table';
import { selectTablesConfig } from '@/store/app/app.selector';
import { selectCampaign } from '@/store/campaign/campaign.selector';
import { getColumnStateFromFilters, getFiltersFromColumnState } from '@/utils/filters';
import { FunnelIcon } from '@heroicons/react/24/solid';
import { ColumnFiltersState, FilterFnOption, Row } from '@tanstack/react-table';
import { FC, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import useColumns from './columns';
import cn from '@/utils/style';

type AnchorTextsTableProps = {
  urlAnchorTexts: UrlAnchorText[];
  isLoading: boolean;
  updateUrlAnchorTexts: (url: UrlAnchorText) => void;
};

const COLUMN_FILTERS: Partial<UrlAnchorTextFilters> & ColumnFilters<{ 'url.target_page': string }> = {
  'url.target_page': STRING_FILTERS_OPTIONS,
  match_type: STRING_FILTERS_OPTIONS,
  keyword_text: STRING_FILTERS_OPTIONS,
  value: STRING_FILTERS_OPTIONS,
};

const anchorTextGlobalFilter = (row: Row<UrlAnchorText>, _columnId: keyof UrlAnchorText, filterValue: string) => {
  const { url, match_type, keyword_text, value } = row.original;

  return url.target_page.includes(filterValue) || match_type.includes(filterValue) || (keyword_text && keyword_text.includes(filterValue)) || value.includes(filterValue);
};

const AnchorTextsTable: FC<AnchorTextsTableProps> = ({ isLoading, urlAnchorTexts, updateUrlAnchorTexts }) => {
  const tableConfig = useSelector(selectTablesConfig);
  const campaign = useSelector(selectCampaign);

  const [columnFiltersState, setColumnFiltersState] = useState<ColumnFiltersState>([]);
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const [globalFilter, setGlobalFilter] = useState('');

  const filtersFromColumnState = useMemo(() => getFiltersFromColumnState(columnFiltersState) as Array<AnalysisFilterValue>, [columnFiltersState]);

  const columns = useColumns({ readOnly: Boolean(campaign?.status === 'kicked_off') });
  const [table] = useTable({
    data: urlAnchorTexts,
    columns: columns,
    tableCustomOptions: ['allowSort', 'allowFilters'],
    defaultSorting: tableConfig['anchortextTable'].sorting,
    tableOptions: {
      onColumnFiltersChange: setColumnFiltersState,
      globalFilterFn: anchorTextGlobalFilter as FilterFnOption<UrlAnchorText>,
      state: {
        columnFilters: columnFiltersState,
        globalFilter: globalFilter,
      },
      meta: {
        updateUrlAnchorTexts,
      },
    },
  });

  const handleConfirm = useCallback((values: AnalysisFiltersFormValues) => {
    setColumnFiltersState(getColumnStateFromFilters(values.filters));
    setIsFiltersModalOpen(false);
  }, []);

  const handleFilterRemove = useCallback((filter: AnalysisFilterValue) => {
    setColumnFiltersState((oldState) => oldState.filter((i) => i.id !== filter.field));
  }, []);

  return (
    <div className='my-b overflow-auto'>
      <div className='my-4 flex w-full items-center gap-4'>
        <div className='flex items-center gap-2'>
          <SearchBarDebounce onChange={(value) => setGlobalFilter(value)} disabled={isLoading} className='ml-2' />
          <div
            className={cn('flex flex-row flex-nowrap items-center gap-2', isLoading ? 'cursor-not-allowed text-gray-400' : 'cursor-pointer text-sky-600')}
            onClick={isLoading ? undefined : () => setIsFiltersModalOpen(true)}
          >
            <p className='text-lg font-bold'>Filters</p>
            <FunnelIcon className='h-6 w-6' />
          </div>
        </div>
      </div>
      <FiltersPills filters={filtersFromColumnState} onFilterRemove={handleFilterRemove} />
      <div className='mt-6 w-full rounded-xl bg-white p-5'>
        <VirtualizedTable
          table={table}
          name='anchortextTable'
          loadingText='Loading Anchor Text...'
          cellStyle='text-left'
          itemHeight={45}
          isLoading={isLoading}
          tableClass='shadow-none'
          containerClass='max-h-[calc(100vh-25rem)]'
        />
      </div>
      {isFiltersModalOpen && (
        <FiltersModal
          isOpen={isFiltersModalOpen}
          onClose={() => setIsFiltersModalOpen(false)}
          columnFilters={COLUMN_FILTERS}
          initialValue={filtersFromColumnState}
          onConfirm={handleConfirm}
        />
      )}
    </div>
  );
};

export default AnchorTextsTable;

import { Button, ErrorMessage, TextInputField } from '@/components';
import { useForgotPasswordMutation } from '@/store/users/users.api';
import { CheckIcon, ChevronLeftIcon } from '@heroicons/react/24/solid';
import { Form, FormikProvider, useFormik } from 'formik';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

const forgotPasswordValidationSchema: yup.SchemaOf<ForgotPasswordValues> = yup.object({
  email: yup.string().email().required('Email is required'),
});

const ForgotPasswordPage: FC = () => {
  const navigate = useNavigate();
  const [forgotPassword, { isSuccess, error, isLoading }] = useForgotPasswordMutation();
  const formik = useFormik<ForgotPasswordValues>({
    onSubmit: (values) => forgotPassword(values),
    initialValues: {
      email: '',
    },
    validationSchema: forgotPasswordValidationSchema,
  });

  return (
    <div className='flex w-screen flex-col items-center gap-y-10 pt-48'>
      <div className='flex w-full max-w-md flex-col gap-y-5 rounded-lg bg-white px-4 py-6 text-left shadow-xl'>
        <button className='flex items-center gap-1' onClick={() => navigate('/')}>
          <ChevronLeftIcon className='w-6' />
          <p>Back</p>
        </button>
        <div className='flex flex-col gap-y-2'>
          <h1 className='font-bold'>Password Recovery</h1>
          <p>Enter your email address and we will send you the link to reset your password</p>
        </div>
        <FormikProvider value={formik}>
          <Form>
            <div className='flex flex-col gap-y-5 text-left '>
              <TextInputField name='email' label='Email' disabled={isLoading} placeholder='jonathansmith@example.com' />
              <Button type='submit' isLoading={isLoading} className='w-full'>
                Request
              </Button>
            </div>
          </Form>
        </FormikProvider>
        {isSuccess && (
          <div className='mt-4 flex items-center gap-1 text-left text-green-500'>
            <CheckIcon className='w-5' />
            <p>An Email has been sent with a link to reset your password</p>
          </div>
        )}
        {error && <ErrorMessage>{(error as APIError)?.data.message || 'something went wrong, try again later...'}</ErrorMessage>}
      </div>
    </div>
  );
};

export default ForgotPasswordPage;

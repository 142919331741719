import { ResetPasswordForm, GoogleSSOButton } from '@/components';
import { useResetPasswordMutation } from '@/store/users/users.api';
import { useLoginWithProviderMutation } from '@/store/auth/auth.api';
import { CodeResponse } from '@react-oauth/google';
import { useSelector } from 'react-redux';
import { selectAuthToken } from '@/store/auth/auth.selector';
import { FC, useCallback, useEffect } from 'react';
import { LoaderFunction, useLoaderData, useNavigate } from 'react-router-dom';
import linklaunch from '@/assets/images/linklaunch.png';

const UserInvitationPage: FC = () => {
  const { uid, token } = useLoaderData() as UserInvitationPageLoaderResult;
  const navigate = useNavigate();
  const authToken = useSelector(selectAuthToken);
  const [resetPassword, { isSuccess, error, isLoading }] = useResetPasswordMutation();
  const [loginWithProvider, { isLoading: loadingSSO, error: SSOError }] = useLoginWithProviderMutation();

  useEffect(() => {
    if (isSuccess) {
      return navigate('/reset-password-success');
    }

    if (authToken) {
      return navigate('/', { replace: true });
    }
  }, [isSuccess, navigate, authToken]);

  const handleSubmit = useCallback(
    async (values: ResetPasswordFormValues) => {
      await resetPassword({ ...values, uid: uid, token: token || '' });
    },
    [resetPassword, uid, token],
  );

  const handleLogin = useCallback(
    async (response: CodeResponse) => {
      await loginWithProvider({ credential: response.code, uid, resetPasswordToken: token });
    },
    [loginWithProvider, uid, token],
  );

  return (
    <div className='flex w-screen flex-col items-center gap-y-10 pt-48'>
      <div className='flex w-full max-w-md flex-col items-center justify-center gap-5 rounded-lg bg-white p-6 shadow-xl'>
        <img src={linklaunch} className='w-80' />
        <section className='flex w-full flex-col gap-y-5 text-left'>
          <h1 className='font-bold'>Welcome to Link Launch!</h1>
          <p>
            You&apos;ve been granted access through an invitation. <br /> To proceed, please establish a password for your account.
          </p>
          <ResetPasswordForm onSubmit={handleSubmit} isLoading={isLoading} error={(error || SSOError) as APIError} />
        </section>
        <div className='flex w-full items-center gap-2'>
          <div className='h-px w-full bg-gray-300'></div>
          <p>or</p>
          <div className='h-px w-full bg-gray-300'></div>
        </div>
        <GoogleSSOButton onClick={handleLogin} isLoading={isLoading || loadingSSO} />
      </div>
    </div>
  );
};

interface UserInvitationPageLoaderResult {
  uid: string;
  token: string;
}

const userInvitationPageLoader: LoaderFunction = ({ params }) => {
  const { uid } = params as { uid: string };
  const searchParams = new URLSearchParams(document.location.search);

  if (!searchParams.has('token') || !uid) {
    window.location.href = '/login';
    return null;
  }

  return {
    uid: uid,
    token: searchParams.get('token'),
  };
};

export default UserInvitationPage;
export { userInvitationPageLoader };

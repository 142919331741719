import { Avatar, Menu } from '@/components';
import { useToggle } from '@/hooks/toggle';
import EditUserModal from '@/pages/UserManagementPage/components/EditUserModal';
import { useGetCurrentUserQuery, useRemoveUserPictureMutation, useUpdateUserMutation } from '@/store/users/users.api';
import { PencilIcon, CameraIcon } from '@heroicons/react/24/solid';
import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import UploadImageModal from './UploadImageModal';
import RemoveImageModal from './RemoveImageModal';

const ProfileSection: FC = () => {
  const { data: currentUser } = useGetCurrentUserQuery();
  const [updateUser] = useUpdateUserMutation();
  const [removeProfilePicture, { isLoading: isRemovingPicture }] = useRemoveUserPictureMutation();

  const [user, setUser] = useState<User | undefined>();
  const [updateError, setUpdateError] = useState<APIError | undefined>();
  const { value: isEditModalOpen, toggle: toggleEditModal } = useToggle();
  const { value: isProfilePictureUploadModalOpen, toggle: toggleProfilePictureUploadModal } = useToggle();
  const { value: isRemoveProfilePictureModalOpen, toggle: toggleRemoveProfilePictureModal } = useToggle();

  useEffect(() => {
    setUser(currentUser);
  }, [currentUser]);

  const { name, email, role, companyName } = useMemo(() => {
    if (!user) {
      return {
        name: '',
        email: '',
        role: '',
        companyName: '',
      };
    }
    return {
      name: user.fullName,
      email: user.email,
      role: user.company?.role || '-',
      companyName: user.company?.name || '-',
    };
  }, [user]);

  const handleEditConfirm = async (values: EditModalUserValues) => {
    if (!user) {
      return;
    }

    try {
      const { data: updatedUser } = await updateUser({
        id: user.id,
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        company: values.company
          ? {
              id: values.company.id,
              role: values.company.role,
            }
          : undefined,
      }).unwrap();

      setUser(updatedUser);
      toggleEditModal(false);
    } catch (upadteError) {
      setUpdateError(updateError);
    }
  };

  const menuItems = useMemo(() => {
    if (!currentUser) {
      return [];
    }

    if (currentUser.profilePictureUrl) {
      return [
        { label: 'Change Profile Photo', onClick: () => toggleProfilePictureUploadModal(true) },
        {
          label: (
            <span>
              Remove <span className=' text-gray-300'>(Use Initials Avatar)</span>
            </span>
          ),
          onClick: () => toggleRemoveProfilePictureModal(true),
        },
      ];
    }

    if (!currentUser.profilePictureUrl) {
      return [{ label: 'Upload Profile Photo', onClick: () => toggleProfilePictureUploadModal(true) }];
    }

    return [];
  }, [toggleProfilePictureUploadModal, toggleRemoveProfilePictureModal, currentUser]);

  const renderAvatar = useMemo(() => {
    if (!user) {
      return;
    }

    return (
      <Fragment>
        <Avatar user={user} displayName={false} className='h-24 w-24 text-4xl' />
        <div className='relative bottom-6 left-16 flex h-6 w-6 cursor-pointer justify-center rounded-full bg-blue-300'>
          <Menu items={menuItems} direction='right' className='absolute left-1 rounded-full py-1' menuIcon={<CameraIcon className='w-4' color='#0284C7' />} />
        </div>
      </Fragment>
    );
  }, [user, menuItems]);

  const handleUploadModalClose = () => {
    toggleProfilePictureUploadModal(false);
  };

  const handleRemoveProfilePicture = async () => {
    await removeProfilePicture();
    toggleRemoveProfilePictureModal(false);
  };

  return (
    <div className='flex w-1/2 max-w-[41rem] flex-col rounded-b-lg shadow-sm'>
      {isEditModalOpen && user && (
        <EditUserModal user={user} onConfirm={handleEditConfirm} isOpen={isEditModalOpen} onClose={() => toggleEditModal(false)} errorMessage={updateError?.data.message} />
      )}

      {isProfilePictureUploadModalOpen && <UploadImageModal isOpen={true} onClose={handleUploadModalClose} />}
      {isRemoveProfilePictureModalOpen && (
        <RemoveImageModal isOpen={true} onClose={() => toggleRemoveProfilePictureModal(false)} onConfirm={handleRemoveProfilePicture} isLoading={isRemovingPicture} />
      )}

      <div className='ounded-t-lg relative border-b border-slate-300'>
        <div className='h-32 rounded-t-lg bg-blue-100'>
          <div className='flex items-center justify-end gap-2 p-4'>
            <button className='flex items-center gap-2' onClick={() => toggleEditModal(true)}>
              <PencilIcon className='h-6 w-6 text-slate-400' />
              Edit Info
            </button>
          </div>
        </div>
        <div className='flex h-32 w-full flex-col items-center justify-center gap-2 bg-white pt-5'>
          <p className='text-2xl leading-8'>{name}</p>
          <p className='text-sm leading-6 text-slate-400'>{email}</p>
        </div>
        <div className='absolute left-[calc(50%-3rem)] top-[calc(50%-4rem)]'>{renderAvatar}</div>
      </div>

      <div className='flex w-full flex-nowrap items-center rounded-b-lg bg-white pb-6 pt-5'>
        <div className='w-1/2 border-r border-slate-300'>
          <p className='text-sm leading-6 text-slate-400'>Role</p>
          <p className='text-2xl leading-8'>{role}</p>
        </div>
        <div className='w-1/2'>
          <p className='text-sm leading-6 text-slate-400'>Company</p>
          <p className='text-2xl leading-8'>{companyName}</p>
        </div>
      </div>
    </div>
  );
};

export default ProfileSection;

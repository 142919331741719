import { Button, ErrorMessage, TextInputField } from '@/components';
import { Form, FormikProvider, useFormik } from 'formik';
import { FC } from 'react';
import * as yup from 'yup';

interface LoginFormProps {
  isLoading: boolean;
  onSubmit: (values: LoginValues) => void;
  error: APIError;
}

const LoginValidationSchema: yup.SchemaOf<LoginValues> = yup.object({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

const LoginForm: FC<LoginFormProps> = ({ isLoading, onSubmit, error }) => {
  const formik = useFormik<LoginValues>({
    onSubmit,
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LoginValidationSchema,
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <div className='flex flex-col gap-y-5 text-left '>
          <TextInputField name='email' label='Email' disabled={isLoading} />
          <div className='relative'>
            <TextInputField name='password' label='Password' type='password' disabled={isLoading} />
            <a href='/forgot-password' className='absolute right-0 top-0 cursor-pointer text-sky-600 underline-offset-4 hover:text-sky-700 hover:underline active:text-sky-800'>
              Forgot password?
            </a>
          </div>
          {error && <ErrorMessage>{error.data ? error.data.message : 'Login Error!'}</ErrorMessage>}
          <Button type='submit' isLoading={isLoading} className='w-full'>
            Login
          </Button>
        </div>
      </Form>
    </FormikProvider>
  );
};

export default LoginForm;

import { Input } from '@/components';
import useDebounce from '@/hooks/debounce';
import cn from '@/utils/style';
import { ArrowPathIcon } from '@heroicons/react/20/solid';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { FC, useEffect, useState } from 'react';

interface SearchBarDebounceProps {
  onChange: (value: string) => void;
  initialValue?: string;
  className?: string;
  inputClassName?: string;
  disabled?: boolean;
  delay?: number;
  isFetching?: boolean;
}

const SearchBarDebounce: FC<SearchBarDebounceProps> = ({ initialValue = '', delay = 2000, isFetching = false, onChange, className, inputClassName, disabled }) => {
  const [searchVal, setSearchVal] = useState(initialValue);
  const debouncedSearchValue = useDebounce(searchVal, delay);

  useEffect(() => {
    onChange(debouncedSearchValue);
  }, [debouncedSearchValue, onChange]);

  return (
    <div className={cn('flex gap-2', className)}>
      <Input
        value={searchVal}
        onChange={(val) => setSearchVal(val)}
        placeholder='Search'
        className={cn('h-7 w-56 pl-7', inputClassName)}
        containerClassName={inputClassName}
        disabled={disabled}
        Icon={<MagnifyingGlassIcon className='absolute left-2 w-4' />}
      />
      {isFetching && <ArrowPathIcon className='w-5 animate-spin opacity-90' />}
    </div>
  );
};

export default SearchBarDebounce;

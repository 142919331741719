import { getAuthToken, getAuthUser, setAuthToken, setAuthUser } from '@/utils/localstorage';
import { googleLogout } from '@react-oauth/google';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../api';
import { deserializeUser } from '../users/users.api';
import authAPI from './auth.api';

const logout = createAsyncThunk('auth/logout', (_, { dispatch }) => {
  localStorage.clear();
  googleLogout();
  dispatch(api.util.resetApiState());
});

const initialState: AuthState = {
  token: null,
  isInitialized: false,
  user: null,
};

const setAuthUserAndToken = (payload: LoginResponse) => {
  setAuthToken(payload.access);
  setAuthUser(payload.user);

  return payload.access;
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    fetchAccessToken: (state) => {
      const authUser = getAuthUser();

      state.token = getAuthToken();
      state.user = authUser ? deserializeUser(authUser) : null;
      state.isInitialized = true;
    },
    updateToken: (state, action) => {
      state.token = action.payload.token;
      setAuthToken(action.payload.token);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, (state, _) => {
      state.token = null;
      state.user = null;
    });
    builder.addMatcher(authAPI.endpoints.login.matchFulfilled, (state, { payload }) => {
      state.token = setAuthUserAndToken(payload);
    });
    builder.addMatcher(authAPI.endpoints.loginWithProvider.matchFulfilled, (state, { payload }) => {
      state.token = setAuthUserAndToken(payload);
    });
  },
});

const { fetchAccessToken, updateToken } = authSlice.actions;

export { fetchAccessToken, logout, updateToken };
export default authSlice;

import { Button, NumberInputField } from '@/components';
import { round } from '@/utils/numbers';
import { Form, FormikProvider, useFormik } from 'formik';
import { FC } from 'react';
import * as yup from 'yup';

interface CltvCvrFormProps {
  isLoading: boolean;
  onSubmit: (values: Partial<CompanyConfig>) => void;
  company: Company;
}

type CltvCvrFormValues = {
  default_cltv: number;
  default_cvr: number;
};

const CltvCvrFormValidationSchema: yup.SchemaOf<CltvCvrFormValues> = yup.object({
  default_cltv: yup.number().min(1, 'Must be greater than 1').required(),
  default_cvr: yup.number().min(0, 'Must be greater than 0').max(100, 'Must be less than 100').required(),
});

const CltvCvrForm: FC<CltvCvrFormProps> = ({ isLoading, onSubmit, company }) => {
  const formikCltvCvr = useFormik<CltvCvrFormValues>({
    onSubmit: (values) => onSubmit({ ...values, default_cvr: Math.round(values.default_cvr * 100) / 10000 }),
    validationSchema: CltvCvrFormValidationSchema,
    initialValues: {
      default_cltv: company.config.default_cltv,
      default_cvr: round(Number(company.config.default_cvr) * 1000000, 0) / 10000,
    },
    enableReinitialize: true,
  });

  return (
    <div className='my-4 flex w-full flex-wrap rounded-leap bg-white px-4 shadow-md'>
      <p className='mt-4 text-left text-xl font-bold'>CLTV / CVR</p>
      <FormikProvider value={formikCltvCvr}>
        <Form className='mx-4 w-full'>
          <div className='mb-4 mt-12 flex'>
            <div className='mx-1 my-1 w-1/2'>
              <p className='text-left'>CLTV</p>
              <NumberInputField name='default_cltv' placeholder='Enter CLTV' suffix='$' className='bg-base-leap' containerClassName='my-2' />
            </div>
            <div className='mx-1 my-1 w-1/2'>
              <p className='text-left'>CVR</p>
              <NumberInputField name='default_cvr' placeholder='Enter CVR' suffix='%' className='bg-base-leap' containerClassName='my-2' />
            </div>
          </div>
          <div className='mb-6 flex justify-end'>
            {formikCltvCvr.dirty && (
              <Button type='submit' isLoading={isLoading}>
                Save
              </Button>
            )}
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default CltvCvrForm;

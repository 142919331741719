import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import ScenarioReviewContext, { ScenarioReviewContextValues, initialValues } from './ScenarioReviewContext';
import { getStateItemUpdater } from '@/utils/arrayStateItem';

const ScenarioReviewProvider: FC<PropsWithChildren> = ({ children }) => {
  const [scenarios, setScenarios] = useState(initialValues.scenarios);
  const [selectedROI, setSelectedROI] = useState(initialValues.selectedROI);
  const [isDirty, setIsDirty] = useState(initialValues.isDirty);
  const approvedScenario = useMemo(() => scenarios.find((s) => s.is_approved), [scenarios]);

  const setApprovedScenario = useCallback((scenarioId: number) => {
    setIsDirty(true);
    setScenarios((oldScenarios) => {
      return oldScenarios.map((s) => ({
        ...s,
        is_approved: s.id === scenarioId ? !s.is_approved : false,
      }));
    });
  }, []);

  const updateScenarios = useCallback((newScenario: Scenario) => {
    setIsDirty(true);
    setScenarios(getStateItemUpdater(newScenario, (a, b) => a.id === b.id));
  }, []);

  const contextValue: ScenarioReviewContextValues = {
    isDirty,
    setIsDirty,

    scenarios,
    setScenarios,
    updateScenarios,

    approvedScenario,
    setApprovedScenario,

    selectedROI,
    setSelectedROI,
  };

  return <ScenarioReviewContext.Provider value={contextValue}>{children}</ScenarioReviewContext.Provider>;
};

export default ScenarioReviewProvider;

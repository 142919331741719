import { Modal, ModalProps, SelectField, TextInputField } from '@/components';
import { useGetCountriesQuery } from '@/store/campaign/campaign.api';
import { getCountryOptions } from '@/utils/countries';
import { FormikProvider, useFormik } from 'formik';
import { FC, useMemo } from 'react';
import { Form } from 'react-router-dom';
import * as yup from 'yup';

interface CustomerModalProps extends Omit<ModalProps, 'onConfirm'> {
  onConfirm: (values: CustomerForm) => void;
  userEmail: string;
}

const billingInfoValidationSchema: yup.SchemaOf<CustomerForm> = yup.object({
  email: yup.string().email().required('email is required'),
  name: yup.string().required('name is required'),
  line1: yup.string().required('address is required'),
  city: yup.string().required('city is required'),
  postal_code: yup.string().required('postal code is required'),
  state: yup.string().required('state is required'),
  country: yup.string().required('country is required'),
});

const CustomerModal: FC<CustomerModalProps> = ({ isOpen, onClose, isLoading, onConfirm, userEmail }) => {
  const { data: countries, isLoading: isCountriesLoading } = useGetCountriesQuery();

  const countriesOptions = useMemo(() => {
    if (!countries?.results) return [];
    return getCountryOptions(countries.results);
  }, [countries]);

  const formik = useFormik<CustomerForm>({
    onSubmit: onConfirm,
    initialValues: {
      email: userEmail,
      name: '',
      line1: '',
      city: '',
      postal_code: '',
      state: '',
      country: '',
    },
    validationSchema: billingInfoValidationSchema,
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} title='Billing Information' className='w-modal' isLoading={isLoading || isCountriesLoading} onConfirm={formik.handleSubmit}>
      <FormikProvider value={formik}>
        <Form>
          <div className='flex flex-col gap-y-6'>
            <TextInputField name='email' label='Email' />
            <TextInputField name='name' label='Name' />
            <TextInputField name='line1' label='Address' />
            <TextInputField name='city' label='City' />
            <TextInputField name='postal_code' label='Postal Code' />
            <TextInputField name='state' label='State' />
            <SelectField name='country' label='Country' options={countriesOptions} />
          </div>
        </Form>
      </FormikProvider>
    </Modal>
  );
};

export default CustomerModal;

import { Button, Label, Modal, ModalProps, Select, Switch } from '@/components';
import { TERM_OPTIONS_JSON } from '@/constants/defaultValues';
import { useGetCampaignQuery } from '@/store/campaign/campaign.api';
import { getBaseUrl } from '@/utils/urls';
import { PlayIcon, Square2StackIcon } from '@heroicons/react/24/solid';
import copy from 'copy-to-clipboard';
import { FC, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetCurrentUserQuery } from '@/store/users/users.api';

const PresentationModal: FC<ModalProps> = ({ isOpen, onClose }) => {
  const { campaignId } = useParams() as unknown as { campaignId: string };
  const { data: campaign } = useGetCampaignQuery({ campaignId });
  const { data: user } = useGetCurrentUserQuery();
  const showROI = user && user.company && user.company.config.roi_visibility;

  const [displayProjectROI, setDisplayProjectROI] = useState(true);
  const [termValue, setTermValue] = useState(TERM_OPTIONS_JSON[2].value);

  const linkToPresentation = useMemo(() => {
    if (displayProjectROI && showROI) {
      return `${getBaseUrl()}/presentation/${campaign?.id}/${termValue}?vt=${campaign?.view_token}&show-roi=true`;
    }

    return `${getBaseUrl()}/presentation/${campaign?.id}?vt=${campaign?.view_token}`;
  }, [termValue, displayProjectROI, campaign, showROI]);

  const handleSwitchChange = useCallback(() => setDisplayProjectROI((old) => !old), []);
  const handleTermChange = useCallback((value: keyof ROISummary) => setTermValue(value), []);

  const handleStartPresentation = useCallback(() => window.open(linkToPresentation, '_blank'), [linkToPresentation]);
  const handleCopyToClipboard = useCallback(() => copy(linkToPresentation), [linkToPresentation]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title='Presentation Mode' className='w-modal'>
      <div className='flex flex-col flex-nowrap gap-6'>
        {showROI && (
          <div className='flex flex-row flex-nowrap items-center justify-between gap-2'>
            <Label>Display Project ROI in presentation</Label>
            <Switch value={displayProjectROI} onChange={handleSwitchChange} />
          </div>
        )}

        {displayProjectROI && showROI && (
          <div>
            <Label>Timeline</Label>
            <Select value={termValue} onChange={handleTermChange} options={TERM_OPTIONS_JSON} disabled={!displayProjectROI} />
          </div>
        )}
      </div>

      <div className='mt-7 flex gap-5'>
        <Button variant='outline-light' className='flex w-full items-center justify-center gap-2' onClick={handleCopyToClipboard}>
          <Square2StackIcon className='w-5' />
          <p>Copy Link</p>
        </Button>
        <Button variant='outline-light' className='flex w-full items-center justify-center gap-2' onClick={handleStartPresentation}>
          <PlayIcon className='w-5' />
          Start Presentation
        </Button>
      </div>
    </Modal>
  );
};

export default PresentationModal;

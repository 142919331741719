import { Skeleton } from '@/components';
import { FC } from 'react';

const URLDetailTopBarSkeleton: FC = () => (
  <nav className='w-full border-b border-b-slate-300 px-8 py-6'>
    <Skeleton className='h-[36px] w-1/2 animate-pulse' />
  </nav>
);

const URLDetailSkeleton: FC = () => (
  <div className='flex w-full gap-6'>
    <Skeleton className='h-[90px] w-full animate-pulse' />
    <Skeleton className='h-[90px] w-full animate-pulse' />
  </div>
);

const URLAudienceSkeleton: FC = () => (
  <div className='w-full'>
    <Skeleton className='mt-6 h-[100px] w-full animate-pulse' />
    <Skeleton className='mt-6 h-[100px] w-full animate-pulse' />
    <Skeleton className='mt-6 h-[100px] w-full animate-pulse' />
    <Skeleton className='mt-6 h-[100px] w-full animate-pulse' />
  </div>
);

export { URLDetailSkeleton, URLAudienceSkeleton, URLDetailTopBarSkeleton };

import { useGetCompanyListQuery } from '@/store/company/company.api';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import CompaniesTable from '../../components/CompaniesTable';
import { EditCompanyModal } from '../../components';
import { useToggle } from '@/hooks/toggle';
import DeleteCompanyModal from '../../components/DeleteCompanyModal';
import CompaniesPageSkeleton from './CompaniesPageSkeleton';
import { ErrorFallback } from '@/components';

const CompaniesPage = () => {
  const { isLoading, data, isError } = useGetCompanyListQuery();
  const { value: isEditCompanyModalOpen, toggle: toggleEditCompanyModal } = useToggle();
  const { value: isDeleteCompanyModalOpen, toggle: toggleDeleteCompanyModalOpen } = useToggle();

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes('edit-company')) {
      toggleEditCompanyModal(true);
      return;
    }

    toggleEditCompanyModal(false);
  }, [location, toggleEditCompanyModal]);

  useEffect(() => {
    if (location.pathname.includes('delete-company')) {
      toggleDeleteCompanyModalOpen(true);
      return;
    }

    toggleDeleteCompanyModalOpen(false);
  }, [location, toggleDeleteCompanyModalOpen]);

  if (isLoading) {
    return <CompaniesPageSkeleton />;
  }

  if (isError) {
    <ErrorFallback />;
  }

  return (
    <div className='h-full'>
      <CompaniesTable companies={data || []} />
      {isEditCompanyModalOpen && <EditCompanyModal isOpen={isEditCompanyModalOpen} />}
      {isDeleteCompanyModalOpen && <DeleteCompanyModal isOpen={isDeleteCompanyModalOpen} />}
    </div>
  );
};

export default CompaniesPage;

import { Modal, ModalProps } from '@/components';

const AllKwsExcludedModal = ({ isOpen, onConfirm, onClose }: ModalProps) => {
  return (
    <Modal isOpen={isOpen} onConfirm={onConfirm} onClose={onClose} title='All keywords are excluded'>
      <div className='p-8'>
        <p className='mt-4'>You are about to exclude all keywords for this target page. Are you sure you want to proceed?</p>
      </div>
    </Modal>
  );
};

export default AllKwsExcludedModal;

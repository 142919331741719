import { getAppConfig, setAppConfig } from '@/utils/localstorage';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const appSlice = createSlice({
  name: 'App',
  initialState: getAppConfig(),
  reducers: {
    setTableConfig(state, { payload }: PayloadAction<TableConfigPayload>) {
      const { name, config } = payload;
      state.tables[name].visibility = config.visibility;
      if (config.sorting) {
        state.tables[name].sorting = config.sorting;
      }
      setAppConfig(state);
    },
    setNavBarConfig(state, { payload }: PayloadAction<NavBarConfigPayload>) {
      state.navBar = { ...state.navBar, ...payload };
      setAppConfig(state);
    },
    setSideBarStatus(state, { payload }: PayloadAction<boolean>) {
      state.sideBar = { ...state.sideBar, isOpen: payload };
      setAppConfig(state);
    },
    setSendgridConfig(state, { payload }: PayloadAction<SendGridConfig>) {
      if (state.sendgrid.find((item) => item.jobId === payload.jobId)) {
        return;
      }
      state.sendgrid.push(payload);
    },
    removeFromSendgridConfig(state, { payload }: PayloadAction<string>) {
      state.sendgrid = state.sendgrid.filter((item) => item.jobId !== payload);
    },
  },
});

const { setTableConfig, setNavBarConfig, setSideBarStatus, setSendgridConfig, removeFromSendgridConfig } = appSlice.actions;

export { setTableConfig, setNavBarConfig, setSideBarStatus, setSendgridConfig, removeFromSendgridConfig };
export default appSlice;

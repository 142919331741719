import { Duration, parse } from 'date-fns';
import intervalToDuration from 'date-fns/intervalToDuration';
import format from 'date-fns/format';

const DEFAULT_FORMAT = 'MM/dd/yy';

const getDateWithDefaultFormat = (date: string) => format(new Date(date), DEFAULT_FORMAT);
const getDateWithFormat = (date: string, outputFormat: string) => format(new Date(date), outputFormat || DEFAULT_FORMAT);

const dateFromString = (inputString: string, format: string = 'MM-dd-yyyy') => {
  return parse(inputString, format, new Date());
};

const secondsToDuration = (seconds: number): Duration => {
  const epoch = new Date(0);
  const secondsAfterEpoch = new Date(seconds * 1000);
  return intervalToDuration({ start: epoch, end: secondsAfterEpoch });
};

const secondsToTime = (seconds: number): string => {
  const duration = secondsToDuration(seconds);
  return `${duration.hours}:${duration.minutes}:${duration.seconds}`.replace(/\b(\d)\b/g, '0$1');
};

export { getDateWithDefaultFormat, getDateWithFormat, DEFAULT_FORMAT, secondsToTime, dateFromString };

import { Button, Checkbox, Modal } from '@/components';
import { useDeleteCampaignAnchorTextsMutation, useSaveCampaignConfigMutation } from '@/store/campaign/campaign.api';
import { selectCampaign } from '@/store/campaign/campaign.selector';
import { updateCampaignScenarioMutation } from '@/store/reducers';
import { AppDispatch } from '@/store/store';
import { FC, Fragment, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

type SelectUrlFieldProps = {
  scenario: Scenario;
  urlId: number;
  hasAnchorsText?: boolean;
  updateScenarioUrls: () => void;
  disabled?: boolean;
};

const SelectUrlField: FC<SelectUrlFieldProps> = ({ scenario, urlId, hasAnchorsText, updateScenarioUrls, disabled }) => {
  const { campaignId } = useParams() as { campaignId: string };
  const campaign = useSelector(selectCampaign);
  const readOnly = campaign?.status === 'kicked_off' || disabled;
  const dispatch = useDispatch<AppDispatch>();

  const [deleteAnchors] = useDeleteCampaignAnchorTextsMutation();
  const [updateCampaignConfig] = useSaveCampaignConfigMutation();

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const isSelected = useMemo(() => {
    const targetUrls = scenario.urls.map((i) => i.url_id);
    return targetUrls.includes(urlId);
  }, [scenario, urlId]);

  const handleCheckboxChange = useCallback(() => {
    if (hasAnchorsText || scenario.is_approved) {
      return setConfirmModalOpen(true);
    }

    updateScenarioUrls();
  }, [updateScenarioUrls, hasAnchorsText, scenario.is_approved]);

  const handleModalConfirm = useCallback(async () => {
    await deleteAnchors({ campaignId }).unwrap();
    if (scenario.is_approved) {
      await dispatch(updateCampaignScenarioMutation({ campaignId, scenario: { ...scenario, is_approved: false } }));
    }
    updateScenarioUrls();
    await updateCampaignConfig({ campaignId, config: { user_progress: { last_step: 'analysis' } } });
  }, [campaignId, deleteAnchors, updateCampaignConfig, updateScenarioUrls, scenario, dispatch]);

  return (
    <Fragment>
      <Checkbox checked={isSelected} onChange={handleCheckboxChange} disabled={readOnly} />
      {confirmModalOpen && (
        <Modal onClose={() => setConfirmModalOpen(false)} isOpen={confirmModalOpen} title=''>
          <div className='mb-4 flex w-[450px] flex-col items-center gap-4'>
            <h4 className='text-center'>Are you sure you want to change this scenario?</h4>
            <p className='text-center'>If you do, all of your anchor text progress will be lost. This action cannot be undone.</p>
            <Button className='w-full' onClick={handleModalConfirm}>
              Continue and Override
            </Button>
            <Button className='w-full' onClick={() => setConfirmModalOpen(false)}>
              Cancel
            </Button>
          </div>
        </Modal>
      )}
    </Fragment>
  );
};

export default SelectUrlField;

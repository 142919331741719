import { Button } from '@/components';
import { selectAuthUser } from '@/store/auth/auth.selector';
import { TrashIcon } from '@heroicons/react/20/solid';
import { createColumnHelper, Row, Table } from '@tanstack/react-table';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

const columnHelper = createColumnHelper<LIRReport>();

const handleDownload = async (row: Row<LIRReport>) => {
  if (!row.original.report_url) return;
  const response = await fetch(row.original.report_url);
  const blob = await response.blob();
  const blobUrl = window.URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = blobUrl;
  a.download = row.original.name;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

type Meta = {
  setCurrentReport: (report: LIRReport) => void;
  toggleDeleteModal: (value: boolean) => void;
};

const useReportsTableColumns = () => {
  const authUser = useSelector(selectAuthUser);
  const userId = authUser?.id;

  const handleReportDelete = useCallback((table: Table<LIRReport>, row: Row<LIRReport>) => {
    const tableMeta = table.options.meta as Meta;

    tableMeta.setCurrentReport(row.original);
    tableMeta.toggleDeleteModal(true);
  }, []);

  return useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Name',
        cell: ({ row }) => {
          const reportUrl = row.original.report_url;

          return (
            <button onClick={() => handleDownload(row)} className={reportUrl && 'cursor-pointer text-blue-500'}>
              {row.original.name}
            </button>
          );
        },
      }),
      columnHelper.accessor('description', {
        header: 'Description',
        cell: ({ row }) => row.original.description,
      }),
      columnHelper.accessor('created_at', {
        header: 'Date Added',
        cell: ({ row }) => new Date(row.original.created_at).toLocaleDateString(),
      }),
      columnHelper.accessor('created_by', {
        header: 'Created By',
        cell: ({ row }) => row.original.created_by?.email,
      }),
      columnHelper.display({
        id: 'action',
        cell: ({ table, row }) => {
          const canDelete = row.original.created_by?.id === userId;
          return (
            <Button variant='warning' disabled={!canDelete} onClick={() => handleReportDelete(table, row)}>
              <TrashIcon className='size-5' />
            </Button>
          );
        },
      }),
    ],
    [userId, handleReportDelete],
  );
};
export default useReportsTableColumns;

import { SVGProps } from 'react';

const AddScenarioIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox='0 0 32 32' fill='currentColor' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M18.625 2H5.20833C3.435 2 2 3.435 2 5.20833V23.2917C2 25.065 3.435 26.5 5.20833 26.5H12.8967C12.2667 25.17 11.9167 23.6883 11.9167 22.125C11.9167 20.7833 12.1733 19.5 12.6517 18.3217C12.605 18.3333 12.5583 18.3333 12.5 18.3333H6.66667C6.025 18.3333 5.5 17.8083 5.5 17.1667C5.5 16.525 6.025 16 6.66667 16H12.5C12.9433 16 13.34 16.2567 13.5267 16.63C14.285 15.4517 15.265 14.4483 16.42 13.6667H6.66667C6.025 13.6667 5.5 13.1417 5.5 12.5C5.5 11.8583 6.025 11.3333 6.66667 11.3333H17.1667C17.8083 11.3333 18.3333 11.8583 18.3333 12.5C18.3333 12.5583 18.3333 12.605 18.3217 12.6517C19.4067 12.2083 20.5967 11.9517 21.8333 11.9283V5.20833C21.8333 3.435 20.3983 2 18.625 2ZM11.3333 9H6.66667C6.025 9 5.5 8.475 5.5 7.83333C5.5 7.19167 6.025 6.66667 6.66667 6.66667H11.3333C11.975 6.66667 12.5 7.19167 12.5 7.83333C12.5 8.475 11.975 9 11.3333 9Z'
        fill='currentColor'
      />
      <path
        d='M22.125 14.25C17.7827 14.25 14.25 17.7827 14.25 22.125C14.25 26.4673 17.7827 30 22.125 30C26.4673 30 30 26.4673 30 22.125C30 17.7827 26.4673 14.25 22.125 14.25ZM25.3333 23.2917H23.2917V25.3333C23.2917 25.9773 22.769 26.5 22.125 26.5C21.481 26.5 20.9583 25.9773 20.9583 25.3333V23.2917H18.9167C18.2727 23.2917 17.75 22.769 17.75 22.125C17.75 21.481 18.2727 20.9583 18.9167 20.9583H20.9583V18.9167C20.9583 18.2727 21.481 17.75 22.125 17.75C22.769 17.75 23.2917 18.2727 23.2917 18.9167V20.9583H25.3333C25.9773 20.9583 26.5 21.481 26.5 22.125C26.5 22.769 25.9773 23.2917 25.3333 23.2917Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default AddScenarioIcon;

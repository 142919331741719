import { ReactNode, Suspense } from 'react';
import { Await } from 'react-router-dom';

type SuspenseWrapperProps<T> = {
  fallback?: ReactNode;
  resolve: Promise<T>;
  error?: APIError;
  children?: ReactNode | ((args: T) => ReactNode);
};

const SuspenseWrapper = <T extends unknown>({ children, fallback, resolve, error }: SuspenseWrapperProps<T>) => {
  const errorText = `something went wrong, ${error?.status} - ${error?.data.message}`;

  return (
    <Suspense fallback={fallback}>
      <Await resolve={resolve} errorElement={error && <p>{errorText}</p>}>
        {children}
      </Await>
    </Suspense>
  );
};

export default SuspenseWrapper;

import { useSteps } from '@/hooks/steps';
import { useToggle } from '@/hooks/toggle';
import { Page } from '@/layouts';
import campaignAPI, { useDeleteCampaignAnchorTextsMutation, useGetCampaignConfigQuery, useSaveCampaignConfigMutation } from '@/store/campaign/campaign.api';
import { selectScenarios, selectSteps } from '@/store/campaign/campaign.selector';
import { setIsDirty } from '@/store/campaign/campaign.slice';
import store, { AppDispatch } from '@/store/store';
import { FC, Suspense, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Await, LoaderFunction, defer, useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { AnalysisFooter, AnalysisTopbar } from '../../components';
import ScenarioReviewPageSkeleton from './ScenarioReviewPageSkeleton';
import { ScenarioChangeWarningModal, ScenarioReviewHeader, ScenarioReviewTabs } from './components';
import { updateCampaignScenarioMutation } from '@/store/reducers';

type ScenarioReviewData = [CampaignJSON, APIListResponse<Scenario>];

const scenarioReviewLoader: LoaderFunction = async ({ params }) => {
  const { campaignId } = params as AnalyisisPageParams;
  const scenariosPromise = store.dispatch(campaignAPI.endpoints.getCampaignScenarios.initiate({ campaignId }, { forceRefetch: true }));
  const campaignPromise = store.dispatch(campaignAPI.endpoints.getCampaign.initiate({ campaignId }, { forceRefetch: true }));

  try {
    const data = Promise.all([campaignPromise.unwrap(), scenariosPromise.unwrap()]);
    return defer({ data });
  } catch (e) {
    return {
      campaignId,
      error: e as APIError,
    };
  } finally {
    campaignPromise.unsubscribe();
    scenariosPromise.unsubscribe();
  }
};

const ScenarioReviewPage: FC = () => {
  const { campaignId } = useParams() as { campaignId: string };
  const { data, error } = useLoaderData() as PageLoaderDefData<ScenarioReviewData>;
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const isDirty = useSelector(selectSteps)['scenario-review'].isDirty;
  const scenarios = useSelector(selectScenarios);

  const { previousStep, nextStep } = useSteps();

  const { data: campaignConfig } = useGetCampaignConfigQuery({ campaignId });
  const [deleteAnchors] = useDeleteCampaignAnchorTextsMutation();
  // const [update] = useUpdateCampaignScenarioMutation();
  const [saveCampaignConfig] = useSaveCampaignConfigMutation();

  const errorMessage = `something went wrong, ${error?.status} - ${error?.data.message}`;
  const approvedScenario = useMemo(() => scenarios.find((s) => s.is_approved), [scenarios]);

  const [updateScenarioError, setUpdateScenarioError] = useState<APIError | undefined>(undefined);
  const { value: isWarningModalOpen, toggle: toggleWarningModal } = useToggle();

  const getDefaultApprovedScenario = useCallback(async () => {
    const [, defaultScenarios] = await data;

    return defaultScenarios.results.find((s) => s.is_approved);
  }, [data]);

  const updateScenario = useCallback(async () => {
    if (!approvedScenario) {
      setUpdateScenarioError({ data: { message: 'Please approve at least one scenario' } } as APIError);
      return;
    }

    try {
      return await dispatch(updateCampaignScenarioMutation({ campaignId, scenario: approvedScenario }));
    } catch (e) {
      console.log({ e });
      setUpdateScenarioError(e as APIError);
    }
  }, [approvedScenario, dispatch, campaignId]);

  const saveAndNavigate = async () => {
    const resp = await updateScenario();

    if (resp) {
      await saveCampaignConfig({ campaignId, config: { user_progress: { last_step: 'anchor-text-generator' } } }).unwrap();
      navigate(`../${nextStep}`);
    }
  };

  const handleSave = useCallback(async () => {
    const defaultApprovedScenario = await getDefaultApprovedScenario();
    if (campaignConfig?.data.has_anchor_texts && defaultApprovedScenario?.id !== approvedScenario?.id) {
      await deleteAnchors({ campaignId });
    }

    dispatch(setIsDirty({ step: 'scenario-review', isDirty: false }));
    await updateScenario();
  }, [campaignConfig, approvedScenario, getDefaultApprovedScenario, deleteAnchors, dispatch, updateScenario, campaignId]);

  const handleNext = async () => {
    const defaultApprovedScenario = await getDefaultApprovedScenario();
    if (campaignConfig?.data.has_anchor_texts && defaultApprovedScenario?.id !== approvedScenario?.id) {
      toggleWarningModal(true);
      return;
    }

    await saveAndNavigate();
  };

  const handlePrevious = () => navigate(`../${previousStep}`);

  const handleOverride = async () => {
    await deleteAnchors({ campaignId });
    await saveAndNavigate();
  };

  return (
    <Suspense fallback={<ScenarioReviewPageSkeleton />}>
      <Await resolve={data} errorElement={error && <p>{errorMessage}</p>}>
        <Page>
          <AnalysisTopbar onSave={handleSave} isDirty={isDirty} />
          <Page.Body>
            <ScenarioReviewHeader />
            <ScenarioReviewTabs />
          </Page.Body>
          <Page.Footer>
            <AnalysisFooter onSave={handleSave} onNext={handleNext} error={updateScenarioError} onPrevious={handlePrevious} />
            {isWarningModalOpen && <ScenarioChangeWarningModal isOpen={true} onClose={() => toggleWarningModal(false)} onOverride={handleOverride} />}
          </Page.Footer>
        </Page>
      </Await>
    </Suspense>
  );
};

export default ScenarioReviewPage;
export { scenarioReviewLoader };

import { Modal, ModalProps } from '@/components';
import { useGetCurrentUserQuery } from '@/store/users/users.api';
import { getEstimatedCampaignCost } from '@/utils/campaigns';
import { parseUrls } from '@/utils/urls';
import { FC, useMemo, useCallback } from 'react';
import { trackEvent } from '@/utils/fullstory';

interface ConfirmCampaignModal extends ModalProps {
  campaign: NewCampaignFormValues;
}

const ConfirmCampaignModal: FC<ConfirmCampaignModal> = ({ campaign, isOpen, onConfirm, onClose }) => {
  const { data: user } = useGetCurrentUserQuery();
  const userCredits = useMemo(() => {
    return user?.company?.credits.dataforseo || 0;
  }, [user]);
  const estimatedCost = useMemo(() => {
    const isCombination = campaign.combinations && campaign.combinations.length > 0;
    const urlsQuantity = isCombination ? campaign.combinations.length : parseUrls(campaign.urls_list || '').length;

    return getEstimatedCampaignCost({
      urlsQuantity: urlsQuantity,
      keywordsQuantity: campaign.keyword_count,
      competitorsQuantity: campaign.url_count,
    });
  }, [campaign]);

  const handleConfirm = useCallback(() => {
    trackEvent({
      name: 'Create Campaign Button Click',
      properties: {
        estimated_cost: estimatedCost,
        user_crediits: userCredits,
      },
      schema: {
        properties: {
          estimated_cost: 'real',
          user_crediits: 'real',
        },
      },
    });

    if (onConfirm) {
      onConfirm();
    }
  }, [onConfirm, estimatedCost, userCredits]);

  return (
    <Modal isOpen={isOpen} title='Confirm Credit Usage' onConfirm={handleConfirm} onClose={onClose} confirmButtonText='Create Campaign' className='max-w-modal'>
      <div className='flex flex-col gap-2'>
        <p>It is estimated this campaign will use the following credits:</p>
        <div className='bg-slate-100 p-3 font-bold'>
          <p>Available Credits: {userCredits || '-'}</p>
        </div>
        <div className='bg-slate-100 p-3 font-bold'>
          <p>Estimated Campaign Credits: {estimatedCost}</p>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmCampaignModal;

import { FC, Fragment, PropsWithChildren, ReactNode } from 'react';
import { Popover as HeadlessPopover, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import cn from '@/utils/style';

interface PopoverProps extends PropsWithChildren {
  title: string | ReactNode;
  className?: string;
  titleClassName?: string;
  showClose?: boolean;
  inner?: string | ReactNode;
}

const Popover: FC<PopoverProps> = ({ children, title, inner, className, titleClassName, showClose = false }) => {
  return (
    <HeadlessPopover className='relative'>
      {({ close }) => {
        return (
          <Fragment>
            <HeadlessPopover.Button className={titleClassName}>{title}</HeadlessPopover.Button>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'
            >
              <HeadlessPopover.Panel className={cn('absolute -left-0 z-10 mt-3 rounded-lg border border-slate-400 bg-white p-2 shadow-md', className)}>
                <div className='flex items-center justify-between px-4 pb-2'>
                  {inner}
                  {showClose ? <XMarkIcon onClick={() => close()} className='ml-auto w-6 cursor-pointer pb-1' /> : null}
                </div>
                {children}
              </HeadlessPopover.Panel>
            </Transition>
          </Fragment>
        );
      }}
    </HeadlessPopover>
  );
};

export default Popover;

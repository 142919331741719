import { Button } from '@/components';
import cn from '@/utils/style';
import { FC, PropsWithChildren } from 'react';

interface ExtraButton {
  title: string;
  onClick: () => void;
}

interface AnalysisCardProps extends PropsWithChildren {
  title?: string;
  className?: string;
  extraButton?: ExtraButton;
}

const AnalysisCard: FC<AnalysisCardProps> = ({ title, children, className, extraButton }) => {
  return (
    <div className={cn('h-[108px] rounded-leap bg-white p-4', className)}>
      <div className='flex flex-col items-start gap-3'>
        <span className='flex w-full items-center justify-between text-xl capitalize'>
          {title}
          {extraButton && (
            <Button onClick={() => extraButton.onClick()} className='text-base'>
              {extraButton.title}
            </Button>
          )}
        </span>
        <span className='w-full text-start text-xl text-sky-600'>{children}</span>
      </div>
    </div>
  );
};

export type { AnalysisCardProps };

export default AnalysisCard;

import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { PlayIcon } from '@heroicons/react/24/solid';
import { Fragment, ReactNode, useCallback } from 'react';
import Label from '../Label';
import cn from '@/utils/style';

interface SelectProps<T> {
  value: T;
  options: Array<OptionType<T>>;
  onChange: (value: T) => void;
  label?: string | ReactNode;
  className?: string;
  withMarker?: boolean;
  direction?: 'left' | 'right';
  optionsClassName?: string;
}

const SelectFilter = <T extends unknown>({ options, value, onChange, label, className = '', withMarker = false, optionsClassName = '', direction = 'left' }: SelectProps<T>) => {
  const getSelectedLabel = useCallback(
    (value: T) => {
      const opt = options.find((opt) => opt.value === value);
      return opt ? opt.label : '';
    },
    [options],
  );

  const renderOptions = (option: { label: string; value: T }) => {
    const renderContent = (selected: boolean, active: boolean) => (
      <Fragment>
        <p className={cn('z-10 block cursor-pointer', selected && 'font-medium', active && 'text-sky-600')}>{String(option.label)}</p>
        {withMarker && selected && (
          <span className='absolute inset-y-0 left-0 flex items-center pl-3 text-sky-500'>
            <CheckIcon className='w-5' />
          </span>
        )}
      </Fragment>
    );

    return (
      <Listbox.Option
        key={String(option.value)}
        value={option.value}
        className={({ selected, active }) =>
          cn('relative px-4 py-3 text-left text-gray-800', withMarker && 'pl-10 pr-4', selected && 'text-sky-600', active && 'bg-gray-50 first:rounded-t-lg last:rounded-b-lg')
        }
      >
        {({ selected, active }) => renderContent(selected, active)}
      </Listbox.Option>
    );
  };

  return (
    <div className='relative flex flex-row flex-nowrap items-center gap-2'>
      {label && <Label className='text-sm font-semibold text-gray-400'>{label}</Label>}
      <Listbox value={value} onChange={onChange}>
        <div>
          <Listbox.Button className={cn('group w-full p-1 outline-none hover:border-gray-300', className)}>
            <div className='flex items-center justify-between font-semibold '>
              <p>{getSelectedLabel(value)}</p>
              <PlayIcon className={'ml-1 w-3 rotate-90 text-gray-400 group-data-[headlessui-state=open]:rotate-[270deg]'} />
            </div>
          </Listbox.Button>
          <Transition as={Fragment} leave='transition ease-in duration-100' leaveFrom='opacity-100' leaveTo='opacity-0'>
            <Listbox.Options
              className={cn(
                'absolute z-50 mt-1 block max-h-60 w-full overflow-scroll rounded-lg bg-white ring-1 ring-black ring-opacity-5 last-child:border-b last-child-last:border-b-0',
                direction === 'left' && 'left-0',
                optionsClassName,
              )}
            >
              {options.map(renderOptions)}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

export default SelectFilter;
export type { SelectProps };

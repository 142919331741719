import { Suspense } from 'react';
import { Await, defer, LoaderFunction, useLoaderData, useNavigate } from 'react-router-dom';
import CampaignLogModalContent from './components/LogsContent';
import store from '@/store';
import campaignAPI from '@/store/campaign/campaign.api';
import LogsSkeleton from './LogsSkeleton';

const logsLoader: LoaderFunction = async ({ params }) => {
  const { campaignId } = params as AnalyisisPageParams;
  const campaignPromise = store.dispatch(campaignAPI.endpoints.getCampaign.initiate({ campaignId, include: ['runs', 'log'] }));

  try {
    return defer({ data: campaignPromise.unwrap() });
  } catch (e) {
    return {
      campaignId,
      error: e as APIError,
    };
  } finally {
    campaignPromise.unsubscribe();
  }
};

const Logs = () => {
  const { data: campaign, error } = useLoaderData() as PageLoaderDefData<CampaignJSON>;
  const navigate = useNavigate();

  return (
    <Suspense fallback={<LogsSkeleton />}>
      <Await resolve={campaign} errorElement={error && <p>{error.data.message}</p>}>
        {(campaign) => <CampaignLogModalContent isOpen onClose={() => navigate('../')} campaign={campaign} />}
      </Await>
    </Suspense>
  );
};

export default Logs;
export { logsLoader };

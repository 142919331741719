import { FC, Fragment } from 'react';
import CollapsableCard from '../CollapsableCards/CollapsableCard';
import { SeparatorLine } from '@/components';

interface SummaryCardProps {
  summary: LCRSummary;
}

const SummaryCard: FC<SummaryCardProps> = ({ summary }) => (
  <Fragment>
    <div className='p-6'>
      <CollapsableCard content={<h4>Summary</h4>} containerClass='bg-white mt-0'>
        <h5 className='my-4'>Themes</h5>
        <div className='max-h-[12rem] overflow-y-auto'>
          {summary.themes.map(({ theme, description }) => (
            <div className='mb-4 flex justify-between gap-2 align-baseline' key={`theme-${theme}`}>
              <span className='h-3 w-3 rounded-full bg-sky-600'></span>
              <div className='w-full'>
                <h5>{theme}</h5>
                <p className='text-slate-500'>{description}</p>
              </div>
            </div>
          ))}
        </div>
        <SeparatorLine />
        <h5 className='my-4'>Outliers</h5>
        <div className='max-h-[12rem] overflow-y-auto'>
          {summary.outliers.map(({ theme, description }) => (
            <div className='mb-4 flex justify-between gap-2 align-baseline' key={`outlier-${theme}`}>
              <span className='h-3 w-3 rounded-full bg-sky-600'></span>
              <div className='w-full'>
                <h5>{theme}</h5>
                <p className='text-slate-500'>{description}</p>
              </div>
            </div>
          ))}
        </div>
      </CollapsableCard>
    </div>
    <SeparatorLine />
  </Fragment>
);

export default SummaryCard;

import { useToggle } from '@/hooks/toggle';
import { useCreateUserFeedbackMutation } from '@/store/users/users.api';
import { FC, Fragment, useEffect } from 'react';
import FeedbackModal from '../FeedbackModal';
import { SideMenuItem } from '../SideBar/components';
import { EnvelopeIcon } from '@heroicons/react/24/solid';

interface FeedbackTrackerProps {
  isSidebarOpen: boolean;
}

const FeedbackTracker: FC<FeedbackTrackerProps> = ({ isSidebarOpen }) => {
  const { value: isModalOpen, toggle: toggleModal } = useToggle();
  const [submitFeedback, { isLoading, isSuccess, error }] = useCreateUserFeedbackMutation();

  useEffect(() => {
    if (isSuccess) {
      toggleModal(false);
    }
  }, [isSuccess, toggleModal]);

  const handleFeedbackSubmit = async (values: FeedbackFormValues) => {
    await submitFeedback(values);
  };

  return (
    <Fragment>
      <SideMenuItem isSidebarOpen={isSidebarOpen} onClick={() => toggleModal(true)} Icon={EnvelopeIcon} title='Contact Us' />
      {isModalOpen && (
        <FeedbackModal
          isOpen={isModalOpen}
          onConfirm={handleFeedbackSubmit}
          onClose={() => toggleModal(false)}
          errorMessage={(error as APIError)?.data.message}
          isLoading={isLoading}
        />
      )}
    </Fragment>
  );
};

export default FeedbackTracker;

import { ResetPasswordForm } from '@/components';
import { useResetPasswordMutation } from '@/store/users/users.api';
import { useCallback, useEffect } from 'react';
import { LoaderFunction, useLoaderData, useNavigate } from 'react-router-dom';
import linklaunch from '../../assets/images/linklaunch.png';

const ResetPasswordPage = () => {
  const { uid, token } = useLoaderData() as ResetPasswordPageLoaderResult;
  const navigate = useNavigate();
  const [resetPassword, { isSuccess, error, isLoading }] = useResetPasswordMutation();

  useEffect(() => {
    if (isSuccess) {
      navigate('/reset-password-success');
    }
  }, [isSuccess, navigate]);

  const handleSubmit = useCallback(
    async (values: ResetPasswordFormValues) => {
      await resetPassword({ ...values, uid: uid, token: token || '' });
    },
    [resetPassword, uid, token],
  );

  return (
    <div className='flex w-screen flex-col items-center gap-y-10 pt-48'>
      <div className='flex w-full max-w-md flex-col items-center justify-center gap-5 rounded-lg bg-white p-6 shadow-xl'>
        <img src={linklaunch} className='w-80' />
        <div className='w-full'>
          <ResetPasswordForm onSubmit={handleSubmit} isLoading={isLoading} error={error as APIError} />
        </div>
      </div>
    </div>
  );
};

interface ResetPasswordPageLoaderResult {
  uid: string;
  token: string;
}

const resetPasswordPageLoader: LoaderFunction = ({ params }) => {
  const { uid } = params as { uid: string };
  const searchParams = new URLSearchParams(document.location.search);

  if (!searchParams.has('token') || !uid) {
    window.location.href = '/login';
    return null;
  }

  return {
    uid: uid,
    token: searchParams.get('token'),
  };
};

export default ResetPasswordPage;
export { resetPasswordPageLoader };

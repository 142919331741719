import { Button, Modal, ModalProps } from '@/components';
import { FC } from 'react';

interface ReadyToReviewModalProps extends ModalProps {
  onReview: () => void;
}

const ReadyToReviewModal: FC<ReadyToReviewModalProps> = ({ isOpen, onClose, onReview }) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen} title=''>
      <div className='mb-4 flex w-[450px] flex-col items-center gap-4'>
        <h4 className='text-center'>Ready to Review Your Campaign?</h4>
        <p className='text-center'>{"We'll do a final review and confirm all your selections for your campaign"}</p>
        <Button className='w-full' onClick={onReview}>
          Review Complete Campaign
        </Button>
        <Button className='w-full' onClick={onClose}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default ReadyToReviewModal;

import { Modal, Button, ModalProps } from '@/components';
import { useDeleteCampaignMutation } from '@/store/campaign/campaign.api';
import { FC } from 'react';

interface DeleteCampaignModalProps extends ModalProps {
  campaignId: number;
}

const DeleteCampaignModal: FC<DeleteCampaignModalProps> = ({ isOpen, campaignId, onClose }) => {
  const [deleteCampaign, { isLoading }] = useDeleteCampaignMutation();

  const handleDelete = async () => {
    await deleteCampaign({ campaignId });
    onClose && onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      footer={
        <div className='flex w-full gap-4'>
          <Button onClick={onClose} className='w-full'>
            Cancel
          </Button>
          <Button className='w-full' variant='warning' onClick={handleDelete} isLoading={isLoading}>
            Delete
          </Button>
        </div>
      }
      title='Delete Campaign'
    >
      <p>Are you sure you want to delete this campaign?</p>
    </Modal>
  );
};

export default DeleteCampaignModal;

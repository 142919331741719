import { selectAuthToken, selectAuthUser, selectIsInitialized } from '@/store/auth/auth.selector';
import { fetchAccessToken, updateToken } from '@/store/auth/auth.slice';
import store from '@/store/store';
import { base62Decode } from '@/utils/baseX';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { LoaderFunction, Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import SocketConnector from './SocketConnector';
import { isInitialized as isFullstoryInitialized } from '@fullstory/browser';

import { Tutorial } from '@/components';
import { TutorialProvider } from '@/components/Tutorial/components';
import 'react-toastify/dist/ReactToastify.css';
import { setIdentity } from '@/utils/fullstory';

const RequireAuth: FC = () => {
  const token = useSelector(selectAuthToken);
  const authUser = useSelector(selectAuthUser);
  const navigate = useNavigate();
  const isInitialized = useSelector(selectIsInitialized);
  const [searchParams] = useSearchParams();
  const hasToken = token || searchParams.has('vt');
  const location = useLocation();

  useEffect(() => {
    if (!hasToken) {
      navigate('/login', { replace: true, state: { from: location } });
      return;
    }
  }, [hasToken, navigate, location]);

  useEffect(() => {
    if (isFullstoryInitialized() && authUser) {
      setIdentity(authUser);
    }
  }, [authUser]);

  if (!isInitialized || !hasToken) {
    return null;
  }

  return (
    <>
      {token ? <SocketConnector /> : null}
      <ToastContainer pauseOnHover newestOnTop />
      <TutorialProvider>
        <Tutorial />
        <Outlet />
      </TutorialProvider>
    </>
  );
};

const authLoader: LoaderFunction = async () => {
  const searchParams = new URLSearchParams(document.location.search);

  if (searchParams.has('t')) {
    const decodedToken = base62Decode(searchParams.get('t') || '');
    store.dispatch(updateToken({ token: decodedToken }));
  }

  const token = store.getState().auth.token;
  const user = store.getState().auth.user;

  if (!token || !user) {
    store.dispatch(fetchAccessToken());
  }

  return null;
};

export { authLoader };
export default RequireAuth;

import { Button, Loading, ModalProps } from '@/components';
import { FC } from 'react';

type ScenarioKeywordsModalFooterProps = {
  isSubmitting: boolean;
  disabled: boolean;
} & Pick<ModalProps, 'onClose' | 'onConfirm'>;

const ScenarioKeywordsModalFooter: FC<ScenarioKeywordsModalFooterProps> = ({ onClose, isSubmitting, onConfirm, disabled }) => (
  <div className={'flex w-full items-center gap-6'}>
    <Button variant='outline-light' onClick={onClose} disabled={isSubmitting} className='w-full'>
      Cancel
    </Button>
    <Button onClick={onConfirm} disabled={isSubmitting || disabled} className='w-full whitespace-nowrap'>
      {isSubmitting ? <Loading className='mx-auto h-5 w-5' /> : 'Confirm'}
    </Button>
  </div>
);

export default ScenarioKeywordsModalFooter;

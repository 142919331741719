import cn from '@/utils/style';
import { ChangeEventHandler, ClipboardEventHandler, ClipboardEvent, FC, FocusEventHandler, KeyboardEventHandler, ReactNode } from 'react';

interface InputProps {
  value?: string | number;
  onChange: (value: string) => void;
  placeholder?: string;
  onKeyUp?: (key: string, value: string) => void;
  className?: string;
  containerClassName?: string;
  hasError?: boolean;
  type?: 'text' | 'password';
  readonly?: boolean;
  disabled?: boolean;
  onBlur?: (value: string) => void;
  onFocus?: (value: string) => void;
  Icon?: JSX.Element;
  prefix?: string;
  suffix?: string | ReactNode;
  onPaste?: (event: ClipboardEvent<HTMLInputElement>) => void;
}

const Input: FC<InputProps> = ({
  value,
  onChange,
  placeholder,
  onKeyUp,
  className,
  containerClassName,
  hasError = false,
  type = 'text',
  readonly = false,
  disabled = false,
  onBlur,
  onFocus,
  Icon,
  prefix,
  suffix,
  onPaste,
}) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    event.preventDefault();
    onChange && onChange(event.target.value);
  };

  const handleKeyup: KeyboardEventHandler<HTMLInputElement> = ({ key, currentTarget }) => {
    onKeyUp && onKeyUp(key, currentTarget.value);
  };

  const handleBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    onBlur && onBlur(event.target.value);
  };

  const handleFocus: FocusEventHandler<HTMLInputElement> = (event) => {
    onFocus && onFocus(event.target.value);
  };

  const handlePaste: ClipboardEventHandler<HTMLInputElement> = (event) => {
    onPaste && onPaste(event);
  };

  return (
    <div className={containerClassName}>
      <div className='relative flex items-center gap-2'>
        {Icon && Icon}
        {prefix ? <p className='text-xl'>{prefix}</p> : null}
        <input
          type={type}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          readOnly={readonly}
          className={cn(
            'w-full rounded-lg border border-gray-200 p-2 shadow-sm outline-none hover:border-gray-300 focus:border-blue-500 focus:ring-2',
            'read-only:bg-slate-100 read-only:outline-none read-only:hover:border-gray-200 read-only:focus:border-gray-200 read-only:focus:ring-0',
            'placeholder:text-slate-400',
            hasError && 'border-red-600 ring-red-300 hover:border-red-600 focus:border-red-600',
            suffix && 'pr-6',
            className,
          )}
          onKeyUp={handleKeyup}
          onBlur={handleBlur}
          onFocus={handleFocus}
          disabled={disabled}
          onPaste={handlePaste}
        />
        {suffix ? <p className='absolute right-2 opacity-60'>{suffix}</p> : null}
      </div>
    </div>
  );
};

export default Input;
export type { InputProps };

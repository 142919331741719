import { VirtualizedTable } from '@/components';
import { useTable } from '@/hooks/table';
import { FC, useMemo, useState } from 'react';
import useAccessRequestsTableColumns from './AccessRequestsColumns';
import DenyRequestModal from './DenyRequestModal';
import { NewUserModal } from '../../components';
import { useScreenSize } from '@/hooks/screenSize';
import { useSelector } from 'react-redux';
import { selectSideBarConfig } from '@/store/app/app.selector';
import { LAYOUT_PADDING, SIDEBAR_WIDTH } from '@/constants/layout';

interface AccessRequestsTableProps {
  access_requests: Array<BetaAccessUser>;
}

const TABLE_BORDER = 1;
const TABLE_INTERNAL_PADDING_XL = 16; // p-4
const TABLE_INTERNAL_PADDING = 10; // p-2.5

const AccessRequestsTable: FC<AccessRequestsTableProps> = ({ access_requests }) => {
  const [isDenyModalOpen, setIsDenyModalOpen] = useState(false);
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
  const [accessRequestId, setAccessRequestId] = useState('');
  const { isOpen } = useSelector(selectSideBarConfig);

  const { width } = useScreenSize();

  const contact = useMemo(() => access_requests.find((ar) => ar.id === accessRequestId), [accessRequestId, access_requests]);

  const [globalFilter, setGlobalFilter] = useState('');

  const columns = useAccessRequestsTableColumns({
    approveAction: (id) => {
      setIsApproveModalOpen(true);
      setAccessRequestId(id);
    },
    denyAction: (id) => {
      setIsDenyModalOpen(true);
      setAccessRequestId(id);
    },
  });

  const matchMedia = window.matchMedia('(min-width: 1536px)');

  const columnsWidth = useMemo(() => {
    const sideBarWidth = isOpen ? SIDEBAR_WIDTH.open : SIDEBAR_WIDTH.closed;
    const tablePadding = matchMedia.matches ? TABLE_INTERNAL_PADDING_XL : TABLE_INTERNAL_PADDING;
    const totalWidth = width - sideBarWidth - LAYOUT_PADDING * 2 - tablePadding * 2 - TABLE_BORDER * 2;

    return totalWidth / columns.length;
  }, [width, isOpen, columns.length, matchMedia]);

  const [table] = useTable({
    data: access_requests,
    columns,
    tableCustomOptions: ['allowSort', 'allowFilters', 'allowSelect'],
    tableOptions: {
      onGlobalFilterChange: setGlobalFilter,
      state: {
        globalFilter,
      },
      meta: {
        size: columnsWidth,
      },
    },
  });

  return (
    <div className='h-full max-w-[100%] rounded-leap bg-white 2xl:max-w-[100%]'>
      <div className='h-[100%] w-full rounded-leap bg-white p-4'>
        <VirtualizedTable table={table} allowResize name='access-requests' containerClass='max-h-full' />
      </div>
      {isDenyModalOpen && <DenyRequestModal accessRequestId={accessRequestId} onClose={() => setIsDenyModalOpen(false)} isOpen={isDenyModalOpen} />}
      {isApproveModalOpen && (
        <NewUserModal
          isOpen={isApproveModalOpen}
          accessRequestId={accessRequestId}
          onClose={() => setIsApproveModalOpen(false)}
          email={contact?.email}
          first_name={contact?.first_name}
          last_name={contact?.last_name}
        />
      )}
    </div>
  );
};

export default AccessRequestsTable;

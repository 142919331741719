import { Input, PriorityCell, Tooltip, WarningMessage } from '@/components';
import cn from '@/utils/style';
import { Table, createColumnHelper } from '@tanstack/react-table';
import { FC, useEffect, useMemo, useState } from 'react';

const columnHelper = createColumnHelper<ScenarioRefineKeyword>();

interface AllocationsInputProps {
  table: Table<ScenarioRefineKeyword>;
  keyword: ScenarioRefineKeyword;
  disabled?: boolean;
  allowZero?: boolean;
}

type AllocationKeywordMeta = {
  updatePriority: ({ keywordId, priority }: { keywordId: number; priority: number }) => void;
  updateEveryPriority: (priority: number) => void;
  updateAllocation: ({ keywordId, anchorsTotal }: { keywordId: number; anchorsTotal: number }) => void;
};

const AllocationsInput: FC<AllocationsInputProps> = ({ table, keyword, disabled = false, allowZero = false }) => {
  const [value, setValue] = useState<number>(0);
  const calculatedValue = useMemo(() => {
    if (keyword.priority === 0) {
      return 0;
    }

    return keyword.anchorsTotal || 0;
  }, [keyword]);

  useEffect(() => {
    setValue(calculatedValue);
  }, [calculatedValue]);

  const handleInputChange = (input: string) => {
    const number = Math.abs(Number(input));
    if (!number && (number !== 0 || !allowZero)) {
      return null;
    }

    setValue(number);
  };

  const handleBlur = (value: string) => {
    if (value === calculatedValue.toString()) {
      return;
    }

    (table.options.meta as AllocationKeywordMeta).updateAllocation({ keywordId: keyword.id, anchorsTotal: Number(value) });
  };

  const handleKeyup = (key: string, value: string) => {
    if (key === 'Enter') {
      handleBlur(value);
    }
  };

  const showWarning = !value && keyword.priority == 2;

  return (
    <div className='flex items-center'>
      <Input
        disabled={keyword.priority === 0 || disabled}
        className={cn('w-16 text-right', !showWarning && 'mr-9')}
        onChange={handleInputChange}
        onBlur={handleBlur}
        onKeyUp={handleKeyup}
        value={value}
      />
      {showWarning && (
        <WarningMessage
          className='ml-3 mt-1'
          tooltip={{
            id: `keyword-${keyword.id}`,
            content: 'There are no Allocations for this Keyword',
          }}
        />
      )}
    </div>
  );
};

const useColumns = (allowAllocation: boolean, allowZeroTotalAnchors: boolean, allowPriorityChange: boolean) => {
  return [
    columnHelper.accessor('keyword', {
      header: () => <p className='text-left'>Keyword</p>,
      cell: ({ getValue }) => <p className='whitespace-nowrap'>{getValue()}</p>,
    }),
    columnHelper.accessor('targetPage', {
      header: () => <p className='text-left'>Target URL</p>,
      cell: ({ getValue, row }) => (
        <Tooltip id={`${row.id}-target-url`} content={<p className='max-w-lg'>{getValue()}</p>} place='top'>
          <p className='max-w-lg overflow-hidden text-ellipsis whitespace-nowrap'>{getValue()}</p>
        </Tooltip>
      ),
    }),
    columnHelper.accessor('totalVolume', {
      header: () => <p className='text-right'>Volume</p>,
      cell: ({ getValue }) => <p className='w-full text-right'>{getValue().toLocaleString()}</p>,
    }),
    columnHelper.accessor('difficulty', {
      header: () => <p className='text-right'>Difficulty</p>,
      cell: ({ getValue }) => <p className='text-right'>{((getValue() || 0) * 100).toLocaleString()}%</p>,
    }),
    columnHelper.accessor('rank', {
      header: () => <p className='text-right'>Rank</p>,
      cell: ({ getValue }) => <p className='text-right'>{getValue()}</p>,
    }),
    columnHelper.accessor('lrdGapKeyword', {
      header: () => <p className='text-right'>Gap</p>,
      cell: ({ getValue }) => {
        const value = getValue() > 0 ? getValue().toLocaleString() : '-';
        return <p className='text-right'>{value}</p>;
      },
    }),
    columnHelper.accessor('priority', {
      header: ({ column, table }) => {
        const rows = column.getFacetedRowModel().flatRows.map((i) => i.original);
        const getValue = () => {
          const hasSamePriority = rows.at(0) && rows.every((i) => i.priority === rows[0].priority);
          return hasSamePriority ? rows[0].priority : 0;
        };

        const handleUpdateKw = (val: number) => {
          (table.options.meta as AllocationKeywordMeta).updateEveryPriority(val);
          // updateAllScenarioKeywords(val);
        };

        return (
          <div className='flex w-full flex-col items-center justify-center gap-2'>
            {allowPriorityChange ? <PriorityCell val={getValue()} onClick={handleUpdateKw} /> : <p>Priority</p>}
          </div>
        );
      },
      cell: ({ getValue, row, table }) => {
        const handleUpdateKw = (val: number) => {
          (table.options.meta as AllocationKeywordMeta).updatePriority({ keywordId: row.original.id, priority: val });
        };

        return (
          <div className='flex items-center justify-center'>
            <PriorityCell val={getValue()} onClick={handleUpdateKw} disabled={!allowPriorityChange} />
          </div>
        );
      },
      enableSorting: false,
      size: 1,
    }),
    columnHelper.accessor('anchorsTotal', {
      header: () => <p className='w-12'>Allocate</p>,
      cell: ({ row, table }) => {
        return (
          <div className='flex w-full items-center justify-center'>
            <AllocationsInput table={table} keyword={row.original} disabled={!allowAllocation} allowZero={allowZeroTotalAnchors} />
          </div>
        );
      },
      enableSorting: false,
      size: 1,
    }),
  ];
};

export default useColumns;

import cn from '@/utils/style';
import { ComponentProps, FC, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

interface SideMenuItemsProps {
  url?: string;
  title: string;
  Icon?: (props: ComponentProps<'svg'>) => JSX.Element | JSX.Element;
  isSidebarOpen: boolean;
  onClick?: () => void;
  tutorialId?: string;
  allowNotification?: boolean;
  notificationContent?: JSX.Element | null;
}

const urlsSlugs: Record<string, string> = {
  '/': '/campaigns',
  '/user-management': '/user-management',
  '/settings': '/settings',
};

const SideMenuItem: FC<SideMenuItemsProps> = ({ url, isSidebarOpen, title, Icon, onClick, tutorialId, allowNotification = false, notificationContent }) => {
  const location = useLocation();
  const isUrlActive = useMemo(() => {
    if (url === undefined) {
      return false;
    }

    return (location.pathname === '/' && url === '/') || location.pathname.startsWith(urlsSlugs[url] !== undefined ? urlsSlugs[url] : url);
  }, [location, url]);

  return (
    <a
      className={cn(
        'flex cursor-pointer items-center justify-center rounded-r-2xl bg-white py-4 text-slate-400 hover:bg-base-leap',
        isUrlActive && 'bg-base-leap text-gray-700',
        isSidebarOpen && 'justify-start gap-2 pl-8',
      )}
      onClick={onClick}
    >
      {Icon && (
        <div className='relative'>
          <Icon className={cn('w-6 text-slate-400', isUrlActive && 'text-sky-600')} id={tutorialId} />
          {allowNotification && notificationContent ? <div className='absolute right-[-.5rem] top-[-.5rem]'>{notificationContent}</div> : null}
        </div>
      )}
      {isSidebarOpen && <p className='whitespace-nowrap'>{title}</p>}
    </a>
  );
};

export default SideMenuItem;

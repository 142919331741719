import { Form, FormikProvider, useFormik } from 'formik';
import { FC } from 'react';
import TextInputField from '../TextInputField';
import Button from '../Button';
import ErrorMessage from '../ErrorMessage';
import * as yup from 'yup';

interface ResetPasswordFormProps {
  onSubmit: (values: ResetPasswordFormValues) => void;
  isLoading: boolean;
  error?: APIError;
}

const resetPasswordValidationSchema: yup.SchemaOf<ResetPasswordFormValues> = yup.object({
  password: yup.string().required('Password is required').min(8, 'Password must be at least 8 characters'),
  confirm_password: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required(),
});

const ResetPasswordForm: FC<ResetPasswordFormProps> = ({ onSubmit, isLoading, error }) => {
  const formik = useFormik<ResetPasswordFormValues>({
    onSubmit: onSubmit,
    initialValues: {
      password: '',
      confirm_password: '',
    },
    validationSchema: resetPasswordValidationSchema,
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <div className='flex flex-col gap-y-5 text-left '>
          <TextInputField name='password' label='Create Password' type='password' disabled={isLoading} />
          <TextInputField name='confirm_password' label='Confirm Your Password' type='password' disabled={isLoading} />
          <Button type='submit' isLoading={isLoading} className='w-full'>
            Confirm
          </Button>
          {error && <ErrorMessage>{error.data.message || 'Something went wrong, try again later...'}</ErrorMessage>}
        </div>
      </Form>
    </FormikProvider>
  );
};

export default ResetPasswordForm;

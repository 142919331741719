import { Checkbox } from '@/components';
import { selectEditAllScenarios } from '@/store/campaign/campaign.selector';
import { setEditAllScenarios } from '@/store/campaign/campaign.slice';
import { AppDispatch } from '@/store/store';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const SelectAllScenarios: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const editAllScenarios = useSelector(selectEditAllScenarios);

  const handleEditAllScenariosToggle = () => dispatch(setEditAllScenarios(!editAllScenarios));

  return (
    <div className='flex gap-4'>
      <p>Apply to all Scenarios</p>
      <Checkbox checked={editAllScenarios} onChange={handleEditAllScenariosToggle} className='text-amber-400 checked:bg-amber-400 checked:hover:bg-amber-400' />
    </div>
  );
};

export default SelectAllScenarios;

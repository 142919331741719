import {
  CarouselIcon,
  SatelinkIcon,
  CrownIcon,
  HelpCenterIcon,
  ListAltIcon,
  PhotoLibraryIcon,
  PlayCircleIcon,
  SlowMotionVideoIcon,
  QuestionMarkIcon,
  OnDemandVideoIcon,
  AnnouncementIcon,
  XLogoIcon,
  ADTopIcon,
  ADBottomIcon,
  ADMiddleIcon,
  SquareMagnifyingGlass,
  MapsHomeWorkIcon,
  AirplaneModeActiveIcon,
  LocalDiningIcon,
  XCarouselIcon,
  NewspaperIcon,
  ShareLocationIcon,
  StarCircleIcon,
  ManageSearchIcon,
  ArticleIcon,
  ChecklistIcon,
  RecentActorsIcon,
  QuizIcon,
  PersonSearchIcon,
  MobileFriendlyIcon,
  PermMediaIcon,
  RelatedTopicsIcon,
  ApplicationIcon,
  ShortVideosIcon,
  WebStoriesIcon,
  ApplicationListIcon,
  PopularStoresIcon,
  IndentedIcon,
} from '@/components/Icons';

import {
  AcademicCapIcon,
  BriefcaseIcon,
  CalendarDaysIcon,
  ChatBubbleLeftIcon,
  DocumentTextIcon,
  FolderIcon,
  FunnelIcon,
  MapPinIcon,
  PhotoIcon,
  ShoppingBagIcon,
  ShoppingCartIcon,
  SparklesIcon,
  StarIcon,
  TagIcon,
} from '@heroicons/react/20/solid';
import { SVGProps } from 'react';

interface SERPFeature {
  name: string;
  Icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
}

const SERPFeaturesMap: Record<number, SERPFeature> = {
  0: { name: 'Instant answer', Icon: AnnouncementIcon },
  1: { name: 'Knowledge panel', Icon: AcademicCapIcon },
  2: { name: 'Carousel', Icon: CarouselIcon },
  3: { name: 'Local pack', Icon: MapPinIcon },
  4: { name: 'Top stories', Icon: DocumentTextIcon },
  5: { name: 'Image pack', Icon: PhotoLibraryIcon },
  6: { name: 'Sitelinks', Icon: SatelinkIcon },
  7: { name: 'Reviews', Icon: StarIcon },
  8: { name: 'Tweet', Icon: XLogoIcon },
  9: { name: 'Video', Icon: PlayCircleIcon },
  10: { name: 'Featured video', Icon: OnDemandVideoIcon },
  11: { name: 'Featured Snippet', Icon: CrownIcon },
  12: { name: 'AMP', Icon: MobileFriendlyIcon },
  13: { name: 'Image', Icon: PhotoIcon },
  14: { name: 'Ads top', Icon: ADTopIcon },
  15: { name: 'Ads bottom', Icon: ADBottomIcon },
  16: { name: 'Shopping ads', Icon: ShoppingCartIcon },
  17: { name: 'Hotels Pack', Icon: MapsHomeWorkIcon },
  18: { name: 'Jobs search', Icon: BriefcaseIcon },
  19: { name: 'Featured images', Icon: PermMediaIcon },
  20: { name: 'Video Carousel', Icon: SlowMotionVideoIcon },
  21: { name: 'People also ask', Icon: HelpCenterIcon },
  22: { name: 'FAQ', Icon: QuestionMarkIcon },
  23: { name: 'Flights', Icon: AirplaneModeActiveIcon },
  24: { name: 'Find results on', Icon: SquareMagnifyingGlass },
  25: { name: 'Recipes', Icon: LocalDiningIcon },
  26: { name: 'Related Topics', Icon: RelatedTopicsIcon },
  27: { name: 'Twitter carousel', Icon: XCarouselIcon },
  28: { name: 'Indented', Icon: IndentedIcon },
  29: { name: 'News', Icon: NewspaperIcon },
  30: { name: 'Address Pack', Icon: ShareLocationIcon },
  31: { name: 'Application', Icon: ApplicationIcon },
  32: { name: 'Events', Icon: CalendarDaysIcon },
  34: { name: 'Popular products', Icon: StarCircleIcon },
  35: { name: 'Related products', Icon: ShoppingBagIcon },
  36: { name: 'Related searches', Icon: ManageSearchIcon },
  37: { name: 'See results about', Icon: ArticleIcon },
  38: { name: 'Short videos', Icon: ShortVideosIcon },
  39: { name: 'Web stories', Icon: WebStoriesIcon },
  40: { name: 'Application list', Icon: ApplicationListIcon },
  41: { name: 'Buying guide', Icon: ChecklistIcon },
  42: { name: 'Organic carousel', Icon: RecentActorsIcon },
  43: { name: 'Things to know', Icon: ListAltIcon },
  44: { name: 'Datasets', Icon: FolderIcon },
  45: { name: 'Discussions and forums', Icon: ChatBubbleLeftIcon },
  46: { name: 'Explore brands', Icon: TagIcon },
  47: { name: 'Questions and answers', Icon: QuizIcon },
  48: { name: 'Popular stores', Icon: PopularStoresIcon },
  49: { name: 'Refine', Icon: FunnelIcon },
  50: { name: 'People also search', Icon: PersonSearchIcon },
  51: { name: 'Ads middle', Icon: ADMiddleIcon },
  52: { name: 'AI overview', Icon: SparklesIcon },
};

export { SERPFeaturesMap };

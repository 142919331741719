import { createContext } from 'react';
import { INITIAL_PAGE, INITIAL_PAGE_SIZE } from '@/constants/pagination';
import { ColumnSort } from '@tanstack/react-table';

interface FilterContextValues {
  filters: Partial<ExtraFilters>;
  removeFilter: (key: keyof ExtraFilters) => void;
  updateFilters: (filter: Partial<ExtraFilters>) => void;
  changePage: (page: number, pageSize: number | undefined) => void;
  changeSort: (sort: ColumnSort) => void;
  setFilters: (filter: Partial<ExtraFilters>) => void;
}

const initialFilters: ExtraFilters = {
  sort: [],
  page: INITIAL_PAGE,
  page_size: INITIAL_PAGE_SIZE,
  name: undefined,
  name__icontains: undefined,
  status: undefined,
};

const initialValues: FilterContextValues = {
  filters: initialFilters,
  removeFilter: () => {},
  updateFilters: () => {},
  changePage: () => {},
  changeSort: () => {},
  setFilters: () => {},
};

const filtersContext = createContext(initialValues);

export default filtersContext;
export { initialFilters };
export type { FilterContextValues };

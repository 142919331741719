import cn from '@/utils/style';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { FC, PropsWithChildren } from 'react';

interface ErrorMessageProps extends PropsWithChildren {
  className?: string;
}

const ErrorMessage: FC<ErrorMessageProps> = ({ children, className }) => {
  return (
    <div className={cn('ml-1 flex items-center gap-1', className)}>
      <ExclamationCircleIcon className='w-5 text-red-500' />
      <p className='text-red-500 first-letter:capitalize'>{children}</p>
    </div>
  );
};

export default ErrorMessage;

import { FC } from 'react';
import { URLsTable, URLForm } from './components';
import { Page } from '@/layouts';
import { FiltersProvider } from '@/components';

const URLPage: FC = () => (
  <Page>
    <Page.TopBar title='Linking Context Report' className='flex items-center'></Page.TopBar>
    <Page.Body>
      <FiltersProvider>
        <URLForm />
        <URLsTable />
      </FiltersProvider>
    </Page.Body>
  </Page>
);

export default URLPage;

import { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { ProgressBar } from '@/components';
import { ArrowPathIcon, ArrowRightIcon } from '@heroicons/react/24/solid';
import { useRetryURLAudienceMutation } from '@/store/poc/poc.api';
import { useNavigate } from 'react-router-dom';
import cn from '@/utils/style';

const columnHelper = createColumnHelper<LCRURL<User>>();

const statusColor = {
  error: 'bg-red-500',
  in_progress: 'bg-yellow-400',
  completed: 'bg-green-500',
  created: 'bg-sky-500',
  queued: 'bg-gray-500',
};

const statusLabel = {
  error: 'Error',
  in_progress: 'In Progress',
  completed: 'Success',
  created: 'Created',
  queued: 'Queued',
};

const URLMessage = {
  error: {
    html: 'Something went wrong. Please try again.',
    url_and_description: 'Fetching URL and Description',
    target_audience: 'Fetching Target Audience',
    stakeholders: 'Fetching Stakeholders',
    stakeholder_questions: 'Fetching Stakeholders Question',
    worse_case_scenario: 'Fetching Worse Case Scenario',
    expert_questions: 'Fetching Expert Question',
    questions_summary: 'Fetching Questions Summary',
    inexistent_page: 'Inexistent page.',
    page_not_found: 'Page not found.',
    openai_authentication_error: 'Access Error. Please contact our support team.',
    openai_permission_error: 'Access Error. Please contact our support team.',
    openai_not_found: 'Access Error. Please contact our support team.',
    openai_rate_limit_error: 'We’re experiencing high demand. Please wait a few moments and try again or contact support if the issue persists.',
    openai_server_error: 'We’re experiencing technical difficulties. Please try again later or contact support.',
    openai_service_overloaded: 'The service is temporarily unavailable. Please try again shortly.',
  },
  in_progress: {
    html: 'Fetching HTML',
    url_and_description: 'Fetching URL and Description',
    target_audience: 'Fetching Target Audience',
    stakeholders: 'Fetching Stakeholders',
    stakeholder_questions: 'Fetching Stakeholders Question',
    worse_case_scenario: 'Fetching Worse Case Scenario',
    expert_questions: 'Fetching Expert Question',
    questions_summary: 'Fetching Questions Summary',
  },
};

const AVAILABLE_TO_RETRY = [
  'html',
  'url_and_description',
  'target_audience',
  'stakeholders',
  'stakeholder_questions',
  'worse_case_scenario',
  'expert_questions',
  'questions_summary',
  'openai_server_error',
  'openai_service_overloaded',
];

const useURLsTableColumns = () => {
  const [retryURLAudience] = useRetryURLAudienceMutation();
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      columnHelper.accessor('url', {
        header: () => <p className='text-left'>URL</p>,
        cell: ({ getValue }) => <p className='text-left'>{getValue()}</p>,
      }),
      columnHelper.accessor('offering', {
        header: () => <p className='text-left'>Offering</p>,
        cell: ({ getValue }) => <p className='text-left'>{getValue()}</p>,
      }),
      columnHelper.accessor('buyer_wants_to_achieve', {
        header: () => <p className='text-left'>Want to Achieve</p>,
        cell: ({ getValue }) => <p className='text-left'>{getValue()}</p>,
      }),
      columnHelper.display({
        id: 'status',
        header: () => <p className='text-left'>Status</p>,
        cell: ({
          row: {
            original: { status, message, progress },
          },
        }) => (
          <div className='flex flex-col text-left'>
            <p className='flex items-center gap-2'>
              <span className={cn(statusColor[status], 'h-3 w-3 rounded-full')}></span>
              {statusLabel[status]}
            </p>
            {status !== 'error' && status !== 'completed' ? <ProgressBar progress={progress || 0} showProgress={false} color='bg-green-700' className='my-1' /> : null}
            {status !== 'completed' ? <p>{message && (status === 'error' || status === 'in_progress') ? URLMessage[status][message] : ''}</p> : null}
          </div>
        ),
        size: 200,
      }),
      columnHelper.accessor('user', {
        header: () => <p className='text-left'>Created By</p>,
        cell: ({ getValue }) => <p className='text-left'>{getValue()?.fullName || '-'}</p>,
      }),
      columnHelper.display({
        id: 'actions',
        header: () => <p className='text-right'>Actions</p>,
        cell: ({ row }) => {
          return (
            <div className='flex w-full justify-end gap-4'>
              {row.original.status === 'error' && AVAILABLE_TO_RETRY.includes(row.original.message || '') && (
                <button onClick={() => retryURLAudience({ id: row.original.id })}>
                  <ArrowPathIcon className='size-6 text-gray-400' />
                </button>
              )}
              <button onClick={() => navigate(`url/${row.original.id}/detail`)}>
                <ArrowRightIcon className={cn('size-6 text-gray-400', !row.original.read_at && row.original.status === 'completed' ? ' stroke-current stroke-1' : null)} />
              </button>
            </div>
          );
        },
      }),
    ],
    [navigate, retryURLAudience],
  );

  return columns;
};

export default useURLsTableColumns;

import { useToggle } from '@/hooks/toggle';
import { Page } from '@/layouts';
import { useGetCampaignQuery } from '@/store/campaign/campaign.api';
import { FC, Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AnalysisSteps from '../AnalysisSteps';
import CancelModal from './CancelModal';

type AnalysisTopbarProps = {
  onSave: () => void | Promise<void>;
  isDirty: boolean;
};

const AnalysisTopbar: FC<AnalysisTopbarProps> = ({ onSave, isDirty }) => {
  const { campaignId } = useParams() as { campaignId: string };
  const navigate = useNavigate();
  const { data: campaign } = useGetCampaignQuery({ campaignId }); // Can't get the campaign from store since it's not updated after every navigation. Rtkquery issue with cache
  const { value: isCancelModalOpen, toggle: toggleCancelModal } = useToggle();

  const handleGoBack = () => {
    if (isDirty) {
      toggleCancelModal(true);
      return;
    }

    navigate('/');
  };

  const handleSave = async () => {
    await onSave();
    toggleCancelModal(false);
    navigate('/');
  };

  const handleDiscard = () => {
    toggleCancelModal(false);
    navigate('/');
  };

  const handleStepClick = async (step: AnalysisStepObject) => {
    await onSave();
    navigate(step.url);
  };

  if (!campaign) {
    return null;
  }

  return (
    <Fragment>
      <Page.TopBar title={campaign.name} goBack={handleGoBack}>
        <AnalysisSteps onClick={handleStepClick} />
      </Page.TopBar>
      {isCancelModalOpen && <CancelModal isOpen={isCancelModalOpen} onClose={() => toggleCancelModal(false)} onSave={handleSave} onDiscard={handleDiscard} />}
    </Fragment>
  );
};

export default AnalysisTopbar;

import { SeparatorLine, Skeleton } from '@/components';

const CardSkeleton = () => <Skeleton className='h-[135px] w-full rounded-leap' />;

const DashboardSkeleton = () => {
  return (
    <div className='flex flex-col py-4'>
      <div className='flex w-full justify-end py-6'>
        <Skeleton className='h-[36px] w-[160px]' />
      </div>
      <SeparatorLine />
      <Skeleton className='my-6 h-[36px] w-full' />
      <div className='my-4 flex flex-col gap-2 px-2'>
        <CardSkeleton />
        <CardSkeleton />
        <CardSkeleton />
        <CardSkeleton />
      </div>
    </div>
  );
};

export default DashboardSkeleton;

import { Button, Modal, ModalProps } from '@/components';
import { FC } from 'react';

interface SkipModalProps extends ModalProps {
  onSkip: () => void;
}

const SkipModal: FC<SkipModalProps> = ({ isOpen, onClose, onSkip }) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen} title=''>
      <div className='mb-4 flex w-[450px] flex-col items-center gap-4'>
        <h4 className='text-center'>Skip Anchor Text</h4>
        <p className='text-center'>Are you sure you want to skip without generating anchor text?</p>
        <Button className='w-full' onClick={onSkip}>
          Continue Without Anchor Text
        </Button>
        <Button className='w-full' onClick={onClose}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default SkipModal;

import { Button, Modal, ModalProps, VirtualizedTable } from '@/components';
import { useTable } from '@/hooks/table';
import { FC, useCallback, useState } from 'react';
import useKeywordAnchorTextMatchColumns from './useKeywordAnchorTextMatchColumns';
import { useScreenSize } from '@/hooks/screenSize';
import { SCREEN_SIZES } from '@/constants/layout';

type DistributeAnchorsByKeywordModalProps = Omit<ModalProps, 'onConfirm'> & {
  urlKeywords: AnchorTextKeywordState[];
  onReset: () => void;
  onConfirm: (keywords: AnchorTextKeywordState[]) => void;
};

const DistributeAnchorsByKeywordModal: FC<DistributeAnchorsByKeywordModalProps> = ({ isOpen, urlKeywords, onClose, onConfirm, onReset }) => {
  const [localUrlkeywords, setLocalUrlkeywords] = useState(urlKeywords);
  const updater = useCallback(
    (keywordId: number, val: number, field: keyof AnchorTextMatches) => {
      setLocalUrlkeywords((prev) => {
        return prev.map((kw) => {
          if (kw.keyword_id !== keywordId) return kw;
          return { ...kw, [field]: val };
        });
      });
    },
    [setLocalUrlkeywords],
  );

  const columns = useKeywordAnchorTextMatchColumns({ isLoading: false });

  const { width } = useScreenSize();

  const modalWidth = width >= SCREEN_SIZES.xxl ? 960 : 840;

  const [table] = useTable({
    data: localUrlkeywords,
    columns,
    tableCustomOptions: ['allowResize'],
    tableOptions: {
      meta: {
        size: modalWidth / columns.length,
        updater,
      },
    },
  });

  const modalFooter = (
    <div className='flex items-center justify-end gap-4 border-t-2 px-6 py-3'>
      <Button variant='outline-light' onClick={onClose}>
        Cancel
      </Button>

      <Button variant='outline-light' onClick={onReset}>
        Remove Match By Keywords
      </Button>

      <Button onClick={() => onConfirm && onConfirm(localUrlkeywords)}>Confirm</Button>
    </div>
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} footer={modalFooter} title='Match Type By Keyword' className='max-w-modal-xl'>
      <VirtualizedTable loadingText={'Loading keywords for this URL...'} table={table} name='distributeanchorsByKeyword' allowResize />
    </Modal>
  );
};

export default DistributeAnchorsByKeywordModal;

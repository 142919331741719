import { SVGProps } from 'react';

const ScaleIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0_425_7256)'>
        <path
          d='M19.7041 6.27108C19.6093 6.105 19.4927 5.98941 19.3132 5.92561L14.1091 4.03139C14.1288 3.46608 13.924 2.89467 13.4932 2.46436C12.607 1.57767 11.1267 1.64864 10.3362 2.65795L5.14008 0.767014C5.13961 0.766546 5.13914 0.766546 5.13914 0.766546C4.77516 0.636514 4.40841 0.829217 4.27148 1.11108L0.978516 7.65014H2.55305L4.90289 2.98326L7.28133 7.65014H8.85961L6.33633 2.69873L9.88945 3.99201C9.90403 4.89539 10.4812 5.65983 11.2971 5.94858V19.6051C11.2971 19.9933 11.6121 20.3083 12.0002 20.3083C12.3884 20.3083 12.7034 19.9933 12.7034 19.6051V5.94858C13.0459 5.82792 13.3538 5.62017 13.5949 5.34061L18.0921 6.97748L15.132 12.858H16.7065L19.076 8.15076L21.4474 12.858H23.022L19.7041 6.27108ZM12.499 4.45608C12.2238 4.73123 11.7766 4.73123 11.5015 4.45608C11.2263 4.18092 11.2263 3.73373 11.5015 3.45858C11.7766 3.18342 12.2238 3.18342 12.499 3.45858C12.7746 3.73416 12.775 4.18003 12.499 4.45608Z'
          fill='currentColor'
        />
        <path
          d='M12.8275 18.949H11.1718C9.17408 18.949 7.54883 20.5742 7.54883 22.5718C7.54883 22.9602 7.86364 23.275 8.25195 23.275H15.7473C16.1356 23.275 16.4504 22.9602 16.4504 22.5718C16.4504 20.5742 14.8252 18.949 12.8275 18.949Z'
          fill='currentColor'
        />
        <path
          d='M23.2973 14.2639H14.8574C14.4691 14.2639 14.1543 14.5787 14.1543 14.967C14.1543 17.6816 16.3628 19.8901 19.0773 19.8901C21.7919 19.8901 24.0004 17.6816 24.0004 14.967C24.0004 14.5787 23.6856 14.2639 23.2973 14.2639Z'
          fill='currentColor'
        />
        <path
          d='M9.14531 9.0564H0.703125C0.314813 9.0564 0 9.37121 0 9.75952C0 12.4748 2.20898 14.6837 4.92422 14.6837C7.63945 14.6837 9.84844 12.4748 9.84844 9.75952C9.84844 9.37121 9.53363 9.0564 9.14531 9.0564Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_425_7256'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ScaleIcon;

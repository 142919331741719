const RelatedTopicsIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6 5C6 3.89543 6.89543 3 8 3H16C17.1046 3 18 3.89543 18 5V6H6V5ZM4 9.5C4 8.39543 4.89543 7.5 6 7.5H18C19.1046 7.5 20 8.39543 20 9.5V10.5H4V9.5ZM2 14C2 12.8954 2.89543 12 4 12H20C21.1046 12 22 12.8954 22 14V17C22 19.2091 20.2091 21 18 21H6C3.79086 21 2 19.2091 2 17V14ZM9.25 15C9.25 14.5858 9.58579 14.25 10 14.25H14C14.4142 14.25 14.75 14.5858 14.75 15C14.75 15.4142 14.4142 15.75 14 15.75H10C9.58579 15.75 9.25 15.4142 9.25 15Z'
      fill='#94A3B8'
    />
  </svg>
);

export default RelatedTopicsIcon;

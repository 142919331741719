import { Skeleton } from '@/components';

const CardSkeleton = () => {
  return <Skeleton className='h-48 w-full' />;
};

const PaymentHeaderSkeleton = () => {
  return (
    <div className='grid grid-cols-3 gap-6 py-6'>
      <CardSkeleton />
      <CardSkeleton />
      <CardSkeleton />
    </div>
  );
};

export default PaymentHeaderSkeleton;

import { Button, Loading, Menu, SearchBarDebounce } from '@/components';
import useTutorialContext from '@/hooks/tutorial';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import UserCard from '../UserCard';
import { useUsersListQuery } from '@/store/users/users.api';
import { CAMPAIGN_NO_TABLE_HEIGHT } from '@/constants/layout';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import { selectAuthUser } from '@/store/auth/auth.selector';
import { useNavigate } from 'react-router-dom';
import ROUTES from '@/constants/routes';

interface UsersCardsProps {
  users: Array<User>;
}

const userSortingsMap = {
  asc: 'first_name',
  desc: '-first_name',
};

const UsersCards: FC<UsersCardsProps> = ({ users }) => {
  const { updateTutorialState } = useTutorialContext();
  const [displayedUsers, setDisplayedUsers] = useState(users);
  const user = useSelector(selectAuthUser);
  const navigate = useNavigate();
  const [sorting, setSorting] = useState<'asc' | 'desc'>('asc');
  const [search, setSearch] = useState<string>('');
  const { ref, inView } = useInView({ threshold: 0.25 });
  const [page, setPage] = useState(1);

  const { isError, isFetching, data } = useUsersListQuery({ page, ordering: userSortingsMap[sorting], search, page_size: 100 });
  const hasNextPage = useMemo(() => data && data.next, [data]);

  const handleOnSearchChange = useCallback((value: string) => {
    setPage(1);
    setSearch(value);
  }, []);

  useEffect(() => {
    updateTutorialState({ run: true });
  }, [updateTutorialState]);

  const handleUserUpdate = (user: User) => {
    const updatedUsers = displayedUsers.map((u) => (u.id === user.id ? user : u));
    setDisplayedUsers(updatedUsers);
  };

  const handleUserDelete = (user: User) => {
    const updatedUsers = displayedUsers.filter((u) => u.id !== user.id);
    setDisplayedUsers(updatedUsers);
  };

  const handleAfterResend = () => {
    setPage(1);
  };

  const renderUsers = (user: User) => {
    return <UserCard key={user.id} user={user} onUserUpdate={handleUserUpdate} onUserDelete={handleUserDelete} afterResendInvite={handleAfterResend} />;
  };

  const menuItems = [
    {
      label: 'A-Z',
      onClick: () => {
        setSorting('asc');
        setPage(1);
      },
    },
    {
      label: 'Z-A',
      onClick: () => {
        setSorting('desc');
        setPage(1);
      },
    },
  ];

  useEffect(() => {
    if (!data) {
      return;
    }
    setDisplayedUsers(data.results);
  }, [data]);

  useEffect(() => {
    if (!isFetching && inView && hasNextPage && !isError) {
      setPage((old) => old + 1);
    }
  }, [hasNextPage, isFetching, inView, isError]);

  return (
    <div>
      <div className='flex w-full items-center justify-between pb-4'>
        <SearchBarDebounce onChange={handleOnSearchChange} inputClassName='w-[25.75rem] h-10' />
        <div className='flex items-center gap-4'>
          <p>
            <span className='text-gray-400'>Sort by:</span> First Name
          </p>
          <div className='relative z-20'>
            <Menu
              items={menuItems}
              menuIcon={
                <div className='flex items-center font-bold text-sky-500'>
                  <p>{sorting === 'asc' ? 'A-Z' : 'Z-A'}</p>
                  <ChevronDownIcon className='w-5' />
                </div>
              }
            />
          </div>
          {user && (user.isSuperuser || (user.company && user.company.role === 'owner')) && (
            <Button id='tutorial-new-user' onClick={() => navigate(ROUTES.newUser)} className='whitespace-nowrap '>
              New User
            </Button>
          )}
        </div>
      </div>
      <div
        style={{
          height: `calc(100vh-${CAMPAIGN_NO_TABLE_HEIGHT}px)`,
        }}
        className='grid grid-cols-2 gap-6'
      >
        {displayedUsers.map(renderUsers)}
      </div>
      <div ref={ref}>{hasNextPage && <Loading className='mx-auto mt-6 size-8' />}</div>
    </div>
  );
};

export default UsersCards;

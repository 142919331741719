const WebStoriesIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2 20.2854V8.32577H22V20.2854C22 21.2324 21.2245 22 20.2678 22H3.73217C2.77555 22 2 21.2324 2 20.2854ZM7 17.9391H14C14.55 17.9391 15 17.4937 15 16.9493C15 16.4048 14.55 15.9594 14 15.9594H7C6.45 15.9594 6 16.4048 6 16.9493C6 17.4937 6.45 17.9391 7 17.9391ZM7 13.9797H17C17.55 13.9797 18 13.5343 18 12.9899C18 12.4454 17.55 12 17 12H7C6.45 12 6 12.4454 6 12.9899C6 13.5343 6.45 13.9797 7 13.9797Z'
      fill='#94A3B8'
    />
    <path
      d='M20.2678 2H3.73217C2.77555 2 2 2.76768 2 3.71459V6.84617H22V3.71459C22 2.76768 21.2245 2 20.2678 2ZM5.00398 5.1622C4.58862 5.1622 4.25187 4.82887 4.25187 4.41773C4.25187 4.00658 4.58858 3.67325 5.00398 3.67325C5.41934 3.67325 5.75609 4.00658 5.75609 4.41773C5.75609 4.82887 5.41934 5.1622 5.00398 5.1622ZM8.06211 5.1622C7.64675 5.1622 7.31 4.82887 7.31 4.41773C7.31 4.00658 7.6467 3.67325 8.06211 3.67325C8.47747 3.67325 8.81422 4.00658 8.81422 4.41773C8.81422 4.82887 8.47747 5.1622 8.06211 5.1622ZM11.2588 5.1622C10.8435 5.1622 10.5067 4.82887 10.5067 4.41773C10.5067 4.00658 10.8435 3.67325 11.2588 3.67325C11.6742 3.67325 12.011 4.00658 12.011 4.41773C12.011 4.82887 11.6743 5.1622 11.2588 5.1622Z'
      fill='#94A3B8'
    />
  </svg>
);

export default WebStoriesIcon;

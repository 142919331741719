import cn from '@/utils/style';
import { FC, PropsWithChildren } from 'react';

interface SkeletonProps extends PropsWithChildren {
  className?: string;
}

const Skeleton: FC<SkeletonProps> = ({ className, children }) => (
  <div
    className={cn(
      'relative isolate animate-pulse overflow-hidden rounded-lg border border-gray-100 bg-white shadow-xl shadow-black/5',
      'before:absolute before:inset-0 before:-translate-x-full before:animate-skeleton-loading before:bg-gradient-to-r before:from-transparent before:via-gray-200 before:to-transparent',
      className,
    )}
  >
    {children}
  </div>
);

export default Skeleton;

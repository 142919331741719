import { Button, LoadingScreen, Modal, ModalProps } from '@/components';
import { FC } from 'react';

interface CancelModalProps extends ModalProps {
  onSave: () => void;
  onDiscard: () => void;
}

const CancelModal: FC<CancelModalProps> = ({ isOpen, onClose, onSave, onDiscard, isLoading }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} className='max-w-[800px]'>
      {isLoading ? (
        <div className='absolute inset-0 z-10 flex items-center justify-center bg-gray-300 opacity-80'>
          <LoadingScreen />
        </div>
      ) : null}
      <div className='mb-4 flex w-[600px] flex-col items-center gap-6'>
        <h2>Warning</h2>
        <p className='text-center text-xl'>
          You haven&apos;t saved your changes as a draft. If you continue without saving, your changes will be lost. Do you wish to save and continue, or discard changes and
          continue?
        </p>
        <div className='flex w-full flex-col items-center gap-2 px-20'>
          <Button className='w-full' onClick={onSave}>
            Save & Continue
          </Button>
          <Button className='w-full' variant='outline-light' onClick={onDiscard}>
            Discard & Continue
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CancelModal;

import api from '../api';

const paymentAPI = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    getPaymentMethods: build.query<APIListResponse<PaymentMethod>, void>({
      query: () => ({
        method: 'GET',
        url: 'payments/payment-method/',
      }),
      providesTags: ['paymentMethod'],
    }),
    createCustomer: build.mutation<APIResponse<Customer>, CustomerForm>({
      query: (data) => ({
        method: 'POST',
        url: 'payments/customer/',
        body: {
          email: data.email,
          name: data.name,
          address: {
            city: data.city,
            country: data.country,
            line1: data.line1,
            postal_code: data.postal_code,
            state: data.state,
          },
        },
      }),
      invalidatesTags: ['currentUser'],
    }),
    attachPaymentToCustomer: build.mutation<APIResponse<PaymentMethod>, { id: string }>({
      query: ({ id }) => ({
        method: 'POST',
        url: 'payments/payment-method/',
        body: {
          id,
        },
      }),
      invalidatesTags: ['paymentMethod'],
    }),
    updateCustomerDefaultPaymentMethod: build.mutation<APIResponse<Customer>, { id: string }>({
      query: ({ id }) => ({
        method: 'POST',
        url: 'payments/customer/default-payment-method/',
        body: {
          id,
        },
      }),
      invalidatesTags: ['paymentMethod'],
    }),
    createInvoice: build.mutation<APIResponse<Invoice>, LineItem>({
      query: (data) => ({
        method: 'POST',
        url: 'payments/invoice/',
        body: data,
      }),
      invalidatesTags: ['invoiceList'],
    }),
    getInvoiceList: build.query<APIListResponse<Invoice>, void>({
      query: () => ({
        method: 'GET',
        url: 'payments/invoice/',
      }),
      providesTags: ['invoiceList'],
    }),
    payInvoice: build.mutation<APIResponse<Invoice>, { invoice_id: string; payment_method_id: string }>({
      query: ({ invoice_id, payment_method_id }) => ({
        method: 'POST',
        url: `payments/invoice/${invoice_id}/pay`,
        body: { payment_method_id },
      }),
      invalidatesTags: ['subscriptionList', 'linkLaunchProductPrices', 'invoiceList'],
    }),
    createSubscription: build.mutation<APIResponse<{ invoice: Invoice }>, CreateSubscriptionParameters>({
      query: ({ lookup_key }) => ({
        method: 'POST',
        url: 'payments/subscription/',
        body: { lookup_key },
      }),
    }),
    getSubscriptionList: build.query<APIListResponse<Subscription>, void>({
      query: () => ({
        method: 'GET',
        url: 'payments/subscription/',
      }),
      providesTags: ['subscriptionList'],
    }),
    updateSubscription: build.mutation<APIResponse<Subscription>, UpdateSubscriptionParameters>({
      query: ({ id, lookup_key, payment_method_id }) => ({
        method: 'PATCH',
        url: `payments/subscription/${id}/`,
        body: { lookup_key, payment_method_id: payment_method_id },
      }),
      invalidatesTags: ['subscriptionList', 'linkLaunchProductPrices'],
    }),
    getLinkLaunchProductPrices: build.query<APIListResponse<Price>, void>({
      query: () => ({
        method: 'GET',
        url: 'payments/link-launch-prices/',
      }),
      providesTags: ['linkLaunchProductPrices'],
      transformResponse: (response: APIListResponse<Price>) => {
        const sorted = response.results.sort((a, b) => {
          return a.unit_amount > b.unit_amount ? 1 : -1;
        });

        return {
          results: sorted,
        };
      },
    }),
  }),
});

const {
  useGetPaymentMethodsQuery,
  useAttachPaymentToCustomerMutation,
  useUpdateCustomerDefaultPaymentMethodMutation,
  useCreateInvoiceMutation,
  useGetInvoiceListQuery,
  usePayInvoiceMutation,
  useCreateSubscriptionMutation,
  useGetSubscriptionListQuery,
  useGetLinkLaunchProductPricesQuery,
  useUpdateSubscriptionMutation,
  useCreateCustomerMutation,
} = paymentAPI;

export {
  useGetPaymentMethodsQuery,
  useAttachPaymentToCustomerMutation,
  useUpdateCustomerDefaultPaymentMethodMutation,
  useCreateInvoiceMutation,
  useGetInvoiceListQuery,
  usePayInvoiceMutation,
  useCreateSubscriptionMutation,
  useGetSubscriptionListQuery,
  useGetLinkLaunchProductPricesQuery,
  useUpdateSubscriptionMutation,
  useCreateCustomerMutation,
};

export default paymentAPI;

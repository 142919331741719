import { FC } from 'react';
import { Button, TextInputField } from '@/components';
import { useCreateURLAudienceMutation } from '@/store/poc/poc.api';
import { Form, FormikProvider, useFormik } from 'formik';
import { LinkIcon } from '@heroicons/react/20/solid';
import { useFilterContext } from '@/hooks/filters';
import cn from '@/utils/style';
import * as yup from 'yup';

const AddURLValidationSchema: yup.SchemaOf<CreateURLAudienceFormValues> = yup.object({
  url: yup.string().url().required('URL is required'),
});

const URLForm: FC = () => {
  const [createURLAudience, { isLoading }] = useCreateURLAudienceMutation();
  const { changePage } = useFilterContext();

  const formik = useFormik<CreateURLAudienceFormValues>({
    onSubmit: async (values, actions) => {
      await createURLAudience(values);
      actions.resetForm();
      changePage(1, undefined);
    },
    initialValues: {
      url: '',
    },
    validationSchema: AddURLValidationSchema,
  });

  return (
    <div className='mb-6 rounded-leap bg-white p-4'>
      <FormikProvider value={formik}>
        <Form>
          <div className='flex items-center gap-x-5 text-left '>
            <TextInputField
              name='url'
              className='pl-7'
              Icon={<LinkIcon className={cn('absolute left-2 w-4', formik.dirty ? 'text-sky-600' : 'text-gray-400')} />}
              placeholder='https://example.com'
            />
            <Button type='submit' isLoading={isLoading}>
              Submit
            </Button>
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default URLForm;

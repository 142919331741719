import { Button, Modal, ModalProps, Pill, TextareaInputField, ToastCard, UploadButton } from '@/components';
import Autocomplete from '@/components/Autocomplete';
import { BASE_API_URL } from '@/constants/application';
import api from '@/store/api';
import { selectAuthToken } from '@/store/auth/auth.selector';
import { useGetCompanyListQuery } from '@/store/company/company.api';
import { useCreateReportMutation } from '@/store/reports/reports.api';
import { Form, FormikProvider, useFormik } from 'formik';
import { ChangeEvent, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

interface LIRImportModalProps extends ModalProps {}

interface ImportFormValues {
  description?: string;
  file: File | null;
}

const formatCompanies = (companies: Company[] | undefined) => {
  if (!companies) {
    return [];
  }

  return companies.map((company) => ({
    label: company.name,
    value: Number(company.id),
  }));
};

const LIRImportModal = ({ isOpen, onClose }: LIRImportModalProps) => {
  const authToken = useSelector(selectAuthToken);
  const { data: companies } = useGetCompanyListQuery();
  const [currentCompany, setCurrentCompany] = useState<number>(0);
  const [selectedCompanies, setSelectedCompanies] = useState<OptionType<number>[]>([]);
  const [search, setSearch] = useState('');
  const uploadButtonRef = useRef<HTMLInputElement>(null);
  const [createReport] = useCreateReportMutation();
  const dispatch = useDispatch();

  const companiesOptions: Array<OptionType<number>> = useMemo(() => {
    if (!search) {
      return formatCompanies(companies);
    }

    return formatCompanies(companies?.filter((company) => company.name.toLowerCase().includes(search.toLowerCase())));
  }, [companies, search]);

  const handleCompanySearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleCompanySelect = (company: number) => {
    setCurrentCompany(company);
    const selectedCompany = companiesOptions.find((c) => c.value === company);
    if (!selectedCompany) {
      return;
    }

    setSelectedCompanies((prev) => Array.from(new Set([...prev, selectedCompany])));
  };

  const handleUploadButtonClick = () => {
    if (uploadButtonRef.current) {
      uploadButtonRef.current.click();
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) {
      return;
    }

    formik.setFieldValue('file', file);
  };

  const ids = useMemo(() => selectedCompanies.map((company) => company.value), [selectedCompanies]);

  const formik = useFormik<ImportFormValues>({
    initialValues: {
      description: '',
      file: null,
    },
    onSubmit: async ({ file, description }, actions) => {
      if (ids.length <= 0 || !file) {
        return;
      }
      actions.setSubmitting(true);
      const {
        data: { id },
      } = await createReport({ name: file.name, description, companies_ids: ids, file }).unwrap();

      try {
        const formData = new FormData();
        formData.append('report_url', file);

        await fetch(`${BASE_API_URL}reports/${id}/upload/`, {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        dispatch(api.util.invalidateTags(['LIRReports']));
        toast(<ToastCard message='File Uploaded' />, { type: 'success' });
      } catch (e) {
        toast(<ToastCard message='Error uploading file' />, { type: 'error' });
      } finally {
        actions.setSubmitting(false);
        onClose && onClose();
      }
    },
  });

  return (
    <Modal isOpen={isOpen} title='Import LIR Report' onClose={onClose} className='w-96 max-w-96'>
      <FormikProvider value={formik}>
        <Form>
          <div className='flex flex-col gap-y-5 text-left'>
            <UploadButton
              fileUploaded={Boolean(formik.values.file)}
              className='justify-center'
              accept='.pdf'
              title='Link Impact Report'
              uploadButtonRef={uploadButtonRef}
              onClick={handleUploadButtonClick}
              onChange={handleFileChange}
            />
            <TextareaInputField name='description' label='Description' />
            <div className='space-y-2'>
              <Autocomplete label='Companies' onSelect={handleCompanySelect} value={currentCompany} options={companiesOptions} onSearch={handleCompanySearch} />
              {selectedCompanies.length > 0 && <p>Selected Companies:</p>}
              <div className='flex max-h-16 w-full flex-wrap gap-2 overflow-auto'>
                {selectedCompanies.map((company) => (
                  <Pill key={company.value} onClick={() => setSelectedCompanies((prev) => prev.filter((c) => c.value !== company.value))}>
                    {company.label}
                  </Pill>
                ))}
              </div>
            </div>
            <Button disabled={!ids.length || !formik.values.file} isLoading={formik.isSubmitting} variant='primary' type='submit'>
              Submit
            </Button>
          </div>
        </Form>
      </FormikProvider>
    </Modal>
  );
};

export default LIRImportModal;

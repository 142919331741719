import linklaunch from '@/assets/images/linklaunch.png';
import { GoogleSSOButton, Button, Input, ErrorMessage } from '@/components';
import { useLoginMutation, useLoginWithProviderMutation } from '@/store/auth/auth.api';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import { selectAuthToken } from '@/store/auth/auth.selector';
import { fetchAccessToken } from '@/store/auth/auth.slice';
import store from '@/store/store';
import { CodeResponse } from '@react-oauth/google';
import { FC, useCallback, useLayoutEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { LoaderFunction, useLocation, useNavigate } from 'react-router-dom';
import { LoginForm } from './components';

interface LoginData {
  passwordRequired: boolean;
  showPassword: boolean;
  credential: string;
  loginToken?: string;
  email?: string;
  password?: string;
  message?: string;
}

const Login: FC = () => {
  const token = useSelector(selectAuthToken);
  const navigate = useNavigate();
  const [login, { isLoading, error }] = useLoginMutation();
  const [loginWithProvider, { isLoading: loadingSSO, error: SSOError }] = useLoginWithProviderMutation();
  const [loginData, setLoginData] = useState<LoginData>({
    passwordRequired: false,
    showPassword: false,
    credential: '',
    loginToken: '',
    email: '',
    password: '',
    message: '',
  });
  const { state } = useLocation();

  const setLoginDataKey = useCallback((key: keyof LoginData, value: boolean | string) => setLoginData((i) => ({ ...i, [key]: value })), []);

  useLayoutEffect(() => {
    if (token) {
      if (!state) {
        return navigate('/', { replace: true });
      }

      const { from } = state;

      if (!from) {
        return navigate('/', { replace: true });
      }

      const { pathname } = from;

      if (pathname === '/profile') {
        return navigate('/', { replace: true });
      }

      return navigate(from, { replace: true });
    }
  }, [token, navigate, state]);

  const handleLogin = useCallback(
    async ({ credential, password, loginToken }: Partial<LoginData>) => {
      const { error } = (await loginWithProvider({ credential, password, loginToken })) as APILoginResponse;

      if (error && error.data) {
        setLoginDataKey('message', error.data.message);

        if (error.data.action === 'password_request') {
          setLoginData((i) => ({ ...i, passwordRequired: true, loginToken: error.data.data.login_token, email: error.data.data.email }));
        }
      }
    },
    [loginWithProvider, setLoginDataKey],
  );

  const handleSSOButtonLogin = useCallback(
    async (response: CodeResponse) => {
      setLoginDataKey('credential', response.code);
      return await handleLogin({ credential: response.code });
    },
    [handleLogin, setLoginDataKey],
  );

  const handlePasswordInput = (value: string) => {
    setLoginDataKey('password', value);
    setLoginDataKey('message', '');
  };

  return (
    <div className='flex w-screen flex-col items-center gap-y-10 pt-48'>
      <div className='flex w-full max-w-md flex-col items-center justify-center gap-5 gap-y-10 rounded-lg bg-white p-6 shadow-xl'>
        <img src={linklaunch} className='w-80' />
        <div className='flex w-full flex-col gap-y-4'>
          {loginData.passwordRequired && (
            <Fragment>
              <div className='relative flex w-full flex-col gap-2'>
                <Input value={loginData.password} onChange={handlePasswordInput} hasError={false} type={loginData.showPassword ? 'text' : 'password'} />
                <div className='absolute bottom-2 right-2 w-6 cursor-pointer text-gray-400' onClick={() => setLoginDataKey('showPassword', !loginData.showPassword)}>
                  {loginData.showPassword ? <EyeIcon /> : <EyeSlashIcon />}
                </div>
              </div>
              {loginData.message && <ErrorMessage>{loginData.message}</ErrorMessage>}
              <Button
                disabled={!loginData.password}
                type='submit'
                onClick={() => handleLogin({ credential: loginData.credential, password: loginData.password, loginToken: loginData.loginToken })}
                isLoading={isLoading}
                className='w-full'
              >
                Login
              </Button>
            </Fragment>
          )}
          {!loginData.passwordRequired && (
            <Fragment>
              <LoginForm isLoading={isLoading || loadingSSO} onSubmit={login} error={(error || SSOError) as APIError} />
              <div className='flex items-center gap-2'>
                <div className='h-px w-full bg-gray-300'></div>
                <p>or</p>
                <div className='h-px w-full bg-gray-300'></div>
              </div>
              <GoogleSSOButton onClick={handleSSOButtonLogin} isLoading={isLoading || loadingSSO} />
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

const loginLoader: LoaderFunction = async () => {
  const isInitialized = store.getState().auth.isInitialized;

  if (!isInitialized) {
    store.dispatch(fetchAccessToken());
  }

  return null;
};

export default Login;
export { loginLoader };

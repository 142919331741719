import { getScenarioProjectedROI } from '@/utils/campaigns';
import { getCurrencyFormat } from '@/utils/numbers';
import cn from '@/utils/style';
import { FC, useMemo, useState } from 'react';
import PagesIncludedModal from '../PagesIncludedModal';
import { useGetCurrentUserQuery } from '@/store/users/users.api';

interface CampaignInsightsProps {
  scenario: Scenario;
}

interface InsightData {
  linksPerMonth: number;
  totalLinks: number;
  pagesIncluded: number;
  avgLinkGap: number;
  totalLinkGap: number;
  budget: string;
  monthlyInvestment: string;
  totalCost: string;
  costPerLink: string;
  projectedROI: string;
  projectedNetRevenue: string;
}

interface FormattedInsightData {
  linksPerMonth: string;
  totalLinks: string;
  pagesIncluded: string;
  avgLinkGap: string;
  totalLinkGap: string;
  budget: string;
  monthlyInvestment: string;
  totalCost: string;
  costPerLink: string;
  projectedROI: string;
  projectedNetRevenue: string;
}

const CARD_TITLES: Record<keyof InsightData, string> = {
  linksPerMonth: 'Links Built Per Month',
  totalLinks: 'Total Links',
  pagesIncluded: 'Pages Included',
  avgLinkGap: 'Average Link Gap',
  totalLinkGap: 'Total Link Gap',
  budget: 'Budget',
  monthlyInvestment: 'Monthly Investment',
  totalCost: 'Total Cost',
  costPerLink: 'Cost Per Link',
  projectedROI: 'Projected ROI',
  projectedNetRevenue: 'Projected Net Revenue',
};

const CampaignInsights: FC<CampaignInsightsProps> = ({ scenario }) => {
  const { data: user } = useGetCurrentUserQuery();
  const showROI = user && user.company && user.company.config.roi_visibility;

  const [isPageIncludedModalOpen, setIsPageIncludedModalOpen] = useState(false);
  const insightData = useMemo<FormattedInsightData>(() => {
    const totalLinkGap = scenario.urls.reduce((acc, url) => {
      if (url.lrd_gap <= 0) {
        return acc;
      }

      return acc + url.lrd_gap;
    }, 0);

    const roi = getScenarioProjectedROI(scenario.roi.summary, scenario.proj_length);

    return {
      linksPerMonth: scenario.links_per_month.toLocaleString(),
      totalLinks: (scenario.links_per_month * scenario.proj_length).toLocaleString(),
      pagesIncluded: scenario.urls.length.toLocaleString(),
      avgLinkGap: Math.round(totalLinkGap / scenario.urls.length).toLocaleString(),
      totalLinkGap: Math.round(totalLinkGap).toLocaleString(),
      budget: getCurrencyFormat((scenario.budget && scenario.budget) || 0),
      monthlyInvestment: getCurrencyFormat(scenario.proj_cost / scenario.proj_length),
      totalCost: getCurrencyFormat(scenario.proj_cost),
      costPerLink: getCurrencyFormat(scenario.cost_per_link),
      projectedROI: `${roi.avg_roi.toLocaleString()} %`,
      projectedNetRevenue: getCurrencyFormat(roi.est_revenue),
    };
  }, [scenario]);

  const handlePagesIncluded = () => {
    setIsPageIncludedModalOpen(true);
  };

  const renderInsights = (key: string, idx: number) => {
    const isPagesIncluded = key === 'pagesIncluded';
    const typedKey = key as keyof InsightData;

    return (
      <div
        key={`${key}-${idx}`}
        className='flex items-center justify-between border-b border-b-slate-300 py-1 text-sm leading-6 last-of-type:border-b-transparent last-of-type:py-0'
      >
        <button className={cn('text-slate-400', isPagesIncluded && 'cursor-pointer text-sky-600 hover:underline')} onClick={isPagesIncluded ? handlePagesIncluded : undefined}>
          {CARD_TITLES[typedKey]}
        </button>
        <b className='whitespace-nowrap'>{insightData[typedKey]}</b>
      </div>
    );
  };

  return (
    <div className='flex w-full flex-col gap-5 rounded-leap bg-white p-5 text-left text-gray-800 shadow-md'>
      <p className='text-xl leading-8'>Campaign Insight</p>
      <div className='flex flex-col gap-1'>
        {Object.keys(insightData)
          .filter((i) => (showROI ? i : !['projectedROI', 'projectedNetRevenue'].includes(i)))
          .map(renderInsights)}
      </div>
      {isPageIncludedModalOpen && <PagesIncludedModal isOpen={isPageIncludedModalOpen} onClose={() => setIsPageIncludedModalOpen(false)} />}
    </div>
  );
};

export default CampaignInsights;

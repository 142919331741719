const PermMediaIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M19 19.5H3V7.5C3 6.95 2.55 6.5 2 6.5C1.45 6.5 1 6.95 1 7.5V19.5C1 20.6 1.9 21.5 3 21.5H19C19.55 21.5 20 21.05 20 20.5C20 19.95 19.55 19.5 19 19.5Z' fill='#94A3B8' />
    <path
      d='M21 4.5H14L12.59 3.09C12.21 2.71 11.7 2.5 11.17 2.5H7C5.9 2.5 5.01 3.4 5.01 4.5L5 15.5C5 16.6 5.9 17.5 7 17.5H21C22.1 17.5 23 16.6 23 15.5V6.5C23 5.4 22.1 4.5 21 4.5ZM18 13.5H10C9.59 13.5 9.35 13.03 9.6 12.7L10.98 10.87C11.18 10.6 11.58 10.6 11.78 10.87L13 12.5L15.22 9.53C15.42 9.26 15.82 9.26 16.02 9.53L18.4 12.7C18.65 13.03 18.41 13.5 18 13.5Z'
      fill='#94A3B8'
    />
  </svg>
);

export default PermMediaIcon;

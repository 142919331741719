import { Button, Checkbox, NumberInputField } from '@/components';
import { Form, FormikProvider, useFormik } from 'formik';
import { FC, useCallback } from 'react';
import * as yup from 'yup';
import TierFields from './TiersField';

interface DefaultCostLinkProps {
  company: Company;
  onSubmit: (values: Partial<CompanyConfig>) => void;
  isLoading?: boolean;
}

type DefaultCostLinkFormValues = {
  default_cost_per_link: number;
  default_cost_tiers: Array<TierValues>;
  default_cost_selected: DefaultCostSelectedType;
};

const EMPTY_TIER: Array<TierValues> = [{ from: 0, to: '', cost: 0 }];

const DefaultCostLinkValidationSchema: yup.SchemaOf<DefaultCostLinkFormValues> = yup.object({
  default_cost_selected: yup.mixed<DefaultCostSelectedType>().required(),
  default_cost_per_link: yup.number().min(1, 'Must be greater than 0').required(),
  default_cost_tiers: yup
    .array()
    .of(
      yup.object({
        cost: yup.number().min(0, 'Must be greater than 0').required(),
        from: yup.number().min(-1, 'Must be equal or greater than 0').required(),
        to: yup.number().when(['default_cost_selected'], {
          is: (default_cost_selected: DefaultCostSelectedType) => default_cost_selected === 'custom',
          then: yup.number().min(1, 'Must be greater than 0').required(),
        }),
      }),
    )
    .min(1, 'Create at least 1 Tier'),
});

const DefaultCostLinkForm: FC<DefaultCostLinkProps> = ({ company, onSubmit, isLoading }) => {
  const formikDefaultCostLink = useFormik<DefaultCostLinkFormValues>({
    onSubmit: (values) => {
      const tiers = (values.default_cost_tiers || []).map((tier: TierValues, index: number) => {
        const lastTier = index + 1 === values.default_cost_tiers?.length;
        return lastTier ? { ...tier, to: '' } : tier;
      });

      onSubmit({ ...values, default_cost_tiers: tiers as Array<TierValues> });
    },
    validationSchema: DefaultCostLinkValidationSchema,
    initialValues: {
      default_cost_selected: company.config.default_cost_selected,
      default_cost_per_link: company.config.default_cost_per_link,
      default_cost_tiers: company.config.default_cost_tiers.length ? company.config.default_cost_tiers : EMPTY_TIER,
    },
    enableReinitialize: true,
  });

  const { default_cost_selected, default_cost_per_link } = formikDefaultCostLink.values;

  const handleDefaultCostCheckbox = useCallback((type: DefaultCostSelectedType) => formikDefaultCostLink.setFieldValue('default_cost_selected', type), [formikDefaultCostLink]);

  return (
    <div className='my-4 flex'>
      <div className='w-full rounded-leap bg-white px-4 shadow-md'>
        <FormikProvider value={formikDefaultCostLink}>
          <Form>
            <p className='mt-4 text-left text-xl font-bold'>Default Cost / Link</p>
            <div className='mb-4 mt-12 flex'>
              <Checkbox checked={default_cost_selected === 'static'} onChange={() => handleDefaultCostCheckbox('static')} />
              <p className='px-4 '>Static Price</p>
            </div>
            <NumberInputField
              disabled={default_cost_selected !== 'static'}
              name='default_cost_per_link'
              placeholder='Enter cost'
              suffix='$ for one link'
              className='bg-base-leap'
              containerClassName=''
            />
            <div className='mt-4 flex'>
              <Checkbox className='mr-2' checked={default_cost_selected === 'custom'} onChange={() => handleDefaultCostCheckbox('custom')} />
              <p className='text-left'>Custom Price</p>
            </div>
            <TierFields name='default_cost_tiers' defaultCostSelected={default_cost_selected} defaultCostPerLink={default_cost_per_link} />
            <div className='mt-4 flex justify-end'>
              {formikDefaultCostLink.dirty && (
                <Button type='submit' isLoading={isLoading}>
                  Save
                </Button>
              )}
            </div>
            <div className='my-6'></div>
          </Form>
        </FormikProvider>
      </div>
    </div>
  );
};

export default DefaultCostLinkForm;

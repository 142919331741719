const uppercaseFirstChar = (text: string): string => {
  if (!text) {
    return '';
  }

  return text[0].toUpperCase() + text.slice(1);
};

const isEmpty = (val: string) => val.trim() === '';
const isNotEmpty = (val: string) => !isEmpty(val);

export { uppercaseFirstChar, isEmpty, isNotEmpty };

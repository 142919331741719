import Button from '@/components/Button';
import Checkbox from '@/components/Checkbox';
import Modal from '@/components/Modal';
import { selectTablesConfig } from '@/store/app/app.selector';
import { setTableConfig } from '@/store/app/app.slice';
import { AppDispatch } from '@/store/store';
import { COLUMN_TEXTS } from '@/utils/table';
import { Column, Table, VisibilityState } from '@tanstack/react-table';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface FilterColumnsProps<T> {
  table: Table<T>;
  name: Tables;
}

const FilterColumns = <T extends unknown>({ table, name }: FilterColumnsProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const tableConfig = useSelector(selectTablesConfig);
  const dispatch = useDispatch<AppDispatch>();
  const tableVisibility = useMemo(() => tableConfig[name]?.visibility || {}, [tableConfig, name]);
  const [selectedColumns, setSelectedColumns] = useState<VisibilityState>(tableVisibility);
  const isSelectAllChecked = useMemo(() => Object.keys(selectedColumns).every((col) => selectedColumns[col]), [selectedColumns]);

  useEffect(() => {
    table.setColumnVisibility((_: VisibilityState) => tableVisibility);
  }, [table, tableVisibility]);

  const closeModal = useCallback(() => setIsOpen(false), []);

  const handleConfirm = () => {
    table.setColumnVisibility((old: VisibilityState) => ({
      ...old,
      ...selectedColumns,
    }));
    dispatch(setTableConfig({ name, config: { visibility: selectedColumns } }));
    closeModal();
  };

  const handleCancel = () => {
    setSelectedColumns(tableConfig[name]?.visibility || {});
    closeModal();
  };

  const handleToggleAll = () => {
    setSelectedColumns((oldColumns: VisibilityState) => {
      return Object.keys(oldColumns).reduce((prev: VisibilityState, curr: string) => {
        prev[curr] = !isSelectAllChecked;

        return prev;
      }, {});
    });
  };

  const renderColumns = (column: Column<T>) => {
    const isChecked = Boolean(selectedColumns[column.id]);

    const handleToggle = () => {
      setSelectedColumns((oldColumns) => ({
        ...oldColumns,
        [column.id]: !isChecked,
      }));
    };

    return (
      <div key={column.id} className='flex cursor-pointer items-center gap-4 py-px hover:bg-blue-200' onClick={handleToggle}>
        <Checkbox checked={isChecked} readOnly />
        <p>{COLUMN_TEXTS[column.id]}</p>
      </div>
    );
  };

  return (
    <Fragment>
      <Button onClick={() => setIsOpen(true)}>Filter Columns</Button>
      {isOpen && (
        <Modal isOpen={isOpen} title='Customize Columns' onClose={handleCancel} onConfirm={handleConfirm}>
          <div className='flex w-96 flex-col'>
            <div className='py-4'>
              <div className='flex cursor-pointer items-center gap-4 py-px hover:bg-blue-200' onClick={handleToggleAll}>
                <Checkbox checked={isSelectAllChecked} readOnly />
                <p>Select All</p>
              </div>
              {table.getAllColumns().map(renderColumns)}
            </div>
          </div>
        </Modal>
      )}
    </Fragment>
  );
};

export default FilterColumns;

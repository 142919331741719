import { Modal, ModalProps, SuccessModal, TextInputField } from '@/components';
import { useUpdateCompanyMutation } from '@/store/company/company.api';
import { FormikProvider, useFormik } from 'formik';
import { FC, Fragment } from 'react';
import { Form, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import * as yup from 'yup';

const editCompanyValidationSchema: yup.SchemaOf<NewCompanyValues> = yup.object({
  name: yup.string().required(),
});

const EditCompanyModal: FC<ModalProps> = ({ isOpen }) => {
  const navigate = useNavigate();
  const { companyId } = useParams() as { companyId: string };
  const [searchParams] = useSearchParams();
  const [updateCompany, { isLoading, isSuccess }] = useUpdateCompanyMutation();

  const navigateToCompanies = () => navigate('/user-management/companies');

  const formik = useFormik<NewCompanyValues>({
    onSubmit: async (values) => await updateCompany({ companyId, data: { name: values.name } }),
    initialValues: { name: searchParams.get('name') ?? '' },
    validationSchema: editCompanyValidationSchema,
  });

  return (
    <Fragment>
      <Modal
        isOpen={isOpen}
        onClose={navigateToCompanies}
        title='Edit Company'
        className='min-w-modal'
        confirmButtonText='Edit'
        onConfirm={formik.handleSubmit}
        isLoading={isLoading}
      >
        <FormikProvider value={formik}>
          <Form>
            <div className='flex w-full flex-col items-end gap-y-5 text-left'>
              <TextInputField name='name' label='Name' placeholder='Enter company name' />
            </div>
          </Form>
        </FormikProvider>
      </Modal>
      {isSuccess && <SuccessModal isOpen={isSuccess} message='New Company was successfully created' onConfirm={() => navigateToCompanies()} />}
    </Fragment>
  );
};

export default EditCompanyModal;

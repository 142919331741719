import { Checkbox, PriorityCell, SERPFeaturesCell } from '@/components';
import { round } from '@/utils/numbers';
import { ClipboardDocumentListIcon } from '@heroicons/react/24/solid';
import { FilterFnOption, Row, createColumnHelper } from '@tanstack/react-table';
import copy from 'copy-to-clipboard';
import { MouseEvent } from 'react';

type KeywordMetaType = {
  updateData: (rowIndex: number, columnId: keyof RefineCampaignKeyword, value: number | boolean) => void;
  updateEveryRowData: (columnId: keyof RefineCampaignKeyword, value: number | boolean) => void;
};

const columnHelper = createColumnHelper<RefineCampaignKeyword>();

const stringFilter = 'stringFilter' as FilterFnOption<RefineCampaignKeyword>;
const numberFilter = 'numberFilter' as FilterFnOption<RefineCampaignKeyword>;

const sortingById = (rowA: Row<RefineCampaignKeyword>, rowB: Row<RefineCampaignKeyword>) => {
  const a = rowA.getIsSelected();
  const b = rowB.getIsSelected();

  return a === b ? 0 : a ? -1 : 1;
};

const getKeywordsColumns = () => {
  const columns = [
    columnHelper.accessor('id', {
      size: 60,
      header: ({ table }) => {
        const allExcluded = table.getRowModel().rows.every((row) => row.original.excluded);

        const handleChange = (e: MouseEvent<HTMLElement>) => {
          e.stopPropagation();
          (table.options.meta as KeywordMetaType).updateEveryRowData('excluded', !allExcluded);
        };

        return (
          <div className='mx-2 flex w-full items-center'>
            <Checkbox checked={!allExcluded} onClick={handleChange} readOnly />
          </div>
        );
      },
      cell: ({ row, table }) => {
        const excluded = row.original.excluded;

        const handleChange = () => {
          (table.options.meta as KeywordMetaType).updateData(row.index, 'excluded', !excluded);
        };

        return <Checkbox checked={!excluded} onClick={handleChange} readOnly />;
      },
      enableSorting: true,
      sortingFn: sortingById,
      minSize: 30,
      maxSize: 60,
    }),
    columnHelper.accessor('text', {
      header: () => <p className='text-left'>Keyword</p>,
      cell: ({ getValue }) => (
        <div className='grid grid-flow-col grid-rows-1 justify-start'>
          <button className='pr-2' onClick={() => copy(getValue())}>
            <ClipboardDocumentListIcon className='w-5 text-slate-500' />
          </button>
          <p className='line-clamp-1 text-ellipsis break-all' title={getValue()}>
            {getValue()}
          </p>
        </div>
      ),
      filterFn: stringFilter,
    }),
    columnHelper.accessor('target_page', {
      header: () => <p className='text-left'>Target URL</p>,
      cell: ({ getValue }) => (
        <p className='line-clamp-1 text-ellipsis break-all' title={getValue()}>
          {getValue()}
        </p>
      ),
      filterFn: stringFilter,
    }),
    columnHelper.accessor('search_volume', {
      header: () => (
        <p className='text-left'>
          Search <br /> Volume
        </p>
      ),
      cell: ({ getValue }) => <p className='w-full text-left'>{getValue().toLocaleString() || '-'}</p>,
      filterFn: numberFilter,
      size: 145,
    }),
    columnHelper.accessor('campaign_rank', {
      header: () => (
        <p className='text-left'>
          Avg.
          <br />
          Rank
        </p>
      ),
      cell: ({ getValue }) => <p className='w-full text-left'>{getValue()}</p>,
      filterFn: numberFilter,
      size: 100,
    }),
    columnHelper.accessor('difficulty', {
      header: () => (
        <p className='whitespace-nowrap text-left'>
          Difficulty
          <br />
          (SEMR)
        </p>
      ),
      cell: ({ getValue }) => <p className='w-full text-left'>{round(getValue() * 100, 2)}%</p>,
      filterFn: numberFilter,
      size: 100,
    }),
    columnHelper.accessor('rootDomainDiff', {
      header: () => (
        <p className='text-left'>
          Domain
          <br />
          Gap
        </p>
      ),
      cell: ({ getValue }) => <p className='w-full text-left'>{getValue().toLocaleString()}</p>,
      filterFn: numberFilter,
      size: 100,
    }),
    columnHelper.accessor('serpFeaturesByKeyword', {
      header: () => {
        return <p className='text-left'>SERP Features</p>;
      },
      cell: ({ row }) => <SERPFeaturesCell row={row} />,
      enableSorting: false,
    }),
    columnHelper.accessor('priority', {
      header: ({ table }) => {
        const rows = table.getRowModel().rows;
        const everyRowPriority = rows.reduce((acc, row) => acc + row.original.priority, 0);
        const val = everyRowPriority === rows.length ? 1 : everyRowPriority === rows.length * 2 ? 2 : 0;

        const handlePriorityChange = (priority: number) => (table.options.meta as KeywordMetaType).updateEveryRowData('priority', priority);

        return (
          <div className='mx-2 flex w-full flex-col items-center justify-center gap-2'>
            <PriorityCell val={val} onClick={handlePriorityChange} />
          </div>
        );
      },
      cell: ({ table, row, getValue }) => {
        const handlePriorityChange = (priority: number) => {
          (table.options.meta as KeywordMetaType).updateData(row.index, 'priority', priority);
        };

        return (
          <div className='flex w-full flex-col items-center justify-center gap-2' key={row.index}>
            <PriorityCell val={getValue()} onClick={handlePriorityChange} disabled={row.original.excluded} />
          </div>
        );
      },
      size: 65,
    }),
  ];

  return columns;
};

export default getKeywordsColumns;

import { FC, useEffect, useMemo, useState } from 'react';
import Input, { InputProps } from '../Input';
import { useField } from 'formik';
import { Label } from 'recharts';
import ErrorMessage from '../ErrorMessage';

type BetweenInputFieldProps = Omit<InputProps, 'onChange' | 'value'> & {
  name: string;
  label?: string;
};

const BetweenInputField: FC<BetweenInputFieldProps> = ({ name, label, ...textInputProps }) => {
  const [field, meta, helpers] = useField<string>(name);
  const [minInput, setMinInput] = useState('');
  const [maxInput, setMaxInput] = useState('');
  const [minValue = '', maxValue = ''] = useMemo(() => field.value.split('-'), [field.value]);

  const { setValue } = helpers;
  const showError = !!meta.error && meta.touched;

  useEffect(() => {
    setValue(`${minInput}-${maxInput}`);
  }, [minInput, maxInput]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleMinChange = (val: string) => {
    if (isNaN(Number(val))) {
      return;
    }

    setMinInput(val);
  };

  const handleMaxChange = (val: string) => {
    if (isNaN(Number(val))) {
      return;
    }

    setMaxInput(val);
  };

  return (
    <div className='flex w-full flex-col gap-2'>
      {label && <Label>{label}</Label>}
      <div className='flex flex-nowrap items-center gap-2'>
        <Input {...textInputProps} value={minValue} onChange={handleMinChange} hasError={showError} />
        <Input {...textInputProps} value={maxValue} onChange={handleMaxChange} hasError={showError} />
      </div>
      {showError && <ErrorMessage>{meta.error}</ErrorMessage>}
    </div>
  );
};

export default BetweenInputField;

const OnDemandVideoIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M21 3H3C1.9 3 1 3.9 1 5V17C1 18.1 1.9 19 3 19H8V20C8 20.55 8.45 21 9 21H15C15.55 21 16 20.55 16 20V19H21C22.1 19 22.99 18.1 22.99 17L23 5C23 3.89 22.1 3 21 3ZM20 17H4C3.45 17 3 16.55 3 16V6C3 5.45 3.45 5 4 5H20C20.55 5 21 5.45 21 6V16C21 16.55 20.55 17 20 17ZM14.48 11.87L10.5 14.15C9.83 14.53 9 14.04 9 13.28V8.72C9 7.95 9.83 7.47 10.5 7.85L14.48 10.13C15.15 10.52 15.15 11.48 14.48 11.87Z'
      fill='#94A3B8'
    />
  </svg>
);

export default OnDemandVideoIcon;

import { TARGET_RANK_OPTIONS, TERM_OPTIONS } from '@/constants/defaultValues';
import { Modal, ModalProps, NumberInputField, SelectField, TextInputField } from '@/components';
import { FormikProvider, useFormik } from 'formik';
import { FC } from 'react';
import * as yup from 'yup';

interface EditModalProps extends Omit<ModalProps, 'onConfirm'> {
  scenario: Scenario;
  onConfirm: (values: EditingScenarioValues, isDirty: boolean) => void;
}

const EditScenarioValidationSchema: yup.SchemaOf<EditingScenarioValues> = yup.object({
  title: yup.string().required('Title is required'),
  proj_length: yup.number().required('Term is Required'),
  cost_per_link: yup.number().required('Cost/Link is Required'),
  target_rank: yup.number().required('Target Rank is Required'),
  aov_cltv: yup.number().required('CLTV is Required'),
  cvr: yup.number().required('CVR is Required'),
  budget: yup.string(),
});

const EditModal: FC<EditModalProps> = ({ isOpen, onClose, onConfirm, scenario, isLoading, errorMessage }) => {
  const defaultTargetRank = TARGET_RANK_OPTIONS.find((option) => option.value === scenario.target_rank);
  const formik = useFormik<EditingScenarioValues>({
    onSubmit: (values) => {
      onConfirm(values, formik.dirty);
    },
    initialValues: {
      title: scenario.title,
      proj_length: scenario.proj_length,
      cost_per_link: scenario.cost_per_link,
      target_rank: defaultTargetRank?.value || TARGET_RANK_OPTIONS[2].value,
      aov_cltv: scenario.aov_cltv,
      cvr: scenario.cvr,
      budget: !scenario.budget || scenario.budget === 0 ? '' : scenario.budget.toString(),
    },
    validationSchema: EditScenarioValidationSchema,
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} onConfirm={formik.submitForm} title='Edit Scenario' isLoading={isLoading} errorMessage={errorMessage}>
      <div className='flex w-[600px] flex-col gap-4'>
        <FormikProvider value={formik}>
          <TextInputField name='title' label='Scenario Name' disabled={isLoading} />
          <div className='flex w-full gap-4'>
            <SelectField name='proj_length' label='Term' options={TERM_OPTIONS} disabled={isLoading} />
            <NumberInputField name='cost_per_link' label='Cost/Link' disabled={isLoading} prefix='$' />
          </div>
          <SelectField name='target_rank' label='Target Rank' options={TARGET_RANK_OPTIONS} disabled={isLoading} />
          <div className='flex w-full gap-4'>
            <NumberInputField name='aov_cltv' label='CLTV' disabled={isLoading} prefix='$' />
            <NumberInputField name='cvr' label='CVR' disabled={isLoading} prefix='%' />
          </div>
          <NumberInputField name='budget' label='Budget/month' disabled={isLoading} prefix='$' />
        </FormikProvider>
      </div>
    </Modal>
  );
};

export default EditModal;

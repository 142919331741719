import { PartialKeys } from '@tanstack/react-table';
import { VirtualItem, Virtualizer, VirtualizerOptions, useVirtualizer as tanstackVirtualizer } from '@tanstack/react-virtual';
import { useMemo } from 'react';

type VirtualizerReturnType<TScrollElement extends Element, TItemElement extends Element> = [
  Virtualizer<TScrollElement, TItemElement>,
  Array<VirtualItem<TItemElement>>,
  {
    paddingTop: number;
    paddingBottom: number;
  },
];

const useVirtualizer = <TScrollElement extends Element, TItemElement extends Element>(
  options: PartialKeys<VirtualizerOptions<TScrollElement, TItemElement>, 'observeElementRect' | 'observeElementOffset' | 'scrollToFn'>,
): VirtualizerReturnType<TScrollElement, TItemElement> => {
  const virtualizer = tanstackVirtualizer({ ...options });

  const items = virtualizer.getVirtualItems();
  const [paddingTop, paddingBottom] = useMemo(
    () => (items.length > 0 ? [Math.max(0, items[0].start - virtualizer.options.scrollMargin), Math.max(0, virtualizer.getTotalSize() - items[items.length - 1].end)] : [0, 0]),
    [items, virtualizer],
  );

  return [virtualizer, items, { paddingTop, paddingBottom }];
};

export { useVirtualizer };

import { FC, Fragment } from 'react';
import { BillingHistory, PaymentHeader, UsedCredits, CustomerModal } from './components';
import { useGetCurrentUserQuery } from '@/store/users/users.api';
import { Button } from '@/components';
import { useToggle } from '@/hooks/toggle';
import { useCreateCustomerMutation } from '@/store/payment/payment.api';

const PaymentSettings: FC = () => {
  const { data: user } = useGetCurrentUserQuery();
  const [createCustomer, { isLoading: isCreatingCustomer }] = useCreateCustomerMutation();
  const { value: isModalOpen, toggle: toggleModal } = useToggle();

  const handleSubmitCustomerInfo = async (data: CustomerForm) => {
    await createCustomer(data);
    toggleModal(false);
  };

  if (user && !user.company?.stripe_customer_id) {
    return (
      <Fragment>
        <p className='py-4 font-bold text-red-600'>You need to set your billing information before accessing the other features</p>
        <Button onClick={() => toggleModal(true)}>Set Billing Info</Button>

        <CustomerModal isOpen={isModalOpen} onClose={() => toggleModal(false)} onConfirm={handleSubmitCustomerInfo} userEmail={user.email} isLoading={isCreatingCustomer} />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Fragment>
        <PaymentHeader />
        <section className='grid w-full grid-cols-2 gap-6'>
          <UsedCredits />
          <BillingHistory />
        </section>
      </Fragment>
    </Fragment>
  );
};

export default PaymentSettings;

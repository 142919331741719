import { useVirtualizer } from '@/hooks/virtualizer';
import { selectTablesConfig } from '@/store/app/app.selector';
import { setTableConfig } from '@/store/app/app.slice';
import { AppDispatch } from '@/store/store';
import cn from '@/utils/style';
import { ColumnSort, Row as RowType, Table } from '@tanstack/react-table';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Header, Row } from './components';
import useResizeColumns from '@/hooks/resizeColumns';

export interface VirtualizedTableProps<T> {
  table: Table<T>;
  name: Tables;
  itemHeight?: number;
  overscan?: number;
  isLoading?: boolean;
  className?: string;
  tableClass?: string;
  headerClass?: string;
  rowStyle?: string;
  cellStyle?: string;
  onSortChange?: (columnSort: ColumnSort) => void;
  loadingText?: string | JSX.Element;
  allowResize?: boolean;
  tableExtraHeader?: JSX.Element;
  containerClass?: string;
  subRowContent?: (row: RowType<T>) => JSX.Element;
  style?: React.CSSProperties;
}

const getCustomItems = <T extends unknown>(rows: Array<RowType<T>>) => {
  const itemsWithExtraRow = [];

  // can't iterate over virtual items, they increment every scroll
  for (let idx = 0; idx < rows.length * 2; idx++) {
    if (idx === 0 || idx % 2 === 0) {
      itemsWithExtraRow.push(rows[idx / 2]);
      continue;
    }

    itemsWithExtraRow.push(null);
  }

  return itemsWithExtraRow;
};

const VirtualizedTable = <T extends unknown>({
  table,
  name,
  itemHeight = 60,
  overscan = 2,
  isLoading = false,
  className,
  tableClass,
  headerClass,
  onSortChange,
  rowStyle,
  cellStyle,
  loadingText = 'Loading campaigns...',
  tableExtraHeader,
  containerClass,
  subRowContent,
  allowResize = false,
  style,
}: VirtualizedTableProps<T>) => {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const tableConfig = useSelector(selectTablesConfig);
  const dispatch = useDispatch<AppDispatch>();

  // setting up css variables to control the resizing
  const { colSizes, handleMouseDown } = useResizeColumns({ table });

  const { rows } = table.getRowModel();

  const fullTableSize = useMemo(() => {
    // this object contains the size of each header and column, that it's the same, so we divide it by 2 to get the full size
    return Object.values(colSizes).reduce((acc, size) => acc + size, 0) / 2;
  }, [colSizes]);

  const [virtualizer, items, { paddingTop, paddingBottom }] = useVirtualizer({
    count: table.options.enableExpanding ? rows.length * 2 : rows.length, // duplicate in case of expandable rows
    getScrollElement: () => tableContainerRef.current,
    estimateSize: useCallback(() => itemHeight, [itemHeight]),
    overscan: overscan,
  });

  const tableItems = useMemo(() => {
    if (table.options.enableExpanding) {
      return getCustomItems(rows);
    }

    return rows;
  }, [rows, table]);

  const tableContent = useCallback(() => {
    if (table.getRowModel().rows.length === 0 || isLoading) {
      return (
        <tr>
          <td className='py-2 text-center' colSpan={table.getCenterTotalSize()}>
            {isLoading ? loadingText : 'No data available with those filters'}
          </td>
        </tr>
      );
    }

    return items.map((virtualRow) => {
      const row = tableItems[virtualRow.index] as RowType<T>;

      if (row) {
        return <Row allowResize={allowResize} key={virtualRow.key} row={row} className={rowStyle} cellStyle={cellStyle} virtualRow={virtualRow} virtualizer={virtualizer} />;
      }

      const prevRow = tableItems[virtualRow.index - 1] as RowType<T>;
      return (
        <tr key={virtualRow.key} ref={(node) => virtualizer.measureElement(node)} data-index={virtualRow.index} className={cn(!prevRow.getIsExpanded() && 'hidden')}>
          <td
            className='block'
            style={{
              maxWidth: fullTableSize,
            }}
          >
            {subRowContent ? subRowContent(prevRow) : null}
          </td>
        </tr>
      );
    });
  }, [isLoading, table, items, loadingText, cellStyle, rowStyle, virtualizer, tableItems, subRowContent, allowResize, fullTableSize]);

  useEffect(() => {
    dispatch(setTableConfig({ name, config: { ...tableConfig[name], sorting: table.options.state.sorting } }));
  }, [table.options.state.sorting]); //eslint-disable-line

  return (
    <div ref={tableContainerRef} className={cn('max-h-[500px] min-h-[100px] w-full overflow-x-auto', containerClass)} style={{ direction: 'ltr', ...style }}>
      <div className={cn(className)}>
        <table className={cn('min-w-full rounded-md tabular-nums shadow-lg', tableClass)} style={{ width: !allowResize ? table.getCenterTotalSize() : '100%', ...colSizes }}>
          <thead className='sticky top-0 z-10 shadow-sm'>
            {table.getHeaderGroups().map((headerGroup) => (
              <Header key={headerGroup.id} className={headerClass} headerGroup={headerGroup} onSortChange={onSortChange} allowResize={allowResize} onResize={handleMouseDown} />
            ))}
          </thead>

          {tableExtraHeader && <thead className='sticky top-14 z-10'>{tableExtraHeader}</thead>}

          <tbody className={cn(subRowContent && 'grid', 'bg-white')} style={{ maxWidth: style?.maxWidth }}>
            {!isLoading && paddingTop > 0 && (
              <tr>
                <td style={{ height: `${paddingTop}px` }} />
              </tr>
            )}

            {tableContent()}

            {!isLoading && paddingBottom > 0 && (
              <tr>
                <td style={{ height: `${paddingBottom}px` }} />
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default VirtualizedTable;

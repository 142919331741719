import { Button, Table } from '@/components';
import { useTable } from '@/hooks/table';
import { createColumnHelper } from '@tanstack/react-table';
import { Form, FormikProvider, useFormik } from 'formik';
import { FC, useMemo } from 'react';
import * as yup from 'yup';
import CTRInput from './CTRInput';

interface CTRConfigProps {
  company: Company;
  onSubmit: (data: Partial<CompanyConfig>) => void;
}

type CTRFormValues = {
  ctr_values: CTRValues;
};

type CTRTableData = {
  id: keyof CTRValues;
  key: keyof CTRValues;
  match_type: string;
  match_percentage: number;
};

const CTRSchema: yup.SchemaOf<CTRFormValues> = yup.object({
  ctr_values: yup.object({
    pos_1: yup.number().min(0, 'Must be greater than 0').max(1).required(),
    pos_2: yup.number().min(0, 'Must be greater than 0').max(1).required(),
    pos_3: yup.number().min(0, 'Must be greater than 0').max(1).required(),
    pos_4: yup.number().min(0, 'Must be greater than 0').max(1).required(),
    pos_5: yup.number().min(0, 'Must be greater than 0').max(1).required(),
    pos_6: yup.number().min(0, 'Must be greater than 0').max(1).required(),
    pos_7: yup.number().min(0, 'Must be greater than 0').max(1).required(),
    pos_8: yup.number().min(0, 'Must be greater than 0').max(1).required(),
    pos_9: yup.number().min(0, 'Must be greater than 0').max(1).required(),
    pos_10: yup.number().min(0, 'Must be greater than 0').max(1).required(),
    pos_20_plus: yup.number().min(0, 'Must be greater than 0').max(1).required(),
  }),
});

const columnHelper = createColumnHelper<CTRTableData>();

const matchTypeMapper: Record<keyof CTRValues, string> = {
  pos_1: 'CTR for position 1',
  pos_2: 'CTR for position 2',
  pos_3: 'CTR for position 3',
  pos_4: 'CTR for position 4',
  pos_5: 'CTR for position 5',
  pos_6: 'CTR for position 6',
  pos_7: 'CTR for position 7',
  pos_8: 'CTR for position 8',
  pos_9: 'CTR for position 9',
  pos_10: 'CTR for position 10',
  pos_20_plus: 'CTR for position 20+',
};

const useCTRTableColumns = () => [
  columnHelper.accessor('match_type', {
    header: () => <p className='text-left'>Match Type</p>,
    cell: ({ getValue }) => <p className='overflow-hidden text-ellipsis whitespace-nowrap text-left'>{getValue()}</p>,
  }),
  columnHelper.accessor('match_percentage', {
    header: () => <p className='whitespace-nowrap text-right'>Match %</p>,
    cell: ({ row }) => <CTRInput ctrKey={row.original.key} />,
    size: 50,
  }),
];

const CTRConfig: FC<CTRConfigProps> = ({ onSubmit, company }) => {
  const tableData: Array<CTRTableData> = useMemo(() => {
    return (Object.keys(company.config.ctr_values) as Array<keyof CTRValues>).reverse().map((i) => ({
      key: i,
      id: i,
      match_type: matchTypeMapper[i],
      match_percentage: company.config.ctr_values[i],
    }));
  }, [company]);

  const ctrTableColumns = useCTRTableColumns();

  const [table] = useTable({
    data: tableData,
    columns: ctrTableColumns,
    tableCustomOptions: [],
    tableOptions: {},
  });

  const formikCTR = useFormik<CTRFormValues>({
    onSubmit,
    initialValues: {
      ctr_values: company.config.ctr_values,
    },
    validationSchema: CTRSchema,
    enableReinitialize: true,
  });

  return (
    <div className='h-1/4 w-1/2 overflow-auto rounded-leap bg-white px-4 py-6 shadow-md'>
      <p className='text-left text-xl font-bold'>Click Through Rate</p>
      <FormikProvider value={formikCTR}>
        <Form>
          <Table table={table} loadingText='Loading...' name='advancedSettingsCtrValues' tableClass='shadow-none w-full' cellStyle='border-b-0' />
          <div className='m-4 mb-2 flex justify-end'>{formikCTR.dirty && <Button type='submit'>Save</Button>}</div>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default CTRConfig;

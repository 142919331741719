import { SuspenseWrapper } from '@/components';
import { Page } from '@/layouts';
import store from '@/store';
import campaignAPI from '@/store/campaign/campaign.api';
import usersAPI from '@/store/users/users.api';
import { FC } from 'react';
import { LoaderFunction, defer, useLoaderData } from 'react-router-dom';
import NewCampaignSkeleton from './NewCampaignSkeleton';
import NewCampaignContainer from './components/NewCampaignContainer';

export type NewCampaignLoaderData = [APIListResponse<Country>, User];

export const newCampaignLoader: LoaderFunction = () => {
  const countryListPromise = store.dispatch(campaignAPI.endpoints.getCountries.initiate());
  const userPromise = store.dispatch(usersAPI.endpoints.getCurrentUser.initiate());

  try {
    const data = Promise.all([countryListPromise.unwrap(), userPromise.unwrap()]);
    return defer({ data });
  } catch (error) {
    return {
      error: error as APIError,
    };
  } finally {
    countryListPromise.unsubscribe();
    userPromise.unsubscribe();
  }
};

const NewCampaign: FC = () => {
  const { data, error } = useLoaderData() as PageLoaderDefData<NewCampaignLoaderData>;

  return (
    <SuspenseWrapper resolve={data} error={error} fallback={<NewCampaignSkeleton />}>
      <Page>
        <Page.TopBar title='New Campaign' />
        <NewCampaignContainer />
      </Page>
    </SuspenseWrapper>
  );
};

export default NewCampaign;

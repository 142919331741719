import { FC } from 'react';
import Modal, { ModalProps } from '../Modal';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import TextareaInputField from '../TextareaInputField';
import SelectField from '../SelectField';

interface FeedbackModalProps extends Omit<ModalProps, 'onConfirm'> {
  onConfirm: (values: FeedbackFormValues) => void;
}

const FEEDBACK_TYPE_OPTIONS: Array<OptionType<FeedbackType>> = [
  { label: 'Report a Bug', value: 'bug' },
  { label: 'Provide Feedback', value: 'feedback' },
  { label: 'Submit an Idea', value: 'idea' },
];

const feedbackValidationSchema: yup.SchemaOf<FeedbackFormValues> = yup.object({
  message: yup.string().required('message is required'),
  type: yup.mixed().oneOf(['bug', 'feedback', 'idea']).required('type is required'),
});

const FeedbackModal: FC<FeedbackModalProps> = ({ isOpen, onConfirm, onClose, isLoading, errorMessage }) => {
  const formik = useFormik<FeedbackFormValues>({
    onSubmit: onConfirm,
    initialValues: {
      type: 'feedback',
      message: '',
    },
    validationSchema: feedbackValidationSchema,
  });

  return (
    <Modal
      isOpen={isOpen}
      title='Feedback'
      onConfirm={formik.handleSubmit}
      onClose={onClose}
      className='min-w-modal'
      isLoading={isLoading}
      confirmButtonText='Send'
      errorMessage={errorMessage}
    >
      <FormikProvider value={formik}>
        <Form>
          <div className='flex flex-col gap-y-6'>
            <SelectField name='type' options={FEEDBACK_TYPE_OPTIONS} label='Feedback Type' />
            <TextareaInputField name='message' label='Message' />
          </div>
        </Form>
      </FormikProvider>
    </Modal>
  );
};

export default FeedbackModal;

import { SVGProps } from 'react';

const UserIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='28' height='29' viewBox='0 0 28 29' fill='currentColor' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_1977_28193)'>
      <path
        d='M14 0.5C6.26882 0.5 0 6.76759 0 14.4994C0 22.2312 6.26821 28.4988 14 28.4988C21.7324 28.4988 28 22.2312 28 14.4994C28 6.76759 21.7324 0.5 14 0.5ZM14 4.68598C16.5582 4.68598 18.6312 6.7596 18.6312 9.31657C18.6312 11.8742 16.5582 13.9472 14 13.9472C11.443 13.9472 9.37003 11.8742 9.37003 9.31657C9.37003 6.7596 11.443 4.68598 14 4.68598ZM13.9969 24.8386C11.4455 24.8386 9.10867 23.9094 7.30625 22.3714C6.86717 21.9969 6.61381 21.4477 6.61381 20.8715C6.61381 18.2783 8.71264 16.2028 11.3065 16.2028H16.6947C19.2892 16.2028 21.38 18.2783 21.38 20.8715C21.38 21.4483 21.1279 21.9963 20.6882 22.3708C18.8864 23.9094 16.549 24.8386 13.9969 24.8386Z'
        fill='#0284C7'
      />
    </g>
    <defs>
      <clipPath id='clip0_1977_28193'>
        <rect width='28' height='28' fill='white' transform='translate(0 0.5)' />
      </clipPath>
    </defs>
  </svg>
);

export default UserIcon;

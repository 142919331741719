import { BellIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { FC } from 'react';
import { SideMenuItem } from '../SideBar/components';

const NotificationButton: FC<{ isSidebarOpen: boolean; hasPendingNotifications?: boolean }> = ({ isSidebarOpen, hasPendingNotifications }) => {
  return (
    <div className='relative'>
      <SideMenuItem
        isSidebarOpen={isSidebarOpen}
        Icon={BellIcon}
        title='Notifications'
        allowNotification={hasPendingNotifications}
        notificationContent={<ExclamationCircleIcon className='w-5 text-red-500' />}
      />
    </div>
  );
};

export default NotificationButton;

import { SVGProps } from 'react';

const TokenIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox='0 0 22 22' fill='currentColor' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M11 0.5C5.21 0.5 0.5 5.21 0.5 11C0.5 16.79 5.21 21.5 11 21.5C16.79 21.5 21.5 16.79 21.5 11C21.5 5.21 16.79 0.5 11 0.5ZM11 18.5C6.8675 18.5 3.5 15.1325 3.5 11C3.5 6.8675 6.8675 3.5 11 3.5C15.1325 3.5 18.5 6.8675 18.5 11C18.5 15.1325 15.1325 18.5 11 18.5Z'
      fill='currentColor'
    />
    <path
      d='M11 5C7.6925 5 5 7.6925 5 11C5 14.3075 7.6925 17 11 17C14.3075 17 17 14.3075 17 11C17 7.6925 14.3075 5 11 5ZM14.18 11.9525L13.3175 12.425C12.9425 12.6275 12.635 12.935 12.425 13.3175L11.9525 14.18C11.7575 14.525 11.3975 14.7425 11 14.7425C10.6025 14.7425 10.2425 14.525 10.0475 14.18L9.575 13.3175C9.365 12.935 9.0575 12.6275 8.6825 12.425L7.82 11.9525C7.4675 11.765 7.25 11.3975 7.25 11C7.25 10.6025 7.4675 10.235 7.82 10.0475L8.6825 9.575C9.0575 9.3725 9.365 9.065 9.575 8.6825L10.0475 7.82C10.2425 7.475 10.6025 7.2575 11 7.2575C11.3975 7.2575 11.7575 7.475 11.9525 7.82L12.425 8.6825C12.635 9.0575 12.9425 9.3725 13.3175 9.575L14.18 10.0475C14.5325 10.235 14.75 10.6025 14.75 11C14.75 11.3975 14.5325 11.765 14.18 11.9525Z'
      fill='currentColor'
    />
  </svg>
);

export default TokenIcon;

import { deepmerge } from 'deepmerge-ts';
import { APP_CONFIG } from '@/constants/defaultValues';

const AUTH_TOKEN = 'AUTH_TOKEN';
const CONFIG = 'CONFIG';
const AUTH_USER = 'AUTH_USER';

const setAuthToken = (token: string) => localStorage.setItem(AUTH_TOKEN, token);
const getAuthToken = () => localStorage.getItem(AUTH_TOKEN);
const removeToken = () => localStorage.removeItem(AUTH_TOKEN);

const setAuthUser = (user: UserJSON) => localStorage.setItem(AUTH_USER, JSON.stringify(user));
const getAuthUser = () => JSON.parse(localStorage.getItem(AUTH_USER) || 'null');
const removeUser = () => localStorage.removeItem(AUTH_USER);

const setAppConfig = (data: AppState) => localStorage.setItem(CONFIG, JSON.stringify(data));

const getAppConfig = (): AppState => {
  const storedConfig = JSON.parse(localStorage.getItem(CONFIG) || '{}');

  return deepmerge(APP_CONFIG, storedConfig);
};

const setScenarioTooltip = (tooltip: TooltipConfig) => {
  const storedConfig = getAppConfig();

  localStorage.setItem(CONFIG, JSON.stringify({ ...storedConfig, tooltip }));
};

const getScenarioTooltip = (id: string): boolean => {
  const { tooltip } = getAppConfig();

  if (!tooltip[id]) {
    return false;
  }

  return tooltip[id].hidden;
};

const getTutorialComplete = () => {
  const { tutorial } = getAppConfig();

  return tutorial.done;
};

const setTutorialComplete = (data: boolean) => {
  const storedConfig = getAppConfig();

  const tutorialData = {
    tutorial: {
      done: data,
    },
  };

  const newConfig = JSON.stringify({ ...storedConfig, ...tutorialData });

  localStorage.setItem(CONFIG, newConfig);
};

export {
  CONFIG,
  setAuthToken,
  removeToken,
  getAuthToken,
  setAuthUser,
  removeUser,
  getAuthUser,
  getAppConfig,
  setAppConfig,
  setTutorialComplete,
  getTutorialComplete,
  setScenarioTooltip,
  getScenarioTooltip,
};

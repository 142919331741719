import { useField } from 'formik';
import { FC } from 'react';
import Label from '../Label';
import Switch, { SwitchProps } from '../Switch';
import cn from '@/utils/style';

type SwitchFieldProps = Omit<SwitchProps, 'value' | 'onChange'> & {
  name: string;
  label?: string;
  labelPosition?: 'left' | 'right';
  className?: string;
};

const SwitchField: FC<SwitchFieldProps> = ({ name, label, labelPosition, className, ...SwitchProps }) => {
  const [field, , helpers] = useField<boolean>(name);
  const { setValue } = helpers;

  return (
    <div className={cn('flex flex-col gap-2', labelPosition === 'left' && 'flex-row', labelPosition === 'right' && 'flex-row-reverse', className)}>
      {label && <Label>{label}</Label>}
      <Switch {...SwitchProps} value={field.value} onChange={setValue} />
    </div>
  );
};

export default SwitchField;

import { Modal, ModalProps, SelectField, SwitchField, TextInputField } from '@/components';
import { selectAuthUser } from '@/store/auth/auth.selector';
import { FormikProvider, useFormik } from 'formik';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { EditUserModalSkeleton } from './EditUserModalSkeleton';
import { useGetCompanyListQuery } from '@/store/company/company.api';

interface EditModalUserProps extends Omit<ModalProps, 'onConfirm'> {
  onConfirm: (values: EditModalUserValues) => void;
  user: User;
}

const ROLE_OPTIONS: Array<OptionType<UserRole>> = [
  {
    label: 'Member',
    value: 'viewer',
  },
  {
    label: 'Admin',
    value: 'manager',
  },
  {
    label: 'Owner',
    value: 'owner',
  },
];

const editUserValidationSchema: yup.SchemaOf<EditModalUserValues> = yup.object({
  first_name: yup.string().required('First name is required'),
  last_name: yup.string().required('Last name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  company: yup.mixed<Company | null>().required('Company is required'),
  config: yup
    .object()
    .shape({
      show_payments: yup.bool().required(),
      show_settings: yup.bool().required(),
      show_users: yup.bool().required(),
      show_linking_context_report: yup.bool().required(),
    })
    .required(),
});

const EditUserModal: FC<EditModalUserProps> = ({ isOpen, onClose, onConfirm, user }) => {
  const authUser = useSelector(selectAuthUser);
  const { data: companies, isLoading, isFetching } = useGetCompanyListQuery();
  const isOwnerOrSuperUser = useMemo(() => {
    if (!authUser) {
      return false;
    }

    return authUser.company?.role === 'owner' || authUser.isSuperuser;
  }, [authUser]);
  const COMPANIES_OPTIONS = useMemo(() => {
    if (!companies) {
      return [];
    }

    return companies.map((company) => ({
      label: company.name,
      value: company.id,
    }));
  }, [companies]);
  const showPermissions = useMemo(() => authUser?.isSuperuser && user.id !== authUser.id, [authUser, user]);

  const formik = useFormik<EditModalUserValues>({
    onSubmit: onConfirm,
    initialValues: {
      first_name: user.firstName,
      last_name: user.lastName,
      email: user.email,
      company: user.company,
      config: {
        show_payments: user.config?.show_payments || false,
        show_settings: user.config?.show_settings || false,
        show_users: user.config?.show_users || false,
        show_linking_context_report: user.config?.show_linking_context_report || false,
      },
    },
    validationSchema: editUserValidationSchema,
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} onConfirm={formik.handleSubmit} title='Edit User' className='min-w-modal' confirmButtonText='Save changes'>
      {isLoading || isFetching ? (
        <EditUserModalSkeleton />
      ) : (
        <FormikProvider value={formik}>
          <div className='flex flex-col gap-y-4'>
            <section className='flex gap-6'>
              <TextInputField name='first_name' label='First Name' />
              <TextInputField name='last_name' label='Last Name' />
            </section>
            <TextInputField name='email' label='Email' disabled />
            <SelectField name='company.id' label='Company' options={COMPANIES_OPTIONS} disabled={!authUser?.isSuperuser} />
            <SelectField name='company.role' label='Role' options={ROLE_OPTIONS} disabled={!isOwnerOrSuperUser} />
            {showPermissions ? (
              <div className='flex w-full flex-col gap-y-2'>
                <SwitchField name='config.show_payments' label='Show Payments' labelPosition='left' className='justify-between' />
                <SwitchField name='config.show_settings' label='Show Settings' labelPosition='left' className='justify-between' />
                <SwitchField name='config.show_users' label='Show Users' labelPosition='left' className='justify-between' />
                <SwitchField name='config.show_linking_context_report' label='Show Linking Context Report' labelPosition='left' className='justify-between' />
              </div>
            ) : null}
          </div>
        </FormikProvider>
      )}
    </Modal>
  );
};

export default EditUserModal;

import { Button, Modal, ChangePasswordForm } from '@/components';
import Page from '@/layouts/Page';
import { logout } from '@/store/auth/auth.slice';
import { AppDispatch } from '@/store/store';
import { ArrowLeftOnRectangleIcon, LockClosedIcon } from '@heroicons/react/24/solid';
import { FC, useCallback } from 'react';
import { useToggle } from '@/hooks/toggle';
import { useDispatch, useSelector } from 'react-redux';
import { ProfileSection } from './components';
import { useChangePasswordMutation } from '@/store/users/users.api';
import { selectAuthUser } from '@/store/auth/auth.selector';

const ProfilePage: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const handleLogout = useCallback(() => dispatch(logout()), [dispatch]);
  const user = useSelector(selectAuthUser);
  const { value: isChangePasswordModalOpen, close: closeChangePasswordModalOpen, toggle: toggleChangePasswordModalOpen } = useToggle({});
  const [changePassword, { error, isLoading }] = useChangePasswordMutation();

  const handleSubmit = useCallback(
    async (values: ChangePasswordFormValues) => {
      const { error } = await changePassword(values).unwrap();
      if (!error) {
        handleLogout();
      }
    },
    [changePassword, handleLogout],
  );

  return (
    <Page>
      <Page.TopBar title='My Profile' className='flex items-center gap-4 '>
        <div className='flex items-center gap-2'>
          {user && !user.isSSO && (
            <Button onClick={() => toggleChangePasswordModalOpen()} variant='outline-light' className='flex items-center whitespace-nowrap'>
              <LockClosedIcon className='mr-2 w-4' />
              Change Password
            </Button>
          )}
          <Button variant='outline-light' className='flex items-center' onClick={handleLogout}>
            <ArrowLeftOnRectangleIcon className='mr-2 w-4' />
            Logout
          </Button>
        </div>
      </Page.TopBar>
      <Page.Body>
        <ProfileSection />
      </Page.Body>

      <Modal isOpen={isChangePasswordModalOpen} title='Change Password' onClose={() => closeChangePasswordModalOpen()} className='min-w-modal'>
        <ChangePasswordForm onSubmit={handleSubmit} isLoading={isLoading} error={error as APIError} />
      </Modal>
    </Page>
  );
};

export default ProfilePage;

import linklaunchBig from '@/assets/images/linklaunch_big.png';
import linklaunchSmall from '@/assets/images/linklaunch_small.png';
import cn from '@/utils/style';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { SideMenu } from './components';
import { useDispatch, useSelector } from 'react-redux';
import { selectSideBarConfig } from '@/store/app/app.selector';
import { AppDispatch } from '@/store/store';
import { setSideBarStatus } from '@/store/app/app.slice';

const SideBar: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { isOpen } = useSelector(selectSideBarConfig);

  const handleMenuToggle = () => dispatch(setSideBarStatus(!isOpen));

  return (
    <div
      className={cn(
        'sticky left-0 z-30 h-screen flex-shrink-0 origin-left transform bg-white text-left text-white duration-100 ease-linear',
        isOpen ? 'w-sideBarOpen' : 'w-sideBarClose -translate-x-[1]',
      )}
    >
      <button
        type='button'
        onClick={handleMenuToggle}
        className={cn(
          'absolute top-[50%] flex items-center justify-center rounded-full border border-brandSlate bg-white duration-100 hover:border-white hover:bg-brandSlate',
          isOpen ? 'left-sideBarOpen' : 'left-sideBarClose rotate-180',
        )}
      >
        <ChevronLeftIcon className='w-6 p-1 text-brandSlate hover:text-white' />
      </button>
      <div className='flex h-navBar max-h-[62px] items-center justify-center pr-2'>
        <img src={isOpen ? linklaunchBig : linklaunchSmall} className={cn('cursor-pointer px-2', isOpen ? 'w-52' : 'w-12')} onClick={() => navigate('/')} />
      </div>
      <div className='mt-10'>
        <SideMenu isOpen={isOpen} />
      </div>
    </div>
  );
};

export default SideBar;

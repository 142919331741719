import { FC } from 'react';
import Loading from '../Loading/Loading';
import { createPortal } from 'react-dom';
import cn from '@/utils/style';

interface LoadingScreenProps {
  className?: string;
  containerClassName?: string;
}

const LoadingScreen: FC<LoadingScreenProps> = ({ className, containerClassName }) => {
  return createPortal(
    <div className={cn('absolute inset-0 z-20 flex items-center justify-center bg-gray-300 opacity-80', containerClassName)}>
      <Loading className={className} />
    </div>,
    document.body,
  );
};

export default LoadingScreen;

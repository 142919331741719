import { Modal, ModalProps } from '@/components';
import { FC } from 'react';

interface DeleteUserModalProps extends Omit<ModalProps, 'onConfirm'> {
  onConfirm: () => void;
  user: User;
}

const DeleteUserModal: FC<DeleteUserModalProps> = ({ isOpen, onClose, onConfirm, user }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} title='Delete User' onConfirm={onConfirm}>
      <div className='flex flex-col items-center gap-y-2'>
        <p>This action cannot be undone.</p>
        <p>
          Are you sure you want to delete the user: <span className='font-bold'>{user.email}</span> from <span className='font-bold'>{user.company?.name}</span>?
        </p>
      </div>
    </Modal>
  );
};

export default DeleteUserModal;

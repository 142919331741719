import { PencilIcon, TrashIcon } from '@heroicons/react/20/solid';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';

interface UseCompaniesTableColumnsParams {
  editAction: (id: string, name: string) => void;
  deleteAction: (id: string) => void;
}

export interface CompaniesTableColumns {
  id: string;
  name: string;
  num_users: number;
  created_at: string;
  credits: string;
}

const columnHelper = createColumnHelper<CompaniesTableColumns>();

const useCompaniesTableColumns = ({ editAction, deleteAction }: UseCompaniesTableColumnsParams) =>
  useMemo(
    () => [
      columnHelper.accessor('name', {
        header: () => <p className='text-left'>Name</p>,
        cell: ({ getValue }) => <p className='text-left'>{getValue()}</p>,
        size: 300,
      }),
      columnHelper.accessor('num_users', {
        header: () => <p className='text-left'>Users</p>,
        cell: ({ getValue }) => <p className='text-left'>{getValue()}</p>,
      }),
      columnHelper.accessor('created_at', {
        header: () => <p className='text-left'>Created Date</p>,
        cell: ({ getValue }) => <p className='text-left'>{getValue()}</p>,
      }),
      columnHelper.accessor('credits', {
        header: () => <p className='text-left'>Credits Remaining</p>,
        cell: ({ getValue }) => <p className='text-left'>{getValue()}</p>,
      }),
      columnHelper.display({
        id: 'actions',
        header: () => <p className='text-right'>Actions</p>,
        cell: ({ row }) => {
          return (
            <div className='flex w-full justify-end gap-4'>
              <button onClick={() => editAction(row.original.id, row.original.name)}>
                <PencilIcon className='size-6 text-gray-400' />
              </button>
              <button onClick={() => deleteAction(row.original.id)}>
                <TrashIcon className='size-6 text-gray-400' />
              </button>
            </div>
          );
        },
      }),
    ],
    [editAction, deleteAction],
  );

export default useCompaniesTableColumns;

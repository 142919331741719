import cn from '@/utils/style';
import { FC } from 'react';

interface ProgressBarProps {
  progress: number;
  color?: string;
  showProgress?: boolean;
  className?: string;
}

const ProgressBar: FC<ProgressBarProps> = ({ progress = 0, color = 'bg-blue-500', showProgress = true, className }) => {
  return (
    <div className={cn('mt-1 flex items-center justify-center gap-2', className || 'w-full')}>
      <div className='h-1 w-full rounded-full bg-gray-300'>
        <div className={cn('block h-1 w-full rounded-full transition', color)} style={{ width: `${progress}%`, transition: 'width 0.5s' }}></div>
      </div>
      {showProgress && <p>{Math.round(progress)}%</p>}
    </div>
  );
};

export default ProgressBar;

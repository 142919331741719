import { createContext, Dispatch, SetStateAction } from 'react';

interface ScenarioReviewContextValues {
  isDirty: boolean;
  setIsDirty: Dispatch<SetStateAction<boolean>>;

  scenarios: Array<Scenario>;
  setScenarios: Dispatch<SetStateAction<Array<Scenario>>>;
  updateScenarios: (scenario: Scenario) => void;

  setApprovedScenario: (scenarioId: number) => void;
  approvedScenario: Scenario | undefined;

  selectedROI: keyof ROISummary | null;
  setSelectedROI: Dispatch<SetStateAction<keyof ROISummary | null>>;
}

const initialValues: ScenarioReviewContextValues = {
  isDirty: false,
  setIsDirty: () => {},

  scenarios: [],
  setScenarios: () => {},
  updateScenarios: () => {},

  setApprovedScenario: () => {},
  approvedScenario: undefined,

  selectedROI: null,
  setSelectedROI: () => {},
};

const ScenarioReviewContext = createContext(initialValues);

export type { ScenarioReviewContextValues };
export { initialValues };
export default ScenarioReviewContext;

const LocalDiningIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7.79922 13.3317L10.6292 10.5017L4.43922 4.32166C3.95922 3.84166 3.12922 3.97166 2.82922 4.59166C2.11922 6.08166 2.37922 7.91166 3.60922 9.15166L7.79922 13.3317ZM14.5792 11.5217C16.1092 12.2317 18.2592 11.7317 19.8492 10.1417C21.7592 8.23166 22.1292 5.49166 20.6592 4.02166C19.1992 2.56166 16.4592 2.92166 14.5392 4.83166C12.9492 6.42166 12.4492 8.57166 13.1592 10.1017L4.10922 19.1517C3.71922 19.5417 3.71922 20.1717 4.10922 20.5617C4.49922 20.9517 5.12922 20.9517 5.51922 20.5617L11.6992 14.4017L17.8792 20.5817C18.2692 20.9717 18.8992 20.9717 19.2892 20.5817C19.6792 20.1917 19.6792 19.5617 19.2892 19.1717L13.1092 12.9917L14.5792 11.5217Z'
      fill='#94A3B8'
    />
  </svg>
);

export default LocalDiningIcon;

import { Meta } from '@/types/table';
import { Header, Table } from '@tanstack/react-table';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

interface useResizeColumnsParams<T> {
  table: Table<T>;
}

type ColumnSizes<T> = Record<string, Required<Omit<Meta<T>, 'columns'>>>;

const TANSTACK_DEFAULT_SIZES = {
  minSize: 20,
  size: 150,
  maxSize: Number.MAX_SAFE_INTEGER,
};

const DEFAULT_MIN_SIZE = 100;
const DEFAULT_SIZE = 150;
const DEFAULT_MAX_SIZE = 800;

const getHeaderCSSVar = (id: string) => `--header-${id}-size`;

const getColumnCSSVar = (id: string) => `--col-${id}-size`;

const useResizeColumns = <T>({ table }: useResizeColumnsParams<T>) => {
  const [currentHeader, setCurrentHeader] = useState<Header<T, unknown> | null>(null);

  const prevX = useRef(0);

  const minSize = useMemo(() => table.options.meta?.minSize || DEFAULT_MIN_SIZE, [table.options.meta]);

  const maxSize = useMemo(() => table.options.meta?.maxSize || DEFAULT_MAX_SIZE, [table.options.meta]);

  const metaSize = useMemo(() => table.options.meta?.size || 0, [table.options.meta]);

  const columnsMetaSize = useMemo(() => table.options.meta?.columns, [table.options.meta?.columns]);

  const defaultColumnSizes = useMemo(() => {
    const fixedCols = table.getAllColumns().filter((column) => column.columnDef.size && column.columnDef.size !== TANSTACK_DEFAULT_SIZES.size);

    const fixedColsSizes = fixedCols.reduce((acc, column) => (acc += column.columnDef.size || 0), 0);

    const responsiveColsLength = table.getAllColumns().length - fixedCols.length;

    const totalMetaSizes = metaSize * table.getAllColumns().length;

    const responsiveColsSizes = (totalMetaSizes - fixedColsSizes) / responsiveColsLength;

    return table.getAllColumns().reduce((acc, { id, columnDef }) => {
      const columnDefSize = columnDef.size && columnDef.size !== TANSTACK_DEFAULT_SIZES.size && columnDef.size;
      const columnDefMinSize = columnDef.minSize && columnDef.minSize !== TANSTACK_DEFAULT_SIZES.minSize && columnDef.minSize;
      const columnDefMaxSize = columnDef.maxSize && columnDef.maxSize !== TANSTACK_DEFAULT_SIZES.maxSize && columnDef.maxSize;

      const columnMetaSize = columnsMetaSize?.[id as keyof T];

      acc[id] = {
        maxSize: columnDefMaxSize || columnMetaSize?.maxSize || maxSize,
        size: columnDefSize || columnMetaSize?.size || responsiveColsSizes || DEFAULT_SIZE,
        minSize: columnDefMinSize || columnMetaSize?.minSize || minSize,
      };

      return acc;
    }, {} as ColumnSizes<T>);
  }, [table, metaSize, maxSize, minSize, columnsMetaSize]);

  const initialColsSizes = useMemo(() => {
    return table.getFlatHeaders().reduce((colSizes, header) => {
      const headerVar = getHeaderCSSVar(header.id);
      const columnVar = getColumnCSSVar(header.column.id);
      const defaultSize = defaultColumnSizes[header.column.id].size;

      colSizes[headerVar] = defaultSize;
      colSizes[columnVar] = defaultSize;
      return colSizes;
    }, {} as Record<string, number>);
  }, [table, defaultColumnSizes]);

  const [colSizes, setColSizes] = useState(initialColsSizes);

  useEffect(() => {
    setColSizes(initialColsSizes);
  }, [initialColsSizes]);

  const handleMouseMove = useCallback(
    (event: globalThis.MouseEvent) => {
      if (!currentHeader) {
        return null;
      }

      if (prevX.current === event.clientX) {
        return null;
      }

      const { id } = currentHeader;
      const headerVar = getHeaderCSSVar(id);
      const columnVar = getColumnCSSVar(id);

      const translation = event.clientX - prevX.current;

      prevX.current = event.clientX;

      const newSize = colSizes[headerVar] + translation;

      const defaultMaxSize = defaultColumnSizes[id].maxSize || maxSize;
      const defaultMinSize = defaultColumnSizes[id].minSize || minSize;

      if (newSize < defaultMinSize || newSize > defaultMaxSize) {
        return null;
      }

      setColSizes((prev) => ({
        ...prev,
        [headerVar]: newSize,
        [columnVar]: newSize,
      }));
    },
    [currentHeader, colSizes, minSize, maxSize, defaultColumnSizes],
  );

  const handleMouseDown = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>, header: Header<T, unknown>) => {
    setCurrentHeader(header);
    prevX.current = event.clientX;
  }, []);

  const removeEventListeners = useCallback(() => {
    window.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('mouseup', removeEventListeners);
  }, [handleMouseMove]);

  const handleMouseUp = useCallback(() => {
    setCurrentHeader(null);
    removeEventListeners();
  }, [removeEventListeners]);

  useEffect(() => {
    if (currentHeader !== null) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      removeEventListeners();
    };
  }, [currentHeader, handleMouseMove, handleMouseUp, removeEventListeners]);

  return { colSizes, handleMouseDown };
};

export default useResizeColumns;

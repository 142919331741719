import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { Dispatch, FC, ReactElement, SetStateAction, useEffect, useMemo, useState } from 'react';
import PriorityKeywordsContent from '../PriorityKeywordsContent';
import AnchorTextMatchContent from '../AnchorTextMatchContent';
import { useSelector } from 'react-redux';
import { selectCampaign, selectScenarios } from '@/store/campaign/campaign.selector';
import cn from '@/utils/style';

interface DisclosureCardProps {
  id: string;
  isOpen: boolean;
  buttonText: string | ((card?: DisclosureCardProps) => ReactElement);
  panelBody: string | ((selectedMethod: MatchMethods, selectedMethodState: Map<MatchMethods, UrlAnchorTextTableColumns[]>) => ReactElement);
  isComplete: boolean;
  disabled: boolean;
}

interface CardTitleProps {
  title: string;
  isComplete?: boolean;
  step: number;
}

const CardTitle: FC<CardTitleProps> = ({ title, isComplete = false, step }) => {
  return (
    <div className='flex items-center gap-4'>
      {isComplete ? (
        <CheckCircleIcon className='w-6 font-bold text-blue-500' />
      ) : (
        <div className='grid h-5 w-5 place-content-center rounded-full bg-blue-500 text-xs font-bold text-white'>{step}</div>
      )}
      <p className='text-lg font-semibold'>{title}</p>
    </div>
  );
};

type DisclosureProps = {
  setCurrentCard: Dispatch<SetStateAction<'priority-keywords' | 'anchor-text-match' | undefined>>;
  selectedMethod: MatchMethods;
  setSelectedMethod: (method: MatchMethods) => void;
  selectedMethodState: Map<MatchMethods, UrlAnchorTextTableColumns[]>;
  setSelectedMethodState: Dispatch<SetStateAction<Map<MatchMethods, UrlAnchorTextTableColumns[]>>>;
};

const Disclosure: FC<DisclosureProps> = ({ setCurrentCard, selectedMethod, selectedMethodState, setSelectedMethod, setSelectedMethodState }) => {
  const campaign = useSelector(selectCampaign);
  const scenarios = useSelector(selectScenarios);

  const approvedScenario = useMemo(() => scenarios.find((s) => s.is_approved), [scenarios]);
  const hasAllocations = useMemo(() => (approvedScenario?.urls || []).some((u) => u.is_allocated), [approvedScenario]);

  const [cards, setCards] = useState<DisclosureCardProps[]>([
    {
      id: 'priority-keywords',
      isOpen: true,
      buttonText: (card) => <CardTitle title='Select Priority Keywords' isComplete={card?.isComplete} step={1} />,
      panelBody: () => <PriorityKeywordsContent onComplete={handlePriorityKeywordCompelte} />,
      isComplete: hasAllocations,
      disabled: false,
    },
    {
      id: 'anchor-text-match',
      isOpen: false,
      buttonText: (card) => <CardTitle title='Select Term and Anchor Text Match' isComplete={card?.isComplete} step={2} />,
      panelBody: (selectedMethod, selectedMethodState) => (
        <AnchorTextMatchContent
          selectedMethod={selectedMethod}
          setSelectedMethod={setSelectedMethod}
          selectedMethodState={selectedMethodState}
          setSelectedMethodState={setSelectedMethodState}
        />
      ),
      disabled: !hasAllocations,
      isComplete: false,
    },
  ]);

  useEffect(() => {
    const currentCard = cards.find((card) => card.isOpen);
    if (currentCard) {
      setCurrentCard(currentCard.id as 'priority-keywords' | 'anchor-text-match');
    }
  }, [cards, setCurrentCard]);

  useEffect(() => {
    if (campaign && campaign.anchors_generation_status === 'in_progress') {
      setCards((old) => old.map((card) => (card.id === 'anchor-text-match' ? { ...card, disabled: false, isOpen: true } : { ...card, isOpen: false, isComplete: true })));
    }
  }, [campaign]);

  function handlePriorityKeywordCompelte() {
    setCards((old) => old.map((card) => (card.id === 'anchor-text-match' ? { ...card, disabled: false, isOpen: true } : { ...card, isOpen: false, isComplete: true })));
  }

  const renderCards = (card: DisclosureCardProps) => {
    const handleClick = () => {
      setCards((old) => old.map((oldCard) => (oldCard.id === card.id ? { ...oldCard, isOpen: !oldCard.isOpen } : { ...oldCard, isOpen: false })));
    };

    return (
      <div key={card.id} className='rounded-lg shadow-md'>
        <button
          className={cn(
            'flex w-full cursor-pointer items-center justify-between rounded-lg bg-white px-4 py-2 text-left focus:outline-none focus-visible:ring focus-visible:ring-opacity-75',
            card.disabled && 'cursor-not-allowed text-gray-400',
          )}
          onClick={handleClick}
          disabled={card.disabled}
        >
          {typeof card.buttonText === 'function' ? card.buttonText(card) : card.buttonText}
        </button>
        {card.isOpen && (
          <div className='rounded-b-lg bg-white px-4 pb-2 pt-4 text-sm text-gray-500'>
            {typeof card.panelBody === 'function' ? card.panelBody(selectedMethod, selectedMethodState) : card.panelBody}
          </div>
        )}
      </div>
    );
  };

  return <div className='flex w-full flex-col gap-4'>{cards.map(renderCards)}</div>;
};

export default Disclosure;
export type { DisclosureCardProps };

import { CodeResponse, useGoogleLogin } from '@react-oauth/google';
import { FC } from 'react';

import googleLogo from '@/assets/images/g-logo.png';

interface GoogleSSOButtonProps {
  email?: string;
  onClick: (resp: CodeResponse) => void;
  isLoading?: boolean;
}

const GoogleSSOButton: FC<GoogleSSOButtonProps> = ({ email, onClick, isLoading = false }) => {
  const loginWithGoogle = useGoogleLogin({
    onSuccess: (response) => onClick(response),
    flow: 'auth-code',
    scope: ['https://www.googleapis.com/auth/userinfo.profile', 'https://www.googleapis.com/auth/userinfo.email'].join(' '),
  });

  return (
    <button
      className='flex w-full items-center justify-center gap-6 rounded-lg border border-gray-300 bg-white p-2 hover:bg-sky-100 active:bg-sky-200 disabled:bg-gray-100 disabled:text-gray-400'
      onClick={() => loginWithGoogle()}
      disabled={isLoading}
    >
      <img className='w-[1.125rem]' src={googleLogo} />
      <p>Sign in with Google</p>
    </button>
  );
};

export default GoogleSSOButton;

import { FONT_SIZES, SCREEN_SIZES } from '@/constants/layout';

const COLUMN_TEXTS: Record<string, string> = {
  name: 'Name',
  status: 'Status',
  keyword_count: 'Keyword Count',
  url_count: 'Url Count',
  failed_urls: 'Failed URLs',
  failed_kws: 'Failed Keywords',
  country: 'Country',
  spreadsheet_id: 'Spreadsheet ID',
};

const CHART_COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d'];

const parseSortFilters = (s: string) => {
  const descOrder = s.at(0) === '-';
  const filedName = descOrder ? s.replace('-', '') : s;

  return { id: filedName, desc: descOrder };
};

// 0.6 is the coeficent to calculate the width for non-monospace fonts (we are currently using Inter as main font, and sans-serif as fallback)
const NON_MONOSPACE_FONT_COEFICIENT = 0.6;

const getUrlMaxLength = (urlLength: number) => {
  const fontSize = window.matchMedia(`(min-width: ${SCREEN_SIZES.xxl}px)`).matches ? FONT_SIZES.base : FONT_SIZES.sm;
  return urlLength * (fontSize * NON_MONOSPACE_FONT_COEFICIENT);
};

export { COLUMN_TEXTS, CHART_COLORS, parseSortFilters, getUrlMaxLength };

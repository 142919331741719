const ApplicationListIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M6.62133 2.5H1.62867C0.730594 2.5 0 3.22582 0 4.11803V9.07805C0 9.97026 0.730594 10.6961 1.62867 10.6961H6.62133C7.51941 10.6961 8.25 9.97026 8.25 9.07805V4.11803C8.25 3.22582 7.51941 2.5 6.62133 2.5Z'
      fill='#94A3B8'
    />
    <path
      d='M6.62133 13.3039H1.62867C0.730594 13.3039 0 14.0297 0 14.9219V19.882C0 20.7742 0.730594 21.5 1.62867 21.5H6.62133C7.51941 21.5 8.25 20.7742 8.25 19.882V14.9219C8.25 14.0297 7.51941 13.3039 6.62133 13.3039Z'
      fill='#94A3B8'
    />
    <path
      d='M23.25 6.22549H11.25C10.8358 6.22549 10.5 5.89187 10.5 5.48039C10.5 5.06891 10.8358 4.73529 11.25 4.73529H23.25C23.6642 4.73529 24 5.06891 24 5.48039C24 5.89187 23.6642 6.22549 23.25 6.22549Z'
      fill='#94A3B8'
    />
    <path
      d='M19.125 9.01961H11.25C10.8358 9.01961 10.5 8.68599 10.5 8.27451C10.5 7.86303 10.8358 7.52941 11.25 7.52941H19.125C19.5392 7.52941 19.875 7.86303 19.875 8.27451C19.875 8.68599 19.5392 9.01961 19.125 9.01961Z'
      fill='#94A3B8'
    />
    <path
      d='M23.25 16.8431H11.25C10.8358 16.8431 10.5 16.5095 10.5 16.098C10.5 15.6866 10.8358 15.3529 11.25 15.3529H23.25C23.6642 15.3529 24 15.6866 24 16.098C24 16.5095 23.6642 16.8431 23.25 16.8431Z'
      fill='#94A3B8'
    />
    <path
      d='M19.125 19.8235H11.25C10.8358 19.8235 10.5 19.4899 10.5 19.0784C10.5 18.667 10.8358 18.3333 11.25 18.3333H19.125C19.5392 18.3333 19.875 18.667 19.875 19.0784C19.875 19.4899 19.5392 19.8235 19.125 19.8235Z'
      fill='#94A3B8'
    />
  </svg>
);

export default ApplicationListIcon;

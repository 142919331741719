import cn from '@/utils/style';
import { CHART_COLORS } from '@/utils/table';

import { memo } from 'react';
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
interface ColumnBarChartProps<T> {
  data: Record<string, T>;
  text?: string | number;
  height?: number;
  width?: string | number;
  className?: string;
}

const ColumnBarChart = <T extends unknown>({ data, text, height = 30, width = '100%', className }: ColumnBarChartProps<T>) => {
  const renderBars = (bar: string, index: number) => {
    return <Bar key={`${bar}-${index}`} dataKey={bar} stackId='graph' fill={CHART_COLORS[index]} isAnimationActive={false} />;
  };

  return (
    <div className={cn('flex flex-grow items-center', className)}>
      <ResponsiveContainer height={height} width={width} className='duration-200 ease-linear hover:scale-110'>
        <BarChart layout='vertical' data={[data]} stackOffset='expand' margin={{ top: 0, bottom: 0 }} barCategoryGap={0}>
          <XAxis hide type='number' />
          <YAxis hide type='category' domain={['min', 'max']} />
          {Object.keys(data).map(renderBars)}
        </BarChart>
      </ResponsiveContainer>
      {text ? <p>{text}</p> : null}
    </div>
  );
};

export default memo(ColumnBarChart);

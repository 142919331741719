import AnalysisCard from '../AnalysisCard';
import { round } from '@/utils/numbers';
import { ArrowTrendingUpIcon, ArrowTrendingDownIcon } from '@heroicons/react/20/solid';

interface AnalysisComparisonCardProps {
  title: string;
  subject: number;
  competitor: number;
  diff: number;
}

const AnalysisComparisonCard = ({ title, subject, competitor, diff }: AnalysisComparisonCardProps) => {
  return (
    <AnalysisCard title={title} className='h-fit w-full text-nowrap'>
      <div className='flex w-full justify-between gap-2'>
        <div className='flex gap-4'>
          <div>
            <div className='flex items-center gap-2'>
              <div className='size-4 rounded-md bg-red-500' />
              <span className='text-base text-black'>{round(subject, 0)}</span>
            </div>
            <p className='text-sm text-gray-500'>Subject</p>
          </div>
          <div>
            <div className='flex items-center gap-2'>
              <div className='size-4 rounded-md bg-sky-500' />
              <span className='text-base text-black'>{round(competitor, 0)}</span>
            </div>
            <p className='text-sm text-gray-500'>Competitor</p>
          </div>
        </div>
        <div>
          <div className='flex items-center justify-center gap-2'>
            {round(diff, 0) > 0 ? <ArrowTrendingUpIcon className='size-4 text-green-500' /> : <ArrowTrendingDownIcon className='size-4 text-red-500' />}
            <span className='text-base text-black'>{round(diff, 0)}</span>
          </div>
          <p className='text-sm text-gray-500'>Difference</p>
        </div>
      </div>
    </AnalysisCard>
  );
};

export default AnalysisComparisonCard;

import { Button, Modal, ModalProps } from '@/components';
import { useDeleteBetaAccessRequestMutation } from '@/store/users/users.api';

interface DenyRequestModalProps extends ModalProps {
  accessRequestId: string;
}

const DenyRequestModal = ({ accessRequestId, onClose, isOpen }: DenyRequestModalProps) => {
  const [deleteBetaAccessRequest, { isLoading }] = useDeleteBetaAccessRequestMutation();

  const handleDeny = async () => {
    await deleteBetaAccessRequest({ id: accessRequestId });
    onClose && onClose();
  };

  return (
    <Modal title='Deny Access Request' isOpen={isOpen} onClose={onClose}>
      <p className='pb-4'>Are you sure you want to deny this access request?</p>
      <div className='flex justify-between gap-2'>
        <Button className='w-1/2' variant='outline-light' onClick={onClose}>
          Cancel
        </Button>
        <Button className='w-1/2' onClick={() => handleDeny()} isLoading={isLoading}>
          Deny
        </Button>
      </div>
    </Modal>
  );
};

export default DenyRequestModal;

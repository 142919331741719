import { Skeleton } from '@/components';
import { useGetCampaignScenarioAnchorsOverviewQuery } from '@/store/campaign/campaign.api';
import { FC, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { AnchorTextOVerviewTable, AnchorTextOverviewGraph } from './components';

const AnchorTextOverview: FC = () => {
  const { campaignId } = useParams() as unknown as { campaignId: number };
  const { data: anchorTextOverview, isLoading } = useGetCampaignScenarioAnchorsOverviewQuery({ campaignId }, { refetchOnMountOrArgChange: true });

  if (isLoading) {
    return (
      <div className='flex flex-col gap-4'>
        <Skeleton className='h-96 w-full rounded-leap py-20'></Skeleton>
        <Skeleton className='h-60 w-full rounded-leap py-20'></Skeleton>
      </div>
    );
  }

  if (!anchorTextOverview || anchorTextOverview.results.length === 0) {
    return (
      <div className='flex h-full w-full justify-center rounded-leap bg-white py-20 text-left text-gray-800 shadow-md'>
        <p className='my-auto text-2xl'>No anchors texts were generated.</p>
      </div>
    );
  }

  return (
    <Fragment>
      <AnchorTextOVerviewTable results={anchorTextOverview.results} />
      <AnchorTextOverviewGraph results={anchorTextOverview.results} />
    </Fragment>
  );
};

export default AnchorTextOverview;

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact, { BugsnagErrorBoundary } from '@bugsnag/plugin-react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';

import App from './App';
import { ErrorFallback } from './components';
import { init as initFullStory } from '@fullstory/browser';
import { BUGSNAG_KEY, GOOGLE_CLIENT_ID, FULLSTORY_ID, FULLSTORY_ENABLED, APP_STAGE, APP_VERSION } from './constants/application';
import './index.css';
import store from './store';

Bugsnag.start({
  apiKey: BUGSNAG_KEY,
  plugins: [new BugsnagPluginReact()],
  onError: ({ errors }) => {
    if (['production', 'staging'].includes(APP_STAGE)) {
      return true;
    }

    console.log(...errors);

    return false;
  },
  releaseStage: APP_STAGE,
  enabledReleaseStages: ['production', 'staging', 'development'],
  appVersion: APP_VERSION,
});

const ErrorBoundary: BugsnagErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React); // eslint-disable-line @typescript-eslint/no-non-null-assertion

if (FULLSTORY_ID) {
  initFullStory({ orgId: FULLSTORY_ID, debug: false, devMode: !FULLSTORY_ENABLED });
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID || ''}>
    <React.StrictMode>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Provider store={store}>
          <App />
        </Provider>
      </ErrorBoundary>
    </React.StrictMode>
  </GoogleOAuthProvider>,
);

import { Input } from '@/components';
import { getNumericValue, round } from '@/utils/numbers';
import { FC, useCallback, useMemo, useState } from 'react';

interface RowPercentageInputProps {
  disabled?: boolean;
  onAfterBlur?: (val: number) => void;
  limit: number;
  initialValue: number;
  hasError?: boolean;
}

const RowPercentageInput: FC<RowPercentageInputProps> = ({ disabled, onAfterBlur, limit, initialValue, hasError }) => {
  const percentageInitialValue = useMemo(() => round((initialValue * 100) / limit, 0), [initialValue, limit]);
  const [inputVal, setInputVal] = useState(percentageInitialValue);

  const handleExactMatchChange = useCallback((val: string) => {
    const numericVal = getNumericValue(val);

    if (numericVal !== 0 && !numericVal && val.trim() !== '') {
      return null;
    }

    setInputVal(Number(numericVal));
  }, []);

  const handleBlur = useCallback(
    (val: string) => {
      const numericVal = getNumericValue(val);
      if (numericVal !== 0 && !numericVal && val.trim() !== '') return null;

      onAfterBlur && onAfterBlur(round((inputVal * limit) / 100, 0));
    },
    [onAfterBlur, inputVal, limit],
  );

  const handleEnter = useCallback(
    (key: string, value: string) => {
      if (key === 'Enter') {
        handleBlur(value);
      }
    },
    [handleBlur],
  );

  return (
    <Input
      value={inputVal}
      onChange={handleExactMatchChange}
      disabled={disabled}
      onBlur={handleBlur}
      onKeyUp={handleEnter}
      hasError={hasError}
      className='w-full text-right'
      suffix='%'
    />
  );
};

export default RowPercentageInput;

import { FC, PropsWithChildren, useCallback } from 'react';
import { INITIAL_PAGE, INITIAL_PAGE_SIZE } from '@/constants/pagination';
import { useQueryStringValues } from '@/hooks/utils';
import filterContext, { FilterContextValues, initialFilters } from './FiltersContext';
import { ColumnSort } from '@tanstack/react-table';

type FiltersProviderProps = PropsWithChildren;

const FiltersProvider: FC<FiltersProviderProps> = ({ children }) => {
  const [queryParameters, appendQueryParameters, deleteQueryParameter, setQueryParameter] = useQueryStringValues<ExtraFilters>(initialFilters);

  const updateFilters = useCallback(
    (filter: Partial<ExtraFilters>) => {
      appendQueryParameters({
        ...filter,
      });
    },
    [appendQueryParameters],
  );

  const removeFilter = useCallback(
    (key: keyof ExtraFilters) => {
      deleteQueryParameter(key);
    },
    [deleteQueryParameter],
  );

  const changePage = useCallback(
    (newPage: number, pageSize: number | undefined) => {
      appendQueryParameters({
        page: newPage,
        page_size: pageSize || INITIAL_PAGE_SIZE,
      });
    },
    [appendQueryParameters],
  );

  const changeSort = useCallback(
    (sort: ColumnSort) => {
      appendQueryParameters({
        sort: [`${sort.desc ? '-' : ''}${sort.id}`],
      });
    },
    [appendQueryParameters],
  );

  const setFilters = useCallback(
    (filter: Partial<ExtraFilters>) => {
      setQueryParameter({
        page: INITIAL_PAGE,
        page_size: INITIAL_PAGE_SIZE,
        sort: [],
        ...filter,
      });
    },
    [setQueryParameter],
  );

  const contextValue: FilterContextValues = {
    filters: queryParameters,
    removeFilter,
    updateFilters,
    changePage,
    changeSort,
    setFilters,
  };

  return <filterContext.Provider value={contextValue}>{children}</filterContext.Provider>;
};

export default FiltersProvider;

import cn from '@/utils/style';
import { ArrowPathIcon } from '@heroicons/react/20/solid';
import { FC } from 'react';

interface LoadingProps {
  className?: string;
}

const Loading: FC<LoadingProps> = ({ className = '' }) => {
  return <ArrowPathIcon className={cn('w-12 animate-spin opacity-60', className)} />;
};

export default Loading;

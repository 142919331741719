import { Input, Label } from '@/components';
import { getNumericValue, round } from '@/utils/numbers';
import { isNotEmpty } from '@/utils/text';
import cn from '@/utils/style';
import { FC, useMemo, useState } from 'react';

interface AnchorTextBulkEditProps {
  readOnly?: boolean;
  onChange: (values: AnchorTextMatches) => void;
  value: UrlAnchorTextTableColumns[];
}

const BULK_EDIT_INPUTS: Record<keyof AnchorTextMatches, string> = {
  exact_match: '% of Anchor Exact Match',
  partial_match: '% of Anchor Partial Match',
  generic_match: '% of Anchor Generic Match',
};

const getBulkValues = (value?: UrlAnchorTextTableColumns) => {
  return {
    exact_match: value ? round((value.exact_match * 100) / value.links_per_month, 0) : 0,
    partial_match: value ? round((value.partial_match * 100) / value.links_per_month, 0) : 0,
    generic_match: value ? round((value.generic_match * 100) / value.links_per_month, 0) : 0,
  };
};

const AnchorTextBulkEdit: FC<AnchorTextBulkEditProps> = ({ readOnly = false, onChange, value }) => {
  const [bulkEditValue, setBulkEditValue] = useState<AnchorTextMatches>(getBulkValues(value.at(0)));

  const bulkValuesSum = useMemo(() => {
    return bulkEditValue.exact_match + bulkEditValue.partial_match + bulkEditValue.generic_match;
  }, [bulkEditValue]);

  const renderInputs = (key: string, idx: number) => {
    const inputKey = key as keyof AnchorTextMatches;

    const handleChange = (val: string) => {
      const numericVal = getNumericValue(val);

      if (numericVal === undefined && isNotEmpty(val)) {
        return null;
      }

      setBulkEditValue((old) => ({
        ...old,
        [inputKey]: Number(numericVal),
      }));
    };

    const handleBlur = (val: string) => {
      const numericVal = getNumericValue(val);

      if (numericVal === undefined && isNotEmpty(val)) {
        return null;
      }

      onChange(bulkEditValue);
    };

    const handleEnter = (key: string, value: string) => {
      if (key === 'Enter') {
        handleBlur(value);
      }
    };

    return (
      <div key={idx} className='flex w-full flex-col flex-nowrap items-start'>
        <Label className='whitespace-nowrap'>{BULK_EDIT_INPUTS[inputKey]}</Label>
        <Input value={bulkEditValue[inputKey]} onChange={handleChange} containerClassName='w-full' onBlur={handleBlur} onKeyUp={handleEnter} disabled={readOnly} />
      </div>
    );
  };

  return (
    <div className='flex flex-row flex-nowrap items-center gap-4'>
      {Object.keys(BULK_EDIT_INPUTS).map(renderInputs)}
      <div className='flex w-full flex-col flex-nowrap items-center'>
        <Label className='whitespace-nowrap'>Total Match</Label>
        <Label className={cn('py-1.5 text-lg font-bold', bulkValuesSum !== 100 && 'text-red-600')}>{bulkValuesSum}%</Label>
      </div>
    </div>
  );
};

export default AnchorTextBulkEdit;

import { Button, Combination, Modal, ModalProps } from '@/components';
import api from '@/store/api';
import { useAddCampaignUrlMutation } from '@/store/campaign/campaign.api';
import { selectKeywords } from '@/store/campaign/campaign.selector';
import { AppDispatch } from '@/store/store';
import { FormikProvider, useFormik } from 'formik';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

interface AddKeywordModalProps extends ModalProps {
  targetPage: string;
}

const AddKeywordModal = ({ isOpen, onClose, onConfirm, targetPage }: AddKeywordModalProps) => {
  const keywords = useSelector(selectKeywords);
  const [addUrl, { isLoading }] = useAddCampaignUrlMutation();
  const dispatch = useDispatch<AppDispatch>();

  const { campaignId, targetPageId } = useParams() as { campaignId: string; targetPageId: string };
  const navigate = useNavigate();

  const currentKeywords = useMemo(() => {
    return keywords.filter((keyword) => keyword.target_page === targetPage).map((keyword) => keyword.text);
  }, [keywords, targetPage]);

  const formik = useFormik({
    initialValues: {
      combination: {
        url: targetPage,
        keywords: currentKeywords,
      },
      keyword_count: 10,
    },
    onSubmit: async (values) => {
      const { status } = await addUrl({
        id: campaignId,
        combinations: [values.combination],
      }).unwrap();
      if (status === 201) {
        dispatch(api.util.invalidateTags(['campaignAnalysis', 'campaignScenarios', 'campaign']));
        onConfirm && onConfirm();
        navigate(`../analysis/${targetPageId}`);
      }
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      footer={<AddKwFooter isLoading={isLoading} onConfirm={formik.handleSubmit} onClose={() => onClose && onClose()} />}
      className='w-modal-xl'
      title='Add Keyword'
    >
      <FormikProvider value={formik}>
        <Combination field='combination' disabled />
      </FormikProvider>
    </Modal>
  );
};

const AddKwFooter = ({ onConfirm, isLoading, onClose }: { onConfirm: () => void; isLoading: boolean; onClose: () => void }) => {
  return (
    <div className='flex w-full justify-center gap-8 px-8'>
      <Button onClick={onClose} type='submit' variant='secondary' className='w-full'>
        Cancel
      </Button>
      <Button onClick={onConfirm} isLoading={isLoading} type='submit' className='w-full'>
        Confirm
      </Button>
    </div>
  );
};

export default AddKeywordModal;

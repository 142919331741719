import { AutocompleteField, Button, Modal, ModalProps, SelectField, SuccessModal, SwitchField, TextInputField, Skeleton } from '@/components';
import { useToggle } from '@/hooks/toggle';
import api from '@/store/api';
import { selectAuthUser } from '@/store/auth/auth.selector';
import { useGetCompanyListQuery } from '@/store/company/company.api';
import { AppDispatch } from '@/store/store';
import { useAddUserMutation, useDeleteBetaAccessRequestMutation } from '@/store/users/users.api';
import { uppercaseFirstChar } from '@/utils/text';
import { PlusCircleIcon } from '@heroicons/react/20/solid';
import { Form, FormikProvider, useFormik } from 'formik';
import { FC, Fragment, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import NewCompanyModal from '../NewCompanyModal';

interface ErrorResponse {
  data: ErrorResponseData;
}

const roles = [
  { label: 'Owner', value: 'owner' },
  { label: 'Manager', value: 'manager' },
  { label: 'Viewer', value: 'viewer' },
];

interface ErrorResponseData {
  email: Array<string>;
  username: Array<string>;
  first_name: Array<string>;
  last_name: Array<string>;
}

const newUserValidationSchema: yup.SchemaOf<NewUserFromValues> = yup.object({
  email: yup.string().email().required(),
  first_name: yup.string().required(),
  last_name: yup.string(),
  company_id: yup.string(),
  config: yup
    .object()
    .shape({
      show_payments: yup.bool().required(),
      show_settings: yup.bool().required(),
      show_users: yup.bool().required(),
      show_linking_context_report: yup.bool().required(),
    })
    .required(),
  role: yup.string().required(),
});

const getErrorMessage = (error: ErrorResponse) => {
  return Object.keys(error.data)
    .filter((i) => i !== 'username')
    .map((i) => error.data[i as keyof ErrorResponseData].map(uppercaseFirstChar).join(' '))
    .join(' | ');
};

interface NewUserModalProps extends ModalProps {
  accessRequestId?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
}

const NewUserModal: FC<NewUserModalProps> = ({ isOpen, accessRequestId, onClose, email, first_name, last_name }) => {
  const authUser = useSelector(selectAuthUser);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [companiesSearch, setCompaniesSearch] = useState('');

  const { data: allCompanies, isLoading: loadingCompanies } = useGetCompanyListQuery();
  const [addUser, { isLoading: addingUser, isSuccess, error }] = useAddUserMutation();
  const [deleteAccessRequest] = useDeleteBetaAccessRequestMutation();

  const { value: isNewCompanyModalOpen, toggle: toggleNewCompanyModal } = useToggle();

  const errorMessage = useMemo(() => {
    if (!error) return '';

    return getErrorMessage(error as ErrorResponse);
  }, [error]);

  const companiesOptions = useMemo(() => {
    if (!allCompanies) return [];

    return allCompanies.reduce((acc, currentCompany) => {
      if (!companiesSearch || currentCompany.name.toLowerCase().includes(companiesSearch.toLowerCase().trim())) {
        return [...acc, { label: currentCompany.name, value: currentCompany.id }];
      }

      return acc;
    }, [] as Array<{ label: string; value: string }>);
  }, [allCompanies, companiesSearch]);

  const formik = useFormik<NewUserFromValues>({
    onSubmit: async (values) => {
      const { error: addUserError } = (await addUser({
        email: values.email,
        username: values.email,
        first_name: values.first_name,
        last_name: values.last_name || '',
        company_id: values.company_id,
        config: values.config,
      })) as APIResponse<UserJSON>;

      if (!addUserError && accessRequestId) {
        await deleteAccessRequest({ id: accessRequestId });
      }
    },
    initialValues: {
      email: email ? email : '',
      first_name: first_name ? first_name : '',
      last_name: last_name ? last_name : '',
      company_id: authUser?.company?.id,
      config: {
        show_payments: false,
        show_settings: false,
        show_users: false,
        show_linking_context_report: false,
      },
      role: '',
    },
    validationSchema: newUserValidationSchema,
  });

  const handleSuccessConfirm = () => {
    dispatch(api.util.invalidateTags(['usersList']));

    if (accessRequestId) {
      return onClose ? onClose() : null;
    }

    return navigate('/user-management/users');
  };

  return (
    <Fragment>
      <Modal
        isOpen={isOpen}
        title='Register New User'
        className='min-w-modal'
        onClose={() => onClose && onClose()}
        onConfirm={formik.submitForm}
        errorMessage={errorMessage}
        confirmButtonText='Send Invitation'
        isLoading={loadingCompanies}
        buttonsLoading={addingUser}
        loadingComponent={
          <div className='min-w-[30rem]'>
            <Skeleton className='h-[35rem]' />
          </div>
        }
      >
        <FormikProvider value={formik}>
          <Form>
            <div className='flex w-full flex-col items-end gap-y-5 text-left'>
              <div className='flex w-full justify-between gap-8'>
                <TextInputField name='first_name' label='First Name' placeholder='Warren' />
                <TextInputField name='last_name' label='Last Name' placeholder='Moore' />
              </div>
              <TextInputField name='email' label='Email' placeholder='warren.moore@example.com' />
              <AutocompleteField
                onSearch={(e) => setCompaniesSearch(e.target.value)}
                name='company_id'
                label='Company'
                options={companiesOptions}
                disabled={!authUser?.isSuperuser}
                creatableButton={
                  authUser?.isSuperuser ? (
                    <Button onClick={() => toggleNewCompanyModal(true)} variant='outline-light' className='flex w-full items-center gap-4'>
                      <PlusCircleIcon className='size-6' />
                      Add New Company
                    </Button>
                  ) : null
                }
              />
              <SelectField name='role' label='Role' options={roles} />
              {authUser?.isSuperuser ? (
                <div className='flex w-full flex-col gap-y-2'>
                  <SwitchField name='config.show_payments' label='Show Payments' labelPosition='left' className='justify-between' />
                  <SwitchField name='config.show_settings' label='Show Settings' labelPosition='left' className='justify-between' />
                  <SwitchField name='config.show_users' label='Show Users' labelPosition='left' className='justify-between' />
                  <SwitchField name='config.show_linking_context_report' label='Show Linking Context Report' labelPosition='left' className='justify-between' />
                </div>
              ) : null}
            </div>
          </Form>
        </FormikProvider>
      </Modal>
      {isNewCompanyModalOpen && (
        <NewCompanyModal
          isOpen={isNewCompanyModalOpen}
          onClose={() => {
            toggleNewCompanyModal(false);
          }}
        />
      )}
      {isSuccess && <SuccessModal isOpen={isSuccess} message='An email with instructions has been sent to the new user' onConfirm={handleSuccessConfirm} />}
    </Fragment>
  );
};

export default NewUserModal;

import api from '../api';

const authAPI = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    login: build.mutation<LoginResponse, LoginValues>({
      query: (data) => ({
        method: 'POST',
        url: 'login/',
        body: data,
      }),
    }),
    loginWithProvider: build.mutation<LoginResponse, SSOLoginValues>({
      query: ({ credential, uid, resetPasswordToken, password, loginToken }) => ({
        method: 'POST',
        url: 'login/sso/',
        body: {
          credential,
          uid,
          reset_password_token: resetPasswordToken,
          password,
          login_token: loginToken,
        },
      }),
    }),
  }),
});

const { useLoginMutation, useLoginWithProviderMutation } = authAPI;

export { useLoginMutation, useLoginWithProviderMutation };

export default authAPI;

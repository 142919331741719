const IndentedIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22 18C22 18.8284 21.3284 19.5 20.5 19.5H9.5C8.67157 19.5 8 18.8284 8 18V15C8 14.1716 8.67157 13.5 9.5 13.5H20.5C21.3284 13.5 22 14.1716 22 15V18Z'
      fill='#94A3B8'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16 9C16 9.82843 15.3284 10.5 14.5 10.5H3.5C2.67157 10.5 2 9.82843 2 9V6C2 5.17157 2.67157 4.5 3.5 4.5H14.5C15.3284 4.5 16 5.17157 16 6V9Z'
      fill='#94A3B8'
    />
  </svg>
);

export default IndentedIcon;

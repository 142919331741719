import { selectSideBarConfig } from '@/store/app/app.selector';
import cn from '@/utils/style';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import { FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';

type PageProps = PropsWithChildren & {
  className?: string;
};

type PageSubcomponentsProps = {
  TopBar: FC<TopBarProps>;
  Body: FC<BodyProps>;
  Footer: FC<FooterProps>;
  className?: string;
};

type TopBarProps = PropsWithChildren & {
  title: string;
  goBack?: () => void;
  className?: string;
};
type BodyProps = PropsWithChildren & { className?: string };
type FooterProps = PropsWithChildren & { className?: string };

const TopBar: FC<TopBarProps> = ({ title, goBack, children, className }) => (
  <nav className={cn('w-full border-b border-b-slate-300 px-8 py-6', className)}>
    <div className='flex w-full max-w-leap-screen items-center justify-between'>
      <div className='flex w-1/2 items-center gap-3'>
        {goBack && (
          <button onClick={goBack}>
            <ArrowLeftIcon className='w-6 text-slate-800' />
          </button>
        )}
        <h2 className='overflow-hidden text-ellipsis whitespace-nowrap text-left font-bold' title={title}>
          {title}
        </h2>
      </div>
      {children}
    </div>
  </nav>
);

const Body: FC<BodyProps> = ({ children, className }) => (
  <section className={cn('mx-auto w-full max-w-leap-screen flex-1 overflow-scroll bg-base-leap py-7 md:p-7', className)}>{children}</section>
);

const Footer: FC<FooterProps> = ({ children, className }) => <footer className={cn('bg-white px-4', className, 'w-full')}>{children}</footer>;

const Page: FC<PageProps> & PageSubcomponentsProps = ({ children, className }) => {
  const { isOpen } = useSelector(selectSideBarConfig);

  return (
    <div className={cn('relative flex max-h-full w-full flex-col duration-500', className, isOpen ? 'max-w-[calc(100vw-230px)]' : 'max-w-[calc(100vw-80px)]')}>{children}</div>
  );
};

Page.propTypes;
Page.TopBar = TopBar;
Page.Body = Body;
Page.Footer = Footer;

export default Page;

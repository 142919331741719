import { Button } from '@/components';
import { getCurrencyFormat, round } from '@/utils/numbers';
import { FC, useMemo } from 'react';
import { useGetCurrentUserQuery } from '@/store/users/users.api';
import { useSearchParams } from 'react-router-dom';
import cn from '@/utils/style';

interface ScenarioReviewCardProps {
  scenario: Scenario;
  roi: keyof ROISummary | null;
  onSelect: (id: number) => void;
  readonly?: boolean;
}

interface ScenarioProposalValues {
  monthlyInvestment: string;
  totalCostForTerm: string;
  costPerLink: string;
  linksBuiltPerMonth: string;
  pagesIncluded: string;
  campaignTerm: number;
  totalLinksToBuild: string;
}

interface ProjectedROIValues {
  averageROI: string;
  projectedRevenue: string;
  cltv: string;
  cvr: string;
}

const mapROITitles: Record<keyof ProjectedROIValues, string> = {
  averageROI: 'Average ROI',
  projectedRevenue: 'Projected Revenue',
  cltv: 'AOV/CLTV',
  cvr: 'CVR',
};

const mapCampaignProposalTitles: Record<keyof ScenarioProposalValues, string> = {
  monthlyInvestment: 'Monthly Investment',
  totalCostForTerm: 'Total Cost for Term',
  costPerLink: 'Cost Per Link',
  linksBuiltPerMonth: 'Links Built Per Month',
  pagesIncluded: 'Pages Included',
  campaignTerm: 'Campaign Term (Months)',
  totalLinksToBuild: 'Total Links To Build',
};

const ScenarioReviewCard: FC<ScenarioReviewCardProps> = ({ scenario, roi, onSelect, readonly }) => {
  const [searchParams] = useSearchParams();
  const { data: user } = useGetCurrentUserQuery(undefined, { skip: searchParams.has('vt') });
  const showROI = (user && user.company && user.company.config.roi_visibility && roi) || searchParams.get('show-roi') === 'true';

  const parsedScenarioProposal = useMemo<ScenarioProposalValues>(() => {
    const { proj_length, cost_per_link, links_per_month } = scenario;
    const monthlyCost = links_per_month * cost_per_link;

    return {
      monthlyInvestment: getCurrencyFormat(monthlyCost || 0),
      totalCostForTerm: getCurrencyFormat((monthlyCost || 0) * proj_length),
      costPerLink: getCurrencyFormat(cost_per_link),
      linksBuiltPerMonth: (links_per_month || 0).toLocaleString(),
      pagesIncluded: scenario.urls.length.toLocaleString(),
      campaignTerm: scenario.proj_length,
      totalLinksToBuild: ((links_per_month || 0) * proj_length).toLocaleString(),
    };
  }, [scenario]);

  const parsedProjectedROI = useMemo<ProjectedROIValues | null>(() => {
    if (!roi || !scenario.roi) {
      return null;
    }

    return {
      averageROI: `${(scenario.roi.summary[roi].avg_roi || 0).toLocaleString()}%`,
      cvr: `${round(scenario.cvr, 2).toFixed(2).toLocaleString()}%`,
      projectedRevenue: getCurrencyFormat(scenario.roi.summary[roi].est_revenue || 0),
      cltv: getCurrencyFormat(scenario.aov_cltv),
    };
  }, [roi, scenario.roi, scenario.aov_cltv, scenario.cvr]);

  const renderROI = (key: string) => {
    if (!parsedProjectedROI) {
      return null;
    }

    const typedKey = key as keyof ProjectedROIValues;

    return (
      <div key={key}>
        <p>{mapROITitles[typedKey]}</p>
        <p className='font-bold'>{parsedProjectedROI[typedKey]}</p>
      </div>
    );
  };

  const renderScenarioProposal = (key: string) => {
    const typedKey = key as keyof ScenarioProposalValues;

    return (
      <div key={key} className='flex items-center justify-between rounded-lg px-3 py-2 even:bg-slate-100'>
        <p>{mapCampaignProposalTitles[typedKey]}</p>
        <p>{parsedScenarioProposal[typedKey]}</p>
      </div>
    );
  };

  return (
    <div className={cn('w-1/3 rounded-2xl bg-slate-200 leading-6 shadow-lg', readonly && 'bg-slate-200', scenario.is_approved && 'bg-sky-600')}>
      <section className={cn('flex flex-col gap-4 px-8 pb-6 pt-10', readonly && 'text-slate-800', scenario.is_approved && 'text-white')}>
        <h2 className='font-bold'>{scenario.title}</h2>
        {showROI && (
          <div>
            <p>Projected ROI</p>
            <section className='grid grid-cols-2 gap-y-2 pt-2 leading-6'>{Object.keys(parsedProjectedROI || {}).map(renderROI)}</section>
          </div>
        )}
      </section>

      <section className={cn('w-full rounded-2xl bg-white px-3', !readonly && 'pb-6')}>
        <div className='py-4'>{Object.keys(parsedScenarioProposal).map(renderScenarioProposal)}</div>
        {!readonly && (
          <div className='px-3'>
            <Button className='w-full' onClick={() => onSelect(scenario.id)} variant={scenario.is_approved ? 'outline-light' : 'primary'}>
              {scenario.is_approved ? 'Approved' : 'Approve'}
            </Button>
          </div>
        )}
      </section>
    </div>
  );
};

export default ScenarioReviewCard;

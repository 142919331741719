import { Modal, ModalProps } from '@/components';
import { FC, Fragment, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import NewPaymentMethodForm from './NewPaymentMethodForm';
import PaymentMethodList from './PaymentMethodList';
import { useGetPaymentMethodsQuery } from '@/store/payment/payment.api';

interface PaymentMethodModalProps extends ModalProps, PropsWithChildren {
  onMethodSelected: (methodId: string) => void;
}

const PaymentMethodModal: FC<PaymentMethodModalProps> = ({ errorMessage, isOpen, onClose, onMethodSelected, children, confirmButtonText = 'Save Changes', isLoading }) => {
  const { data: paymentMethods, isLoading: loadingPaymentMethods, isFetching } = useGetPaymentMethodsQuery();

  const defaultPaymentMethod = useMemo(() => paymentMethods?.results.find((pm) => pm.default), [paymentMethods]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>(defaultPaymentMethod?.id || '');
  const [newMethod, setNewMethod] = useState(false);

  const handleDefaultMethod = useCallback(() => {
    selectedPaymentMethod && onMethodSelected(selectedPaymentMethod);
  }, [selectedPaymentMethod, onMethodSelected]);

  const handleChangePaymentMethod = (methodId: string) => setSelectedPaymentMethod(methodId);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title='Change Payment Method' className='w-modal' isLoading={isLoading || loadingPaymentMethods || isFetching}>
      {children}
      {!newMethod && (
        <Fragment>
          {errorMessage && <p className='py-2 text-center'>{errorMessage}</p>}
          <PaymentMethodList
            paymentMethods={paymentMethods?.results || []}
            onAddMethod={() => setNewMethod(true)}
            value={selectedPaymentMethod}
            onChange={handleChangePaymentMethod}
            onClose={onClose}
            onConfirm={handleDefaultMethod}
            confirmButtonText={confirmButtonText}
          />
        </Fragment>
      )}

      {newMethod && <NewPaymentMethodForm onClose={() => setNewMethod(false)} onConfirm={() => setNewMethod(false)} />}
    </Modal>
  );
};

export default PaymentMethodModal;

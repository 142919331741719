import { Skeleton } from '@/components';
import { FC } from 'react';

const UsersPageSkeleton: FC = () => {
  return (
    <div className='w-full'>
      <div className='mb-4 flex w-full justify-between '>
        <Skeleton className='h-8 w-1/4 animate-pulse' />
        <div className='flex w-full justify-end gap-2'>
          <Skeleton className='h-8 w-1/4 animate-pulse' />
          <Skeleton className='h-8 w-[12%] animate-pulse' />
        </div>
      </div>
      <div className='mb-6 flex w-full justify-between gap-6 overflow-auto'>
        <Skeleton className='h-44 w-1/2 animate-pulse' />
        <Skeleton className='h-44 w-1/2 animate-pulse' />
      </div>
      <div className='mb-6 flex w-full justify-between gap-6 overflow-auto '>
        <Skeleton className='h-44 w-1/2 animate-pulse' />
        <Skeleton className='h-44 w-1/2 animate-pulse' />
      </div>
      <div className='flex w-full justify-between gap-6 overflow-auto '>
        <Skeleton className='h-44 w-1/2 animate-pulse' />
        <Skeleton className='h-44 w-1/2 animate-pulse' />
      </div>
    </div>
  );
};

export default UsersPageSkeleton;

import { VirtualizedTable } from '../Table';
import { UseTableOptions, useTable } from '@/hooks/table';
import { useMemo } from 'react';
import getKeywordsColumns from './keywordColumns';
import { VirtualizedTableProps } from '../Table/VirtualizedTable';

interface RefineKeywordsTableProps extends Omit<VirtualizedTableProps<RefineCampaignKeyword>, 'name' | 'table'> {
  data: RefineCampaignKeyword[];
  tableOptions: UseTableOptions<RefineCampaignKeyword>;
  filterFn?: (keyword: RefineCampaignKeyword) => boolean;
}

const RefineKeywordsTable = ({ tableOptions, data, ...tableProps }: RefineKeywordsTableProps) => {
  const columns = useMemo(() => getKeywordsColumns(), []);

  const [table] = useTable({
    data,
    columns,
    ...tableOptions,
  });

  return <VirtualizedTable name='keywords' table={table} {...tableProps} />;
};

export default RefineKeywordsTable;

import useTutorialContext from '@/hooks/tutorial';
import { FC, useCallback, useEffect } from 'react';
import ReactJoyride, { ACTIONS, CallBackProps, EVENTS, LIFECYCLE, Step } from 'react-joyride';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TutorialTooltip } from './components';
import { getTutorialComplete, setTutorialComplete } from '@/utils/localstorage';
import { useGetCurrentUserQuery } from '@/store/users/users.api';

const STEPS_LENGTH = 5;
const CAMPAIGN_TUTORIAL_CONTENT =
  'This page is your central hub for managing advertising campaigns. You can create new campaigns, assign labels for easy organization, and filter campaigns by type and status. Keep track of your budgets, monitor the progress of campaigns, and review key statistics for each campaign, such as fetch status and run status. Customize your campaign strategy with options for URLs, keyword combinations, and more. Start optimizing your advertising efforts with precision and ease.';
const COLLABORATOR_TUTORIAL_CONTENT = 'Control user access efficiently. Set view/edit permissions, invite collaborators, and confirm settings for seamless teamwork.';
const USER_MANAGEMENT_TUTORIAL_CONTENT =
  "This page provides you with powerful tools for managing users and companies within your organization. You can easily create new company profiles and add new users. Sort and filter users by various criteria, including first name and company name, for efficient user management. Each user's role is clearly defined, whether they are a member or owner of a company. Keep track of user details, including email addresses, and ensure smooth company administration. Simplify the process of organizing and maintaining your user base with this comprehensive user management platform.";
const NEW_COMPANY_TUTORIAL_CONTENT = 'Create a new company. Enter the company name and proceed.';
const NEW_USER_TUTORIAL_CONTENT =
  "Register a new user and grant them access. Enter the user's email (e.g., warren.moore@example.com) and select their company (e.g., Test & Co). You can send them an invitation to establish their account";

const initialSteps: Array<Step> = [
  {
    target: '#tutorial-sidebar-1',
    title: 'Campaigns',
    content: CAMPAIGN_TUTORIAL_CONTENT,
    placement: 'right-start',
    styles: {
      spotlight: {
        borderRadius: '50%',
      },
    },
    spotlightPadding: 20,
    disableBeacon: true,
  },
  {
    target: '#tutorial-cards-1',
    title: 'Collaborator Access',
    content: COLLABORATOR_TUTORIAL_CONTENT,
    styles: {
      spotlight: {
        borderRadius: '50%',
      },
    },
    spotlightPadding: 20,
    placement: 'left-start',
    disableBeacon: true,
  },
  {
    target: '#tutorial-sidebar-2',
    title: 'User Management',
    content: USER_MANAGEMENT_TUTORIAL_CONTENT,
    styles: {
      spotlight: {
        borderRadius: '50%',
      },
    },
    spotlightPadding: 20,
    placement: 'right-start',
    disableBeacon: true,
  },
  {
    target: '#tutorial-new-company',
    title: 'New Company',
    content: NEW_COMPANY_TUTORIAL_CONTENT,
    placement: 'bottom-end',
    disableBeacon: true,
  },
  {
    target: '#tutorial-new-user',
    title: 'New User',
    content: NEW_USER_TUTORIAL_CONTENT,
    placement: 'bottom-end',
    disableBeacon: true,
  },
];

const Tutorial: FC = () => {
  const [searchParams] = useSearchParams();
  const { tutorialState, updateTutorialState } = useTutorialContext();
  const navigate = useNavigate();
  const tutorialComplete = getTutorialComplete();
  const { data: user } = useGetCurrentUserQuery(undefined, { skip: searchParams.has('vt') });

  useEffect(() => {
    updateTutorialState({ steps: initialSteps });
  }, [updateTutorialState]);

  const handleStepAfterActions = useCallback(
    ({ action, step }: { action: string; step: number }) => {
      if (action === ACTIONS.NEXT) {
        const isSecondStep = step === 1;

        updateTutorialState({ stepIndex: step + 1, run: !isSecondStep });
        isSecondStep && navigate('/users');
        return;
      }

      if (action === ACTIONS.PREV) {
        const isThirdStep = step === 2;
        isThirdStep && navigate('/');
        updateTutorialState({ stepIndex: step - 1, run: !isThirdStep });
        return;
      }
    },
    [navigate, updateTutorialState],
  );

  const handleCallback = (data: CallBackProps) => {
    const { action, index, lifecycle, type } = data;

    if ((action === ACTIONS.NEXT && type === EVENTS.TOUR_END) || action === ACTIONS.CLOSE) {
      updateTutorialState({ run: false, stepIndex: 0, tourActive: false });
      setTutorialComplete(true);
      return;
    }

    if (type === EVENTS.STEP_AFTER) {
      handleStepAfterActions({ action: action, step: index });
      return;
    }

    // RESET
    if (action === ACTIONS.RESET || lifecycle === LIFECYCLE.COMPLETE) {
      updateTutorialState({ run: false, stepIndex: 0, tourActive: false });
    }
  };

  const hideWalkthrough = user && user.company && !user.company.config.walkthrough_visibility;

  return (
    <ReactJoyride
      steps={tutorialState.steps}
      continuous
      run={true || tutorialComplete || hideWalkthrough ? false : tutorialState.run}
      stepIndex={tutorialState.stepIndex}
      callback={handleCallback}
      tooltipComponent={(props) => <TutorialTooltip {...props} stepsCount={STEPS_LENGTH} />}
      disableOverlayClose
    />
  );
};

export default Tutorial;

import { SVGProps } from 'react';

const TaskIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width='28' height='29' viewBox='0 0 28 29' fill='currentColor' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M14 0.5C6.28 0.5 0 6.78 0 14.5C0 22.22 6.28 28.5 14 28.5C21.72 28.5 28 22.22 28 14.5C28 6.78 21.72 0.5 14 0.5ZM14 24.5C8.49 24.5 4 20.01 4 14.5C4 8.99 8.49 4.5 14 4.5C19.51 4.5 24 8.99 24 14.5C24 20.01 19.51 24.5 14 24.5Z'
        fill='#0284C7'
      />
      <path
        d='M14 6.5C9.59 6.5 6 10.09 6 14.5C6 18.91 9.59 22.5 14 22.5C18.41 22.5 22 18.91 22 14.5C22 10.09 18.41 6.5 14 6.5ZM18.24 15.77L17.09 16.4C16.59 16.67 16.18 17.08 15.9 17.59L15.27 18.74C15.01 19.2 14.53 19.49 14 19.49C13.47 19.49 12.99 19.2 12.73 18.74L12.1 17.59C11.82 17.08 11.41 16.67 10.91 16.4L9.76 15.77C9.29 15.52 9 15.03 9 14.5C9 13.97 9.29 13.48 9.76 13.23L10.91 12.6C11.41 12.33 11.82 11.92 12.1 11.41L12.73 10.26C12.99 9.8 13.47 9.51 14 9.51C14.53 9.51 15.01 9.8 15.27 10.26L15.9 11.41C16.18 11.91 16.59 12.33 17.09 12.6L18.24 13.23C18.71 13.48 19 13.97 19 14.5C19 15.03 18.71 15.52 18.24 15.77Z'
        fill='#0284C7'
      />
    </svg>
  );
};

export default TaskIcon;

import { Skeleton } from '@/components';
import { PageSkeleton } from '@/layouts/Page';
import { FC } from 'react';

const ScenarioReviewPageSkeleton: FC = () => (
  <PageSkeleton>
    <div className='flex flex-nowrap items-center justify-between pb-8'>
      <Skeleton className='h-10 w-64' />
      <Skeleton className='h-10 w-48' />
    </div>
    <div className='flex flex-nowrap items-center justify-between pb-8'>
      <Skeleton className='h-10 w-96' />
      <Skeleton className='h-10 w-64' />
    </div>

    <div className='flex flex-nowrap items-center gap-3'>
      <Skeleton className='h-[700px] w-1/3' />
      <Skeleton className='h-[700px] w-1/3' />
      <Skeleton className='h-[700px] w-1/3' />
    </div>
  </PageSkeleton>
);

export default ScenarioReviewPageSkeleton;

import { ArchiveIcon, DuplicateIcon, Menu } from '@/components';
import { EllipsisVerticalIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/solid';
import { FC, useMemo } from 'react';

type CampaignCardOptionsActions = {
  onEdit: () => void;
  onArchive: () => void;
  onRemove: () => void;
  onDuplicate: () => void;
};

interface CampaignCardOptionsProps {
  actions: CampaignCardOptionsActions;
  isArchived: boolean;
}

const CampaignCardOptions: FC<CampaignCardOptionsProps> = ({ actions, isArchived }) => {
  const { onArchive, onEdit, onRemove, onDuplicate } = actions;
  const menuItems = useMemo(() => {
    return [
      {
        label: 'Edit name',
        onClick: onEdit,
        icon: <PencilIcon className='size-6 text-[#64748B]' />,
      },
      {
        label: 'Duplicate',
        onClick: onDuplicate,
        icon: <DuplicateIcon className='size-6 text-[#64748B]' />,
      },
      {
        label: isArchived ? 'Unarchive' : 'Archive',
        onClick: onArchive,
        icon: <ArchiveIcon />,
      },
      {
        label: 'Remove',
        onClick: onRemove,
        className: 'text-red-500',
        icon: <TrashIcon className='size-6 text-red-500' />,
      },
    ];
  }, [onArchive, onEdit, onRemove, onDuplicate, isArchived]);

  return (
    <Menu items={menuItems} direction='right' menuIcon={<EllipsisVerticalIcon className='size-8' />} containerClassName='z-20 w-fit text-sm font-semibold font-inter left-0 mt-0' />
  );
};

export default CampaignCardOptions;

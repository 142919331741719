import { STRIPE_PUBLISHABLE_KEY } from '@/constants/application';
import { Page } from '@/layouts';
import { setNavBarConfig } from '@/store/app/app.slice';
import { selectAuthUser } from '@/store/auth/auth.selector';
import { AppDispatch } from '@/store/store';
import cn from '@/utils/style';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const stripeAppearance = {
  variables: {
    borderRadius: '8px',
    spacingGridRow: '16px',
  },
};

const SettingsPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [selectedTab, setSelectedTab] = useState<'general' | 'payment'>('general');
  const navigate = useNavigate();
  const authUser = useSelector(selectAuthUser);

  const handleGeneralSettingsClick = useCallback(() => {
    navigate('general');
    setSelectedTab('general');
  }, [navigate]);

  const handlePaymentSettingsClick = useCallback(() => {
    navigate('payment');
    setSelectedTab('payment');
  }, [navigate]);

  useEffect(() => {
    if (authUser?.isSuperuser) {
      return;
    }

    if (!(authUser?.config.show_settings || authUser?.config.show_payments)) {
      navigate('/');
      return;
    }

    if (authUser?.config.show_settings && !authUser?.config.show_payments) {
      navigate('general');
      setSelectedTab('general');
      return;
    }

    if (!authUser?.config.show_settings && authUser?.config.show_payments) {
      navigate('payment');
      setSelectedTab('payment');
      return;
    }
  }, [navigate, authUser]);

  useEffect(() => {
    dispatch(setNavBarConfig({ title: 'Settings', analysisStep: undefined, back: undefined }));
  }, [dispatch]);

  return (
    <Page>
      <Page.TopBar title='Settings' className='flex items-center'>
        <div className='flex items-center whitespace-nowrap rounded-lg bg-white p-1'>
          {authUser?.config.show_settings ||
            (authUser?.isSuperuser && (
              <button onClick={handleGeneralSettingsClick} className={cn('px-6 py-2 font-medium text-slate-400', selectedTab === 'general' && 'rounded-lg bg-sky-600 text-white')}>
                Basic Info
              </button>
            ))}
          {authUser?.config.show_payments ||
            (authUser?.isSuperuser && (
              <button
                disabled
                onClick={() => false && handlePaymentSettingsClick()}
                className={cn('px-6 py-2 font-medium text-slate-400', selectedTab === 'payment' && 'rounded-lg bg-sky-600 text-white')}
              >
                Payment
              </button>
            ))}
        </div>
      </Page.TopBar>
      <Page.Body>
        <Elements stripe={stripePromise} options={{ appearance: stripeAppearance }}>
          <Outlet />
        </Elements>
      </Page.Body>
    </Page>
  );
};

export default SettingsPage;

import { Input, LoadingScreen, Tooltip } from '@/components';
import { useGetCampaignQuery, useRegenerateAnchorTextMutation, useUpdateUrlAnchorTextsMutation } from '@/store/campaign/campaign.api';
import cn from '@/utils/style';
import { LockClosedIcon, LockOpenIcon } from '@heroicons/react/24/solid';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

interface AnchorTextCardProps {
  anchorText: UrlAnchorText;
  idx: number;
  updateUrlAnchorText: (data: UrlAnchorText) => void;
}

const AnchorTextCard: FC<AnchorTextCardProps> = ({ anchorText, idx, updateUrlAnchorText }) => {
  const { campaignId } = useParams() as { campaignId: string };

  const { data: campaign } = useGetCampaignQuery({ campaignId });
  const [updateAnchorText, { isLoading: isUpdating }] = useUpdateUrlAnchorTextsMutation();
  const [regenerateAnchorText, { isLoading: isRegenerating }] = useRegenerateAnchorTextMutation();

  const [anchorTextValue, setAnchorTextValue] = useState('');
  const [isRegenerateDisabled, setIsGenerateDisabled] = useState(anchorText.status === 'locked');

  const campaignStatus = useMemo(() => campaign?.status, [campaign]);

  useEffect(() => {
    if (campaignStatus === 'kicked_off') {
      setIsGenerateDisabled(true);
    }
  }, [campaignStatus, isRegenerateDisabled]);

  useEffect(() => {
    setAnchorTextValue(anchorText.value);
  }, [anchorText.value]);

  const handleToggleStatus = useCallback(async () => {
    const updatedAnchorText = await updateAnchorText({
      campaign_id: Number(campaignId),
      url: anchorText.url,
      anchor_text: {
        id: anchorText.id,
        status: anchorText.status === 'created' ? 'locked' : 'created',
        value: anchorTextValue,
        source: anchorTextValue !== anchorText.value ? 'user' : 'openai',
      },
    }).unwrap();

    setIsGenerateDisabled(updatedAnchorText.data.status === 'locked');
    updateUrlAnchorText(updatedAnchorText.data);
  }, [anchorText, campaignId, updateAnchorText, updateUrlAnchorText, anchorTextValue]);

  const handleRefreshAnchorText = useCallback(async () => {
    const refreshedAnchorText = await regenerateAnchorText({
      campaign_id: Number(campaignId),
      url: anchorText.url,
      anchor_text_id: anchorText.id,
      generated_anchor_text: anchorText.value,
    }).unwrap();

    updateUrlAnchorText({
      ...refreshedAnchorText.data,
      id: anchorText.id,
    });
  }, [regenerateAnchorText, campaignId, anchorText, updateUrlAnchorText]);

  const handleBlur = async () => {
    if (anchorTextValue === anchorText.value) {
      return;
    }

    const updatedAnchorText = await updateAnchorText({
      campaign_id: Number(campaignId),
      url: anchorText.url,
      anchor_text: {
        id: anchorText.id,
        value: anchorTextValue,
        source: anchorTextValue !== anchorText.value ? 'user' : 'openai',
      },
    }).unwrap();

    updateUrlAnchorText(updatedAnchorText.data);
  };

  const tooltipContent =
    (campaignStatus === 'kicked_off' && <p className='font-normal text-gray-500'>Cannot refresh while the campaign is kicked off</p>) ||
    (isRegenerateDisabled && <p className='font-normal text-gray-500'>Unlock the Anchor Text before Refreshing it</p>) ||
    (anchorText.match_type === 'exact' && <p className='font-normal text-gray-500'>Cannot refresh an exact match Anchor Text</p>);

  return (
    <div className='relative flex w-full flex-col flex-nowrap gap-2 px-4 py-2 even:bg-gray-100'>
      {isRegenerating && (
        <div className='absolute inset-0 z-10 flex h-full w-full items-center justify-center bg-gray-300 opacity-80'>
          <LoadingScreen containerClassName='p-0' />
        </div>
      )}
      <div className='flex flex-nowrap items-center justify-between '>
        <p className='font-bold'>Anchor Text {idx + 1}</p>
        {anchorText.status === 'locked' ? (
          <LockClosedIcon
            className={cn('w-4', campaignStatus === 'kicked_off' ? ' cursor-not-allowed text-gray-500' : 'cursor-pointer text-sky-500')}
            onClick={campaignStatus !== 'kicked_off' ? handleToggleStatus : undefined}
          />
        ) : (
          <LockOpenIcon
            className={cn('w-4', campaignStatus === 'kicked_off' ? ' cursor-not-allowed text-gray-500' : 'cursor-pointer text-sky-500')}
            onClick={campaignStatus !== 'kicked_off' ? handleToggleStatus : undefined}
          />
        )}
      </div>

      <div className='flex flex-nowrap items-start gap-8'>
        <div className='flex flex-col flex-nowrap items-start text-sm'>
          <p>Keyword</p>
          <p>Type</p>
        </div>

        <div className='flex flex-col flex-nowrap items-start text-sm'>
          <p className='font-bold'>{anchorText.keyword_text || anchorText.value}</p>
          <p className='font-bold'>{anchorText.match_type}</p>
        </div>
      </div>

      <Input
        value={anchorTextValue}
        onChange={(val) => setAnchorTextValue(val)}
        onBlur={handleBlur}
        disabled={isRegenerateDisabled || isUpdating}
        onKeyUp={(key) => key === 'Enter' && handleBlur()}
      />

      <Tooltip id={`regenerate-${anchorText.id}`} place='top' content={tooltipContent || null}>
        <p
          className={cn(
            'cursor-pointer text-left text-sm text-sky-500',
            (isRegenerateDisabled || anchorText.match_type === 'exact' || campaignStatus === 'kicked_off') && 'cursor-not-allowed text-gray-500',
          )}
          onClick={isRegenerateDisabled || anchorText.match_type === 'exact' || campaignStatus === 'kicked_off' ? undefined : handleRefreshAnchorText}
        >
          Refresh
        </p>
      </Tooltip>
    </div>
  );
};

export default AnchorTextCard;

import cn from '@/utils/style';
import { ArrowSmallUpIcon } from '@heroicons/react/20/solid';
import { FC, memo } from 'react';

interface TrendCellProps {
  value: number;
  className?: string;
}

const TrendCell: FC<TrendCellProps> = ({ value, className }) => {
  return (
    <div className={cn('flex items-center justify-end gap-px', className)}>
      <ArrowSmallUpIcon className={cn('w-6', value >= 0 ? 'text-green-600' : 'rotate-180 text-red-600')} />
      <p className={cn(value >= 0 ? 'text-green-600' : 'text-red-600')}>{Math.abs(value).toLocaleString()}</p>
    </div>
  );
};

export default memo(TrendCell);

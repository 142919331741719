import { Button, Modal } from '@/components';
import { FC } from 'react';

interface StartOverModalProps {
  isOpen: boolean;
  onClose: () => void;
  onStartOver: () => void;
  confirmLoading?: boolean;
}

const StartOverModal: FC<StartOverModalProps> = ({ isOpen, onClose, onStartOver, confirmLoading = false }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} title=''>
      <div className='mb-4 flex w-[450px] flex-col items-center gap-4'>
        <h4 className='text-center'>Are you sure you want to start over?</h4>
        <p className='text-center'>By starting over, you will lose any unlocked anchor text entries.</p>
        <Button className='w-full' isLoading={confirmLoading} onClick={onStartOver}>
          Confirm
        </Button>
        <Button className='w-full' variant='outline-light' onClick={onClose}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default StartOverModal;

import { Modal, ModalProps, PagesIncluded } from '@/components';
import { FC } from 'react';

const PagesIncludedModal: FC<ModalProps> = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} title='Pages Included' className='max-w-modal-xl'>
      <div className='overflow-scroll'>
        <PagesIncluded showScenariosNames={false} />
      </div>
    </Modal>
  );
};

export default PagesIncludedModal;

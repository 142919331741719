import { Button, Modal, ModalProps } from '@/components';
import { FC } from 'react';

interface ScenarioChangeModalProps extends ModalProps {
  onOverride: () => void;
}

const ScenarioChangeWarningModal: FC<ScenarioChangeModalProps> = ({ isOpen, onOverride, onClose }) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen} title=''>
      <div className='mb-4 flex w-[450px] flex-col items-center gap-4'>
        <h4 className='text-center'>Are you sure you want to proceed with a different scenario?</h4>
        <p className='text-center'>If you do, all of your anchor text progress will be lost. This action cannot be undone.</p>
        <Button className='w-full' onClick={onOverride}>
          Continue and Override
        </Button>
        <Button className='w-full' onClick={onClose}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default ScenarioChangeWarningModal;

import { Button, Loading, Modal, ModalProps } from '@/components';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { FC } from 'react';

type GeneratingAnchorTextsModalProps = ModalProps & {
  onRegenerate: () => void;
};

const GeneratingAnchorTextsModal: FC<GeneratingAnchorTextsModalProps> = ({ isOpen, onClose, errorMessage, onRegenerate }) => {
  return (
    <Modal isOpen={isOpen} title='Saving configurations...' onClose={errorMessage ? onClose : undefined}>
      {errorMessage ? (
        <div className='flex w-full flex-col items-center'>
          <div className='flex max-w-lg flex-col items-center gap-4'>
            <ExclamationCircleIcon className='w-16 text-red-500' />
            <p>{errorMessage}</p>
          </div>
          <Button className='mx-auto mt-5' onClick={onRegenerate}>
            Regenerate
          </Button>
        </div>
      ) : (
        <div className='flex flex-col items-center gap-4'>
          <Loading />
          <p>You will be redirected soon</p>
        </div>
      )}
    </Modal>
  );
};

export default GeneratingAnchorTextsModal;

import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { FC, PropsWithChildren } from 'react';
import Tooltip from '../Tooltip';
import cn from '@/utils/style';

interface WarningMessageProps extends PropsWithChildren {
  className?: string;
  message?: string;
  tooltip?: {
    content: string;
    id: string;
  };
}

interface ContentProps {
  className?: string;
  message?: string;
}

const Content: FC<ContentProps> = ({ className, message }) => {
  return (
    <div className={cn('ml-1 flex items-center gap-1', className)}>
      <ExclamationTriangleIcon className='h-6 w-6 p-px text-orange-500' />
      {message && <p className='whitespace-normal text-orange-500 first-letter:capitalize'>{message}</p>}
    </div>
  );
};

const WarningMessage: FC<WarningMessageProps> = ({ message, className, tooltip }) => {
  if (!tooltip) {
    return <Content className={className} message={message} />;
  }

  return (
    <Tooltip id={tooltip.id} place='top' content={<p>{tooltip.content}</p>} className='max-w-[250px]'>
      <Content className={className} message={message} />
    </Tooltip>
  );
};

export default WarningMessage;

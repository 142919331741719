import { KeywordsModal } from '@/pages/Campaigns/pages/AnalysisPage/components';
import cn from '@/utils/style';
import { FC, useMemo, useState } from 'react';

interface KeywordsSummaryProps {
  scenario: Scenario;
  keywords: Keyword[];
}

interface KeywordData {
  priorityKeywords: number;
  monthlySearchVolume: number;
}

interface FormattedKeywordData {
  priorityKeywords: string;
  monthlySearchVolume: string;
}

const CARD_TITLES: Record<keyof KeywordData, string> = {
  priorityKeywords: 'Priority Keywords',
  monthlySearchVolume: 'Monthly Search Volume',
};

const KeywordsSummary: FC<KeywordsSummaryProps> = ({ scenario, keywords }) => {
  const [isPriorityKeywordsModalOpen, setIsPriorityKeywordsModalOpen] = useState(false);
  const keywordData = useMemo<FormattedKeywordData>(() => {
    const priorityNotExcludedKeywords = (keywords || []).filter((keyword) => keyword.priority !== 0 && !keyword.excluded);
    const totalVolumeSum = scenario.urls.reduce((acc, url) => {
      return acc + url.total_volume;
    }, 0);

    return {
      priorityKeywords: priorityNotExcludedKeywords.length.toLocaleString(),
      monthlySearchVolume: Math.round(totalVolumeSum / scenario.proj_length).toLocaleString(),
    };
  }, [keywords, scenario]);

  const handlePriorityKeywords = () => {
    setIsPriorityKeywordsModalOpen(true);
  };

  const renderFields = (key: string, idx: number) => {
    const isPriorityKeywords = key === 'priorityKeywords';
    const typedKey = key as keyof KeywordData;

    return (
      <div
        key={`${key}-${idx}`}
        className='flex items-center justify-between border-b border-b-slate-300 py-1 text-sm leading-6 last-of-type:border-b-transparent last-of-type:py-0'
      >
        <button
          className={cn('text-slate-400', isPriorityKeywords && 'cursor-pointer text-sky-600 hover:underline')}
          onClick={isPriorityKeywords ? handlePriorityKeywords : undefined}
        >
          {CARD_TITLES[typedKey]}
        </button>
        <b className='whitespace-nowrap'>{keywordData[typedKey]}</b>
      </div>
    );
  };

  return (
    <div className='flex w-full flex-col gap-5 rounded-leap bg-white p-5 text-left text-gray-800 shadow-md'>
      <p className='text-xl leading-8'>Keyword Parameters</p>
      <div className='flex flex-col gap-1'>{Object.keys(CARD_TITLES).map(renderFields)}</div>
      {isPriorityKeywordsModalOpen && (
        <KeywordsModal
          isOpen={isPriorityKeywordsModalOpen}
          onClose={() => setIsPriorityKeywordsModalOpen(false)}
          onConfirm={() => setIsPriorityKeywordsModalOpen(false)}
          readonly
        />
      )}
    </div>
  );
};

export default KeywordsSummary;
